import React from "react";
import Paper from "@mui/material/Paper";
import List from "./List";
import CustomHooks from "../../../../../@components/hooks";
import { useParams } from "react-router-dom";
import useService from "../../useService";
import confettiAnimation from "../../../../../static/confetti-animation.json";
import confettiAnimation2 from "../../../../../static/confetti2.json";
import Lottie from "lottie-react";

const Index = ({ DAO, onFilterCollections }) => {
  const { albumId } = useParams();
  const service = useService(DAO?.id);
  const confettiRef = React.useRef(null);
  const confettiRef2 = React.useRef(null);

  const { data: cards, refetch: refetchCards } = CustomHooks.useFetch(
    ["fetch_cards"],
    () => service.cards(albumId)
  );

  const { mutate: submitCard } = CustomHooks.usePost(service.submitCard, {
    onSuccess: async () => {
      triggerConfetti();
      await refetchCards(albumId);
    },
  });

  const triggerConfetti = () => {
    confettiRef.current?.goToAndPlay(0);
    confettiRef2.current?.goToAndPlay(0);
  };

  const handleDrop = React.useCallback(
    (index, data) => {
      if (data.spaceItem.album_card.length === 0) {
        submitCard({
          album_id: albumId,
          album_space_type: "Collection",
          space_id: data.spaceItem.id,
          nft_id: data.droppedItem.nft.id,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cards]
  );

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
          px: { md: "30px", mobile: "15px" },
          py: { md: "50px", mobile: "40px" },
        }}
      >
        {cards && (
          <List
            refetch={() => refetchCards(albumId)}
            DAO={DAO}
            items={cards}
            handleDrop={handleDrop}
            onFilterCollections={onFilterCollections}
          />
        )}
      </Paper>
      <Lottie
        lottieRef={confettiRef}
        autoplay={false}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          pointerEvents: "none",
        }}
        resizeMode="cover"
        animationData={confettiAnimation}
      />
      <Lottie
        lottieRef={confettiRef2}
        autoplay={false}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          pointerEvents: "none",
        }}
        resizeMode="cover"
        animationData={confettiAnimation2}
      />
    </>
  );
};

export default Index;
