import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Transactions from "./transactions";
import UI from "../../../@components/UI";
import truncateEthAddress from "truncate-eth-address";
import { useAccount } from "wagmi";
import { useHistory, useLocation } from "react-router-dom";
import Tokens from "./tokens";
import useService from "./useService";
import useTokensService from "./tokens/useService";
import { ethers } from "ethers";
import erc20Artifact from "../../../abis/contracts/modules/token/ERC20Token.sol/ERC20Token.json";
import treasuryArtifact from "../../../abis/contracts/modules/treasury/Treasury.sol/Treasury.json";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import { useUserContext } from "../../../@components/userContext";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useUserRole } from "../context";
import CustomHooks from "../../../@components/hooks";
import customHooks from "../../../@components/hooks";
import { useTranslation } from "react-i18next";

const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const { isBrandMember } = useUserRole();
  const history = useHistory();
  const { handleApiError } = useHandleErrors();
  const { pathname } = useLocation();
  const service = useService(DAO?.id);
  const tokensService = useTokensService(DAO?.id);
  const provider = customHooks.useEthersProvider();
  const { isConnected } = useAccount();
  const [availableTokens, setAvailableTokens] = React.useState([]);

  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  const getTokenBalance = async (token) => {
    if (token.token_address) {
      const tokenContract = new ethers.Contract(
        token.token_address,
        erc20Artifact.abi,
        provider
      );
      try {
        const balance = await tokenContract.balanceOf(
          DAO?.treasury_contract?.address
        );

        return ethers.formatUnits(balance, token.decimals).toString();
      } catch (error) {
        //handleMetamaskError(error);
        return ethers.formatUnits(0, token.decimals).toString();
      }
    } else {
      return 0;
    }
  };

  const setTokens = async (data) => {
    const tokens = await Promise.all(
      data.map(async (token) => {
        return {
          id: token.id,
          logo: token.icon_url,
          symbol: token.token_symbol,
          tokenType: token.token_type,
          isDefaultToken: token.is_default_token,
          isDaoToken: token.is_dao_token,
          balance: await getTokenBalance(token),
        };
      })
    );

    const treasury = new ethers.Contract(
      DAO.treasury_contract.address,
      treasuryArtifact.abi,
      provider
    );

    const treasuryNativeBalance = await treasury.getBalance();

    const updatedTokens = tokens.map((token) => {
      if (token.tokenType === "NATIVE") {
        token.balance = ethers.formatEther(treasuryNativeBalance);
      }
      return token;
    });

    setAvailableTokens(updatedTokens);
  };

  React.useEffect(() => {
    DAO && fetchTreasuryData();

    // eslint-disable-next-line
  }, [DAO]);

  const removeToken = (tokenId) => {
    tokensService
      .remove(tokenId)
      .then(() => {
        fetchTreasuryData();
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const fetchTreasuryData = async () => {
    await service
      .info()
      .then((response) => {
        setTokens(response.data.tokens);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  return (
    <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          px: { md: "0px", mobile: "15px" },
          pb: { md: "25px", mobile: "15px" },
          pt: { md: "40px", mobile: "40px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
          }}
        >
          <Typography
            variant={"h2"}
            sx={{ lineHeight: { md: "44px", mobile: "30px" } }}
          >
            {t("brands.treasury.title")}
          </Typography>
          <Box
            sx={{
              ml: { md: "15px", mobile: "0px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <UI.CopyToClipboard
              label={truncateEthAddress(DAO?.treasury_contract?.address || "")}
              value={DAO?.treasury_contract?.address}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: { md: "none", mobile: "flex" },
              flexDirection: { mobile: "column", md: "row" },
              mr: { md: "20px", mobile: "0px" },
            }}
          >
            <UI.VertMenu>
              {isBrandMember && (
                <MenuItem
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  onClick={() =>
                    history.push({
                      pathname: `${pathname}/tokens/new`,
                      state: { scrollToTop: false, daoId: DAO?.id },
                    })
                  }
                >
                  <ListItemIcon sx={{ mr: "10px" }}>
                    <AddCircleIcon sx={{ color: "#944DFF" }} />
                  </ListItemIcon>
                  {t("brands.treasury.addToken")}
                </MenuItem>
              )}
              {isBrandMember && (
                <MenuItem
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  onClick={() =>
                    history.push({
                      pathname: `${pathname}/withdraw`,
                      state: { scrollToTop: false },
                    })
                  }
                >
                  <ListItemIcon sx={{ mr: "10px" }}>
                    <CreditCardIcon sx={{ color: "#944DFF" }} />
                  </ListItemIcon>
                  {t("brands.treasury.withdraw")}
                </MenuItem>
              )}
            </UI.VertMenu>
          </Box>
          <Box sx={{ display: { md: "flex", mobile: "none" } }}>
            {isBrandMember && (
              <Box sx={{ width: "178px", mr: { md: "20px", mobile: "0px" } }}>
                <UI.Button
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  onClick={() =>
                    history.push({
                      pathname: `${pathname}/tokens/new`,
                      state: { scrollToTop: false, daoId: DAO?.id },
                    })
                  }
                  title={t("brands.treasury.addToken")}
                  type={"secondary"}
                />
              </Box>
            )}
            {isBrandMember && (
              <Box sx={{ width: "178px", ml: "10px" }}>
                <UI.Button
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  onClick={() =>
                    history.push({
                      pathname: `${pathname}/withdraw`,
                      state: { scrollToTop: false },
                    })
                  }
                  title={t("brands.treasury.withdraw")}
                  type={"primary"}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: { md: "0px 0px 20px", mobile: "0px 15px 30px" } }}>
        <Typography variant={"subtitle2"}>
          {t("brands.treasury.subtitle1")}
          <br></br>
          <br></br>
          {t("brands.treasury.subtitle2")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { md: "40px 0px", mobile: "30px 0px" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            mb: "30px",
          }}
        >
          <Tokens.Slider
            items={availableTokens}
            onRemove={(tokenId) => removeToken(tokenId)}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: { md: "0px 0px", mobile: "20px 15px" },
          }}
        >
          <Typography variant={"h2"}>
            {t("brands.treasury.transactions")}
          </Typography>
          <Box sx={{ mt: "20px" }}>
            <Transactions.List DAO={DAO} />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

Index.Tokens = Tokens;
Index.Transactions = Transactions;

export default Index;
