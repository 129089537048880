import React from "react";
import AlbumSkeletonList from "./AlbumSkeletonList";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import UI from "../../../@components/UI";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation } from "react-router-dom";
import useService from "./useService";
import CustomHooks from "../../../@components/hooks";

const Item = ({ item }) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Grid
      item
      mobile={12}
      md={4}
      lg={4}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
      onClick={() => {
        history.push({
          pathname: `${location.pathname}/${item.id}`,
          state: { scrollToTop: false },
        });
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "390px",
          height: "150px",
          borderRadius: "10px",
          border: "1px solid transparent",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "#944dff 0px 0px 5px 0px",
            border: "2px solid #944dff",
            transform: { md: "scale(1.02)", mobile: "none" },
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            transition: "all 0.3s ease-in-out",
            backgroundImage: `url(${item.avatar_url})`,
            borderRadius: (theme) => theme.borderRadius.primary,
            position: "relative",
            "::before": {
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "50%", // adjust this value as needed to control the gradient's height
              backgroundImage:
                "linear-gradient(rgba(8, 10, 25, 0.2), rgba(8, 10, 25, 0.5), rgba(8, 10, 25, 0.2))",
              zIndex: 1, // ensure it's below the Typography
              borderRadius: (theme) => theme.borderRadius.primary,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "2",
            }}
          >
            <Typography
              variant={"h8"}
              sx={{
                color: "#fff",
              }}
            >
              {item.name}
            </Typography>
            {item.is_hidden && (
              <Typography
                variant={"subtitle4"}
                sx={{ color: "#fff", opacity: "0.7", ml: "4px" }}
              >
                {"(" + t("commons.private") + ")"}
              </Typography>
            )}
          </Box>
        </Box>
        {item.is_hidden && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              inset: 0,
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              textAlign: "center",
              borderRadius: (theme) => theme.borderRadius.primary,
            }}
          ></Box>
        )}
      </Box>
    </Grid>
  );
};

const List = ({ DAO }) => {
  const albumService = useService(DAO?.id);

  const { data: items, isLoading: busy } = CustomHooks.useFetch(
    ["albums"],
    albumService.list
  );

  const { t } = useTranslation();
  return (
    <Grid container spacing={2} columns={12}>
      {busy && <AlbumSkeletonList />}
      {!busy &&
        items?.length > 0 &&
        items?.map((item, index) => (
          <Item item={item} key={"collection_" + index} />
        ))}
      {!busy && items && items.length === 0 && (
        <Grid
          item
          mobile={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <UI.NoResults text={t("brands.albums.notFound")} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default List;
