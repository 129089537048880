import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Social from "./social";
import Brands from "./brands";
import Collections from "./collections";
import Skeleton from "@mui/material/Skeleton";
import Container from "@mui/material/Container";
import useExternalURL from "../../@components/hooks/useExternalURL";
import UI from "../../@components/UI";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Index = ({ user }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [socialsVisible, setSocialsVisible] = React.useState(null);
  const { getURL } = useExternalURL();

  React.useEffect(() => {
    if (
      user?.discord ||
      user?.facebook ||
      user?.instagram ||
      user?.linkedin ||
      user?.reddit ||
      user?.telegram ||
      user?.twitter
    ) {
      setSocialsVisible(true);
    }
  }, [user]);

  return (
    <>
      <Box sx={{ padding: { md: "10px 70px", mobile: "0px" } }}>
        <Grid container>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{ display: user?.description ? "block" : "none" }}
          >
            <Typography
              sx={{
                mb: "20px",
                textAlign: "center",
              }}
              variant={"h3"}
            >
              {t("profile.home.aboutMe")}
            </Typography>

            {user ? (
              <Typography
                sx={{
                  mt: "0px",
                  textAlign: "center",
                  overflowWrap: "break-word",
                }}
                variant={"subtitle2"}
              >
                {user?.description}
              </Typography>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Skeleton
                  height={"30px"}
                  width={"100%"}
                  variant={"rectangular"}
                />
                <Skeleton
                  sx={{ mt: "10px" }}
                  height={"30px"}
                  width={"100%"}
                  variant={"rectangular"}
                />
                <Skeleton
                  height={"30px"}
                  width={"100%"}
                  variant={"rectangular"}
                />
                <Skeleton
                  sx={{ mt: "10px" }}
                  height={"30px"}
                  width={"100%"}
                  variant={"rectangular"}
                />
                <Skeleton
                  sx={{ mt: "10px" }}
                  height={"30px"}
                  width={"100%"}
                  variant={"rectangular"}
                />
              </Box>
            )}
          </Grid>
          {user ? (
            <Grid
              item
              mobile={12}
              md={12}
              sx={{
                display: socialsVisible ? "block" : "none",
                mt: { mobile: "30px", md: "44px" },
              }}
            >
              <Social user={user} />
            </Grid>
          ) : (
            <Container
              maxWidth={"md"}
              sx={{ textAlign: "center", mt: { mobile: "30px", md: "44px" } }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Skeleton
                  sx={{ mt: "10px", mx: "8px" }}
                  height={"35px"}
                  width={"35px"}
                  variant={"circular"}
                />
                <Skeleton
                  sx={{ mt: "10px", mx: "8px" }}
                  height={"35px"}
                  width={"35px"}
                  variant={"circular"}
                />
                <Skeleton
                  sx={{ mt: "10px", mx: "8px" }}
                  height={"35px"}
                  width={"35px"}
                  variant={"circular"}
                />
                <Skeleton
                  sx={{ mt: "10px", mx: "8px" }}
                  height={"35px"}
                  width={"35px"}
                  variant={"circular"}
                />
                <Skeleton
                  sx={{ mt: "10px", mx: "8px" }}
                  height={"35px"}
                  width={"35px"}
                  variant={"circular"}
                />
                <Skeleton
                  sx={{ mt: "10px", mx: "8px" }}
                  height={"35px"}
                  width={"35px"}
                  variant={"circular"}
                />
              </Box>
            </Container>
          )}
          {user ? (
            <Grid
              item
              mobile={12}
              md={12}
              sx={{
                display: user?.webpage_url ? "flex" : "none",
                justifyContent: "center",
                mt: "28px",
              }}
            >
              <Link
                target="_blank"
                href={getURL(user?.webpage_url || "")}
                underline="hover"
              >
                <Typography
                  sx={{ 
                    textAlign: "center",
                    textDecoration: "underline",
                    transition: "all 0.2s ease-in-out",
                    color: (theme) => theme.palette.text.link,
                      "&:hover": {
                        color: (theme) => theme.palette.text.linkHover,
                      },
                  }}
                  variant={"subtitle2"}
                >
                  {getURL(user?.webpage_url || "")}
                </Typography>
              </Link>
            </Grid>
          ) : (
            <Grid
              item
              mobile={12}
              md={12}
              sx={{
                mt: { mobile: "38px", md: "38px" },
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                sx={{ mt: "10px", mx: "8px" }}
                height={"35px"}
                width={"135px"}
                variant={"rectangular"}
              />
            </Grid>
          )}

          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              mt: { mobile: "38px", md: "80px" },
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { mobile: "center", md: "space-between" },
              }}
            >
              <Typography
                sx={{
                  mb: "30px",
                  ml: "6px",
                  textAlign: "center",
                }}
                variant={"h3"}
              >
                {t("profile.home.collectedCards")}
              </Typography>
              <Box
                sx={{
                  width: "200px",
                  mr: "6px",
                  mb: "6px",
                  height: "42px",
                  display: { md: "flex", mobile: "none" },
                }}
              >
                <UI.Button
                  type={"primary"}
                  title={t("profile.home.collectMoreButton")}
                  onClick={() =>
                    history.push({
                      pathname: `/collection/explore`,
                      state: { scrollToTop: true },
                    })
                  }
                />
              </Box>
            </Box>
            <Collections />
            <Box
              sx={{
                width: "240px",
                height: "42px",
                mt: "80px",
                mx: "auto",
                display: { md: "none", mobile: "flex" },
              }}
            >
              <UI.Button
                type={"primary"}
                title={t("profile.home.collectMoreButton")}
                onClick={() =>
                  history.push({
                    pathname: `/collection/explore`,
                    state: { scrollToTop: true },
                  })
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              mt: { mobile: "38px", md: "80px" },
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                mb: "20px",
                textAlign: "center",
              }}
              variant={"h3"}
            >
              {t("profile.home.myBrands")}
            </Typography>
            <Brands />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
