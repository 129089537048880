import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import MaterialDialog from "@mui/material/Dialog";
import { Controller, useForm, useWatch } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import { useFormDataContext } from "../context";
import { useBlockContext } from "../../../../@components/contextAPI";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },

  inputOuter: {
    marginTop: "6px",
    marginBottom: "0px",
    display: "flex",
    flexDirection: "column",
  },
}));

const GovernanceDialog = ({ open, onClose, onSave }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { formData } = useFormDataContext();
  const [isLoadingVotingDelayAvgTime, setIsLoadingVotingDelayAvgTime] =
    React.useState(false);
  const [isLoadingVotingPeriodAvgTime, setIsLoadingVotingPeriodAvgTime] =
    React.useState(false);
  const [votingDelayBlocksAvgTime, setVotingDelayBlocksAvgTime] =
    React.useState(0);
  const [votingPeriodBlocksAvgTime, setVotingPeriodBlocksAvgTime] =
    React.useState(0);

  const { getAverageBlockTimeForBlocks } = useBlockContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      quorum: formData?.quorum || 4,
      voting_delay: formData?.voting_delay || 720,
      voting_period: formData?.voting_period || 51840,
      timelock_delay: formData?.timelock_delay || 3600,
    },
    mode: "all",
  });

  const quorumSliderValue = useWatch({ control, name: "quorum" });
  const votingDelayValue = useWatch({ control, name: "voting_delay" });
  const votingPeriodValue = useWatch({ control, name: "voting_period" });

  const fetchVotingDelayAvgTime = async (numOfBlocks) => {
    setIsLoadingVotingDelayAvgTime(true);
    const avgTime = await getAverageBlockTimeForBlocks(numOfBlocks);
    setVotingDelayBlocksAvgTime(avgTime);
    setIsLoadingVotingDelayAvgTime(false);
  };

  const fetchVotingPeriodAvgTime = async (numOfBlocks) => {
    setIsLoadingVotingPeriodAvgTime(true);
    const avgTime = await getAverageBlockTimeForBlocks(numOfBlocks);
    setVotingPeriodBlocksAvgTime(avgTime);
    setIsLoadingVotingPeriodAvgTime(false);
  };

  React.useEffect(() => {
    votingDelayValue && fetchVotingDelayAvgTime(votingDelayValue);
    // eslint-disable-next-line
  }, [votingDelayValue]);

  React.useEffect(() => {
    votingPeriodValue && fetchVotingPeriodAvgTime(votingPeriodValue);
    // eslint-disable-next-line
  }, [votingPeriodValue]);

  const onSubmit = async (values) => {
    onSave(values, false);
    onClose();
  };

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 30px", mobile: "40px 10px" },
          width: "700px",
          margin: { mobile: "0px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={(e) => {
        onClose();
      }}
    >
      <DialogContent sx={{ p: { mobile: "0px" } }}>
        <CloseIcon
          onClick={(e) => {
            onClose();
          }}
          sx={{
            position: "absolute",
            right: 25,
            top: 10,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <Grid container>
          <Grid item mobile={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant={"h1"} sx={{ textAlign: "center" }}>
                {t(
                  "applyYourBrand.review.governance.governanceEditDialog.title"
                )}
              </Typography>

              <Box sx={{ mt: "30px", px: "10px" }}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ fontSize: "15px", mb: "30px" }}
                >
                  {t(
                    "applyYourBrand.review.governance.governanceEditDialog.subtitle"
                  )}
                </Typography>

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ textAlign: "left" }}
                >
                  <Grid item md={12} mobile={12}>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography
                          variant={"h6"}
                          sx={{ mb: "5px" }}
                        >
                          {t(
                            "applyYourBrand.review.governance.governanceEditDialog.minimumParticipation.title"
                          )}
                        </Typography>
                        <UI.Tooltip>
                          {t(
                            "applyYourBrand.review.governance.governanceEditDialog.minimumParticipation.tooltip"
                          )}
                        </UI.Tooltip>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            width: { md: "80%", mobile: "70%" },
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Controller
                            name={"quorum"}
                            control={control}
                            render={({ field }) => (
                              <UI.MaterialSlider
                                {...field}
                                defaultValue={formData?.quorum || 4}
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ width: { md: "10%", mobile: "20%" } }}>
                          <UI.TextField
                            value={`${quorumSliderValue}%`}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={12} mobile={12}>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography
                          variant={"h6"}
                          sx={{ mb: "5px" }}
                        >
                          {t(
                            "applyYourBrand.review.governance.governanceEditDialog.votingDelay.title"
                          )}
                        </Typography>
                        <UI.Tooltip>
                          {t(
                            "applyYourBrand.review.governance.governanceEditDialog.votingDelay.tooltip"
                          )}
                        </UI.Tooltip>
                      </Box>
                      <Controller
                        name={"voting_delay"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <UI.NumberField
                            endAdornment={
                              <InputAdornment position="start">
                                {t("commons.blocks")}
                              </InputAdornment>
                            }
                            {...field}
                            placeholder={60}
                          />
                        )}
                      />
                      {errors.voting_delay == null && (
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ mt: "15px" }}>
                            <Alert
                              variant="outlined"
                              severity="info"
                            >
                              {!isLoadingVotingDelayAvgTime &&
                                `${votingDelayValue} ${
                                  parseInt(votingDelayValue) === 1
                                    ? t("commons.block")
                                    : (parseInt(votingDelayValue) === 2)
                                      ? t("commons.2blocks")
                                      : t("commons.blocks")
                                } = ${parseInt(votingDelayBlocksAvgTime)} ${
                                    parseInt(votingDelayBlocksAvgTime) === 1
                                      ? t("commons.second")
                                      : ( parseInt(votingDelayBlocksAvgTime) === 2 )
                                        ? t("commons.2seconds")
                                        : t("commons.seconds")
                                  }
                              `}
                              {isLoadingVotingDelayAvgTime &&
                                t(
                                  "applyYourBrand.review.governance.governanceEditDialog.calculating"
                                )}
                            </Alert>
                          </Box>
                        </Box>
                      )}
                      <Box sx={{ mt: "15px" }}>
                        {errors.voting_delay?.type === "required" && (
                          <Alert
                            variant="outlined"
                            severity="error"
                          >
                            {t(
                              "applyYourBrand.review.governance.governanceEditDialog.votingDelay.error.required"
                            )}
                          </Alert>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={12} mobile={12}>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography
                          variant={"h6"}
                          sx={{ mb: "5px" }}
                        >
                          {t(
                            "applyYourBrand.review.governance.governanceEditDialog.votingPeriod.title"
                          )}
                        </Typography>
                        <UI.Tooltip>
                          {t(
                            "applyYourBrand.review.governance.governanceEditDialog.votingPeriod.tooltip"
                          )}
                        </UI.Tooltip>
                      </Box>
                      <Controller
                        name={"voting_period"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <UI.NumberField
                            endAdornment={
                              <InputAdornment position="start">
                                {t("commons.blocks")}
                              </InputAdornment>
                            }
                            {...field}
                            placeholder={3}
                          />
                        )}
                      />
                      {errors.voting_period == null && (
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ mt: "15px" }}>
                            <Alert
                              variant="outlined"
                              severity="info"
                            >
                              {!isLoadingVotingPeriodAvgTime &&
                                `${votingPeriodValue} ${
                                  parseInt(votingPeriodValue) === 1
                                    ? t("commons.block")
                                    : (parseInt(votingPeriodValue) === 2)
                                      ? t("commons.2blocks")
                                      : t("commons.blocks")
                                } = ${parseInt(votingPeriodBlocksAvgTime)} ${
                                  parseInt(votingPeriodBlocksAvgTime) === 1
                                    ? t("commons.second")
                                    : ( parseInt(votingPeriodBlocksAvgTime) === 2 )
                                      ? t("commons.2seconds")
                                      : t("commons.seconds")
                                }
                              `}
                              {isLoadingVotingPeriodAvgTime &&
                                t(
                                  "applyYourBrand.review.governance.governanceEditDialog.calculating"
                                )}
                            </Alert>
                          </Box>
                        </Box>
                      )}
                      <Box sx={{ mt: "15px" }}>
                        {errors.voting_period?.type === "required" && (
                          <Alert
                            variant="outlined"
                            severity="error"
                          >
                            {t(
                              "applyYourBrand.review.governance.governanceEditDialog.votingPeriod.error.required"
                            )}
                          </Alert>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={12} mobile={12}>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography
                          variant={"h6"}
                          sx={{ mb: "5px" }}
                        >
                          {t(
                            "applyYourBrand.review.governance.governanceEditDialog.timeLockDelay.title"
                          )}
                        </Typography>
                        <UI.Tooltip>
                          {t(
                            "applyYourBrand.review.governance.governanceEditDialog.timeLockDelay.tooltip"
                          )}
                        </UI.Tooltip>
                      </Box>
                      <Controller
                        name={"timelock_delay"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <UI.NumberField
                            endAdornment={
                              <InputAdornment position="start">
                                {t("commons.seconds")}
                              </InputAdornment>
                            }
                            {...field}
                            placeholder={3600}
                          />
                        )}
                      />
                      <Box sx={{ mt: "15px" }}>
                        {errors.timelock_delay?.type === "required" && (
                          <Alert
                            variant="outlined"
                            severity="error"
                          >
                            {t(
                              "applyYourBrand.review.governance.governanceEditDialog.timeLockDelay.error.required"
                            )}
                          </Alert>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    mt: "30px",
                    px: "15px",
                    display: "flex",
                    justifyContent: "center",
                    mb: "20px",
                  }}
                >
                  <Box sx={{ width: "210px" }}>
                    <UI.Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubmit((values) => onSubmit(values));
                      }}
                      title={t("buttons.save")}
                      type={"primary"}
                    />
                  </Box>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

const Action = ({ action: Component, onSave }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Box
        component={"span"}
        onClick={(e) => {
          setOpen(!open);
        }}
      >
        {Component}
      </Box>
      <GovernanceDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSave={onSave}
      />
    </Box>
  );
};

GovernanceDialog.Action = Action;

export default GovernanceDialog;
