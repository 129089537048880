import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import cardNewButtonIcon from "../../../../../../static/card-new-icon.png";
import DropdownMenu from "./DropdownMenu";

const AddNewCard = ({ item }) => {
  const [openMenu, setOpenMenu] = React.useState(null);
  const { t } = useTranslation();

  return (
    <>
      <Box
        key={item.id}
        sx={{
          width: { md: "155px", mobile: "102px" },
          height: { md: "232px", mobile: "153px" },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: (theme) => theme.borderRadius.secondary,
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
            transform: "scale(1.03)",
            cursor: "pointer",
          },
        }}
      >
        <Box
          onClick={(e) => {
            setOpenMenu(e.currentTarget);
          }}
          component={"img"}
          sx={{ width: "52px", height: "52px", mt: { md: "30px", mobile: "14px" }, }}
          src={cardNewButtonIcon}
        />
        <div>
          <Typography
            variant={"h8"}
            sx={{
              mt: { md: "24px", mobile: "20px" },
              fontSize: { md: "14px", mobile: "12px" },
              display: "flex",
              textAlign: "center",
              px: { md: "30px", mobile: "10px" },
            }}
          >
            {t("brands.albums.addNewCard")}
          </Typography>
        </div>
      </Box>
      <DropdownMenu openMenu={openMenu} onClose={() => setOpenMenu(null)} />
    </>
  );
};
export default AddNewCard;
