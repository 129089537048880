import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import UI from "../../../../../../../@components/UI";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { useUserContext } from "../../../../../../../@components/userContext";
import useService from "../../../../useService";
import CustomHooks from "../../../../../../../@components/hooks";
import Alert from "@mui/material/Alert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddField from "../../../../../proposal/collection/AddField";

const Code = ({ DAO }) => {
  const { albumId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const { user: loggedInUser } = useUserContext();
  const service = useService(DAO?.id);

  const generateRandomCode = () => {
    const length = Math.floor(Math.random() * 2) + 4; // Generates 4 or 5
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const { mutate: createText } = CustomHooks.usePost(service.newCard, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.albums.cards.snackbar"), {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/album/${albumId}/edit`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      codes: [
        {
          id: 0,
          value: generateRandomCode(),
        },
      ],
    },
  });

  const {
    fields: codes,
    append: addCode,
    remove: removeCode,
  } = useFieldArray({
    control,
    name: "codes",
  });

  const onSubmit = async (form) => {
    const data = {
      name: "Code",
      album_space_type: "Code",
      data_json: {
        title: form.title,
        description: form.description,
      },
      codes: form.codes,
    };
    await createText({ albumId: albumId, data: data });
  };
  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
      }}
    >
      {/*<UI.Busy.FullscreenIndicator show={isLoading}>*/}
      {/*  <Typography variant={"subtitle1"}>Creating Card ...</Typography>*/}
      {/*  <Typography variant={"subtitle1"}>*/}
      {/*    Please do not close this window.*/}
      {/*  </Typography>*/}
      {/*</UI.Busy.FullscreenIndicator>*/}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "50px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.albums.backToAlbum")}
            onClick={() => history.go(-1)}
          />

          <Typography
            variant={"h3"}
            sx={{
              mb: "25px",
              mt: "30px",
            }}
          >
            {t("brands.albums.createCard.code.createCodeCard")}
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "10px",
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography variant={"h6"}>{t("brands.albums.createCard.code.title")}</Typography>
                </Box>

                <Controller
                  name={"title"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <UI.TextField {...field} />}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.value?.type === "required" && (
                    <Alert variant="outlined" severity="error">
                      {t("brands.albums.createCard.error.title")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "10px",
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography variant={"h6"}>{t("brands.albums.createCard.code.description")}</Typography>
                </Box>

                <Controller
                  name={"description"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField multiline rows={5} {...field} />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.value?.type === "required" && (
                    <Alert variant="outlined" severity="error">
                      {t("brands.albums.createCard.error.description")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "10px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography variant={"h6"}>{t("brands.albums.createCard.code.generateCode")}</Typography>
                </Box>
              </Box>
            </Grid>
            {codes.map((item, index) => (
              <Grid item md={12} mobile={12} key={item.id} sx={{ pt: "0"}}>
                <Box
                  sx={{
                    marginBottom: "25px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    pt: "0px"
                  }}
                >
                  <Box sx={{ display: "flex", pt: "0" }}>
                    <Controller
                      name={`codes.${index}.value`}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => <UI.TextField {...field} />}
                    />
                    {codes.length > 1 && (
                      <Box
                        onClick={() => removeCode(index)}
                        sx={{
                          cursor: "pointer",
                          ml: "8px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{
                            color: (theme) => theme.palette.colors.darkRed,
                            opacity: "0.8",
                            "&:hover": {
                              opacity: 1,
                            },
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
            <Grid item md={12} mobile={12}>
              <AddField
                title={t("brands.albums.createCard.code.generateAdditionalCode")}
                onClick={() => {
                  addCode({
                    id: codes.length - 1,
                    value: generateRandomCode(),
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            mb: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length || !loggedInUser?.email_verified
              }
              title={t("brands.albums.createCard.button.createCard")}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Code;
