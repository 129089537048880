import React from "react";
import Box from "@mui/material/Box";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Autoplay } from "swiper/modules";

import "./styles.css";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import UI from "../../@components/UI";
import CustomHooks from "../../@components/hooks";
import useCollectionService from "../../collections/useService";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const ImageItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        position: "relative",
        height: { md: "316px", xl: "316px", mobile: "316px" },
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: (theme) => theme.borderRadius.primary,
        background:
          "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
      }}
    >
      <UI.ImageWithSkeleton
        sx={{
          borderRadius: (theme) => theme.borderRadius.primary,
          inset: "0px",
          boxSizing: "border-box",
          padding: "0px",
          border: "none",
          margin: "auto",
          display: "block",
          width: "0px",
          height: "0px",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          objectFit: "cover",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
        src={item.nft_image_url}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          bottom: "0px",
          height: "50%",
          width: "100%",
          background:
            "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            ml: "10px",
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              width: "100%",
              position: "absolute",
              bottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  mr: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Avatar src={item.dao?.avatar_url} alt={"avatar"} />
              </Box>
              <Typography
                variant={"h9"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  fontSize: "13px !important",
                  color: "#fff",
                  maxWidth: { md: "170px", xl: "200px", mobile: "150px" },
                }}
              >
                <UI.OverflowTip>{item?.dao?.name}</UI.OverflowTip>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mr: "12px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant={"h9"}
                  sx={{
                    fontSize: "13px !important",
                    maxWidth: "60px",
                    color: "#fff",
                  }}
                >
                  <UI.OverflowTip>
                    {`${item?.nft_amount_minted}/${item?.nft_amount_limit}`}{" "}
                  </UI.OverflowTip>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const VideoItem = ({ item, onClick }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        position: "relative",
        height: { md: "316px", xl: "316px", mobile: "316px" },
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: (theme) => theme.borderRadius.primary,
        background:
          "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
      }}
    >
      <Box
        sx={{
          inset: "0px",
          boxSizing: "border-box",
          padding: "0px",
          border: "none",
          margin: "auto",
          display: "block",
          width: "0px",
          height: "0px",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          objectFit: "cover",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        <video
          playsInline
          width={isMobile ? "fit-content" : "100%"}
          height={isMobile ? "100%" : "auto"}
          autoPlay="autoplay"
          loop
          muted
        >
          <source src={item.nft_image_url} />
        </video>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          bottom: "0px",
          height: "50%",
          width: "100%",
          background:
            "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            ml: "20px",
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              width: "100%",
              position: "absolute",
              bottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  mr: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Avatar src={item.dao.avatar_url} alt={"avatar"} />
              </Box>
              <Typography
                variant={"h9"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  fontSize: "13px !important",
                  color: "#fff",
                  maxWidth: { md: "170px", xl: "200px", mobile: "150px" },
                }}
              >
                <UI.OverflowTip>{item?.dao?.name}</UI.OverflowTip>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mr: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "20px",
                }}
              >
                <Typography
                  variant={"h9"}
                  sx={{
                    fontSize: "13px !important",
                    maxWidth: "60px",
                    color: "#fff",
                  }}
                >
                  <UI.OverflowTip>
                    {`${item?.nft_amount_minted}/${item?.nft_amount_limit}`}{" "}
                  </UI.OverflowTip>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SkeletonItem = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "316px",
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "0px",
        background:
          "linear-gradient(rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)",
      }}
    >
      <Skeleton
        variant={"rectangular"}
        sx={{
          inset: "0px",
          boxSizing: "border-box",
          padding: "0px",
          border: "none",
          margin: "auto",
          display: "block",
          width: "0px",
          height: "0px",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};
const NewCollections = ({ onClick }) => {
  const collectionService = useCollectionService();
  const history = useHistory();
  const { t } = useTranslation();

  const { data: collections } = CustomHooks.useFetch(
    ["collections_featured"],
    collectionService.featured
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { mobile: "column", md: "column" },
        }}
      >
        <Swiper
          grabCursor={true}
          slidesPerView={4}
          spaceBetween={100}
          slidesPerGroup={1}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false,
          }}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            100: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            1000: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            1100: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1150: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1400: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="desktopExplorePreview"
        >
          {collections?.length > 0
            ? collections.map((item) => (
                <SwiperSlide key={item.id}>
                  {item.nft_file_type.toLowerCase().startsWith("image") && (
                    <ImageItem item={item} onClick={(item) => onClick(item)} />
                  )}
                  {item.nft_file_type.startsWith("video") && (
                    <VideoItem
                      playsinline
                      onClick={(item) => onClick(item)}
                      item={item}
                    />
                  )}
                </SwiperSlide>
              ))
            : [0, 1, 2, 3].map((index) => (
                <SwiperSlide key={index}>
                  <SkeletonItem />
                </SwiperSlide>
              ))}
        </Swiper>
        <Box
          sx={{
            display: { md: "flex", mobile: "flex" },
            width: "100%",
            justifyContent: "center",
          }}
        >
          <UI.Button
            type={"primary"}
            size={"small"}
            onClick={() => {
              history.push({
                pathname: "/collection/explore",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              mt: { md: "60px", mobile: "40px" },
              width: { md: "250px", mobile: "90%" },
              height: "46px",
              display: { md: "flex", mobile: "flex" },
            }}
            title={t("commons.seeMore")}
          />
        </Box>
      </Box>
    </>
  );
};

export default NewCollections;
