import { useState, useEffect, createContext, useContext } from "react";
import useProposalService from "../proposal/useService";
import useHandleErrors from "../../@components/UI/useHandleErrors";
export const MenuContext = createContext();

export default function MenuProvider({ children }) {
  const service = useProposalService();
  const { handleApiError } = useHandleErrors();
  const [activeVotes, setActiveVotes] = useState(0);

  useEffect(() => {
    updateMenu();
    // eslint-disable-next-line
  }, []);

  const updateMenu = async () => {
    await service
      .proposals("active")
      .then((response) => {
        response.data.results && setActiveVotes(response.data.results.length);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  return (
    <MenuContext.Provider value={{ activeVotes, updateMenu }}>
      {children}
    </MenuContext.Provider>
  );
}

export const useMenu = () => useContext(MenuContext);
