import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import ribbonIcon from "../../../../static/ribbon.png";

const DragDropTicket = ({
  brandName,
  brandAvatar,
  name,
  name_slo,
  data,
  fileType,
  mainUtility,
  isFreeNFT,
  selectedNFT,
}) => {
  const [previewfile, setPreviewfile] = React.useState(null);
  const [isVideoLoading, setIsVideoLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        width: { mobile: "250px", md: "300px" },
        borderRadius: (theme) => theme.borderRadius.primary,
        "-webkit-transform": "translateZ(0)",
        "-webkit-perspective": "1000",
        "-webkit-backface-visibility": "hidden",
        boxShadow: "0px 0px 20px #944DFF",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderRadius: "8px 8px 0 0",
          border: "2px solid #944DFF",
          borderBottom: "none",
          padding: { mobile: "13px", md: "20px" },
          height: { mobile: "70px", md: "88px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box>
              {brandName ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: { mobile: "175.5px", md: "185.4px" },
                  }}
                >
                  <UI.OverflowTip>{`${brandName}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {name ? (
              <Typography
                variant={"h2"}
                sx={{
                  lineHeight: { mobile: "17px", md: "unset !important" },
                  fontSize: {
                    mobile: "15px !important",
                    md: "1rem !important",
                  },
                  maxWidth: { mobile: "175.5px", md: "185.4px" },
                }}
              >
                <UI.OverflowTip>
                  {name_slo !== null &&
                  name_slo !== "" &&
                  i18n.language === "sl"
                    ? name_slo
                    : name || ""}
                </UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"10px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={brandAvatar} alt={"avatar"} />
          </Box>
        </Box>
      </Box>
      <Box
        onClick={() =>
          setPreviewfile(selectedNFT !== null ? selectedNFT.image_url : data)
        }
        sx={{
          cursor: "pointer",
          textAlign: "center",
          height: { mobile: "250px", md: "300px" },
          width: { mobile: "250px", md: "300px" },
          overflow: "hidden",
          borderLeft: "2px solid #944DFF",
          borderRight: "2px solid #944DFF",
        }}
      >
        <Box
          sx={{
            height: { mobile: "250px", md: "300px" },
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            component={"span"}
            sx={{
              boxSizing: "border-box",
              display: "block",
              overflow: "hidden",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: 1,
              border: "0px",
              margin: "0px",
              padding: "0px",
              position: "absolute",
              inset: 0,
              lineHeight: "0",
              fontSize: "0",
            }}
          >
            {data ? (
              fileType?.startsWith("video") ? (
                <>
                  {selectedNFT.image_url && (
                    <>
                      <video
                        key={selectedNFT.id}
                        playsInline
                        width={"fit-content"}
                        height={"100%"}
                        autoplay="autoplay"
                        loop
                        muted
                        style={!isVideoLoading ? {} : { visibility: "hidden" }}
                        onLoadStart={(e) => {
                          setIsVideoLoading(true);
                        }}
                        onLoadedData={(e) => {
                          setIsVideoLoading(false);
                        }}
                      >
                        <source src={selectedNFT.image_url} />
                      </video>
                      {isVideoLoading && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                          }}
                        >
                          <UI.Busy.Indicator width={"150px"} height={"150px"} />
                        </Box>
                      )}
                    </>
                  )}
                </>
              ) : (
                <UI.ImageWithSkeleton
                  sx={{
                    position: "absolute",
                    inset: 0,
                    boxSizing: "border-box",
                    padding: 0,
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  src={selectedNFT !== null ? selectedNFT.image_url : data}
                  width={"100%"}
                  alt={"slider-item"}
                />
              )
            ) : (
              <Skeleton
                sx={{
                  height: { mobile: "250px", md: "300px" },
                  width: { mobile: "250px", md: "300px" },
                }}
                variant={"rectangular"}
              />
            )}
          </Box>
          {selectedNFT === null && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                width: "100%",
                transform: "translateX(-50%)",
              }}
            >
              <Typography
                variant={"h4"}
                sx={{
                  letterSpacing: "normal",
                  padding: { md: "10px 0", mobile: "8px 0px" },
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Choose your NFT
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderRadius: "0 0 0.5rem 0.5rem",
          border: "2px solid #944DFF",
          borderTop: "none",
          padding: { mobile: "13px", md: "20px" },
          height: { mobile: "70px", md: "88px" },
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <UI.UtilityTag utilityType={mainUtility?.type} />
          <UI.Tag
            sx={{
              ml: "10px",
              backgroundColor: (theme) => theme.palette.tag.green,
            }}
          >
            {t("utilityTag.limited")}
          </UI.Tag>
          {isFreeNFT && (
            <Box>
              <Typography
                variant={"h2"}
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "900",
                  textTransform: "uppercase",
                  color: "white",
                  fontStyle: "italic",
                  fontSize:
                    i18n.language === "en"
                      ? { md: "15px !important", mobile: "12px !important" }
                      : { md: "13px !important", mobile: "11px !important" },
                  lineHeight: "unset",
                  position: "absolute",
                  bottom:
                    i18n.language === "en"
                      ? { md: "5px", mobile: "4px" }
                      : { md: "5.5px", mobile: "4.5px" },
                  right:
                    i18n.language === "en"
                      ? "-7.5px"
                      : { md: "-11.5px", mobile: "-12px" },
                  transform: "rotate(315deg)",
                  zIndex: 1,
                }}
              >
                {t("commons.free")}
              </Typography>
              <Box
                component={"img"}
                src={ribbonIcon}
                sx={{
                  position: "absolute",
                  width: { md: "84px", mobile: "60px" },
                  bottom: { md: "-15.5px", mobile: "-10.4px" },
                  right: { md: "-18.5px", mobile: "-12.5px" },
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        onClose={() => setPreviewfile(null)}
        open={!!previewfile}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "700px",
          },
        }}
        PaperProps={{
          maxWidth: "700px",
        }}
      >
        {previewfile && fileType?.startsWith("video") && (
          <video
            playsInline
            width="100%"
            height={"auto"}
            autoplay="autoplay"
            loop
          >
            <source src={previewfile} />
          </video>
        )}
        {previewfile && fileType?.startsWith("image") && (
          <Box
            component={"img"}
            sx={{
              maxHeight: { md: "600px", mobile: "auto" },
              width: { md: "auto", mobile: "100%" },
              margin: "0 auto",
            }}
            src={previewfile}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default DragDropTicket;
