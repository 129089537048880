import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import backArrow from "../../../../static/back-arrow.png";
import { useFormDataContext } from "../context";
import { Controller, useForm } from "react-hook-form";
import UI from "../../../../@components/UI";
import maticLogo from "../../../../static/matic-logo.png";
import usdtLogo from "../../../../static/usdt-logo.png";
import usdcLogo from "../../../../static/usdc-icon.png";
import paypalLogo from "../../../../static/paypal.png";
import stripeLogo from "../../../../static/stripe.png";
import cryptoLogo from "../../../../static/crypto.png";
import { useUserContext } from "../../../../@components/userContext";
import { useAccount } from "wagmi";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  actionArea: {
    padding: "30px !important",
    border: "1px solid rgb(148 77 255 / 17%) !important",
    borderRadius: "0px",
  },
  actionAreaSelected: {
    padding: "30px !important",
    border: "1px solid rgb(148 77 255 / 100%) !important",
    borderRadius: "0px",
  },
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
}));

const BillingMethod = ({ nextStep, previouseStep, onSave }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const { formData } = useFormDataContext();
  const { isConnected } = useAccount();

  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { isDirty, errors },
    getValues,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      payment: {
        paypal: {
          selected: formData?.payment?.paypal?.selected || false,
          secret_key: formData?.payment?.paypal?.secret_key,
          public_key: formData?.payment?.paypal?.public_key,
        },
        stripe: {
          selected: formData?.payment?.stripe?.selected || false,
          secret_key: formData?.payment?.stripe?.secret_key,
          public_key: formData?.payment?.stripe?.public_key,
        },
      },
    },
    mode: "all",
  });

  const paypalSelected = watch("payment.paypal.selected");
  const stripeSelected = watch("payment.stripe.selected");

  React.useEffect(() => {
    if (paypalSelected === false) {
      // If paypal is not selected
      // clear errors
      clearErrors("payment.paypal.secret_key");
      clearErrors("payment.paypal.public_key");
      // reset values
      setValue("payment.paypal.secret_key", "");
      setValue("payment.paypal.public_key", "");
    }
  }, [paypalSelected, clearErrors, setValue]);

  React.useEffect(() => {
    if (stripeSelected === false) {
      // If stripe is not selected
      // clear errors
      clearErrors("payment.stripe.secret_key");
      clearErrors("payment.stripe.public_key");
      // reset values
      setValue("payment.stripe.secret_key", "");
      setValue("payment.stripe.public_key", "");
    }
  }, [stripeSelected, clearErrors, setValue]);

  const onSubmit = async (values, action) => {
    switch (action) {
      case "save_and_continue": {
        try {
          await onSave(values, false);
          nextStep();
        } catch (e) {}
        break;
      }
      case "save_and_back": {
        if (isDirty) {
          await onSave(values, false);
          previouseStep();
        }

        !isDirty && previouseStep();
        break;
      }
      case "save": {
        await onSave(values, true);
        break;
      }
      default: {
        await onSave(values, true);
        break;
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit((values) => onSubmit(values, "save_and_continue"))}
    >
      <Box
        sx={{
          p: { md: "40px 100px", mobile: "50px 10px 60px" },
          pt: "0px !important",
        }}
      >
        <Box
          onClick={handleSubmit((values) => onSubmit(values, "save_and_back"))}
          className={classes.backButtonOuter}
          sx={{
            display: "flex",
            cursor: "pointer",
            mb: { md: "50px", mobile: "30px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mr: "8px",
            }}
          >
            <img
              className={"backArrow"}
              style={{ transition: "0.4s ease-in" }}
              src={backArrow}
              alt={"back-arrow"}
              width={"15px"}
              height={"15px"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box className={classes.backButton}>
              {t("applyYourBrand.paymentMethod.backButton.label")}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mb: "50px",
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            textAlign: "left",
          }}
        >
          <Box>
            <Typography
              variant={"h3"}
              sx={{
                mb: { md: "undefined", mobile: "20px" },
              }}
            >
              {t("applyYourBrand.paymentMethod.title")}
            </Typography>
            <Typography variant={"subtitle2"}>
              {t("applyYourBrand.paymentMethod.subtitle")}
            </Typography>
          </Box>
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item mobile={12} md={12} sx={{ textAlign: "left" }}>
            <Typography variant={"h4"}>
              {t("applyYourBrand.paymentMethod.paymentOption.crypto.title")}
            </Typography>
            <Box className={classes.inputOuter} sx={{ mt: "30px" }}>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <UI.Checkbox
                  disabled
                  value={true}
                  nomargin={"true"}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        component={"img"}
                        src={cryptoLogo}
                        sx={{ width: "40px", ml: "22px" }}
                      />
                      <Typography
                        variant={"h8"}
                        sx={{
                          ml: "15px",
                        }}
                      >
                        {t(
                          "applyYourBrand.paymentMethod.paymentOption.crypto.title"
                        )}
                      </Typography>
                      <Box
                        sx={{
                          ml: "30px",
                          mt: "2px",
                          display: { md: "flex", mobile: "none" },
                        }}
                      >
                        <Tooltip title={"MATIC"} arrow>
                          <Box
                            component={"img"}
                            src={maticLogo}
                            sx={{
                              width: "18px",
                              height: "18px",
                              "&:hover": {
                                transform: "scale(1.1)",
                              },
                              transition: "all 0.5s ease-in-out",
                            }}
                          />
                        </Tooltip>
                        <Tooltip title={"USDT"} arrow>
                          <Box
                            component={"img"}
                            src={usdtLogo}
                            sx={{
                              width: "18px",
                              height: "18px",
                              ml: "14px",
                              "&:hover": {
                                transform: "scale(1.1)",
                              },
                              transition: "all 0.5s ease-in-out",
                            }}
                          />
                        </Tooltip>
                        <Tooltip title={"USDC"} arrow>
                          <Box
                            component={"img"}
                            src={usdcLogo}
                            sx={{
                              width: "18px",
                              height: "18px",
                              ml: "14px",
                              "&:hover": {
                                transform: "scale(1.1)",
                              },
                              transition: "all 0.5s ease-in-out",
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                  }
                />
              </Box>
              <Box
                sx={{
                  ml: "56px",
                  mt: "18px",
                  display: { md: "none", mobile: "flex" },
                }}
              >
                <Tooltip title={"MATIC"} arrow>
                  <Box
                    component={"img"}
                    src={maticLogo}
                    sx={{
                      width: "18px",
                      height: "18px",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                      transition: "all 0.5s ease-in-out",
                    }}
                  />
                </Tooltip>
                <Tooltip title={"USDT"} arrow>
                  <Box
                    component={"img"}
                    src={usdtLogo}
                    sx={{
                      width: "18px",
                      height: "18px",
                      ml: "14px",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                      transition: "all 0.5s ease-in-out",
                    }}
                  />
                </Tooltip>
                <Tooltip title={"USDC"} arrow>
                  <Box
                    component={"img"}
                    src={usdcLogo}
                    sx={{
                      width: "18px",
                      height: "18px",
                      ml: "14px",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                      transition: "all 0.5s ease-in-out",
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>

            <Box sx={{ mt: "60px" }}>
              <Typography variant={"h4"}>
                {t("applyYourBrand.paymentMethod.paymentOption.fiat.title")}
              </Typography>
            </Box>
            <Box className={classes.inputOuter} sx={{ mt: "30px" }}>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <Controller
                  name={"payment.paypal.selected"}
                  rules={{ required: false }}
                  control={control}
                  render={({ field }) => (
                    <UI.Checkbox
                      {...field}
                      nomargin={"true"}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            component={"img"}
                            src={paypalLogo}
                            sx={{ width: "40px", ml: "25px" }}
                          />
                          <Typography
                            variant={"h8"}
                            sx={{
                              ml: "15px",
                            }}
                          >
                            PAYPAL
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: "20px" }}>
                {paypalSelected && (
                  <UI.Accordion
                    expanded={true}
                    readonly
                    sx={{ backgroundColor: (theme) => theme.palette.bgTransparent.primary  }}
                    title={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          component={"img"}
                          src={paypalLogo}
                          sx={{ width: "40px" }}
                        />
                        <Typography
                            variant={"h8"}
                            sx={{
                              ml: "15px",
                            }}
                          >
                          PAYPAL
                        </Typography>
                      </Box>
                    }
                  >
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography variant={"h5"}>CLIENT ID</Typography>
                      </Box>

                      <Controller
                        name={"payment.paypal.public_key"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => <UI.TextField {...field} />}
                      />
                      <Box className={classes.description} sx={{ mt: "15px" }}>
                        {errors.payment?.paypal?.public_key?.type ===
                          "required" && (
                          <Alert
                            variant="outlined"
                            severity="error"
                          >
                            {t(
                              "applyYourBrand.paymentMethod.paymentOption.fiat.paypal.clientId.error.required" 
                            )}
                          </Alert>
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography variant={"h5"}>SECRET KEY</Typography>
                      </Box>

                      <Controller
                        name={"payment.paypal.secret_key"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => <UI.TextField {...field} />}
                      />
                      <Box className={classes.description} sx={{ mt: "15px" }}>
                        {errors.payment?.paypal?.secret_key?.type ===
                          "required" && (
                          <Alert
                            variant="outlined"
                            severity="error"
                          >
                            {t(
                              "applyYourBrand.paymentMethod.paymentOption.fiat.paypal.secretId.error.required"
                            )}
                          </Alert>
                        )}
                      </Box>
                    </Box>
                  </UI.Accordion>
                )}
              </Box>
            </Box>
            <Box className={classes.inputOuter} sx={{ mt: "25px" }}>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <Controller
                  name={"payment.stripe.selected"}
                  rules={{ required: false }}
                  control={control}
                  render={({ field }) => (
                    <UI.Checkbox
                      {...field}
                      nomargin={"true"}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            component={"img"}
                            src={stripeLogo}
                            sx={{ width: "40px", ml: "22px" }}
                          />
                          <Typography
                            variant={"h8"}
                            sx={{
                              ml: "15px",
                            }}
                          >
                            STRIPE
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: "20px" }}>
                {stripeSelected && (
                  <UI.Accordion
                    expanded={true}
                    readonly
                    sx={{ mt: "40px", backgroundColor: (theme) => theme.palette.bgTransparent.primary  }}
                    title={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          component={"img"}
                          src={stripeLogo}
                          sx={{ width: "40px" }}
                        />
                        <Typography
                            variant={"h8"}
                            sx={{
                              ml: "15px",
                            }}
                          >
                          STRIPE
                        </Typography>
                      </Box>
                    }
                  >
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography variant={"h5"}>PUBLIC KEY</Typography>
                      </Box>

                      <Controller
                        name={"payment.stripe.public_key"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => <UI.TextField {...field} />}
                      />
                      <Box className={classes.description} sx={{ mt: "15px" }}>
                        {errors.payment?.stripe?.public_key?.type ===
                          "required" && (
                          <Alert
                            variant="outlined"
                            severity="error"
                          >
                            {t(
                              "applyYourBrand.paymentMethod.paymentOption.fiat.stripe.publicKey.error.required"
                            )}
                          </Alert>
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography variant={"h5"}>SECRET KEY</Typography>
                      </Box>

                      <Controller
                        name={"payment.stripe.secret_key"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => <UI.TextField {...field} />}
                      />
                      <Box className={classes.description} sx={{ mt: "15px" }}>
                        {errors.payment?.stripe?.secret_key?.type ===
                          "required" && (
                          <Alert
                            variant="outlined"
                            severity="error"
                          >
                            {t(
                              "applyYourBrand.paymentMethod.paymentOption.fiat.stripe.secretKey.error.required"
                            )}
                          </Alert>
                        )}
                      </Box>
                    </Box>
                  </UI.Accordion>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            mt: { md: "80px", mobile: "60px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "210px" }}>
              <UI.Button
                disabled={
                  !!Object.keys(errors).length ||
                  !isConnected ||
                  !loggedInUser?.email_verified
                }
                sx={{
                  filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                }}
                title={t("buttons.saveAndContinue")}
                type={"primary"}
              />
              <UI.Button
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  const allValues = getValues();
                  await onSubmit(allValues, "save");
                }}
                disabled={
                  !isDirty || !isConnected || !loggedInUser?.email_verified
                }
                sx={{
                  mt: "10px",
                  filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                }}
                title={t("buttons.saveForLater")}
                type={"tertiary"}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default BillingMethod;
