import React from "react";
import Box from "@mui/material/Box";
import ConnectWalletWithAccountDialog from "../../../profile/dialogs/ConnectWalletWithAccountDialog";
import AccountAlreadyAssignedDialog from "../../../profile/dialogs/AccountAlreadyAssignedDialog";

const ConnectWalletToAccountButton = ({ children }) => {
  const [
    showConnectWalletWithAccountDialog,
    setShowConnectWalletWithAccountDialog,
  ] = React.useState(false);
  const [showAccountAssignedDialog, setShowAccountAssignedDialog] =
    React.useState(false);

  return (
    <>
      <Box
        onClick={() => {
          setShowConnectWalletWithAccountDialog(true);
        }}
        sx={{ p: "0 10px" }}
      >
        {children}
      </Box>
      <ConnectWalletWithAccountDialog
        open={showConnectWalletWithAccountDialog}
        onClose={() => setShowConnectWalletWithAccountDialog(false)}
        onSuccess={() => {
          setShowConnectWalletWithAccountDialog(false);
        }}
        onNoExistingAccountError={() => {
          setShowConnectWalletWithAccountDialog(false);
          setShowAccountAssignedDialog(Math.random());
        }}
      />
      <AccountAlreadyAssignedDialog toggleOpen={showAccountAssignedDialog} />
    </>
  );
};

export default ConnectWalletToAccountButton;
