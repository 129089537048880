import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UI from "../../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";

import List from "./List";
import Create from "./Create";
import { useUserRole } from "../context";
import { useTranslation } from "react-i18next";

const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { isBrandMember } = useUserRole();

  return (
    <React.Fragment>
      {DAO && (
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            pb: "20px",
            pt: "0px",
            background: "transparent",
          }}
        >
          {isBrandMember && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                px: { md: "0px", mobile: "10px" },
                pb: "36px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "270px" }}>
                  <UI.Button
                    onClick={() => history.push(`${location.pathname}/new`)}
                    title={t("brands.albums.createAlbum")}
                    type={"primary"}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{ p: { mobile: "10px", md: "0px" }, pt: "0" }}>
            <List DAO={DAO} />
          </Box>
        </Paper>
      )}
    </React.Fragment>
  );
};

Index.List = List;
Index.Create = Create;

export default Index;
