import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const UtilityTag = (props) => {
  const { t } = useTranslation();
  const { utilityType, isMainUtility = true, customStyles } = props;
  const getUtilityName = () => {
    if (utilityType === "digital_content") {
      return t("utilityTag.digitalContent");
    } else if (utilityType === "product") {
      return t("utilityTag.product");
    } else if (utilityType === "service") {
      return t("utilityTag.service");
    } else if (utilityType === "ticket") {
      return t("utilityTag.eventTicket");
    } else if (utilityType === "merch") {
      return t("utilityTag.merchendise");
    } else if (utilityType === "discord_access") {
      return t("utilityTag.discordAccess");
    } else if (utilityType === "custom") {
      return t("utilityTag.custom");
    }
  };

  return (
    <Box
      sx={{
        height: { md: "23px", mobile: "21px" },
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: "100px",
        backgroundColor: isMainUtility ? (theme) => theme.palette.tag.primary : (theme) => theme.palette.tag.green,
        width: "fit-content",
        p: "4px 14px",
        lineHeight: "unset",
        ...props.sx,
        ...customStyles,
      }}
    >
      <Typography
        variant={"h10"}
        sx={{
          color: "#fff",
          ...(customStyles?.perks_badge_text_color && {
            color: customStyles.perks_badge_text_color,
          }),
        }}
      >
        {getUtilityName()}
      </Typography>
    </Box>
  );
};

export default UtilityTag;
