import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { useUserContext } from "../../@components/userContext";
import Typography from "@mui/material/Typography";
import SettingsButton from "./SettingsButton";
import { useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { useUserRole } from "./context";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MobileSlideMenu from "./MobileSlideMenu";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  categoryItem: {
    width: "180px",
    height: "40px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    background: ({ selected }) => {
      return selected
        ? theme.palette.bgTransparent.secondary
        : theme.palette.bgTransparent.tertiary;
    },
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
  },
}));

const MobileMenu = ({ DAO, currentUrl }) => {
  const { t } = useTranslation();
  const { isBrandMember } = useUserRole();
  const { user: loggedInUser } = useUserContext();
  const history = useHistory();
  const location = useLocation();
  const [triggerMenuOpen, setTriggerMenuOpen] = React.useState(null);

  const swiperRef = React.useRef(null);

  const initialSlideToMenuItem = () => {
    if (location.pathname === `${currentUrl}/tresuary`) {
      swiperRef.current.swiper.slideTo(4);
    } else if (location.pathname === `${currentUrl}/vote`) {
      swiperRef.current.swiper.slideTo(5);
    } else if (location.pathname === `${currentUrl}/membership`) {
      swiperRef.current.swiper.slideTo(6);
    } else {
      swiperRef.current.swiper.slideTo(0);
    }
  };

  React.useEffect(() => {
    initialSlideToMenuItem();

    // eslint-disable-next-line
  }, [DAO, loggedInUser?.email_verified]);
  return (
    <Box
      sx={{
        mt: "8px",
        mb: "30px",
        display: {
          mobile: "block",
          md: "none",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          ml: "10px",
          mb: "9px",
          pr: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              onClick={() => history.push("/brand/explore")}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                ml: "6px",
                mr: "10px",
              }}
            >
              <ArrowBackIosIcon
                sx={{
                  color: "#944DFF",
                  height: "20px",
                }}
                style={{ transition: "0.4s ease-in" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Paper
                sx={{
                  borderRadius: "50%",
                  width: { md: "175px", mobile: "35px" },
                  height: { md: "175px", mobile: "35px" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  overflow: "hidden",
                  background: "transparent",
                }}
              >
                {DAO?.avatar_url && (
                  <img
                    onClick={() => {
                      history.push(`/brand/${DAO?.id}`);
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                      margin: "0 auto",
                      textAlign: "center",
                    }}
                    src={DAO?.avatar_url}
                    alt={"dao-logo"}
                  />
                )}
              </Paper>
            </Box>
            <Typography
              sx={{
                ml: "10px",
                textAlign: "left",
                maxWidth: "268px",
              }}
              variant={"h4"}
            >
              {DAO?.name}
            </Typography>
            {isBrandMember && (
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  zIndex: 2,
                }}
              >
                <SettingsButton DAO={DAO} />
              </Box>
            )}
          </Box>
        </Box>
        <IconButton
          sx={{ display: { mobile: "flex", md: "none" } }}
          onClick={() => setTriggerMenuOpen(Math.random())}
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
        >
          <MenuIcon
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontSize: "24px",
              "&:hover": {
                transition: "all 0.1s ease-in-out",
                transform: "scale(1.2)",
              },
            }}
          />
        </IconButton>
        <MobileSlideMenu
          setTriggerMenuOpen={setTriggerMenuOpen}
          triggerMenuOpen={triggerMenuOpen}
        />
      </Box>
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        slidesPerView={3.3}
        className="collection-category-swiper"
      >
        <SwiperSlide>
          <Item
            selected={location.pathname === `/brand/${DAO?.id}`}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(0);
              history.push(`${currentUrl}`);
            }}
            index={0}
            title={t("brands.menu.home")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/brand/${DAO?.id}/feed`)}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(1);
              history.push(`${currentUrl}/feed`);
            }}
            index={1}
            title={t("brands.menu.feed")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/brand/${DAO?.id}/album`)}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(2);
              history.push(`${currentUrl}/album`);
            }}
            index={2}
            title={t("brands.menu.albums")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={
              !!location.pathname.startsWith(`/brand/${DAO?.id}/collection`)
            }
            onSelect={() => {
              swiperRef.current.swiper.slideTo(2);
              history.push(`${currentUrl}/collection`);
            }}
            index={2}
            title={t("brands.menu.collections")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={
              !!location.pathname.startsWith(`/brand/${DAO?.id}/community`)
            }
            onSelect={() => {
              swiperRef.current.swiper.slideTo(3);
              history.push(`${currentUrl}/community`);
            }}
            index={3}
            title={t("brands.menu.community")}
          />
        </SwiperSlide>
        {isBrandMember && loggedInUser?.email_verified && (
          <SwiperSlide>
            <Item
              isAdminItem={true}
              selected={
                !!location.pathname.startsWith(`/brand/${DAO?.id}/tresuary`)
              }
              onSelect={() => {
                swiperRef.current.swiper.slideTo(4);
                history.push(`${currentUrl}/tresuary`);
              }}
              index={4}
              title={t("brands.menu.treasury")}
            />
          </SwiperSlide>
        )}
        {isBrandMember && loggedInUser?.email_verified && (
          <SwiperSlide>
            <Item
              isAdminItem={true}
              selected={
                !!location.pathname.startsWith(`/brand/${DAO?.id}/vote`)
              }
              onSelect={() => {
                swiperRef.current.swiper.slideTo(5);
                history.push(`${currentUrl}/vote`);
              }}
              index={5}
              title={t("brands.menu.vote")}
            />
          </SwiperSlide>
        )}
        {isBrandMember && loggedInUser?.email_verified && (
          <SwiperSlide>
            <Item
              isAdminItem={true}
              selected={
                !!location.pathname.startsWith(`/brand/${DAO?.id}/membership`)
              }
              onSelect={() => {
                swiperRef.current.swiper.slideTo(6);
                history.push(`${currentUrl}/membership`);
              }}
              index={6}
              title={t("brands.menu.membership")}
            />
          </SwiperSlide>
        )}
      </Swiper>
    </Box>
  );
};

const Item = (props) => {
  const { selected, index, onSelect, title } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile });
  return (
    <Box
      sx={{ position: "relative" }}
      className={classes.categoryItem}
      onClick={() => onSelect(index)}
      {...props}
    >
      <Typography
        sx={{
          margin: "0 auto",
          fontWeight: "bold",
          fontSize: "12px !important",
        }}
      >
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default MobileMenu;
