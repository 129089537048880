import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { useSnackbar } from "notistack";
import { useUserContext } from "../../@components/userContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoginWithWallet from "../../@components/UI/loginWithWallet";
import qvrseIcon from "../../static/qvrse-white-logo.png";
import qvrseIconDark from "../../static/qvrse-dark-logo.png";
import useAuth from "../../@components/auth/useAuth";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { useAccountModal } from "@rainbow-me/rainbowkit";
import { useTheme } from "@mui/material/styles";


const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: "-15px",
    top: "10.5px",
    backgroundColor: "#44b700",
    color: "#44b700",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const LoginWithWalletDialog = ({
  open,
  onClose,
  onSuccess,
  showLogInDialog,
  onNoExistingAccountError,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { setUser: setUserContext } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(null);
  const { handleMetamaskError, handleApiError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const { isConnected } = useAccount();
  const { loginWithWallet } = useAuth();
  const { openAccountModal } = useAccountModal();

  const signIn = () => {
    setIsLoading(true);
    loginWithWallet()
      .then((response) => {
        const { user } = response;
        setIsLoading(false);
        setUserContext(user);

        enqueueSnackbar(t("profile.dialogs.loginWithWallet.snackbar"), {
          variant: "success",
        });
        onSuccess();
      })
      .catch((error) => {
        if (error?.type === "metamask") {
          handleMetamaskError(error?.error);
        } else {
          handleApiError(error);
          onNoExistingAccountError();
        }

        setIsLoading(false);
      });
  };

  return (
    <MaterialDialog
      fullWidth={true}
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 60px 50px", mobile: "28px 10px 30px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "10px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogContent sx={{ p: { mobile: "5px 10px 17px", md: "0px" } }}>
        <CloseIcon
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <Typography variant={"h3"} sx={{ textAlign: "center", mb: "10px" }}>
          {t("profile.dialogs.loginWithWallet.loginWithWallet")}
        </Typography>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          {t("profile.dialogs.loginWithWallet.connectWallet")}
        </Typography>
        <Grid container>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mt: { mobile: "16px", md: "20px" },
              }}
            >
              <Typography
                variant={"subtitle1"}
                sx={{
                  textAlign: "center",
                  mb: "12px",
                  fontSize: "24px !important",
                  fontWeight: "600",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {t("profile.dialogs.loginWithWallet.step1")}
              </Typography>
              {!isConnected && (
                <LoginWithWallet.Button
                  title={
                    isMobile
                      ? t("profile.dialogs.loginWithWallet.connectButton")
                      : t("profile.dialogs.loginWithWallet.connectWalletButton")
                  }
                  onNoExistingAccountError={() => {}}
                />
              )}
              {isConnected && (
                <Box
                  onClick={openAccountModal}
                  sx={{
                    margin: { mobile: "0 auto", md: "unset" },
                    cursor: "pointer",
                    display: "flex",
                    width: "286px",
                    borderRadius: (theme) => theme.borderRadius.secondary,
                    justifyContent: "center",
                    height: "46px",
                    padding: "10px 26px",
                    alignItems: "center",
                    backgroundColor: (theme) =>
                      theme.palette.bgTransparent.primary,
                    transition: "all 0.2s ease-in-out",
                    border: "1px solid transparent",
                    "&:hover": {
                      border: "1px solid transparent",
                      filter:
                        "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.10))",
                      backgroundColor: (theme) =>
                        theme.palette.bgTransparent.secondary,
                    },
                  }}
                >
                  <Box
                    style={{
                      fontFamily: "Quantico",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: (theme) => theme.palette.text.secondary,
                      height: "60px",
                      cursor: "pointer",
                      border: "none",
                      borderRadius: "5px",
                      display: "flex",
                      backgroundColor: "transparent",
                      alignItems: "center",
                      ":hover": {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                  >
                    <StyledBadge
                      sx={{
                        "&:hover": {
                          color: "rgba(255, 255, 255, 1)",
                        },
                      }}
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      variant="dot"
                    >
                      {t("commons.connected")}
                    </StyledBadge>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                m: "30px 0 22px",
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Box
                component={"span"}
                sx={{
                  position: "absolute",
                  top: "50%",
                  borderBottom: "1px solid",
                  borderColor: (theme) => theme.palette.colors.gray,
                  width: {
                    md: isConnected ? "130px" : "130px",
                    mobile: isConnected ? "60px" : "80px",
                  },
                  left: {
                    md: isConnected ? "140px" : "90px",
                    mobile: isConnected ? "125px" : "80px",
                  },
                }}
              />
              <Typography variant={"subtitle1"} sx={{ mx: "20px" }}>
                {!isConnected ? t("commons.and") : t("commons.continueTo")}
              </Typography>
              <Box
                component={"span"}
                sx={{
                  position: "absolute",
                  top: "50%",
                  borderBottom: "1px solid",
                  borderColor: (theme) => theme.palette.colors.gray,
                  width: {
                    md: isConnected ? "130px" : "130px",
                    mobile: isConnected ? "60px" : "80px",
                  },
                  right: {
                    md: isConnected ? "140px" : "90px",
                    mobile: isConnected ? "125px" : "80px",
                  },
                }}
              />
            </Box>
            <Typography
              variant={"subtitle1"}
              sx={{
                textAlign: "center",
                mb: "12px",
                fontSize: "24px !important",
                fontWeight: "600",
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {t("profile.dialogs.loginWithWallet.step2")}
            </Typography>
            <Box
              onClick={() => {
                isConnected && signIn();
              }}
              sx={{
                opacity: isConnected ? 1 : 0.5,
                margin: { mobile: "0 auto", md: "unset" },
                cursor: isConnected ? "pointer" : "cursor",
                display: "flex",
                width: "286px",
                height: "46px",
                borderRadius: (theme) => theme.borderRadius.secondary,
                justifyContent: "center",
                p: "10px 26px",
                alignItems: "center",
                transition: "all 0.2s ease-in-out",
                backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                border: "1px solid transparent",
                "&:hover": {
                  border: "1px solid transparent",
                  filter:
                    isConnected &&
                    "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.10))",
                  backgroundColor: isConnected
                    ? (theme) => theme.palette.bgTransparent.secondary
                    : (theme) => theme.palette.bgTransparent.primary,
                },
              }}
            >
              {theme.palette.type === "dark" ? (
                <Box 
                  component={"img"} 
                  src={qvrseIcon} 
                  sx={{ width: "20px", height: "20px" }}
                />
              ) : (
                <Box
                  component={"img"}
                  src={qvrseIconDark}
                  sx={{ width: "20px", height: "20px" }}
                />
              )}
              <Typography variant={"h8"} sx={{ ml: "10px" }}>
                {t("profile.dialogs.loginWithWallet.signIn")}
              </Typography>
            </Box>
            <Typography
              variant={"subtitle3"}
              sx={{ textAlign: "center", mt: "30px" }}
            >
              {t("profile.dialogs.loginWithWallet.dontHaveWallet")}{" "}
              <Box
                onClick={() => {
                  showLogInDialog();
                }}
                component={"span"}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  transition: "all 0.2s ease-in-out",
                  color: (theme) => theme.palette.text.link,
                  "&:hover": {
                    color: (theme) => theme.palette.text.linkHover,
                  },
                }}
              >
                {t("profile.dialogs.loginWithWallet.login")}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.loginWithWallet.loading")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.loginWithWallet.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

const Action = ({ element: Component, onSuccess }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <LoginWithWalletDialog
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={(address) => {
          onSuccess(address);
          setOpen(false);
        }}
      />
    </>
  );
};

LoginWithWalletDialog.Action = Action;

export default LoginWithWalletDialog;
