import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { useDrag } from "react-dnd";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import shopingCartIcon from "../../../../../static/shoping-cart.png";
import React from "react";

const useStyles = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
  },
  categoryItem: {
    width: "85px",
    height: {md: "85px", mobile: 0},
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    background: "transparent",
    marginLeft: ({ isMobile, isFirstItem }) => {
      return isFirstItem ? "0px" : isMobile ? "10px" : "17px";
    },
    marginRight: ({ isMobile }) => {
      return isMobile ? "10px" : "17px";
    },
    borderRadius: theme.borderRadius.secondary,
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    boxShadow: ({ selected }) => {
      return selected ? "#944dff 0px 0px 10px 0px" : "none";
    },
    border: ({ selected }) => {
      return selected ? "2px solid #944DFF" : "2px solid transparent";
    },
    "&:hover": {
      boxShadow: "#944dff 0px 0px 10px 0px",
      border: "2px solid #944DFF",
    },
  },
}));

const Item = ({
  contentType,
  cardType,
  selected,
  index,
  contentData,
  onSelect,
  isFirstItem,
  collection,
}) => {
  const history = useHistory();
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: cardType.toString(),
      item: { index, nft: collection },
      canDrag: !collection.not_owned,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [collection]
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile, isFirstItem });

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: (theme) => theme.borderRadius.primary,
        cursor: "pointer",
      }}
      ref={dragRef}
      className={classes.categoryItem}
      onClick={(e) => {
        e.stopPropagation();
        onSelect(index);
      }}
      onTouchEnd={(e) => {
        e.stopPropagation();
        onSelect(index);
      }}
    >
      {contentType?.startsWith("video") && (
        <video
          playsInline
          width="100%"
          height={"auto"}
          autoplay="autoplay"
          loop
        >
          <source src={contentData} />
        </video>
      )}
      {contentType?.startsWith("image") && (
        <Box
          component={"img"}
          src={contentData}
          sx={{ width: "100%", height: "100%", opacity: opacity, borderRadius: "5px" }}
        />
      )}
      {collection.not_owned && (
        <Box
          onClick={() => {
            history.push(
              `/brand/${collection?.dao_id}/collection/${collection.collection_id}`
            );
          }}
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            backgroundColor: "#944DFF",
            width: "36px",
            height: "36px",
            borderRadius: "0px 20px 0px 0px",
          }}
        >
          <Box
            component={"img"}
            src={shopingCartIcon}
            sx={{
              width: "16px !important",
              position: "absolute",
              top: "13px",
              left: "7px",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Item;
