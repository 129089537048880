import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { useTranslation } from "react-i18next";


const DropdownMenu = ({ openMenu, onClose }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    openMenu && setAnchorEl(openMenu);
  }, [openMenu]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          width: { md: 200, mobile: 140 },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
          overflow: "visible",
          mt: 1.5,
          py: "8px",
          mx: "10px",
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 18,
            width: 10,
            height: 10,
            background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Typography
        variant={"subtitle4"}
        sx={{
          px: "16px",
          pt: "12px",
          pb: "12px",
        }}
      >
        {t("brands.albums.dropdown.collectionCard")}
      </Typography>
      <MenuItem
        sx={{ py: "0px", minHeight: "0px" }}
        onClick={() =>
          history.push({
            pathname: `${location.pathname}/create/collection`,
            state: { scrollToTop: true },
          })
        }
      >
        <Typography
          variant={"h9"}
          sx={{
            pl: "8px",
            py: "7px"
          }}
        >
          {t("brands.albums.dropdown.collections")}
        </Typography>
      </MenuItem>
      <Typography
        variant={"subtitle4"}
        sx={{
          px: "16px",
          pt: "16px",
          pb: "8px",
        }}
      >
        {t("brands.albums.dropdown.rewardCard")}
      </Typography>
      <MenuItem
        sx={{ py: "0px", minHeight: "0px" }}
        onClick={() =>
          history.push({
            pathname: `${location.pathname}/create/discount`,
            state: { scrollToTop: true },
          })
        }
      >
        <Typography
          variant={"h9"}
          sx={{
            pl: "8px",
            py: "7px"
          }}
        >
          {t("brands.albums.dropdown.discount")}
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{ py: "0px", minHeight: "0px" }}
        onClick={() =>
          history.push({
            pathname: `${location.pathname}/create/text`,
            state: { scrollToTop: true },
          })
        }
      >
        <Typography
          variant={"h9"}
          sx={{
            pl: "8px",
            py: "7px"
          }}
        >
          {t("brands.albums.dropdown.text")}
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{ py: "0px", minHeight: "0px" }}
        onClick={() =>
          history.push({
            pathname: `${location.pathname}/create/video`,
            state: { scrollToTop: true },
          })
        }
      >
        <Typography
          variant={"h9"}
          sx={{
            pl: "8px",
            py: "7px"
          }}
        >
          {t("brands.albums.dropdown.video")}
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{ py: "0px", minHeight: "0px" }}
        onClick={() =>
          history.push({
            pathname: `${location.pathname}/create/animation`,
            state: { scrollToTop: true },
          })
        }
      >
        <Typography
          variant={"h9"}
          sx={{
            pl: "8px",
            py: "7px"
          }}
        >
          {t("brands.albums.dropdown.animation")}
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{ py: "0px", minHeight: "0px" }}
        onClick={() =>
          history.push({
            pathname: `${location.pathname}/create/image`,
            state: { scrollToTop: true },
          })
        }
      >
        <Typography
          variant={"h9"}
          sx={{
            pl: "8px",
            py: "7px"
          }}
        >
          {t("brands.albums.dropdown.image")}
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{ py: "0px", minHeight: "0px" }}
        onClick={() =>
          history.push({
            pathname: `${location.pathname}/create/spatial`,
            state: { scrollToTop: true },
          })
        }
      >
        <Typography
          variant={"h9"}
          sx={{
            pl: "8px",
            py: "7px"
          }}
        >
          {t("brands.albums.dropdown.spatial")}
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{ py: "0px", minHeight: "0px" }}
        onClick={() =>
          history.push({
            pathname: `${location.pathname}/create/code`,
            state: { scrollToTop: true },
          })
        }
      >
        <Typography
          variant={"h9"}
          sx={{
            pl: "8px",
            py: "7px"
          }}
        >
          {t("brands.albums.dropdown.code")}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default DropdownMenu;
