import React, { useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import useService from "../useService";
// Import Swiper styles
// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Skeleton from "@mui/material/Skeleton";

import { Navigation, Autoplay } from "swiper/modules";

import "./styles.css";

import Typography from "@mui/material/Typography";

import CustomHooks from "../../@components/hooks";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  actionText: {
    backgroundImage:
      "linear-gradient(to right, #b185f2, #b185f2 50%, #ffffff 50%)",
    backgroundSize: "200% 100%",
    backgroundPosition: "-100%",
    display: "inline-block",
    padding: "5px 0",
    position: "relative",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    transition: "all 0.3s ease-in-out",
    "&:before": {
      content: '""',
      background: "#b185f2",
      display: "block",
      position: "absolute",
      bottom: "-2px",
      left: 0,
      width: 0,
      height: "2px",
      transition: "all 0.3s ease-in-out",
    },
    "&:hover": {
      backgroundPosition: "0",
    },
    "&:hover::before": {
      width: "100%",
    },
  },
}));

const Item = ({ item }) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [imageLoaded, setImageLoaded] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isExternalUrl = item.action.url.includes("https");

  return (
    <Box
      sx={{
        position: "relative",
        maxHeight: { md: "652px", xxl: "652px" },
      }}
    >
      <>
        {!imageLoaded && <SkeletonItem />}
        <Box
          component={"img"}
          onLoad={() => setImageLoaded(true)}
          sx={{
            inset: "0px",
            borderRadius: "8px !important",
            boxSizing: "border-box",
            padding: "0px",
            border: "none",
            margin: "auto",
            display: "block",
            width: "0px",
            height: "0px",
            minWidth: "100%",
            maxWidth: "100%",
            minHeight: "100%",
            maxHeight: "100%",
            objectFit: "cover",
            ...{ visibility: imageLoaded ? "visible" : "hidden" },
          }}
          src={
            isMobile
              ? item.mobile_background_image_url || item.background_image_url
              : item.background_image_url
          }
        />
      </>

      <Box
        sx={{
          position: "absolute",
          borderRadius: "8px !important",
          display: imageLoaded ? "flex" : "none",
          width: "100%",
          height: "100%",
          left: "0",
          top: "0",
          background: {
            md: "linear-gradient(90deg, rgba(8, 10, 25, 0.35) 0%, rgba(8, 10, 25, 0.15) 24.92%, rgba(8, 10, 25, 0) 100%);",
            mobile:
              "linear-gradient(0deg, rgba(8, 10, 25, 0.35) 50%, rgba(8, 10, 25, 0.15) 74.92%, rgba(8, 10, 25, 0) 100%);",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            ml: { md: "8%", mobile: "7%" },
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: { mobile: "absolute", md: "unset" },
              bottom: { mobile: "44px", md: "unset" },
              pr: { mobile: "15px", md: "unset" },
            }}
          >
            <Box>
              <Typography
                variant={"h1"}
                sx={{
                  color: "#fff",
                }}
              >
                {i18n.language === "sl"
                  ? item.title_slo || item.title
                  : item.title}
              </Typography>
              <Typography
                variant={"subtitle1"}
                sx={{
                  fontSize: {
                    md: "18px !important",
                    mobile: "16px !important",
                  },
                  color: "#fff",
                  opacity: "0.7",
                  mt: { md: "8px", mobile: "3px" },
                }}
              >
                {i18n.language === "sl"
                  ? item.subtitle_slo || item.subtitle
                  : item.subtitle}
              </Typography>
            </Box>
            {isExternalUrl && (
              <Box
                sx={{
                  cursor: "pointer",
                  mt: { md: "18px", mobile: "10px" },
                  borderBottom: "2px solid #FFFFFF",
                  width: "fit-content",
                }}
              >
                <Link
                  target="_blank"
                  to={{ pathname: item.action.url }}
                  underline="hover"
                >
                  <Typography className={classes.actionText} variant={"h6"}>
                    {i18n.language === "sl"
                      ? item.action.text_sl || "Več"
                      : item.action.text}
                  </Typography>
                </Link>
              </Box>
            )}
            {!isExternalUrl && (
              <Box
                onClick={() => {
                  history.push({
                    pathname: item.action.url,
                    state: { scrollToTop: true },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  mt: { md: "18px", mobile: "10px" },
                  borderBottom: "2px solid #FFFFFF",
                  width: "fit-content",
                }}
              >
                <Typography className={classes.actionText} variant={"h6"}>
                  {i18n.language === "sl"
                    ? item.action.text_sl || "Več"
                    : item.action.text}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SkeletonItem = () => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxHeight: { md: "652px", xl: "652px" },
      }}
    >
      <Skeleton
        variant={"rectangular"}
        sx={{
          display: "block",
          width: "100%",
          borderRadius: (theme) => theme.borderRadius.primary,
          height: { md: "460px", xl: "652px", mobile: "400px" },
          margin: 0,
        }}
      />
    </Box>
  );
};

const FeaturedCollections = () => {
  const service = useService();

  const { data: items, isLoading } = CustomHooks.useFetch(
    ["news"],
    service.featured
  );

  return (
    <>
      <Swiper
        initialSlide={1}
        grabCursor={true}
        slidesPerView={1}
        spaceBetween={20}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="featuredCollections"
      >
        {items &&
          !isLoading &&
          items?.length > 0 &&
          items.map((item) => (
            <SwiperSlide key={item.id}>
              <Item item={item} />
            </SwiperSlide>
          ))}
      </Swiper>
      {isLoading && <SkeletonItem />}
    </>
  );
};

export default FeaturedCollections;
