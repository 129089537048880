import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import UI from "../../../../@components/UI";
import applyLaterIcon from "../../../../static/apply-later-icon.png";
import { useTranslation } from "react-i18next";

const ApplyLaterDialog = ({ toggleOpen, onConfirm }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
          ppadding: { md: "40px 60px 50px", mobile: "28px 10px 30px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "15px" },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: "0px" }}>
        <CloseIcon
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 30,
            top: 36,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Box
              component={"img"}
              src={applyLaterIcon}
              sx={{
                width: "60px",
                height: "68px",
                display: "flex",
                margin: "0 auto",
              }}
            />
            <Typography
              variant={"h3"}
              sx={{
                textAlign: "center",
                mt: { md: "35px", mobile: "26px" },
              }}
            >
              {t("applyYourBrand.apply.applyLaterDialog.title")}
            </Typography>
            <Typography variant={"subtitle2"} sx={{ m: { mobile: "10px 10px 0", md: "16px 10px 0" } }}>
              {t("applyYourBrand.apply.applyLaterDialog.subtitle")}
            </Typography>

            <Box sx={{ mt: "35px", px: "10px" }}>
              <UI.Button
                onClick={() => onConfirm()}
                type={"primary"}
                title={t("buttons.ok")}
                sx={{ width: "200px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default ApplyLaterDialog;
