import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useCollectionService from "../useService";
import UI from "../../../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useAccount } from "wagmi";
import Ticket from "./Ticket";
import Skeleton from "@mui/material/Skeleton";
import Admin from "./admin";
import Payment from "./payment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "../../../../static/info-icon.png";
import CustomHooks from "../../../../@components/hooks";
import { useSnackbar } from "notistack";
import { useUserRole } from "../../context";
import BuyButton from "./BuyButton";
import truncateEthAddress from "truncate-eth-address";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import SupriseBag from "./SupriseBag";
import SupriseBagMobile from "./SurpriseBagMobile";
import ChooseYourNFT from "./ChooseYourNFT";
import ChooseYourNFTMobile from "./ChooseYourNFTMobile";
import DragDropTicket from "./DragDropTicket";
import UserNFTs from "./UserNFTs";
import { useUserContext } from "../../../../@components/userContext";

const Index = ({ DAO }) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const { user: loggedInUser } = useUserContext();
  const { isBrandMember } = useUserRole();
  const history = useHistory();
  const { id } = useParams();
  const { address } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const collectionService = useCollectionService(DAO?.id);
  const [mainUtility, setMainUtility] = React.useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [selectedNFT, setSelectedNFT] = React.useState(null);

  let query = CustomHooks.useQuery();
  const qvrsePayment = query.get("qvrsePayment");
  const paymentWithselectedNFT = query.get("selectedNFT");

  const paymentSuccessful = () => {
    enqueueSnackbar(
      t("brands.collection.collectionPage.snackbar.paymentSuccess"),
      {
        variant: "success",
      }
    );
    history.push({
      pathname: `${pathname}/payment/success`,
      state: { scrollToTop: true, selectedNFT: paymentWithselectedNFT || null },
    });
  };
  if (qvrsePayment === "success") {
    paymentSuccessful();
  }

  const {
    data: collection,
    refetch,
    isLoading,
  } = CustomHooks.useFetch(
    ["full_collection"],
    () => collectionService.collection(id),
    {
      enabled: false,
      onSuccess: async (collection) => {
        setMainUtility(
          collection?.utilities.find((utility) => utility.is_main_utility)
        );
      },
    }
  );

  React.useEffect(() => {
    DAO && id && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DAO, id]);

  const { mutate: toggleVisiblity, isLoading: isChangingVisibility } =
    CustomHooks.usePost(collectionService.toggleVisibility, {
      onSuccess: async () => {
        enqueueSnackbar(
          t("brands.collection.collectionPage.snackbar.visibilityChanged"),
          {
            variant: "success",
          }
        );
        refetch();
      },
    });

  const { mutate: toggleEnablePurchasing } = CustomHooks.usePost(
    collectionService.toggleEnablePurchase,
    {
      onSuccess: async () => {
        enqueueSnackbar(
          t("brands.collection.collectionPage.snackbar.enablePurchase"),
          {
            variant: "success",
          }
        );
        refetch();
      },
    }
  );

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <UI.Busy.FullscreenIndicator show={isChangingVisibility}>
        <Typography variant={"subtitle1"}>
          {t("brands.collection.collectionPage.loadingVisibility")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("brands.collection.collectionPage.loadingVisibility2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: { mobile: "none", md: "flex" },
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "0px", mobile: "10px" },
            pb: "30px",
            pt: { md: "0px", mobile: "20px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: { md: "block", mobile: "none" } }}>
              <UI.BackButton
                title={t("brands.collection.collectionPage.backButton")}
                onClick={() =>
                  history.push({
                    pathname: `/brand/${DAO?.id}/collection`,
                    state: { scrollToTop: true },
                  })
                }
                address={collection?.nft_address}
              />
            </Box>
            {DAO?.maintainer_address?.toLowerCase() ===
              address?.toLowerCase() && (
              <Box
                sx={{
                  display: { mobile: "block", md: "none" },
                }}
              >
                <Tooltip title={t("commons.admin")} arrow>
                  <AdminPanelSettingsIcon
                    sx={{
                      fontSize: "50px",
                      color: "#944dff",
                      cursor: "pointer",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `${pathname}/admin`,
                        state: {
                          scrollToTop: false,
                          daoId: DAO?.id,
                          collectionId: id,
                          collection: collection,
                        },
                      })
                    }
                    aria-label="admin"
                    aria-haspopup="true"
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex" }}>
            {isBrandMember && (
              <Box sx={{ display: "flex", mr: "55px" }}>
                <UI.SwitchButton
                  leftText={t(
                    "brands.collection.collectionPage.visibilityButton.private"
                  )}
                  rightText={t(
                    "brands.collection.collectionPage.visibilityButton.public"
                  )}
                  isChecked={collection?.visible}
                  onChange={() =>
                    toggleVisiblity({
                      collectionId: collection?.id,
                      isVisible: !collection?.visible,
                    })
                  }
                />
              </Box>
            )}
            <Box sx={{ display: { md: "flex", mobile: "none" } }}>
              <Tooltip
                title={t("brands.collection.collectionPage.qSchool")}
                arrow
              >
                <Box sx={{ mr: "14px" }}>
                  <Link
                    target="_blank"
                    href={"https://qschool.qvrse.io/first_steps.php"}
                    underline="hover"
                  >
                    <IconButton
                      sx={{
                        p: "0px",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                      onClick={() => {}}
                      aria-label="qschool"
                      aria-haspopup="true"
                    >
                      <img
                        alt={"qschool"}
                        src={InfoIcon}
                        width={"36px"}
                        height={"100%"}
                      />
                    </IconButton>
                  </Link>
                </Box>
              </Tooltip>
            </Box>
            {isBrandMember && (
              <Box
                sx={{
                  display: { mobile: "none", md: "block" },
                }}
              >
                <Tooltip title={t("commons.admin")} arrow>
                  <AdminPanelSettingsIcon
                    sx={{
                      fontSize: "50px",
                      mt: "-5px",
                      color: "#944dff",
                      cursor: "pointer",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `${pathname}/admin`,
                        state: {
                          scrollToTop: false,
                          daoId: DAO?.id,
                          collectionId: id,
                          collection: collection,
                        },
                      })
                    }
                    aria-label="admin"
                    aria-haspopup="true"
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            mt: { mobile: "15px", md: "0px" },
            mb: { mobile: "40px", md: "0px" },
            width: "100%",
            paddingLeft: { mobile: "10px", md: "0px" },
            paddingRight: { mobile: "10px", md: "0px" },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={4} mobile={12}>
              <Box
                sx={{
                  display: "flex",
                  mb: { mobile: "54px", md: "34px" },
                  justifyContent: { mobile: "center", md: "unset" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {collection?.manual_nft_selection === false && (
                    <Ticket
                      isFreeNFT={collection?.total_price_with_tax === "0.00"}
                      brandName={DAO?.name}
                      brandAvatar={DAO?.avatar_url}
                      name={collection?.name}
                      name_slo={collection?.name_slo}
                      fileType={collection?.nft_file_type}
                      media={collection?.media}
                      data={collection?.nft_image_url}
                      animationUrl={collection?.nft_animation_url || null}
                      mainUtility={mainUtility}
                    />
                  )}
                  {collection?.manual_nft_selection === true && (
                    <DragDropTicket
                      selectedNFT={selectedNFT}
                      isFreeNFT={collection?.total_price_with_tax === "0.00"}
                      brandName={DAO?.name}
                      brandAvatar={DAO?.avatar_url}
                      name={collection?.name}
                      name_slo={collection?.name_slo}
                      fileType={selectedNFT?.file_type}
                      data={collection?.nft_image_url}
                      mainUtility={mainUtility}
                    />
                  )}
                </Box>
              </Box>
              {collection?.has_multiple_images === true &&
                collection?.manual_nft_selection === true &&
                isMobile && (
                  <ChooseYourNFTMobile
                    selected={selectedNFT}
                    collection={collection}
                    onSelect={(item) => setSelectedNFT(item)}
                  />
                )}
              {collection?.has_multiple_images === true &&
                collection?.manual_nft_selection === false &&
                isMobile && <SupriseBagMobile collection={collection} />}
              <Box
                sx={{
                  width: "250px",
                  margin: "auto",
                  mt: "34px",
                  mb: "34px",
                  display: { md: "none", mobile: "block" },
                }}
              >
                <BuyButton
                  selectedNFT={selectedNFT}
                  DAO={DAO}
                  collection={collection}
                  isBrandMember={isBrandMember}
                  toggleVisiblity={toggleVisiblity}
                  toggleEnablePurchasing={toggleEnablePurchasing}
                />
              </Box>
            </Grid>
            <Grid item md={8} mobile={12}>
              <Box sx={{ ml: { md: "60px", mobile: "0px" } }}>
                <Box sx={{ mb: "2px" }}>
                  {DAO?.name ? (
                    <Typography variant={"subtitle3"}>{DAO?.name}</Typography>
                  ) : (
                    <Skeleton
                      sx={{ mb: "10px" }}
                      height={"15px"}
                      width={"300px"}
                      variant={"rectangular"}
                    />
                  )}
                </Box>
                <Box sx={{ mb: "10px" }}>
                  {collection?.name ? (
                    <Typography variant={"h2"}>
                      {collection?.name_slo !== null &&
                      collection?.name_slo !== "" &&
                      i18n.language === "sl"
                        ? collection?.name_slo
                        : collection?.name || ""}
                    </Typography>
                  ) : (
                    <Skeleton
                      height={"30px"}
                      width={"300px"}
                      variant={"rectangular"}
                    />
                  )}
                </Box>
                {collection ? (
                  <Box
                    sx={{ mb: { md: "5px", mobile: "20px" }, display: "flex" }}
                  >
                    <UI.UtilityTag utilityType={mainUtility?.type} />
                    <UI.Tag
                      sx={{
                        ml: "10px",
                        backgroundColor: (theme) => theme.palette.tag.green,
                      }}
                    >
                      {collection?.is_imported
                        ? t("utilityTag.imported")
                        : t("utilityTag.limited")}
                    </UI.Tag>
                  </Box>
                ) : (
                  <Skeleton
                    sx={{ mb: "30px", display: "flex" }}
                    height={"30px"}
                    width={"300px"}
                    variant={"rectangular"}
                  />
                )}
                {isMobile && (
                  <Box
                    sx={{
                      mb: "20px",
                    }}
                  >
                    <Typography
                      variant={"h9"}
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      <UI.CopyToClipboard
                        label={truncateEthAddress(
                          collection?.nft_address || ""
                        )}
                        value={collection?.nft_address || ""}
                      />
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    mt: { mobile: "0px", md: "28px" },
                    mb: { mobile: "24px", md: "26px" },
                    wordBreak: "break-word",
                  }}
                >
                  {collection?.description && (
                    <UI.ReadMore fontSize={"16px"} maxTextLength={400}>
                      {collection?.description_slo !== null &&
                      collection?.description_slo !== "" &&
                      i18n.language === "sl"
                        ? collection?.description_slo
                        : collection?.description || ""}
                    </UI.ReadMore>
                  )}
                </Box>
                {collection ? (
                  <>
                    {!collection.is_imported && (
                      <Box
                        sx={{
                          mb: "11px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography variant={"subtitle1"}>
                            {t("commons.price")}
                          </Typography>
                          <Typography
                            variant={"subtitle1"}
                            sx={{
                              ml: "4.5px",
                              fontSize: "11px !important",
                              mt: "4.3px",
                            }}
                          >
                            {t("commons.withTax")}
                          </Typography>
                        </Box>

                        {collection?.total_price_with_tax > 0 && (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{ width: "20px", height: "20px", ml: "10px" }}
                            >
                              <UI.PaymentIcon currency={collection?.currency} />
                            </Box>
                            <Typography
                              variant={"h1"}
                              sx={{
                                fontSize: "15px !important",
                                ml: "10px",
                                lineHeight: "unset !important",
                              }}
                            >
                              {`${collection?.total_price_with_tax} ${collection?.currency}`}
                            </Typography>
                          </Box>
                        )}
                        {collection?.total_price_with_tax === "0.00" && (
                          <Typography
                            variant={"h1"}
                            sx={{
                              fontSize: "15px !important",
                              ml: "10px",
                              lineHeight: "unset !important",
                            }}
                          >
                            {t("commons.free")}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                ) : (
                  <Skeleton
                    sx={{ mb: "10px" }}
                    height={"15px"}
                    width={"300px"}
                    variant={"rectangular"}
                  />
                )}
                {collection ? (
                  <>
                    {!collection.is_imported && (
                      <Box
                        sx={{
                          mb: { mobile: "22px", md: "31px" },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant={"subtitle1"}>
                          {t("commons.availability")}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant={"h1"}
                            sx={{
                              fontSize: "15px !important",
                              ml: "10px",
                              lineHeight: "unset !important",
                            }}
                          >
                            {`${collection?.nft_amount_minted}/${collection?.nft_amount_limit}`}
                          </Typography>
                          {collection?.nft_amount_limit -
                            collection?.nft_amount_minted <=
                          0 ? (
                            <UI.Tag
                              sx={{
                                ml: "12px",
                                mb: "1px",
                                backgroundColor: (theme) =>
                                  theme.palette.tag.red,
                              }}
                            >
                              {t("commons.missedChance")}
                            </UI.Tag>
                          ) : collection?.nft_amount_limit -
                              collection?.nft_amount_minted <=
                            5 ? (
                            <UI.Tag
                              sx={{
                                ml: "12px",
                                mb: "1px",
                                backgroundColor: (theme) =>
                                  theme.palette.tag.green,
                              }}
                            >
                              {t("brands.collection.collectionPage.onlyLeft", {
                                amount:
                                  collection?.nft_amount_limit -
                                  collection?.nft_amount_minted,
                              })}
                            </UI.Tag>
                          ) : null}
                        </Box>
                      </Box>
                    )}
                  </>
                ) : (
                  <Skeleton
                    sx={{ mb: "10px" }}
                    height={"15px"}
                    width={"300px"}
                    variant={"rectangular"}
                  />
                )}

                <Box sx={{ display: { md: "block", mobile: "none" } }}>
                  <BuyButton
                    selectedNFT={selectedNFT}
                    DAO={DAO}
                    collection={collection}
                    isBrandMember={isBrandMember}
                    toggleEnablePurchasing={toggleEnablePurchasing}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              {collection?.has_multiple_images === true &&
                collection?.manual_nft_selection === false &&
                !isMobile && <SupriseBag collection={collection} />}

              {collection &&
                collection?.has_multiple_images === true &&
                collection?.manual_nft_selection === true &&
                !isMobile && (
                  <ChooseYourNFT
                    selected={selectedNFT}
                    collection={collection}
                    onSelect={(item) => setSelectedNFT(item)}
                  />
                )}
              {!isLoading && collection && id && DAO && loggedInUser && (
                <UserNFTs collectionId={id} DAO={DAO} />
              )}
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box sx={{ mb: { mobile: "10px", md: "15px" } }}>
                <Typography
                  variant={"h2"}
                  sx={{
                    fontSize: {
                      mobile: "18px !important",
                      md: "22px !important",
                    },
                  }}
                >
                  {t("commons.perks")}
                </Typography>
              </Box>
              <UI.Utilities
                showCustomLinks={false}
                gasFeesPayedBySystem={
                  collection?.free_nft_gas_fee_payed_by_system
                }
                utilities={collection?.utilities}
                currency={collection?.currency}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

Index.Admin = Admin;
Index.Payment = Payment;
export default Index;
