import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Collections from "./collections";
import Cards from "./cards";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/BorderColor";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Edit from "./edit";
import useService from "../useService";
import CustomHooks from "../../../../@components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { MultiBackend, Preview, DndProvider } from "react-dnd-multi-backend";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import UI from "../../../../@components/UI";
import Skeleton from "@mui/material/Skeleton";
import { useUserRole } from "../../context";

const IndexComponent = ({ DAO }) => {
  const { albumId } = useParams();
  const service = useService(DAO?.id);
  const { isBrandMember } = useUserRole();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [collections, setCollections] = React.useState([]);
  const [isSticky, setIsSticky] = React.useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [page, setPage] = React.useState(0);

  // Effect to handle scroll event
  React.useEffect(() => {
    const handleScroll = () => {
      const sticky = window.scrollY > 0;
      setIsSticky(sticky);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { data: album, refetch: fetchAlbum } = CustomHooks.useFetch(
    ["album"],
    () => service.album(albumId),
    {
      enabled: false,
    }
  );

  const {
    mutate: fetchMissingCollections,
    isLoading: loadingMissingCollections,
  } = CustomHooks.usePost(service.missingCollections, {
    onSuccess: (response) => {
      //map every collection id to collection_id and add property not_owned=true
      response = response.map((collection) => {
        return {
          ...collection,
          collection_id: collection.id,
          not_owned: true,
        };
      });
      setCollections((prev) => [...prev, ...response]);
    },
  });

  const {
    data: allFetchedCollections,
    refetch,
    isLoading: loadingOwnedCollections,
  } = CustomHooks.useFetch(["collection"], () => service.collections(albumId), {
    enabled: false,
    onSuccess: (collections) => {
      //setCollections(collections);
    },
  });

  React.useEffect(() => {
    if (DAO) {
      refetch(albumId);
      fetchAlbum(albumId);
    }
    // eslint-disable-next-line
  }, [DAO]);

  const filterCollections = async (collectionIds) => {
    // Filter collections that are present in allFetchedCollections
    const filteredCollections = allFetchedCollections.filter((collection) =>
      collectionIds.includes(collection.collection_id)
    );

    // Find the collectionIds that are not present in allFetchedCollections
    const missingCollectionIds = collectionIds.filter(
      (id) =>
        !allFetchedCollections.some(
          (collection) => collection.collection_id === id
        )
    );

    if (missingCollectionIds.length > 0) {
      await fetchMissingCollections({
        ids: missingCollectionIds,
      });
    }

    // Combine the filtered and fetched collections
    const combinedCollections = [...filteredCollections];
    // Update the state with combined collections
    if (filteredCollections.length > 0) {
      setCollections(combinedCollections);
    } else {
      setCollections([]);
    }
  };

  const generatePreview = ({ item, style }) => {
    const { index, nft: collection } = item;

    return (
      <Box style={{ ...style, zIndex: 999 }}>
        <Collections.Slider.Item
          cardType={collection.collection_id}
          selected={true}
          onSelect={() => {}}
          collection={collection}
          contentType={collection.nft_file_type}
          contentData={collection.nft_image_url}
          index={index}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: { mobile: "0px 10px", md: "0px" },
      }}
    >
      <UI.BackButton
        title={t("brands.albums.backToAlbums")}
        onClick={() => history.push(`/brand/${DAO.id}/album`)}
      />
      <Preview>{generatePreview}</Preview>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: { mobile: "22px", md: "20px" },
          mt: { mobile: "20px", md: "40px" },
        }}
      >
        <Box sx={{ display: "flex" }}>
          {album != null ? (
            <Typography variant={"h3"}>{album?.name}</Typography>
          ) : (
            <Skeleton
              height={"40px"}
              width={"130px"}
              variant={"rectangular"}
              sx={{
                backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                borderRadius: (theme) => theme.borderRadius.primary,
              }}
            />
          )}
          {isBrandMember && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                width: { md: "36px", mobile: "32px" },
                height: { md: "36px", mobile: "32px" },
                ml: { md: "12px", mobile: "10px" },
                borderRadius: "50%",
                backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                "&:hover": {
                  transform: "scale(1.05)",
                  backgroundColor: (theme) =>
                    theme.palette.bgTransparent.secondary,
                },
              }}
            >
              <Tooltip title={t("actions.edit")} arrow>
                <EditIcon
                  onClick={() => {
                    history.push(`${location.pathname}/edit`);
                  }}
                  sx={{ fontSize: { md: "18px", mobile: "16px" } }}
                />
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mb: { mobile: "22px", md: "20px" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant={"subtitle1"}>
          {t("brands.albums.collected")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Typography variant={"h6"} sx={{ ml: "10px" }}>
            {`${album?.amount_collected || 0}/${
              album?.max_collect_amount || 0
            }`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mb: "30px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant={"subtitle2"}>{album?.description}</Typography>
      </Box>
      {DAO && collections && (
        <Box
          sx={{
            mb: { mobile: "30px", md: "40px" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant={"h6"}>
            {t("brands.albums.collectionsNeeded")}{" "}
          </Typography>
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              px: { md: "30px", mobile: "20px" },
              py: "30px",
              mt: "12px",
              height: { md: "142px", mobile: "100px" },
              borderRadius: (theme) => theme.borderRadius.primary,
              position: isSticky ? "sticky" : "relative",
              top: isSticky ? (!isMobile ? 82 : 10) : "auto",
              zIndex: isSticky ? 1000 : 1, // Ensure it's above other content
              background: (theme) => theme.palette.bgTransparent.primary,
              backdropFilter: "blur(30px)",
            }}
          >
            <Box
              sx={{
                display:
                  collections?.length > 10 && page > 0 ? "block" : "none",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#944dff",
                position: "absolute",
                left: "-20px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              <ArrowBackIosIcon
                onClick={() => {
                  if (page >= 10) {
                    setPage(page - 10);
                  }
                }}
                sx={{ position: "absolute", top: "8px", left: "13px" }}
              />
            </Box>
            <Collections.Slider
              page={page}
              collections={collections}
              DAO={DAO}
              isLoading={loadingMissingCollections || loadingOwnedCollections}
              onSelect={() => console.log("selected")}
            />
            <Box
              sx={{
                display:
                  collections?.length > 10 && page + 10 < collections.length - 1
                    ? "block"
                    : "none",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#944dff",
                position: "absolute",
                right: "-20px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              <ArrowForwardIosIcon
                onClick={() => {
                  if (page + 10 < collections.length - 1) {
                    setPage(page + 10);
                  }
                }}
                sx={{ position: "absolute", top: "8px", left: "10px" }}
              />
            </Box>
          </Box>
          <Box sx={{ mt: "18px" }}>
            <Cards
              DAO={DAO}
              onFilterCollections={(collectionIds) => {
                setPage(0);
                filterCollections(collectionIds);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
const Index = ({ DAO }) => {
  return (
    <>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <IndexComponent DAO={DAO} />
      </DndProvider>
    </>
  );
};

Index.Edit = Edit;
export default Index;
