import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import ArrowForwardIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { Link } from "react-router-dom";

const NewsHeader = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        alignItems: "center",
        mb: { md: "38px", mobile: "22px" },
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          fontSize: { md: "30px !important", mobile: "26px !important" },
          textAlign: { md: "center", mobile: "center" },
        }}
      >
        {t("explore.news.title")}
      </Typography>
    </Box>
  );
};

const NewsFooter = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Link to={{ pathname: "https://blog.qvrse.io/" }} target="_blank">
        <UI.Button
          type={"primary"}
          size={"small"}
          sx={{
            mt: { md: "20px", mobile: "10px" },
            width: { md: "240px", mobile: "330px" },
            height: "46px",
            mr: { md: "0px", mobile: "10px" },
          }}
          title={t("explore.news.button.seeAll")}
        />
      </Link>
    </Box>
  );
};

const NewsItem = ({ item }) => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        width: { md: "385px", mobile: "300px" },
        height: "auto",
        borderRadius: (theme) => theme.borderRadius.primary,
        cursor: "pointer",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
        },
      }}
    >
      <img
        src={item.image}
        alt={item.title}
        style={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
          borderRadius: "10px 10px 0 0",
        }}
      />
      <Box p={{ md: "22px 32px 38px", mobile: "12px 18px 38px" }}>
        <Box
          sx={{
            mb: "12px",
            mt: "5px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              borderRadius: "100px",
              backgroundColor: (theme) =>
                theme.palette.bgTransparent.quaternary,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              p: "4px 14px",
            }}
          >
            <Typography variant={"h10"}>
              {i18n.language === "sl" ? item.kategorija : item.category}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Typography variant={"subtitle4"}>
              {i18n.language === "sl" ? item.datum : item.date}
            </Typography>
          </Box>
        </Box>

        <Typography variant={"h3"} sx={{ mb: 2, lineHeight: "1.4", mt: 3 }}>
          {i18n.language === "sl" ? item.naslov : item.title}
        </Typography>
        <Typography variant={"subtitle2"} sx={{ mb: 2 }}>
          {i18n.language === "sl" ? item.opis : item.description}
        </Typography>
        <Link
          to={{
            pathname: item.link,
          }}
          target="_blank"
        >
          <UI.Button
            type={"secondary"}
            size={"small"}
            sx={{
              mt: { md: "24px", mobile: "14px" },
              width: "140px",
              height: "46px",
              display: "flex",
              p: "0",
            }}
            title={
              <Box
                display={"flex"}
                justifyContent={"spaceBetween"}
                alignItems={"center"}
              >
                <Typography variant={"h8"} sx={{ ml: "8px" }}>
                  {t("explore.news.button.readMore")}
                </Typography>
                <ArrowForwardIcon
                  sx={{
                    fontSize: "22px",
                    ml: "5px",
                    color: (theme) => theme.palette.text.primary,
                  }}
                />
              </Box>
            }
          />
        </Link>
      </Box>
    </Box>
  );
};

const NewsSlider = ({ news }) => {
  return (
    <>
      <NewsHeader />
      <Swiper
        pagination={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={false}
        spaceBetween={10}
        modules={[Autoplay, Pagination, Navigation]}
        grabCursor={true}
        slidesPerView={1.2}
        breakpoints={{
          600: {
            slidesPerView: 3,
          },
          1800: {
            slidesPerView: 4,
          },
        }}
        className="newsSwiper"
      >
        {news.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                display: "flex !important",
                justifyContent: "center !important",
              }}
            >
              <NewsItem item={item} />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <NewsFooter />
    </>
  );
};

const Index = () => {
  const newsData = [
    {
      category: "NFT certificate",
      kategorija: "NFT certifikat",
      date: "5 Apr 2024",
      datum: "5. apr. 2024",
      title: "DIVINE KALY NFT CERTIFIED IMAGES",
      naslov: "DIVINE KALY NFT CERTIFICIRANE SLIKE",
      description:
        "Kaly Kolonič Potočnik presents something new with her brand of energy paintings ...",
      opis: "Kaly Kolonič Potočnik s svojo znamko energijskih slik predstavlja nekaj novega ...",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/04/BeautyPlus_20240403183935710_save-720x380.jpg",
      link: "https://blog.qvrse.io/2024/04/05/divine-kaly-nft-certificirane-slike/",
    },
    {
      category: "The virtual world",
      kategorija: "Virtualni svet",
      date: "7 Feb 2024",
      datum: "7. febr. 2024",
      title: "MRFYLAND RULES ON QVRSE",
      naslov: "MRFYLAND KRALJUJE NA QVRSU",
      description:
        "MRFY CARD 2.0, which opens the door to an even better MRFYLAND ...",
      opis: "MRFY CARD 2.0, ki odpira vrata v še boljši MRFYLAND, je pošla v pičlih 45 minutah!",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/02/Posnetek-zaslona-2024-02-07-211650-585x380.png",
      link: "https://blog.qvrse.io/2024/02/07/mrfyland-kraljuje-na-qvrsu/",
    },
    {
      category: "The virtual world",
      kategorija: "Virtualni svet",
      date: "30 Jan 2024",
      datum: "30 jan. 2024",
      title: "TONY LAND GUESTS AT THE QVRSE ARENA",
      naslov: "TONY LAND GOSTUJE V QVRSE ARENI",
      description:
        "Tony Cetinski will present his new AI spot in the most innovative way!",
      opis: "Tony Cetinski bo svoj novi AI spot predstavil na najbolj inovativen način!",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/01/TONY-CETINSKI-ADI-SOSE-SVE-ILI-NISTA-Screenshot-29-720x380.jpg",
      link: "https://blog.qvrse.io/2024/01/30/tony-land-gostuje-v-qvrse-areni/",
    },
    {
      category: "NFT souvenir",
      kategorija: "NFT spominek",
      date: "26 Jan 2024",
      datum: "26 jan. 2024",
      title: "1st NFT collection by Tony Cetinski",
      naslov: "1. NFT zbirka Tonyja Cetinskega",
      description:
        "Through the virtual party, you can choose the best Tony Cetinski hairstyle!",
      opis: "Preko virtualne zabave lahko izbereš najboljšo pričesko Tonyja Cetinskega!",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/01/cetinski.d9d12410-1.jpg",
      link: "https://blog.qvrse.io/2024/01/26/tony-hair/",
    },
  ];

  return (
    <div>
      <NewsSlider news={newsData} />
    </div>
  );
};

export default Index;
