import React from "react";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import useService from "../../../useService";
import CustomHooks from "../../../../../../@components/hooks";

const Dialog = ({ albumSpace, DAO, open, onClose, data, refetch }) => {
  const { albumId } = useParams();
  const service = useService(DAO?.id);

  const { mutate: submitCard } = CustomHooks.usePost(service.submitCard, {
    onSuccess: async () => {
      await refetch();
    },
  });

  React.useEffect(() => {
    if (open && albumSpace.album_card.length === 0) {
      submitCard({
        album_id: albumId,
        album_space_type: albumSpace.album_space_type,
        space_id: data.id,
      });
    }

    // eslint-disable-next-line
  }, [open]);
  return (
    <MaterialDialog
      sx={{
        "& .MuiPaper-root": {
          background: "transparent",
          boxShadow: "none",
          maxWidth: "max-content",
        },
      }}
      PaperProps={{
        maxWidth: "1200px",
      }}
      open={open}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 15,
          top: 15,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogContent sx={{ p: "0px" }}>
        <Box sx={{ mt: "10px" }}>
          <Box
            sx={{
              maxHeight: { md: "600px", mobile: "auto" },
              width: { md: "auto", mobile: "100%" },
              margin: "0 auto",
            }}
            component={"img"}
            src={data.data_json.images.ipfs}
          />
        </Box>
      </DialogContent>
    </MaterialDialog>
  );
};

const Action = ({ element: Component, data, DAO, albumSpace, refetch }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box
        sx={{ cursor: "pointer" }}
        component={"span"}
        onClick={() => setOpen(!open)}
      >
        {Component}
      </Box>

      <Dialog
        refetch={refetch}
        DAO={DAO}
        open={open}
        albumSpace={albumSpace}
        onClose={() => setOpen(false)}
        data={data}
      />
    </>
  );
};

Dialog.Action = Action;

export default Dialog;
