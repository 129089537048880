import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import EmailConfirmationIcon from "../../static/email-confirmation.png";
import { useUserContext } from "../../@components/userContext";
import useService from "../useService";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const EmailConfirmationDialog = ({ toggleOpen }) => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService();
  const { handleApiError } = useHandleErrors();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  const sendConfirmationEmail = async () => {
    await service
      .sendConfirmationEmail()
      .then(() => {
        enqueueSnackbar(t("profile.dialogs.emailConfirmation.snackbar"), {
          variant: "success",
        });
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 60px 50px", mobile: "28px 10px 30px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "15px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <CloseIcon
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box
              component={"img"}
              src={EmailConfirmationIcon}
              sx={{ width: "60px", display: "flex", margin: "0 auto" }}
            />
            <Typography
              variant={"h3"}
              sx={{
                textAlign: "center",
                mt: { md: "35px", mobile: "26px" },
              }}
            >
              {t("profile.dialogs.emailConfirmation.message")}
            </Typography>
            <Typography variant={"subtitle2"} sx={{ m: { mobile: "10px 0px 0", md: "16px 10px 0" } }}>
              {t("profile.dialogs.emailConfirmation.message2")}{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                {loggedInUser?.email}
              </Box>{" "}
              {t("profile.dialogs.emailConfirmation.message3")}
            </Typography>
            <Box
              sx={{ 
                m: { mobile: "24px 16px 22px", md: "34px 30px 33px" }, 
                borderBottom: "1px solid",
                borderColor: (theme) => theme.palette.colors.gray,
              }}
            ></Box>
            <Box sx={{ mt: { md: "40px", mobile: "20px" }, px: "10px" }}>
              <Typography
                variant={"subtitle3"}
                sx={{ textAlign: "center" }}
              >
                {t("profile.dialogs.emailConfirmation.message4")}{" "}
                <Box
                  onClick={() => sendConfirmationEmail()}
                  component={"span"}
                  sx={{
                    cursor: "pointer",
                    fontWeight: 400,
                    color: (theme) => theme.palette.colors.primary,
                  }}
                >
                  {t("profile.dialogs.emailConfirmation.link")}
                </Box>{" "}
                {t("profile.dialogs.emailConfirmation.message5")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default EmailConfirmationDialog;
