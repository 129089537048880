import React from "react";
import Box from "@mui/material/Box";
import visaLogo from "../../static/visa-logo.png";
import dinersLogo from "../../static/diners-logo.png";
import amexLogo from "../../static/amex-logo.png";
import mastercardLogo from "../../static/mastercard-logo.png";
import maestroLogo from "../../static/maestro-logo.png";
import maticLogo from "../../static/matic-logo.png";
import usdtLogo from "../../static/usdt-logo.png";
import usdcLogo from "../../static/usdc-icon.png";
import paypalLogo from "../../static/paypal.png";

import Tooltip from "@mui/material/Tooltip";

const Index = () => {
  return (
    <Box
      sx={{
        height: "60px",
        background: "transparent",
        display: "flex",
        justifyContent: "center",
        py: "10px",
        px: { md: "50px", lg: "100px" },
      }}
    >
      <Box
        sx={{
          width: "fit-content",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Tooltip title={"MATIC"} arrow>
            <Box
              component={"img"}
              src={maticLogo}
              sx={{
                width: "auto",
                height: "24px",
                mr: "10px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </Tooltip>
          <Tooltip title={"USDT"} arrow>
            <Box
              component={"img"}
              src={usdtLogo}
              sx={{
                width: "auto",
                height: "24px",
                mx: "10px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </Tooltip>
          <Tooltip title={"USDC"} arrow>
            <Box
              component={"img"}
              src={usdcLogo}
              sx={{
                width: "auto",
                height: "24px",
                mx: "10px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            mx: "50px",
          }}
        >
          <Tooltip title={"Paypal"} arrow>
            <Box
              component={"img"}
              src={paypalLogo}
              sx={{
                width: "35px",
                height: "24px",
                mx: "50px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </Tooltip>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Tooltip title={"Visa"} arrow>
            <Box
              component={"img"}
              src={visaLogo}
              sx={{
                width: "35px",
                height: "24px",
                mx: "5px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </Tooltip>
          <Tooltip title={"Diners Club"} arrow>
            <Box
              component={"img"}
              src={dinersLogo}
              sx={{
                width: "35px",
                height: "24px",
                mx: "5px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </Tooltip>
          <Tooltip title={"American Express"} arrow>
            <Box
              component={"img"}
              src={amexLogo}
              sx={{
                width: "35px",
                height: "24px",
                mx: "5px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </Tooltip>
          <Tooltip title={"Mastercard"} arrow>
            <Box
              component={"img"}
              src={mastercardLogo}
              sx={{
                width: "35px",
                height: "24px",
                mx: "5px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </Tooltip>
          <Tooltip title={"Maestro"} arrow>
            <Box
              component={"img"}
              src={maestroLogo}
              sx={{
                width: "35px",
                height: "24px",
                ml: "5px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
