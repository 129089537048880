import { ConnectButton } from "@rainbow-me/rainbowkit";
import QvrsButton from "../Button";
import Box from "@mui/material/Box";
import React from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Typography from "@mui/material/Typography";

const StyledBadgeNotConnected = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: "-10px",
    top: "10px",
    backgroundColor: "#ff6767",
    color: "#ff6767",
    boxShadow: "0 0 0 2px #0f1224",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const MobileButton = ({ signInBusy, isAuthenticated }) => {
  const { t } = useTranslation();
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openChainModal,
        openAccountModal,
        openConnectModal,
        mounted,
      }) => {
        return (
          <div
            {...(!mounted && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (
                !mounted ||
                !account ||
                !chain ||
                (!isAuthenticated && !signInBusy)
              ) {
                return (
                  <Box
                    onClick={() => {
                      openConnectModal();
                    }}
                    sx={{ p: "0 10px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          mr: "7px",
                        }}
                      >
                        <AccountBalanceWalletIcon sx={{ color: (theme) => theme.palette.text.primary, fontSize: "1.35rem" }} />
                      </Box>
                      <Typography variant={"h4"} >
                        {t("appBar.connectWallet")}
                      </Typography>
                    </Box>
                  </Box>
                );
              } else if (signInBusy) {
                return (
                  <Box
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        fontFamily: "Quantico",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: (theme) => theme.palette.text.secondary,
                        height: "60px",
                        ml: "5px",
                        border: "none",
                        borderRadius: (theme) => theme.borderRadius.secondary,
                        display: "flex",
                        backgroundColor: "transparent",
                        alignItems: "center",
                        ":hover": {
                          color: (theme) => theme.palette.text.primary,
                        },
                      }}
                    >
                      <StyledBadgeNotConnected
                        sx={{
                          "&:hover": {
                            color: (theme) => theme.palette.text.primary,
                          },
                        }}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        variant="dot"
                      >
                        Logging in...
                      </StyledBadgeNotConnected>
                    </Box>
                  </Box>
                );
              }
              if (chain.unsupported) {
                return (
                  <QvrsButton
                    onClick={openChainModal}
                    title={"Wrong network"}
                    type={"secondary"}
                  />
                );
              }
              return (
                <Box
                  onClick={() => {
                    openAccountModal();
                  }}
                  sx={{ p: "0 10px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      padding: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        mr: "7px",
                      }}
                    >
                      <AccountBalanceWalletIcon sx={{ color: (theme) => theme.palette.text.primary, fontSize: "1.35rem" }} />
                    </Box>
                    <Typography variant={"h4"}>
                      {t("appBar.wallet")}
                    </Typography>
                  </Box>
                </Box>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default MobileButton;
