import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../../@components/UI";
import discountIcon from "../../../../../../static/discount-icon.png";

const Card = ({ item }) => {
  return (
    <Box
      key={item.id}
      sx={{
        borderRadius: (theme) => theme.borderRadius.secondary,
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        width: { md: "155px", mobile: "102px" },
        height: { md: "232px", mobile: "153px" },
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid #944DFF",
        boxShadow: "#944dff 0px 0px 10px 0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "155px",
          width: "100%",
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.secondary,
        }}
      >
        <UI.ImageWithSkeleton
          sx={{
            width: { md: "110px", mobile: "70px" },
            height: { md: "110px", mobile: "70px" },
            mb: "5px",
            transition: "all 0.3s ease-in-out",
          }}
          src={discountIcon}
        />
      </Box>
      {!item?.is_locked && (
        <Typography
          variant={"h2"}
          sx={{
            position: "absolute",
            bottom: { md: "5px", mobile: "0px" },
            p: "8px",
            fontSize: { md: "14px !important", mobile: "11px !important" },
            maxWidth: "185.4px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            lineHeight: { mobile: "1.2", md: "1.3" },
          }}
        >
          {"Discount"}
          <br />
          {`${item?.data_json?.value}%`}
        </Typography>
      )}
    </Box>
  );
};

export default Card;
