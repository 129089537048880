import React from "react";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Badge from "@mui/material/Badge";
import MaterialDialog from "@mui/material/Dialog";
import PreviewSwiper from "./PreviewSwiper";
import { Box } from "@material-ui/core";

const GradientCircularProgress = (props) => {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        size={45}
        disableShrink
        sx={{ "svg circle": { stroke: "url(#my_gradient)" }, ...props.sx }}
      />
    </React.Fragment>
  );
};

const StoryDialog = ({ media, open, onClose }) => {
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          margin: { md: "30px", mobile: "0px" },
          position: "relative",
          padding: "0px",
          width: { md: "50%", mobile: "100%" },
          height: "initial",
          maxWidth: "1200px",
          textAlign: "center",
          background: "transparent",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <PreviewSwiper items={media} />
    </MaterialDialog>
  );
};

const StoryMedia = ({ avatar, media }) => {
  const [showStoryDialog, setShowStoryDialog] = React.useState(false);
  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <Avatar
            sx={{
              width: 22,
              height: 22,
            }}
            alt="Remy Sharp"
            src={avatar}
          />
        }
      >
        {media[0].file_type.startsWith("image") && (
          <Avatar
            src={media[0].image_url}
            alt={"avatar"}
            onClick={() => setShowStoryDialog(true)}
            sx={{
              cursor: "pointer",
              border: "1px solid #944DFF",
              boxShadow: "0px 0px 15px 0px #944DFF",
            }}
          />
        )}
        {media[0].file_type.startsWith("video") && (
          <Box
            component={"video"}
            src={media[0].image_url}
            alt={"avatar"}
            onClick={() => setShowStoryDialog(true)}
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: { md: "50%", mobile: "100%" },
              cursor: "pointer",
              border: "1px solid #944DFF",
              boxShadow: "0px 0px 15px 0px #944DFF",
            }}
          />
        )}
        <GradientCircularProgress
          sx={{
            color: "#b185f2",
            position: "absolute",

            top: -2.9,
            left: -3,
            zIndex: -1,
          }}
        />
      </Badge>

      <StoryDialog
        open={showStoryDialog}
        onClose={() => setShowStoryDialog(false)}
        media={media}
      />
    </>
  );
};

export default StoryMedia;
