import React from "react";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import { useDrop } from "react-dnd";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";


const Card = ({ item, onDrop }) => {
  const { t } = useTranslation();

  const collectionId =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections[0].id;

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: collectionId.toString(),
    drop: (data, nft) => {
      onDrop({ droppedItem: data, spaceItem: item });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  let border = "2px solid #944DFF";
  let display = "none";
  if (isActive) {
    border = "2px solid #2fd729";
    display = "flex";
  } else if (canDrop) {
    border = "2px solid #944dff";
    display = "flex";
  }
  return (
    <Box
      ref={drop}
      key={item.id}
      sx={{
        borderRadius: (theme) => theme.borderRadius.secondary,
        position: "relative",
        cursor: "pointer",
        width: { md: "155px", mobile: "102px" },
        height: { md: "232px", mobile: "153px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: border,
        boxShadow: "#944dff 0px 0px 5px 0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: { md: "40px", mobile: "28px" },
        }}
      >
        <Typography
          variant={"h9"}
          sx={{
            p: "8px",
            mt: "5px",
            maxWidth: { md: "135px", mobile: "90px" },
            display: "flex",
            alignItems: "center",
            fontSize: { md: "14px", mobile: "11px" },
          }}
        >
          <UI.OverflowTip>{item?.collections[0]?.name}</UI.OverflowTip>
        </Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          display: display,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          borderRadius: (theme) => theme.borderRadius.secondary
        }}
      >
        <Typography variant={"h8"} sx={{ color: "#ffffff" }}>
          {t("brands.albums.cards.dropHere")}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { md: "100%", mobile: "98px" },
          height: { md: "155px", mobile: "98px" },
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.primary,
        }}
      >
        {item && item?.collections && (
          <UI.ImageWithSkeleton
            sx={{
              width: { md: "100%", mobile: "100%" },
              height: { md: "auto", mobile: "auto" },
              transition: "all 0.3s ease-in-out",
            }}
            src={
              (item?.collections.length !== 0 &&
                item?.collections[0]?.nft_image_url) ||
              null
            }
          />
        )}
      </Box>
    </Box>
  );
};

const Action = ({ element: Component, item, onSelect }) => {
  const collectionId =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections[0].id;
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          onSelect([]);
        }}
      >
        <Box
          sx={{
            transition: "all 0.3s ease-in-out",
            cursor: "pointer",
          }}
          component={"span"}
          onClick={(e) => {
            e.stopPropagation();
            onSelect([collectionId]);
          }}
        >
          {Component}
        </Box>
      </ClickAwayListener>
    </>
  );
};

Card.Action = Action;

export default Card;
