import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import collectionsIcon from "../../../../../../static/group-nft-icon.png";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";


const UnconfirmedSpace = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "155px",
          width: "100%",
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.primary,
        }}
      >
        <Box
          component={"img"}
          sx={{ width: { md: "72px", mobile: "54px"} , height: { md: "72px", mobile: "54px"}, mb: { md: "0", mobile: "6px"} }}
          src={collectionsIcon}
        />
      </Box>
      <Typography
        variant={"h8"}
        sx={{
          position: "absolute",
          bottom: { md: "20px", mobile: "8px" },
          lineHeight: { md: "1.4", mobile: "1.2" },
          mt: { md: "24px", mobile: "20px" },
          fontSize: { md: "14px", mobile: "11px" },
          display: "flex",
          textAlign: "center",
          px: { md: "30px", mobile: "10px" },
        }}
      >
        {t("brands.albums.cards.nftChoice")}
      </Typography>
    </>
  );
};

const Card = ({ item }) => {
  return (
    <>
      <Box
        key={item.id}
        sx={{
          position: "relative",
          cursor: "pointer",
          width: { md: "155px", mobile: "102px" },
          height: { md: "232px", mobile: "153px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid #944DFF",
          boxShadow: "#944dff 0px 0px 5px 0px",
          borderRadius: (theme) => theme.borderRadius.secondary,
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        }}
      >
        <UnconfirmedSpace item={item} />
      </Box>
    </>
  );
};

const Action = ({ element: Component, item, onSelect }) => {
  const collectionIds =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections.map((collection) => collection.id);
  return (
    <>
      <ClickAwayListener onClickAway={() => onSelect([])}>
        <Box
          sx={{ cursor: "pointer" }}
          component={"span"}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(collectionIds);
          }}
        >
          {Component}
        </Box>
      </ClickAwayListener>
    </>
  );
};

Card.Action = Action;

export default Card;
