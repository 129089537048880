import * as React from "react";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "./inputs/TextField";
import Alert from "@mui/material/Alert";
import UI from "./index";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";


const Transfer = ({ open, onClose, onConfirm }) => {
  const [address, setAddress] = React.useState("");
  const { t } = useTranslation();

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 30px", mobile: "20px 5px" },
          width: "700px",
          textAlign: "center",
          margin: { mobile: "15px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogContent sx={{ px: { mobile: "15px", md: "25px" } }}>
        <CloseIcon
          onClick={onClose}
          sx={{
            position: "absolute",
            right: { mobile: "15px", md: "30px" },
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Box sx={{ mt: "30px" }}>
          <DialogContentText sx={{ textAlign: "start", fontSize: "18px" }}>
            {t("profile.dialogs.ticketTransferred.title")}
          </DialogContentText>
        </Box>
        <Box sx={{ mt: "10px" }}>
          <TextField
            fullWidth
            placeholder={t("profile.dialogs.ticketTransferred.placeholder")}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Box>
        <Alert 
          variant="outlined" 
          severity="info"
          sx={{
            mt: "15px",
            display: { md: "block", mobile: "none" },
          }}
        >
          {t("profile.dialogs.ticketTransferred.alert")}
        </Alert>
      </DialogContent>
      <Box sx={{ mt: { md: "30px", mobile: "10px" } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "10px",
            pb: "15px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UI.Button
              type={"primary"}
              title={t("buttons.send")}
              onClick={() => onConfirm(address)}
              sx={{
                width: "150px",
                height: "50px",
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
            />
          </Box>
        </Box>
      </Box>
    </MaterialDialog>
  );
};

const Action = ({ element: Component, onConfirm }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <Transfer
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={(address) => {
          onConfirm(address);
          setOpen(false);
        }}
      />
    </>
  );
};

Transfer.Action = Action;

export default Transfer;
