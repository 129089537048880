import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Busy from "./busy";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { makeStyles } from "@mui/styles";
import pdfIcon from "../../static/pdf-upload.png";
import { useSnackbar } from "notistack";
// in this component we set IPFS up to host out nft data of file storage
const projectId = "2FJrR2634gDmft79seWtyfxalex";
const projectSecret = "7919990e44d516f4c2cbeb92b9df199a";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "28px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  description: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
}));

const AvatarPreview = ({ url, type, width, height }) => {
  return (
    <Box
      sx={{
        width: width ? width : "250px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        height: height ? height : "250px",
        overflow: "hidden",
        borderRadius: "50%",
        border: "2px solid transparent",
        "&:hover": {
          boxShadow: "#944dff 0px 0px 15px 0px",
          border: "2px solid #944dff",
          transform: "scale(1.1)",
          transition: "all 0.2s ease 0s",
        },
      }}
    >
      {type === "image" && (
        <img
          style={{
            width: "100%",
            height: "100%",
            margin: "0 auto",
            textAlign: "center",
            objectFit: "cover",
          }}
          src={url}
          alt={"dao-logo"}
        />
      )}
      {type === "video" && (
        <video
          playsInline
          width="auto"
          height={"270px"}
          controls
          controlsList="nodownload"
        >
          <source src={url} />
        </video>
      )}
    </Box>
  );
};

const Upload = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    id,
    onChange,
    name,
    defaultValue,
    acceptFiles,
    avatarPreviewWidth,
    avatarPreviewHeight,
    previewHeight = "280px",
    noHeight,
    noPadding,
    showAvatarPreview = false,
  } = props;
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [uploadData, setUploadData] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);

  const maxFileSize = 25 * 1048576; // 25MB

  React.useEffect(() => {
    defaultValue?.ipfs && defaultValue?.file && setUploadData(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    uploadData && onChange(uploadData);
  }, [uploadData, onChange]);

  const onUpload = async (e) => {
    setUploading(true);

    const file = e.target.files[0];
    if (file) {
      setUploadData(null);
      if (file.size > maxFileSize) {
        enqueueSnackbar(`The file is too large! Maximum file size: 25MB`, {
          variant: "error",
        });
      } else {
        try {
          const added = await client.add(file, {
            progress: (prog) => console.log(`recieved: ${prog}`),
          });
          const url = `https://hazellabs.infura-ipfs.io/ipfs/${added.path}`;
          console.log(url);
          setUploadData({ ipfs: url, file: file });
          setUploading(false);
        } catch (error) {
          setUploading(false);
          enqueueSnackbar(error || "Error uploading file:", {
            variant: "error",
          });
        }
      }
    } else {
      setUploading(false);
    }
  };

  return (
    <React.Fragment>
      <input
        ref={ref}
        name={name}
        accept={acceptFiles}
        style={{ display: "none" }}
        id={id}
        onChange={onUpload}
        type="file"
      />
      <label htmlFor={id} style={{ width: "100px", height: "300px" }}>
        <Box
          variant="raised"
          component="span"
          sx={{
            cursor: "pointer",
            padding: noPadding ? "0px" : "15px",
            borderRadius: (theme) => theme.borderRadius.primary,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: noHeight ? "auto" : { md: "300px", mobile: "220px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {uploading && <Busy.Indicator />}
            {!uploadData && !uploading && (
              <Box
                sx={{
                  width: { md: "500px", mobile: "100%" },
                  borderRadius: (theme) => theme.borderRadius.primary,
                  p: "17px",
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <Paper
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.bgTransparent.primary,
                    "&:hover": {
                      backgroundColor: (theme) =>
                        theme.palette.bgTransparent.secondary,
                    },
                  }}
                >
                  <Box
                    sx={{
                      p: { md: "33px", mobile: "15px 15px 17px" },
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        flex: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          display: {
                            md: "block",
                            mobile: "none",
                          },
                          textAlign: "left",
                        }}
                        variant={"h4"}
                      >
                        {title}
                      </Typography>
                      <Typography
                        sx={{
                          display: {
                            md: "none",
                          },
                          mobile: "block",
                          textAlign: "left",
                        }}
                        variant={"h4"}
                      >
                        {title}
                      </Typography>
                      {!subtitle && (
                        <Typography
                          variant={"subtitle2"}
                          sx={{ textAlign: "left" }}
                        >
                          Click here to select a file.
                        </Typography>
                      )}
                      {subtitle && (
                        <Typography
                          variant={"subtitle3"}
                          sx={{
                            mt: { md: "10px", mobile: "0px" },
                            textAlign: "left",
                          }}
                        >
                          {subtitle}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ color: "#944DFF", flex: 1 }}>
                      <FileUploadIcon
                        sx={{
                          fontSize: "80px",
                          pt: { md: "0px", mobile: "15px" },
                          pl: { md: "0px", mobile: "10px" },
                        }}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            )}
            {uploadData &&
              uploadData.ipfs &&
              uploadData.file &&
              uploadData.file.type?.startsWith("image/") &&
              (showAvatarPreview ? (
                <AvatarPreview
                  url={uploadData.ipfs}
                  type={"image"}
                  width={avatarPreviewWidth}
                  height={avatarPreviewHeight}
                />
              ) : (
                <img
                  alt={"file-preview"}
                  style={{ height: previewHeight }}
                  src={uploadData.ipfs}
                  sx={{ borderRadius: (theme) => theme.borderRadius.primary }}
                />
              ))}
            {uploadData &&
              uploadData.ipfs &&
              uploadData.file &&
              uploadData.file.type?.startsWith("video/") &&
              (showAvatarPreview ? (
                <AvatarPreview url={uploadData.ipfs} type={"video"} />
              ) : (
                <video
                  playsInline
                  width="auto"
                  height={"270px"}
                  controls
                  controlsList="nodownload"
                  sx={{ borderRadius: (theme) => theme.borderRadius.primary }}
                >
                  <source src={uploadData.ipfs} />
                </video>
              ))}
            {uploadData &&
              uploadData.ipfs &&
              uploadData.file &&
              uploadData.file.type === "application/pdf" && (
                <Box>
                  <Box component={"img"} src={pdfIcon} sx={{ width: "50px" }} />
                  <Box className={classes.description}>
                    {uploadData.file?.name}
                  </Box>
                </Box>
              )}
          </Box>
        </Box>
      </label>
    </React.Fragment>
  );
});

export default Upload;
