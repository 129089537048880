import React from "react";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import videoIcon from "../../../../../../static/video-icon.png";
const Card = ({ item }) => {
  return (
    <Box
      key={item.id}
      sx={{
        borderRadius: (theme) => theme.borderRadius.secondary,
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        position: "relative",
        cursor: "pointer",
        width: { md: "155px", mobile: "102px" },
        height: { md: "232px", mobile: "153px" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid #944DFF",
        boxShadow: "#944dff 0px 0px 10px 0px",
        //backgroundColor: backgroundColor,
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "155px",
          width: "100%",
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.secondary,
        }}
      >
        <UI.ImageWithSkeleton
          sx={{
            width: { md: "110px", mobile: "70px" },
            height: { md: "110px", mobile: "70px" },
            transition: "all 0.3s ease-in-out",
            mb: "5px",
          }}
          src={videoIcon}
        />
      </Box>
    </Box>
  );
};

export default Card;
