import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../@components/UI";
import useService from "./useService";
import Avatar from "@mui/material/Avatar";
import truncateEthAddress from "truncate-eth-address";
import SkeletonList from "../../../qvrse/membership/SkeletonList";
import CustomHooks from "../../../@components/hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MaterialAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Utility from "../proposal/collection/extraUtilities";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import Alert from "@mui/material/Alert";
import { ethers } from "ethers";
import stakingArtifact from "../../../abis/contracts/modules/staking/Staking.sol/Staking.json";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import { useAccount } from "wagmi";
import { useUserContext } from "../../../@components/userContext";
import { useSnackbar } from "notistack";
import RemoveMemberDialog from "./RemoveMemberDialog";
import LeaveBrandDialog from "./LeaveBrandDialog";
import { useHistory, useLocation } from "react-router-dom";
import VotingPowerDialog from "./VotingPowerDialog";
import customHooks from "../../../@components/hooks";
import { useTranslation } from "react-i18next";

const Item = ({
  numOfMembers,
  item,
  memberFrom,
  roleId,
  role,
  status,
  logo,
  email,
  wallet_address,
  amount,
  token,
  stakeId,
  canLeaveBrand,
  staking_contract_address,
  userId,
  DAO,
  refetch,
}) => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const signer = customHooks.useEthersSigner();
  const service = useService(DAO?.id);
  const { isConnected, address } = useAccount();
  const { handleApiError, handleMetamaskError } = useHandleErrors();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = React.useState(false);
  const [showLeaveBrandDialog, setShowLeaveBrandDialog] = React.useState(false);

  const closeStake = async () => {
    await service
      .closeStake(stakeId)
      .then(() => {
        enqueueSnackbar(t("brands.membership.list.snackbar.successEnd"), {
          variant: "success",
        });
        refetch();
        setIsLoading(false);
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  const { mutate: sendConfirmationEmail } = CustomHooks.usePost(
    service.sendConfirmationEmail,
    {
      onSuccess: () => {
        enqueueSnackbar(t("brands.membership.list.snackbar.successEmail"), {
          variant: "success",
        });
      },
    }
  );

  const { mutate: removeMembership } = CustomHooks.usePost(
    service.removeMembership,
    {
      onSuccess: () => {
        enqueueSnackbar(t("brands.membership.list.snackbar.successRemove"), {
          variant: "success",
        });
        refetch();
      },
    }
  );

  const endMembership = async () => {
    setIsLoading(true);
    try {
      const staking = new ethers.Contract(
        staking_contract_address,
        stakingArtifact.abi,
        signer
      );
      await staking.completeStake(stakeId);
      await closeStake();
    } catch (error) {
      setIsLoading(false);
      handleMetamaskError(error);
    }
  };

  return (
    <Box
      sx={{
        mb: "15px",
      }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <MaterialAccordion
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          ":before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          sx={{
            height: "80px",
            padding: { md: "18px 30px", mobile: "15px" },
          }}
          expandIcon={
            <ExpandMoreIcon
              sx={{ color: (theme) => theme.palette.text.primary }}
            />
          }
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Box sx={{ mr: "20px" }}>
                  <Avatar src={logo} alt={"avatar"} />
                </Box>

                <Box>
                  <Typography
                    variant={"h5"}
                    sx={{
                      display: { md: "none", mobile: "flex" },
                    }}
                  >
                    {role?.toLowerCase() === "admin" &&
                      t("brands.membership.addMember.role.roles.admin")}
                    {role?.toLowerCase() === "moderator" &&
                      t("brands.membership.addMember.role.roles.moderator")}
                    {role?.toLowerCase() === "ticket redeemer" &&
                      t(
                        "brands.membership.addMember.role.roles.ticketRedeemer"
                      )}
                  </Typography>
                  <Typography
                    variant={"h7"}
                    sx={{
                      fontFamily: {
                        mobile: "Open Sans",
                        md: "Quantico",
                      },
                      fontWeight: {
                        mobile: "400",
                        md: "700",
                      },
                      lineHeight: {
                        mobile: "1.7",
                        md: "1.5",
                      },
                      textTransform: "lowercase",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      maxWidth: { md: "500px", mobile: "280px" },
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <UI.OverflowTip> {email}</UI.OverflowTip>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: { md: "flex", mobile: "none" },
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "20px",
                }}
              >
                <Typography variant={"h7"}>
                  {role?.toLowerCase() === "admin" &&
                    t("brands.membership.addMember.role.roles.admin")}
                  {role?.toLowerCase() === "moderator" &&
                    t("brands.membership.addMember.role.roles.moderator")}
                  {role?.toLowerCase() === "ticket redeemer" &&
                    t("brands.membership.addMember.role.roles.ticketRedeemer")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { md: "flex", mobile: "none" },
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "20px",
                }}
              >
                {status === "ACTIVE" && (
                  <Tooltip title={t("commons.confirmed")} arrow>
                    <TaskAltIcon sx={{ color: "#4DFF94" }} />
                  </Tooltip>
                )}
                {status === "NOT ACTIVE" && (
                  <Tooltip title={t("commons.notConfirmed")} arrow>
                    <WarningIcon sx={{ color: "#FFED4D" }} />
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { md: "15px  35px", mobile: "15px  15px" },
          }}
        >
          <Utility.AccordionItem
            title={t("brands.membership.list.walletAddress")}
            value={
              <Box onClick={(e) => e.stopPropagation()}>
                <UI.CopyToClipboard
                  label={truncateEthAddress(wallet_address)}
                  value={wallet_address}
                  showBorder
                />
              </Box>
            }
          />
          <Utility.AccordionItem
            title={t("brands.membership.list.memberSince")}
            value={memberFrom}
          />
          {role === "ADMIN" && amount > 0 && (
            <Utility.AccordionItem
              title={t("brands.membership.list.canBeRevoked")}
              value={canLeaveBrand}
            />
          )}
          {role === "ADMIN" && (
            <Utility.AccordionItem
              title={t("brands.membership.list.votingPower")}
              value={`${amount} ${token.token_symbol}`}
            />
          )}
          <Box sx={{ mt: "20px" }}>
            {status === "NOT ACTIVE" && (
              <Box>
                <Alert
                  variant="outlined"
                  severity="info"
                  sx={{ mt: "20px", mb: "20px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {t("brands.membership.list.alert.didNotConfirm")}
                    {"  "}
                    <Box
                      sx={{
                        ml: "5px",
                        cursor: "pointer",
                        textDecoration: "underline",
                        transition: "all 0.2s ease-in-out",
                        color: (theme) => theme.palette.text.link,
                        "&:hover": {
                          color: (theme) => theme.palette.text.linkHover,
                        },
                      }}
                      onClick={() => {
                        sendConfirmationEmail(roleId);
                      }}
                      component={"span"}
                    >
                      {t("brands.membership.list.sendEmail")}
                    </Box>
                  </Box>
                </Alert>
              </Box>
            )}
            {DAO?.memberRole === "ADMIN" &&
              role === "ADMIN" &&
              address === wallet_address && (
                <Box sx={{ width: "200px", my: "25px" }}>
                  <UI.Button
                    disabled={
                      !(
                        !moment(
                          item?.stake_info[0]?.datetime_completion
                        ).isAfter() &&
                        amount > 0 &&
                        isConnected &&
                        numOfMembers !== 1 &&
                        // parseInt(timeline_progress) >= 100 &&
                        address === wallet_address &&
                        loggedInUser?.email_verified
                      )
                    }
                    onClick={() => endMembership()}
                    type={"primary"}
                    title={t("brands.membership.action2")}
                  />
                </Box>
              )}
            {DAO?.memberRole === "ADMIN" && role !== "ADMIN" && (
              <Box sx={{ width: "200px", my: "25px" }}>
                <UI.Button
                  disabled={!(isConnected && loggedInUser?.email_verified)}
                  onClick={() => {
                    setShowRemoveDialog(true);
                  }}
                  type={"primary"}
                  title={t("brands.membership.action3")}
                />
              </Box>
            )}
            {DAO?.memberRole === "MODERATOR" &&
              role !== "ADMIN" &&
              address !== wallet_address && (
                <Box sx={{ width: "200px", my: "25px" }}>
                  <UI.Button
                    disabled={!(isConnected && loggedInUser?.email_verified)}
                    onClick={() => {
                      setShowRemoveDialog(true);
                    }}
                    type={"primary"}
                    title={t("brands.membership.action3")}
                  />
                </Box>
              )}
            {DAO?.memberRole === "MODERATOR" && address === wallet_address && (
              <Box sx={{ width: "200px" }}>
                <UI.Button
                  disabled={
                    !(
                      isConnected &&
                      loggedInUser?.email_verified &&
                      numOfMembers !== 1
                    )
                  }
                  onClick={() => {
                    setShowLeaveBrandDialog(true);
                  }}
                  type={"primary"}
                  title={t("brands.membership.action2")}
                />
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </MaterialAccordion>
      {showRemoveDialog && (
        <RemoveMemberDialog
          onClose={() => setShowRemoveDialog(false)}
          onConfirm={() => {
            removeMembership({ role_id: roleId, user_id: userId });
            setShowRemoveDialog(false);
          }}
        />
      )}
      {showLeaveBrandDialog && (
        <LeaveBrandDialog
          onClose={() => setShowLeaveBrandDialog(false)}
          onConfirm={() => {
            removeMembership({ role_id: roleId, user_id: userId });
            setShowLeaveBrandDialog(false);
            window.location.reload();
          }}
        />
      )}
    </Box>
  );
};

const List = ({ DAO }) => {
  const { t } = useTranslation();
  const service = useService(DAO?.id);
  const location = useLocation();
  const { user: loggedInUser } = useUserContext();
  const history = useHistory();
  const [didAdminStakeTokens, setDidAdminStakeTokens] = React.useState(null);
  const [showVotingPowerDialog, setShowVotingPowerDialog] =
    React.useState(false);

  const {
    data: items,
    isLoading,
    refetch,
  } = CustomHooks.useFetch(["members"], service.list, {
    enabled: false,
  });

  React.useEffect(() => {
    if (items && loggedInUser) {
      if (DAO?.memberRole === "ADMIN") {
        const adminHasOpenStakes = items.find((item) => {
          return (
            item.role_name === "ADMIN" &&
            item.stake_info.some(
              (info) =>
                info.staker_wallet_address === loggedInUser?.address &&
                info.amount > 0
            )
          );
        });
        if (adminHasOpenStakes) {
          setDidAdminStakeTokens(true);
        } else {
          setDidAdminStakeTokens(false);
          setShowVotingPowerDialog(Math.random());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, loggedInUser]);

  React.useEffect(() => {
    DAO && refetch();
  }, [DAO, refetch]);

  return (
    <Box>
      <VotingPowerDialog
        toggleOpen={showVotingPowerDialog}
        onConfirm={() => {
          setShowVotingPowerDialog(Math.random());
          history.push(`${location.pathname}/votingPower`);
        }}
      />
      {didAdminStakeTokens != null && !didAdminStakeTokens && (
        <Typography
          variant={"subtitle2"}
          sx={{
            mb: "15px",
            display: { md: "block", mobile: "none" },
          }}
        >
          <Alert variant="outlined" severity="info">
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              {t("brands.membership.list.alert.confirmAdmin1")} {"  "}
              <Box
                sx={{
                  color: (theme) => theme.palette.text.linkBlue,
                  textDecorationColor: (theme) => theme.palette.text.linkBlue,
                  cursor: "pointer",
                  fontWeight: "bold",
                  ml: "5px",
                  "&:hover": {
                    color: (theme) => theme.palette.text.linkBlueHover,
                    textDecorationColor: (theme) =>
                      theme.palette.text.linkBlueHover,
                    transition: "all 0.3s ease-in-out",
                  },
                }}
                onClick={() => history.push(`${location.pathname}/votingPower`)}
                component={"span"}
              >
                {t("brands.membership.list.alert.confirmAdmin2")}
              </Box>
            </Box>
          </Alert>
        </Typography>
      )}

      {isLoading && <SkeletonList />}
      {!isLoading &&
        items &&
        items.length > 0 &&
        items.map((item) => (
          <Item
            numOfMembers={items.length}
            item={item}
            refetch={() => refetch()}
            key={item?.role_id}
            DAO={DAO}
            staking_contract_address={DAO?.staking_contract?.address}
            stakeId={item?.stake_info[0]?.id || 0}
            token={DAO?.dao_token}
            roleId={item?.role_id}
            userId={item?.user?.id}
            wallet_address={item?.user?.address}
            canLeaveBrand={moment(
              item?.stake_info[0]?.datetime_completion
            ).format("DD MMM YYYY HH:mm")}
            logo={item?.user?.avatar_url}
            amount={item?.stake_info.reduce(
              (sum, info) => sum + info.amount,
              0
            )}
            email={item?.user?.email}
            role={(item?.role_name).replace("_", " ")}
            memberFrom={moment(item?.datetime_created).format(
              "DD MMM YYYY HH:mm"
            )}
            status={item?.status_text}
          />
        ))}
      {!isLoading && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={t("brands.membership.list.notFound")} />
        </Box>
      )}
    </Box>
  );
};

export default List;
