import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import BigList from "../BigList";
import SmallList from "../SmallList";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [display, setDisplay] = React.useState("big");

  React.useEffect(() => {
    if (isMobile) {
      setDisplay("small");
    } else {
      setDisplay("big");
    }
  }, [isMobile]);

  const handleDisplay = (event, newDisplay) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        px: { lg: "100px", md: "50px", mobile: "10px" },
        mb: "50px",
      }}
    >
      <Typography sx={{ display: { mobile: "flex", md: "none" } }} variant="h2">
        {t("appBar.brands")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: { mobile: "40px", md: "46px" },
        }}
      >
        <ToggleButtonGroup
          value={display}
          exclusive
          onChange={handleDisplay}
          aria-label="item display"
        >
          <ToggleButton
            value="big"
            aria-label="big display list"
            sx={{ padding: { mobile: "8px", md: "11px" } }}
          >
            <ViewModuleIcon
              sx={{ fontSize: { mobile: "1.2rem", md: "1.5rem" } }}
            />
          </ToggleButton>
          <ToggleButton
            value="small"
            aria-label="small display list"
            sx={{ padding: { mobile: "8px", md: "11px" } }}
          >
            <ViewListIcon
              sx={{ fontSize: { mobile: "1.2rem", md: "1.5rem" } }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {display === "big" && <BigList />}
      {display === "small" && <SmallList />}
    </Container>
  );
};
Index.List = BigList;
export default Index;
