import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../@components/UI";
import Alert from "@mui/material/Alert";
import ChangePasswordDialog from "../dialogs/ChangePasswordDialog";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";
import useService from "../useService";
import { useTranslation } from "react-i18next";

const ResetPasswordButton = ({ user }) => {
  const { t } = useTranslation();
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    React.useState(null);
  const service = useService();
  const { handleApiError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();

  const resetPassword = async () => {
    await service
      .resetPassword({ email: user?.email })
      .then(async () => {
        enqueueSnackbar(t("profile.settings.resetPasswordDialog.snackbar"), {
          variant: "success",
        });
      })
      .catch((error) => {
        handleApiError(error);
      });
  };
  return (
    <>
      <Box sx={{ display: "flex", mb: "10px" }}>
        <Typography variant={"h3"}>
          {t("profile.settings.resetPasswordDialog.title")}
        </Typography>
      </Box>

      {user?.has_password === true && (
        <>
          <UI.Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowChangePasswordDialog(Math.random());
            }}
            sx={{ width: { md: "250px", mobile: "100%" } }}
            type={"secondary"}
            title={t("profile.settings.resetPasswordDialog.button")}
          />
          <Typography
            variant={"subtitle1"}
            sx={{ mt: "15px" }}
          >
            {t("profile.settings.resetPasswordDialog.forgotPassword")}{" "}
            <Box
              onClick={() => resetPassword()}
              component={"span"}
              sx={{
                cursor: "pointer",
                color: (theme) => theme.palette.colors.primary,
              }}
            >
              {t("profile.settings.resetPasswordDialog.resetPassword")}
            </Box>
          </Typography>
        </>
      )}
      {user?.has_password === false && (
        <>
          <UI.Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              resetPassword();
            }}
            sx={{ width: { md: "250px", mobile: "100%" } }}
            type={"secondary"}
            title={t("profile.settings.resetPasswordDialog.generateButton")}
          />
          <Box
            sx={{
              mt: "15px",
            }}
          >
            <Alert variant="outlined" severity="info">
              {t("profile.settings.resetPasswordDialog.alert")}
            </Alert>
          </Box>
        </>
      )}
      <ChangePasswordDialog toggleOpen={showChangePasswordDialog} />
    </>
  );
};

export default ResetPasswordButton;
