import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../../@components/UI";
import collectionsIcon from "../../../../../../static/group-nft-icon.png";
import { useDrop } from "react-dnd";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";


const ConfirmedSpace = ({ item }) => {
  return (
    <Box
      sx={{ borderRadius: (theme) => theme.borderRadius.primary, display: "flex", flexDirection: "column",}}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: { md: "40px", mobile: "28px" },
        }}
      >
        <Typography
          variant={"h9"}
          sx={{
            p: "8px",
            mt: "7px",
            maxWidth: { md: "135px", mobile: "90px" },
            display: "flex",
            alignItems: "center",
            fontSize: { md: "14px", mobile: "11px" },
          }}
        >
          <UI.OverflowTip>
            {(item?.album_card.length !== 0 &&
              item?.album_card[0]?.collection_name) ||
              null}
          </UI.OverflowTip>
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { md: "100%", mobile: "98px" },
          height: { md: "155px", mobile: "98px" },
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.primary,
        }}
      >
        {item && item?.collections && (
          <UI.ImageWithSkeleton
            sx={{
              width: { md: "100%", mobile: "100%" },
              height: { md: "auto", mobile: "auto" },
              transition: "all 0.3s ease-in-out",
            }}
            src={
              (item?.album_card.length !== 0 &&
                item?.album_card[0]?.image_url) ||
              null
            }
          />
        )}
      </Box>
    </Box>
  );
};

const UnconfirmedSpace = ({ display }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          display: display,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          borderRadius: (theme) => theme.borderRadius.secondary,
        }}
      >
        <Typography variant={"h8"} sx={{ color: "#ffffff" }}>
          {t("brands.albums.cards.dropHere")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "155px",
          width: "100%",
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.secondary,
        }}
      >
        <Box
          component={"img"}
          sx={{ width: { md: "72px", mobile: "54px"} , height: { md: "72px", mobile: "54px"}, mb: { md: "0", mobile: "6px"} }}
          src={collectionsIcon}
        />
      </Box>
      <Typography
        variant={"h8"}
        sx={{
          position: "absolute",
          bottom: { md: "20px", mobile: "8px" },
          lineHeight: { md: "1.4", mobile: "1.2" },
          mt: { md: "24px", mobile: "20px" },
          fontSize: { md: "14px", mobile: "11px" },
          display: "flex",
          textAlign: "center",
          px: { md: "30px", mobile: "10px" },
        }}
      >
        {t("brands.albums.cards.nftChoice")}
      </Typography>
    </>
  );
};

const Card = ({ item, onDrop }) => {
  const collectionIds =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections.map((collection) => collection.id.toString());

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: collectionIds,
    drop: (data, nft) => {
      onDrop({ droppedItem: data, spaceItem: item });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const spaceIsConfirmed = item.album_card.length !== 0;
  const isActive = isOver && canDrop;
  let border = "2px solid #944DFF";
  let display = "none";
  if (isActive) {
    border = "2px solid #2fd729";
    display = "flex";
  } else if (canDrop) {
    border = "2px solid #944dff";
    display = "flex";
  }
  return (
    <>
      <Box
        ref={drop}
        key={item.id}
        sx={{
          borderRadius: (theme) => theme.borderRadius.secondary,
          position: "relative",
          cursor: "pointer",
          width: { md: "155px", mobile: "102px" },
          height: { md: "232px", mobile: "153px" },
          display: "flex",
          justifyContent: "center",
          alignItems: spaceIsConfirmed ? "flex-start" : "center",
          border: border,
          boxShadow: "#944dff 0px 0px 5px 0px",
          //backgroundColor: backgroundColor,
        }}
      >
        {!spaceIsConfirmed && (
          <UnconfirmedSpace item={item} display={display} />
        )}
        {spaceIsConfirmed && <ConfirmedSpace item={item} display={display} />}
      </Box>
    </>
  );
};

const Action = ({ element: Component, item, onSelect }) => {
  const collectionIds =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections.map((collection) => collection.id);
  return (
    <>
      <ClickAwayListener onClickAway={() => onSelect([])}>
        <Box
          sx={{ cursor: "pointer" }}
          component={"span"}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(collectionIds);
          }}
        >
          {Component}
        </Box>
      </ClickAwayListener>
    </>
  );
};

Card.Action = Action;

export default Card;
