import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import Grid from "@mui/material/Grid";
import Ticket from "./Ticket";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CustomHooks from "../../../../../../@components/hooks";
import useCollectionService from "../../../useService";
import Typography from "@mui/material/Typography";
import PerkItem from "./PerkItem";
import { useUserContext } from "../../../../../../@components/userContext";
import LogInDialogFlow from "../../../../../../profile/dialogs/LogInDialogFlow";
import CreateAccountDialog from "../../../../../../profile/dialogs/RegisterDialog";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import confettiAnimation from "../../../../../../static/confetti-animation.json";
import confettiAnimation2 from "../../../../../../static/confetti2.json";

const Success = ({ DAO }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const collectionService = useCollectionService(DAO?.id);
  const [mainUtility, setMainUtility] = React.useState(null);
  const [groupedUtilities, setGroupedUtilities] = React.useState(null);
  const history = useHistory();
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const [triggerCreateAccountDialog, setTriggerCreateAccountDialog] =
    React.useState(null);
  const [selectedNFT, setSelectedNFT] = React.useState(null);

  const { user: loggedInUser } = useUserContext();
  const confettiRef = React.useRef(null);
  const confettiRef2 = React.useRef(null);

  React.useEffect(() => {
    if (confettiRef.current && confettiRef2.current) {
      confettiRef.current.play();
      confettiRef2.current.play();
    }
  }, []);

  const { data: collection } = CustomHooks.useFetch(
    ["collection"],
    () => collectionService.collection(id),
    {
      onSuccess: async (collection) => {
        setMainUtility(
          collection?.utilities.find((utility) => utility.is_main_utility)
        );

        const groupedUtilities = collection?.utilities.reduce(
          (acc, utility) => {
            const type = utility.type;
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(utility);
            return acc;
          },
          {}
        );
        setGroupedUtilities(groupedUtilities);
      },
    }
  );

  React.useEffect(() => {
    if (collection && state.selectedNFT !== "null") {
      const nft = collection?.collection_images.find(
        (nft) => parseInt(nft.id) === parseInt(state.selectedNFT)
      );

      setSelectedNFT(nft);
    }
  }, [state, collection]);

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: { md: "flex", mobile: "none" },
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "0px", mobile: "10px" },
            pb: "30px",
            pt: { md: "0px", mobile: "20px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <UI.BackButton
              title={t("brands.collection.collectionPage.success.backButton")}
              onClick={() => history.push(`/brand/${DAO?.id}/collection/${id}`)}
              address={collection?.nft_address}
            />
          </Box>
        </Box>
        <Box
          sx={{
            mt: { mobile: "14px", md: "0px" },
            mb: { mobile: "40px", md: "0px" },
            width: "100%",
            paddingLeft: { mobile: "10px", md: "0px" },
            paddingRight: { mobile: "10px", md: "0px" },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={4} mobile={12}>
              <Box
                sx={{
                  display: "flex",
                  mb: { mobile: "32px", md: "60px" },
                  justifyContent: { mobile: "center", md: "unset" },
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Ticket
                    brandName={DAO?.name}
                    brandAvatar={DAO?.avatar_url}
                    name={collection?.name}
                    name_slo={collection?.name_slo || null}
                    fileType={
                      selectedNFT !== null
                        ? selectedNFT?.file_type
                        : collection?.nft_file_type
                    }
                    data={
                      selectedNFT !== null
                        ? selectedNFT?.image_url
                        : collection?.nft_image_url
                    }
                    mainUtility={mainUtility}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} mobile={12}>
              <Box sx={{ ml: { md: "40px", mobile: "0px" } }}>
                <Typography
                  variant={"h2"}
                  sx={{
                    textAlign: {
                      mobile: "center",
                      md: "start",
                    },
                    fontSize: {
                      mobile: "22px",
                      md: "28px",
                    },
                    marginTop: { md: "50px", mobile: "0" },
                  }}
                >
                  {t("brands.collection.collectionPage.success.title")}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    mt: { md: "16px", mobile: "14px" },
                    display: "none",
                  }}
                >
                  {t("brands.collection.collectionPage.success.subtitle")}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    mt: { md: "10px", mobile: "14px" },
                    display: "none",
                  }}
                >
                  {t("brands.collection.collectionPage.success.subtitle2")}
                </Typography>
                <Typography
                  variant={"h4"}
                  sx={{
                    mt: { md: "30px", mobile: "20px" },
                    mb: { md: "15px", mobile: "12px" },
                    textAlign: {
                      mobile: "center",
                      md: "start",
                    },
                  }}
                >
                  {t("brands.collection.collectionPage.success.perks")}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.bgTransparent.primary,
                    padding: { md: "14px 30px 15px", mobile: "20px 15px 10px" },
                    mb: "5px",
                    display: "none",
                  }}
                >
                  {groupedUtilities &&
                    Object.keys(groupedUtilities).map((key) => {
                      return (
                        <Box
                          sx={{
                            m: { md: "10px 0px 10px", mobile: "0px 0px 12px" },
                          }}
                        >
                          <PerkItem
                            collection={collection}
                            perkName={key}
                            numOfPerks={groupedUtilities[key].length}
                          />
                        </Box>
                      );
                    })}
                </Box>
                {loggedInUser && (
                  <Box
                    sx={{
                      mt: "12px",
                      display: "flex",
                      justifyContent: { md: "start", mobile: "center" },
                      width: "100%",
                    }}
                  >
                    <UI.Button
                      type={"primary"}
                      title={t(
                        "brands.collection.collectionPage.success.button"
                      )}
                      sx={{
                        width: "280px",
                        mt: { md: "7px", mobile: "10px" },
                      }}
                      onClick={() =>
                        history.push({
                          pathname: `/profile`,
                          state: { scrollToTop: true },
                        })
                      }
                    />
                  </Box>
                )}

                {!loggedInUser && (
                  <Box
                    sx={{
                      mt: "12px",
                      display: "flex",
                      flexDirection: { mobile: "column", md: "row" },
                      alignItems: { mobile: "center", md: "flex-start" },
                      justifyContent: { md: "start", mobile: "center" },
                      width: "100%",
                    }}
                  >
                    <UI.Button
                      data-test={"login-button"}
                      type={"primary"}
                      title={t("buttons.logIn")}
                      sx={{
                        width: { mobile: "320px", md: "240px" },
                        mt: { md: "7px", mobile: "10px" },
                        marginRight: { md: "16px", mobile: "0" },
                      }}
                      onClick={() => setTriggerLoginDialog(Math.random())}
                    />
                    <UI.Button
                      data-test={"create-account-button"}
                      type={"secondary"}
                      title={t("buttons.createAccount")}
                      sx={{
                        width: { mobile: "320px", md: "240px" },
                        mt: { md: "7px", mobile: "12px" },
                      }}
                      onClick={() =>
                        setTriggerCreateAccountDialog(Math.random())
                      }
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />
      <CreateAccountDialog
        toggleOpen={triggerCreateAccountDialog}
        onRegister={() => {
          setTriggerCreateAccountDialog(null);
        }}
        showLogInDialog={() => {
          setTriggerCreateAccountDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
      />
      <Lottie
        lottieRef={confettiRef}
        autoplay={false}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          pointerEvents: "none",
        }}
        resizeMode="cover"
        animationData={confettiAnimation}
      />
      <Lottie
        lottieRef={confettiRef2}
        autoplay={false}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          pointerEvents: "none",
        }}
        resizeMode="cover"
        animationData={confettiAnimation2}
      />
    </Box>
  );
};

export default Success;
