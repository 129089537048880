import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import paypalLogo from "../../../../static/paypal.png";
import stripeLogo from "../../../../static/stripe.png";
import cryptoLogo from "../../../../static/crypto.png";
import Tooltip from "@mui/material/Tooltip";

const Index = ({ DAO }) => {
  return (
    <Container maxWidth={"md"} sx={{ textAlign: "center" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Tooltip title={"Crypto"} arrow>
          <Box
            component={"img"}
            src={cryptoLogo}
            sx={{
              width: "45px",
              mx: "8px",
              "&:hover": {
                transform: "scale(1.1)",
              },
              transition: "all 0.5s ease-in-out",
            }}
          />
        </Tooltip>

        {DAO?.fiat_payment_options?.paypal && (
          <Tooltip title={"Paypal"} arrow>
            <Box
              component={"img"}
              src={paypalLogo}
              sx={{
                width: "45px",
                mx: "8px",
                "&:hover": {
                  transform: "scale(1.1)",
                },
                transition: "all 0.5s ease-in-out",
              }}
            />
          </Tooltip>
        )}
        {DAO?.fiat_payment_options?.stripe && (
          <Tooltip title={"Stripe"} arrow>
            <Box
              component={"img"}
              src={stripeLogo}
              sx={{
                width: "45px",
                mx: "8px",
                "&:hover": {
                  transform: "scale(1.1)",
                },
                transition: "all 0.5s ease-in-out",
              }}
            />
          </Tooltip>
        )}
      </Box>
    </Container>
  );
};

export default Index;
