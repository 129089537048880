import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";
// Import Swiper styles
// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Typography from "@mui/material/Typography";
import CustomHooks from "../../@components/hooks";
import useService from "../useService";
import PostDialog from "./PostDialog";
import multipleImagesIcon from "../../static/multiple-image-icon.png";
import multipleVideosIcon from "../../static/multiple-video-icon.png";
import pollIcon from "../../static/poll-icon.png";
import { Pagination } from "swiper/modules";
import Busy from "../../@components/UI/busy";

// const PostItem = ({ collection, item }) => {
//   const history = useHistory();
//   return (
//     <Box
//       onClick={() => {
//         history.push(`/brand/${collection?.dao_id}/feed/`);
//       }}
//       sx={{
//         width: "348px",
//         height: "130px",
//         borderRadius: (theme) => theme.borderRadius.secondary,
//         border: "1px solid transparent",
//         cursor: "pointer",
//         background: "rgba(217, 217, 217, 0.06)",
//         transition: "all 0.3s ease-in-out",
//         "&:hover": {
//           // boxShadow: "#944dff 0px 0px 10px 0px",
//           // border: "1px solid #944dff",
//           // transform: { md: "scale(1.05)", mobile: "none" },
//         },
//       }}
//     >
//       <Box
//         sx={{
//           borderRadius: (theme) => theme.borderRadius.secondary,
//           width: "100%",
//           height: "100%",
//           display: "flex",
//           justifyContent: "center",
//           flexDirection: "column",
//           transition: "all 0.3s ease-in-out",
//
//           position: "relative",
//
//           "::before": {
//             content: '""',
//             position: "absolute",
//             bottom: 0,
//             left: 0,
//             right: 0,
//             height: "50%", // adjust this value as needed to control the gradient's height
//             backgroundImage:
//               "linear-gradient(rgba(8, 10, 25, 0), rgba(8, 10, 25, 0.21), rgba(8, 10, 25, 0.49))",
//             zIndex: 1, // ensure it's below the Typography
//           },
//         }}
//       >
//         <Typography
//           variant={"subtitle1"}
//           sx={{
//             position: "absolute",
//             top: 0,
//             padding: "17px",
//           }}
//         >
//           {item.description}
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

const PollItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: "320px",
        height: "130px",
        borderRadius: (theme) => theme.borderRadius.secondary,
        border: "1px solid transparent",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          // boxShadow: "#944dff 0px 0px 10px 0px",
          // border: "1px solid #944dff",
          // transform: { md: "scale(1.05)", mobile: "none" },
        },
      }}
    >
      <Box
        component={"img"}
        src={pollIcon}
        sx={{
          margin: "0 auto",
          width: "120px !important",
          height: "120px !important",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      />
    </Box>
  );
};

const VideoItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: "320px",
        height: "130px",
        borderRadius: (theme) => theme.borderRadius.secondary,
        border: "1px solid transparent",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Box
        component={"img"}
        src={multipleVideosIcon}
        sx={{
          margin: "0 auto",
          width: "120px !important",
          height: "120px !important",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      />
    </Box>
  );
};

const ImageItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: "320px",
        height: "130px",
        borderRadius: (theme) => theme.borderRadius.secondary,
        border: "1px solid transparent",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Box
        component={"img"}
        src={multipleImagesIcon}
        sx={{
          margin: "0 auto",
          width: "120px !important",
          height: "120px !important",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      />
    </Box>
  );
};

const PostsSlider = ({ collection }) => {
  const service = useService();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [selectedPost, setSelectedPost] = useState(null);

  const {
    data: posts,
    isLoading: postsLoading,
    refetch: fetchPosts,
  } = CustomHooks.useFetch(
    ["posts"],
    () =>
      service.posts({
        dao_id: collection.dao_id,
        collection_id: collection.event_id,
      }),
    {
      enabled: false,
      onSuccess: async (collection) => {
        if (selectedPost !== null) {
          setSelectedPost(
            collection?.find((item) => item.id === selectedPost.id)
          );
        }
      },
    }
  );

  useEffect(() => {
    if (collection) {
      fetchPosts({
        dao_id: collection.dao_id,
        collection_id: collection.event_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  return (
    <>
      {postsLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Busy.Indicator />
        </Box>
      )}
      {!postsLoading && posts && posts.length > 0 && (
        <Box
          sx={{
            ml: { md: "40px", mobile: "0px" },
            mt: { md: "10px", mobile: "0px" },
          }}
        >
          <Box sx={{ mb: "20px" }}>
            <Typography variant={"h4"}>{"MEDIA"}</Typography>
          </Box>
          <Swiper
            pagination={true}
            spaceBetween={10}
            modules={[Pagination]}
            grabCursor={true}
            slidesPerView={isMobile ? 2.7 : 3.7}
            className="nftSwiper"
          >
            {posts?.map((item) => {
              const isImageItem =
                item?.files?.length > 0 &&
                item?.files[0]?.media_type === "image";

              const isVideoItem =
                item?.files?.length > 0 &&
                item?.files[0]?.media_type === "video";

              const isPollItem =
                Object.keys(item?.content).length > 0 &&
                item?.content?.type === "poll";

              const isIframeItem =
                Object.keys(item?.content).length === 0 &&
                item?.iframe_url !== null;

              return (
                <SwiperSlide>
                  {isImageItem && !isPollItem && (
                    <ImageItem
                      item={item}
                      onClick={() => setSelectedPost(item)}
                    />
                  )}
                  {isVideoItem && !isPollItem && (
                    <VideoItem
                      item={item}
                      onClick={() => setSelectedPost(item)}
                    />
                  )}
                  {isPollItem && (
                    <PollItem
                      item={item}
                      onClick={() => setSelectedPost(item)}
                    />
                  )}
                  {isIframeItem && (
                    <VideoItem
                      item={item}
                      onClick={() => setSelectedPost(item)}
                    />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
          <PostDialog
            DAO={{ ...collection?.dao_info, memberRole: "GUEST" }}
            post={selectedPost}
            refetch={() => {
              fetchPosts({
                dao_id: collection.dao_id,
                collection_id: collection.event_id,
              });
            }}
            onClose={() => setSelectedPost(null)}
          />
        </Box>
      )}
    </>
  );
};

export default PostsSlider;
