import React from "react";
import Container from "@mui/material/Container";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";

import Home from "./home";
import Settings from "./settings";
import Created from "./created";
import Finance from "./finance";
import NFT from "./nft";
import useService from "./useService";
import Menu from "./Menu";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomHooks from "../@components/hooks";

const Index = ({ match }) => {
  const history = useHistory();
  const service = useService();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { data: user, refetch } = CustomHooks.useFetch(
    ["user"],
    service.basic_info,
    {
      enabled: false,
    }
  );

  const checkUser = async () => {
    const access_token = localStorage.getItem("access_token");

    if (access_token == null) {
      history.push("/?email_redirect_profile=Please login to continue");
    } else {
      await refetch();
    }
  };

  React.useEffect(() => {
    checkUser();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <ContainerWrapper>
        {isMobile && <MobileMenu user={user} currentUrl={match?.url} />}
        {!isMobile && <Header user={user} />}
        {!isMobile && <Menu user={user} currentUrl={match?.url} />}

        <Switch>
          <Route path={`${match.url}/settings`}>
            <Settings user={user} refetch={() => refetch()} />
          </Route>
          <Route path={`${match.url}/created`}>
            <Created user={user} />
          </Route>
          <Route path={`${match.url}/finance`}>
            <Finance user={user} />
          </Route>
          <Route path={`${match.url}/nft/:id`}>
            <NFT user={user} />
          </Route>
          <Route path={`${match.url}/`}>
            <Home user={user} />
          </Route>
          <Route path="/" component={Home} />
          <Redirect from="*" to="/" />
        </Switch>
      </ContainerWrapper>
    </React.Fragment>
  );
};

const ContainerWrapper = ({ children }) => {
  return (
    <Container
      maxWidth={"md"}
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {children}
    </Container>
  );
};

export default Index;
