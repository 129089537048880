import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import UI from "../../../../../@components/UI";
import { useUserContext } from "../../../../../@components/userContext";
import { useAccount } from "wagmi";
import { useFormData } from "../context";
import { makeStyles } from "@mui/styles";
import backArrow from "../../../../../static/back-arrow.png";
import Ticket from "./Ticket";
import useService from "../../../collection/useService";
import useHandleErrors from "../../../../../@components/UI/useHandleErrors";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
}));

const Create = ({ previouseStep, DAO, onRemoveDraft }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { user: loggedInUser } = useUserContext();
  const collectionService = useService(DAO?.id);
  const { isConnected } = useAccount();
  const { data } = useFormData();
  const [isLoading, setIsLoading] = React.useState(false);
  const { prepareCollectionNFTUri } = UI.useIPFS();
  const { handleApiError, handleMetamaskError } = useHandleErrors();

  const saveCollectionToDatabase = async (collectionData) => {
    const data = {
      name: collectionData.basic_info.name,
      description: collectionData?.basic_info?.description,
      nft_address: collectionData.address,
      picture_url: collectionData.nft_url,
      ticket_supply: 0,
      ticket_name: collectionData.basic_info.name,
      ticket_symbol: collectionData.basic_info.symbol,
      nft_file_type: collectionData.basic_info.picture_url.file?.type,

      nft_animation_url: collectionData.basic_info.nft_animation_url,
      payment_token: null,
      currency: collectionData?.payment?.crypto?.token
        ? collectionData?.payment?.crypto?.currency
        : collectionData?.payment?.fiat.currency,
      isFreeMintEnabled: collectionData?.isFreeMintEnabled || false,
      is_imported: collectionData?.isImported || false,
      visible: true,
      total_price_no_vat: 0,
      total_price_with_tax: 0,
      total_vat_amount: 0,
      utilities: [
        collectionData?.main_utility,
        ...collectionData?.extra_utilities,
      ],
      request_info_fields: [],
    };

    await collectionService
      .save(data)
      .then(() => {
        enqueueSnackbar(t("brands.collection.import.review.snackbar.success"), {
          variant: "success",
        });
        setIsLoading(false);
        onRemoveDraft();
        history.push({
          pathname: `/brand/${DAO?.id}/collection`,
          state: { scrollToTop: true },
        });
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = async () => {
    const form = { ...data };

    const nftUri = await prepareCollectionNFTUri(form, DAO);

    const allData = {
      ...form,
      nft_ipfs_uri: nftUri,
      nft_url: form.basic_info.picture_url.ipfs,
      avatar_url: form.basic_info.picture_url.ipfs,
      total_price: form?.totalPrice,
      total_price_with_tax: form?.totalPrice,
      total_tax_amount: form?.totalPrice,
    };

    setIsLoading(true);
    try {
      //Save collection to BE
      await saveCollectionToDatabase({
        ...allData,
        address: form.basic_info.nft_collection_address,
      });
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        mt: { md: "15px", mobile: "20px" },
        p: { md: "40px 65px", mobile: "0px 10px" },
        overflowWrap: "break-word",
      }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />

      <Box
        onClick={() => previouseStep()}
        className={classes.backButtonOuter}
        sx={{
          display: "flex",
          cursor: "pointer",
          mb: { md: "50px", mobile: "30px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mr: "8px",
          }}
        >
          <img
            className={"backArrow"}
            style={{ transition: "0.4s ease-in" }}
            src={backArrow}
            alt={"back-arrow"}
            width={"15px"}
            height={"15px"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box className={classes.backButton}>
            {t("brands.collection.import.review.backButton")}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          mb: "50px",
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          textAlign: "left",
        }}
      >
        <Box>
          <Typography variant={"h3"}>
            {t("brands.collection.import.review.title")}
          </Typography>
        </Box>
      </Box>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={4} mobile={12}>
          <Box
            sx={{
              display: "flex",
              mb: "60px",
              justifyContent: { mobile: "center", md: "unset" },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Ticket
                brandName={DAO?.name}
                brandAvatar={DAO?.avatar_url}
                name={data?.basic_info?.name}
                fileType={data?.basic_info?.picture_url?.file?.type}
                data={data?.basic_info?.picture_url?.ipfs}
                mainUtility={data?.main_utility}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} mobile={12}>
          <Box sx={{ ml: { md: "60px", mobile: "0px" } }}>
            <Box sx={{ mb: "14px" }}>
              {DAO?.name ? (
                <Typography
                  variant={"subtitle3"}
                  sx={{
                    lineHeight: "13px !important",
                  }}
                >
                  {DAO?.name}
                </Typography>
              ) : (
                <Skeleton
                  sx={{ mb: "10px" }}
                  height={"15px"}
                  width={"300px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            <Box sx={{ mb: "30px" }}>
              {data?.basic_info?.name ? (
                <Typography variant={"h2"}>{data?.basic_info?.name}</Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"300px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {data ? (
              <Box sx={{ mb: "30px", display: "flex" }}>
                <UI.UtilityTag utilityType={data?.main_utility?.type} />
                <UI.Tag
                  sx={{
                    ml: "10px",
                    backgroundColor: (theme) => theme.palette.tag.green,
                  }}
                >
                  {t("commons.imported")}
                </UI.Tag>
              </Box>
            ) : (
              <Skeleton
                sx={{ mb: "30px", display: "flex" }}
                height={"30px"}
                width={"300px"}
                variant={"rectangular"}
              />
            )}
            <Box sx={{ mb: "30px" }}>
              {data?.basic_info?.description ? (
                <UI.ReadMore fontSize={"16px"} maxTextLength={800}>
                  {data?.basic_info?.description}
                </UI.ReadMore>
              ) : (
                <Skeleton
                  height={"150px"}
                  width={"100%"}
                  variant={"rectangular"}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          mobile={12}
          sx={{
            mt: { md: "5px", mobile: "10px" },
            mb: { md: "15px", mobile: "16px" },
            pt: "0 !important",
          }}
        >
          <Box sx={{ mb: "30px" }}>
            <Typography
              variant={"h2"}
              sx={{
                fontSize: { mobile: "18px", md: "22px" },
                lineHeight: "1.5 !important",
              }}
            >
              {t("brands.collection.import.review.perks")}
            </Typography>
          </Box>
          <UI.Utilities
            utilities={[
              {
                ...data?.main_utility,
                custom_info_fields: data?.main_utility.custom_info_fields.map(
                  (field) => ({
                    ...field,
                    title: field.name,
                  })
                ),
              },
              ...data?.extra_utilities.map((utility) => ({
                ...utility,
                custom_info_fields: utility.custom_info_fields.map((field) => ({
                  ...field,
                  title: field.name,
                })),
              })),
            ]}
            currency={
              data?.payment?.crypto?.token
                ? data?.payment?.crypto?.currency
                : data?.payment?.fiat?.currency
            }
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          mt: "40px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "250px" }}>
            <UI.Button
              onClick={() => onSubmit()}
              disabled={!isConnected || !loggedInUser?.email_verified}
              sx={{
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
              title={t("buttons.createCollection")}
              type={"primary"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Create;
