import { useMutation } from "@tanstack/react-query";
import useHandleErrors from "../UI/useHandleErrors";

const usePost = (mutationFn, options) => {
  const { handleApiError } = useHandleErrors();

  return useMutation({
    mutationFn,
    ...{
      onError: (error) => handleApiError(error),
      ...options,
    },
  });
};

export default usePost;
