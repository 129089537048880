import React from "react";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import settingsIcon from "../../static/settings-icon-white.png";
import DropdownMenu from "./DropdownMenu";

const Iframe = ({ url }) => {
  return (
    <Box
      component={"iframe"}
      title={"animation"}
      src={url}
      sx={{
        borderRadius: (theme) => theme.borderRadius.primary,
        border: "none",
        height: { md: "370px", mobile: "160px" },
        width: { md: "370px", mobile: "160px" },
      }}
    />
  );
};

const Ticket = ({
  nft,
  data,
  fileType,
  animationUrl,
  onTransfer,
  onMoveToWallet,
}) => {
  const [openMenu, setOpenMenu] = React.useState(null);
  const [previewfile, setPreviewfile] = React.useState(null);

  return (
    <Box
      sx={{
        width: { md: "348px", mobile: "160px" },
      }}
    >
      <Box
        onClick={() => setPreviewfile(data)}
        sx={{
          borderRadius: (theme) => theme.borderRadius.primary,
          cursor: "pointer",
          textAlign: "center",
          height: { md: "370px", mobile: "160px" },
          width: { md: "370px", mobile: "160px" },
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            height: { md: "370px", mobile: "160px" },
            borderRadius: (theme) => theme.borderRadius.primary,
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            component={"span"}
            sx={{
              boxSizing: "border-box",
              display: "block",
              overflow: "hidden",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: 1,
              border: "0px",
              margin: "0px",
              padding: "0px",
              position: "absolute",
              inset: 0,
              lineHeight: "0",
              fontSize: "0",
            }}
          >
            {animationUrl === null ? (
              data ? (
                fileType?.startsWith("video") ? (
                  <video
                    playsInline
                    width={"fit-content"}
                    height={"100%"}
                    autoplay="autoplay"
                    loop
                    muted
                  >
                    <source src={data} />
                  </video>
                ) : (
                  <UI.ImageWithSkeleton
                    sx={{
                      borderRadius: (theme) => theme.borderRadius.primary,
                      position: "absolute",
                      inset: 0,
                      boxSizing: "border-box",
                      padding: 0,
                      border: "none",
                      margin: "auto",
                      display: "block",
                      width: 0,
                      height: 0,
                      minWidth: "100%",
                      maxWidth: "100%",
                      minHeight: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    src={data}
                    width={"100%"}
                    alt={"slider-item"}
                  />
                )
              ) : (
                <Skeleton
                  height={"300px"}
                  width={"300px"}
                  variant={"rectangular"}
                />
              )
            ) : (
              <Iframe url={animationUrl} />
            )}
          </Box>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              setOpenMenu(e.currentTarget);
            }}
            sx={{
              position: "absolute",
              width: { md: "50px", mobile: "40px" },
              height: { md: "50px", mobile: "40px" },
              bottom: { md: "10px", mobile: "5px" },
              left: { md: "10px", mobile: "5px" },
              background: "rgba(148, 77, 255, 1)",
              borderRadius: "100px",
            }}
          >
            <Box
              component={"img"}
              src={settingsIcon}
              sx={{
                position: "absolute",
                top: "50%",
                right: "50%",
                transform: "translateX(50%) translateY(-50%)",
                width: { md: "26px", mobile: "20px" },
                height: { md: "26px", mobile: "20px" },
                "&:hover": {
                  cursor: "pointer",
                  transform: "translateX(50%) translateY(-50%) rotate(-180deg)",
                  transition: "all 0.8s ease 0s",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Dialog
        onClose={() => setPreviewfile(null)}
        open={!!previewfile}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "700px",
          },
        }}
        PaperProps={{
          maxWidth: "700px",
        }}
      >
        {previewfile && fileType?.startsWith("video") && (
          <video
            playsInline
            width="100%"
            height={"auto"}
            autoplay="autoplay"
            loop
          >
            <source src={previewfile} />
          </video>
        )}
        {previewfile && fileType?.startsWith("image") && (
          <Box
            component={"img"}
            sx={{
              maxHeight: { md: "600px", mobile: "auto" },
              width: { md: "auto", mobile: "100%" },
              margin: "0 auto",
            }}
            src={previewfile}
          />
        )}
      </Dialog>
      <DropdownMenu
        onTransfer={onTransfer}
        onMoveToWallet={onMoveToWallet}
        nft={nft}
        openMenu={openMenu}
        onClose={() => setOpenMenu(null)}
      />
    </Box>
  );
};

export default React.memo(Ticket);
