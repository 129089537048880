import React from "react";
import Container from "@mui/material/Container";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import useService from "../useService";
import { MenuProvider } from "./context";
import { UserRoleProvider } from "./context";
import Info from "./home";
import Token from "./token";
import Tresuary from "./tresuary";
import Settings from "./settings";
import Votes from "./vote";
import Vote from "./vote/@id";
import Proposal from "./proposal";
import Memberships from "./membership";
import Membership from "./membership/@id";
import Community from "./community";
import Albums from "./album";
import Album from "./album/@id";

import Menu from "./Menu";
import Collections from "./collection";
import Collection from "./collection/@id";
import SeatReseration from "./collection/@id/seatReservation";
import Feed from "./feed";
import Showroom from "./showroom";

import MobileMenu from "./MobileMenu";
import Header from "./Header";
import CustomHooks from "../../@components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUserContext } from "../../@components/userContext";
import Box from "@mui/material/Box";

const Index = ({ match }) => {
  const { id } = useParams();
  const service = useService(id);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { user: loggedInUser } = useUserContext();
  const { data: DAO, refetch } = CustomHooks.useFetch(["dao"], service.dao);

  const showroomMatch = useRouteMatch("/brand/:brandId/showroom");
  const isShowRoomPage = showroomMatch !== null;

  React.useEffect(() => {
    loggedInUser && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  return (
    <React.Fragment>
      <MenuProvider DAO={DAO}>
        <UserRoleProvider DAO={DAO}>
          <ContainerWrapper>
            {isShowRoomPage === false && (
              <>
                {!isMobile && <Header DAO={DAO} />}

                {!isMobile && <Menu DAO={DAO} currentUrl={match?.url} />}
                {isMobile && <MobileMenu DAO={DAO} currentUrl={match?.url} />}
              </>
            )}

            <Switch>
              <Route path={`${match.url}/tresuary/withdraw`}>
                <Proposal.Withdraw DAO={DAO} />
              </Route>
              <Route path={`${match.url}/tresuary/tokens/new`}>
                <Tresuary.Tokens.Create />
              </Route>
              <Route path={`${match.url}/tresuary`}>
                <Tresuary DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection/new`}>
                <Collections.Create DAO={DAO} />
              </Route>
              <Route path={`${match.url}/album/new`}>
                <Albums.Create DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection/import`}>
                <Collections.Import DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection/import/draft/:id`}>
                <Collections.Import DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection/new/draft/:id`}>
                <Collections.Create DAO={DAO} />
              </Route>
              <Route path={`${match.url}/feed/new`}>
                <Feed.Create DAO={DAO} />
              </Route>
              <Route path={`${match.url}/vote/:id/collection`}>
                <Vote.Collection DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection/:id/admin`}>
                <Collection.Admin DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection/:id/payment/success`}>
                <Collection.Payment.Success DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection/:id/payment/failed`}>
                <Collection.Payment.Failed DAO={DAO} />
              </Route>
              <Route path={`${match.url}/album/:albumId/edit`}>
                <Album.Edit DAO={DAO} />
              </Route>
              <Route path={`${match.url}/album/:albumId`}>
                <Album DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection/:id/seat-reservation`}>
                <SeatReseration DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection/:id`}>
                <Collection DAO={DAO} />
              </Route>

              <Route path={`${match.url}/vote/:id/withdraw`}>
                <Vote.Withdraw DAO={DAO} />
              </Route>
              <Route path={`${match.url}/vote/:id/settings`}>
                <Vote.Settings DAO={DAO} />
              </Route>
              <Route path={`${match.url}/vote/:id/addMember`}>
                <Vote.AddMember DAO={DAO} />
              </Route>
              <Route path={`${match.url}/album`}>
                <Albums DAO={DAO} />
              </Route>
              <Route path={`${match.url}/collection`}>
                <Collections DAO={DAO} />
              </Route>
              <Route path={`${match.url}/feed`}>
                <Feed DAO={DAO} />
              </Route>
              <Route path={`${match.url}/vote`}>
                <Votes DAO={DAO} />
              </Route>
              <Route path={`${match.url}/token`}>
                <Token DAO={DAO} />
              </Route>
              <Route path={`${match.url}/showroom`}>
                <Showroom DAO={DAO} />
              </Route>
              <Route path={`${match.url}/membership/votingPower`}>
                <Memberships.VotingPower DAO={DAO} />
              </Route>
              <Route path={`${match.url}/membership/add`}>
                <Memberships.Add DAO={DAO} />
              </Route>
              <Route path={`${match.url}/membership/:id`}>
                <Membership DAO={DAO} />
              </Route>
              <Route path={`${match.url}/membership`}>
                <Memberships DAO={DAO} />
              </Route>
              <Route path={`${match.url}/community`}>
                <Community DAO={DAO} />
              </Route>

              <Route path={`${match.url}/settings/governor`}>
                <Settings.Governor DAO={DAO} />
              </Route>
              <Route path={`${match.url}/settings/general`}>
                <Settings.General DAO={DAO} refetch={() => refetch()} />
              </Route>
              <Route path={`${match.url}/settings/payment`}>
                <Settings.Payment DAO={DAO} refetch={() => refetch()} />
              </Route>
              <Route path={`${match.url}/settings/billing`}>
                <Settings.Billing DAO={DAO} refetch={() => refetch()} />
              </Route>
              <Route path={`${match.url}/settings/plugin`}>
                <Settings.Plugin DAO={DAO} refetch={() => refetch()} />
              </Route>

              <Route path={`${match.url}/`}>
                <Info DAO={DAO} />
              </Route>
            </Switch>
          </ContainerWrapper>
        </UserRoleProvider>
      </MenuProvider>
    </React.Fragment>
  );
};

const ContainerWrapper = ({ children }) => {
  const showroomMatch = useRouteMatch("/brand/:brandId/showroom");
  const isShowRoomPage = showroomMatch !== null;

  return (
    <>
      {isShowRoomPage === false && (
        <Container
          maxWidth={"md"}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingLeft: { mobile: "0px" },
            paddingRight: { mobile: "0px" },
          }}
        >
          {children}
        </Container>
      )}
      {isShowRoomPage === true && (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingLeft: { mobile: "0px" },
            paddingRight: { mobile: "0px" },
          }}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default Index;
