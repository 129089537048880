import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import UI from "../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Controller, useForm } from "react-hook-form";
import useService from "./useService";
import { useSnackbar } from "notistack";
import useHandleErrors from "../../@components/UI/useHandleErrors";

const useStyles = makeStyles((theme) => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Edit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleApiError } = useHandleErrors();
  const service = useService();
  const history = useHistory();
  const classes = useStyles();
  const [info, setInfo] = React.useState(null);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      description: info?.description,
      webpage_url: info?.webpage_url,
      about: info?.about,
      avatar_url: info?.avatar_url,
      whitepaper_url: info?.whitepaper_url,
      discord: info?.discord,
      instagram: info?.instagram,
      reddit: info?.reddit,
      telegram: info?.telegram,
      twitter: info?.twitter,
      facebook: info?.facebook,
    },
  });

  React.useEffect(() => {
    const fetchData = async () => {
      await service
        .info()
        .then((response) => {
          setInfo(response.data);
          reset({
            description: response.data?.description,
            webpage_url: response.data?.webpage_url,
            about: response.data?.about,
            avatar_url: response.data?.avatar_url,
            whitepaper_url: response.data?.whitepaper_url,
            discord: response.data?.discord,
            instagram: response.data?.instagram,
            reddit: response.data?.reddit,
            telegram: response.data?.telegram,
            twitter: response.data?.twitter,
            linkedin: response.data?.linkedin,
            facebook: response.data?.facebook,
          });
        })
        .catch((error) => {
          handleApiError(error);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (form) => {
    const data = {
      ...form,
      avatar_url: form?.avatar_url?.ipfs,
      whitepaper_url: form?.whitepaper_url?.ipfs,
    };

    await service
      .save(data)
      .then(() => {
        enqueueSnackbar("Info page succesfully updated", {
          variant: "success",
        });
        history.push({
          pathname: `/qvrse`,
          state: { scrollToTop: true },
        });
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  return (
    <Paper sx={{ width: "100%", marginLeft: { md: "20px", mobile: "0px" } }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "70px", mobile: "0px" },
            pb: "20px",
            pt: { md: "70px", mobile: "30px" },
          }}
        >
          <UI.BackButton 
            title={
              <Typography variant={"h3"}>
                {"EDIT INFO"} 
              </Typography>
            }
            onClick={() => history.go(-1)}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: { md: "45px 80px", mobile: "20px 10px" },
          }}
        >
          <Typography variant={"subtitle2"} sx={{ mt: "30px", mb: "30px" }}>
          Here you can edit or add more info about your brand. 
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h2"}>About</Typography>
                  <UI.Tooltip>Describe your organization.</UI.Tooltip>
                </Box>

                <Controller
                  name={"about"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField
                      multiline
                      rows={12}
                      {...field}
                      placeholder={"About"}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h2"}>Short Description</Typography>
                  <UI.Tooltip>
                    Enter a short one to three-sentence description of your
                    organization.
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"description"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"Description"} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h2"}>Webpage url</Typography>
                  <UI.Tooltip>Enter your web page url address</UI.Tooltip>
                </Box>

                <Controller
                  name={"webpage_url"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"Webpage URL"} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h2"}>Discord</Typography>
                  <UI.Tooltip>Discord url</UI.Tooltip>
                </Box>

                <Controller
                  name={"discord"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"URL"} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h2"}>LinkedIn</Typography>
                  <UI.Tooltip>Twitter url</UI.Tooltip>
                </Box>

                <Controller
                  name={"linkedin"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"URL"} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h2"}>Twitter</Typography>
                  <UI.Tooltip>Twitter url</UI.Tooltip>
                </Box>

                <Controller
                  name={"twitter"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"URL"} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h2"}>Telegram</Typography>
                  <UI.Tooltip>Telegram url</UI.Tooltip>
                </Box>

                <Controller
                  name={"telegram"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"URL"} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h2"}>Instagram</Typography>
                  <UI.Tooltip>Telegram url</UI.Tooltip>
                </Box>

                <Controller
                  name={"instagram"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"URL"} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h2"}>Facebook</Typography>
                  <UI.Tooltip>Facebook url</UI.Tooltip>
                </Box>

                <Controller
                  name={"facebook"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField {...field} placeholder={"URL"} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "30px",
                }}
              >
                <Controller
                  name={"avatar_url"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.Upload
                      {...field}
                      id={"avatar"}
                      title={"Upload New Avatar Picture"}
                      acceptFiles={"image/jpeg,image/gif,image/png"}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "30px",
                }}
              >
                <Controller
                  name={"whitepaper_url"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.Upload
                      {...field}
                      id={"whitepaper"}
                      title={"Upload new document"}
                      acceptFiles={"application/pdf"}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            pb: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px", mt: { md: "0px", mobile: "20px" } }}>
            <UI.Button
              // disabled={!!loading}
              title={"Save"}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Edit;
