import React from "react";
import List from "./List";
import CustomHooks from "../../../@components/hooks";
import useService from "../../useService";
import { ethers } from "ethers";
import erc721Artifact from "../../../abis/contracts/modules/collection/Collection.sol/Collection.json";
import { useAccount } from "wagmi";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import UI from "../../../@components/UI";
import { useSnackbar } from "notistack";
import MintingList from "./MintingList";
import customHooks from "../../../@components/hooks";

const Index = () => {
  const service = useService();
  const signer = customHooks.useEthersSigner();
  const { address: ownerAddress } = useAccount();
  const { handleMetamaskError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [areNFTsInMinting, setAreNFTsInMinting] = React.useState(false);

  const {
    data: userData,
    isLoading: loadingProfile,
    refetch,
  } = CustomHooks.useFetch(["full_user_profile"], service.info, {
    enabled: false,
    onSuccess: (profile) => {
      if (profile?.waiting_mint_nfts?.length > 0) {
        console.log("nfts are in minting");
        setAreNFTsInMinting(true);
        localStorage.setItem(
          "amount_of_nfts_after_minting_over",
          profile.owned_nfts.length + profile.waiting_mint_nfts.length
        );
      } else {
        const amount_of_nfts_after_minting = localStorage.getItem(
          "amount_of_nfts_after_minting_over"
        );

        if (
          amount_of_nfts_after_minting &&
          parseInt(amount_of_nfts_after_minting) === profile.owned_nfts.length
        ) {
          console.log("no nfts are in minting");
          setAreNFTsInMinting(false);
          localStorage.removeItem("amount_of_nfts_after_minting_over");
        }
      }
    },
  });

  React.useEffect(() => {
    if (areNFTsInMinting) {
      //set timeout for 1 minute and refetch data until not waiting minting nfts
      const interval = setInterval(() => {
        console.log("refresh");
        refetch();
      }, 60000);
      return () => clearInterval(interval);
    }

    // eslint-disable-next-line
  }, [areNFTsInMinting]);

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  const transferTicket = async (ticket, recipientAddress) => {
    setLoading(true);

    try {
      const nft_contract = new ethers.Contract(
        ticket?.nft_address,
        erc721Artifact.abi,
        signer
      );

      await nft_contract.transferFrom(
        ownerAddress,
        recipientAddress,
        ticket.nft_id
      );
      setLoading(false);
    } catch (e) {
      handleMetamaskError(e);
      setLoading(false);
    }
  };

  const { mutate: generateQR } = CustomHooks.usePost(service.generateQR, {
    onSuccess: () => {
      enqueueSnackbar("QR Ticket was succesfully sent to your email", {
        variant: "success",
      });
    },
  });

  return (
    <>
      <UI.Busy.FullscreenIndicator show={loading} />
      <List
        items={userData?.owned_nfts}
        mintingItems={userData?.waiting_mint_nfts}
        busy={loadingProfile}
        onGenerateQrTicket={(ticket) => generateQR(ticket)}
        onTransferTicket={(ticket, address) => transferTicket(ticket, address)}
      />
    </>
  );
};
Index.List = List;
Index.MintingList = MintingList;
export default Index;
