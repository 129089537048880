import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import UserMenu from "./UserMenu";
import { useTranslation } from "react-i18next";

const UserAvatar = ({ user }) => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = React.useState(null);
  return (
    <>
      <IconButton
        sx={{ display: { mobile: "none", md: "flex" } }}
        onClick={(e) => setOpenMenu(e.currentTarget)}
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
      >
        <Tooltip
          title={user?.email_verified ? "" : t("profile.avatar.notVerified")}
          arrow
        >
          <Badge
            badgeContent={"!"}
            overlap="circular"
            color="error"
            invisible={user?.email_verified}
          >
            <Avatar
              sx={{
                transition: "box-shadow 0.3s",
                width: "32px",
                height: "32px",
                boxShadow: user?.email_verified ? "unset" : "0 0 0 2px #d32f2f",
                "&:hover": {
                  boxShadow: user?.email_verified
                    ? "0 0 0 2px #944DFF"
                    : "0 0 0 2px #d32f2f",
                },
              }}
              src={user?.avatar_url}
            />
          </Badge>
        </Tooltip>
      </IconButton>
      <UserMenu
        user={user}
        openMenu={openMenu}
        onClose={() => setOpenMenu(null)}
      />
    </>
  );
};

export default UserAvatar;
