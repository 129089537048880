import { useRef, useEffect, useState } from "react";

const useScratchCard = (open) => {
  const canvasRef = useRef(null);
  const [grayCount, setGrayCount] = useState(0);
  const [eraseCount, setEraseCount] = useState(0);

  useEffect(() => {
    let isDrag = false;
    if (!open || canvasRef.current == null) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // default value
    context.globalCompositeOperation = "source-over";

    var x, y, radius;
    x = y = radius = 191 / 2;

    // fill circle
    context.beginPath();
    context.fillStyle = "#CCCCCC";
    context.arc(x, y, radius, 0, Math.PI * 2, false);
    context.fill();

    const clearArc = (x, y) => {
      context.globalCompositeOperation = "destination-out";
      context.beginPath();
      context.arc(x, y, 10, 0, Math.PI * 2, false);
      context.fill();
    };

    const judgeVisible = () => {
      var imageData = context.getImageData(0, 0, 191, 191),
        pixels = imageData.data,
        result = {},
        i,
        len;

      // count alpha values
      for (i = 3, len = pixels.length; i < len; i += 4) {
        result[pixels[i]] || (result[pixels[i]] = 0);
        result[pixels[i]]++;
      }

      setGrayCount(result[255] || 0);
      setEraseCount(result[0] || 0);
    };

    const handleMouseDown = (event) => {
      isDrag = true;
      clearArc(event.offsetX, event.offsetY);
      judgeVisible();
    };

    const handleMouseMove = (event) => {
      if (!isDrag) {
        return;
      }
      clearArc(event.offsetX, event.offsetY);
      judgeVisible();
    };

    const handleMouseUp = () => {
      isDrag = false;
    };

    const handleTouchStart = (event) => {
      if (event.targetTouches.length !== 1) {
        return;
      }

      event.preventDefault();
      isDrag = true;
      clearArc(
        event.touches[0].clientX - canvas.getBoundingClientRect().left,
        event.touches[0].clientY - canvas.getBoundingClientRect().top
      );
      judgeVisible();
    };

    const handleTouchMove = (event) => {
      if (!isDrag || event.targetTouches.length !== 1) {
        return;
      }

      event.preventDefault();
      clearArc(
        event.touches[0].clientX - canvas.getBoundingClientRect().left,
        event.touches[0].clientY - canvas.getBoundingClientRect().top
      );
      judgeVisible();
    };

    const handleTouchEnd = () => {
      isDrag = false;
    };

    // Attach event listeners
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mouseleave", handleMouseUp);
    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);
    canvas.addEventListener("touchend", handleTouchEnd);

    // Clean up event listeners
    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mouseleave", handleMouseUp);
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
      canvas.removeEventListener("touchend", handleTouchEnd);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, canvasRef.current]);

  return { canvasRef, grayCount, eraseCount };
};

export default useScratchCard;
