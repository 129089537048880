import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../@components/UI";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import maticIcon from "../../../static/matic-icon-white.png";

//import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   shakeAnimation: {
//     animation: `$shake 3s ease infinite`, // Reduced total duration to 3 seconds
//   },
//   "@keyframes shake": {
//     "0%, 100%": { transform: "skewY(0deg)" },
//     "5%": { transform: "skewY(6deg)" }, // Adjusted for 3s duration
//     "10%": { transform: "skewY(-6deg)" }, // Adjusted for 3s duration
//     "15%": { transform: "skewY(6deg)" }, // Adjusted for 3s duration
//     "20%": { transform: "skewY(-6deg)" }, // Adjusted for 3s duration
//     "25%": { transform: "skewY(6deg)" }, // Adjusted for 3s duration
//     "30%": { transform: "skewY(-6deg)" }, // Adjusted for 3s duration
//     "35%, 100%": { transform: "skewY(0deg)" }, // Shortened pause
//   },
// }));

const Item = ({ item }) => {
  return (
    <Grid
      item
      mobile={12}
      md={4}
      lg={4}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <UI.FlipCard
        frontComponent={<FrontSide item={item} />}
        backComponent={<BackSide item={item} />}
      />
    </Grid>
  );
};

const BackSide = ({ item }) => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        cursor: "pointer",
        textAlign: "left",
        margin: "0 auto",
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        opacity: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderBottom: "none",
          padding: "10px 20px ",
          height: "70px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "left" }}>
              {item?.dao_info.name ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: "185.4px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao_info?.name} `}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.nft_name ? (
              <Typography
                variant={"h2"}
                sx={{
                  lineHeight: "22px !important",
                  fontSize: "1rem !important",
                  maxWidth: "185.4px",
                }}
              >
                <UI.OverflowTip>
                  {item?.nft_name_slo !== null &&
                  item?.nft_name_slo !== "" &&
                  item?.nft_name_slo !== undefined &&
                  i18n.language === "sl"
                    ? item?.nft_name_slo
                    : item?.nft_name || ""}
                </UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={item?.dao_info?.avatar_url} alt={"avatar"} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "290px",
          flexDirection: "column",
          p: "10px 20px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      >
        <Box sx={{ display: "flex", mt: "5px" }}>
          <Typography
            variant={"subtitle4"}
            sx={{
              mr: "10px",
            }}
          >
            Price
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {item?.free_nft_gas_fee_payed_by_system === false && (
              <Box sx={{ width: "18px", height: "18px" }}>
                <UI.PaymentIcon currency={item?.currency} />
              </Box>
            )}
            {item?.full_price !== "0.00" && (
              <Typography variant={"h8"} sx={{ ml: "7px" }}>
                {`${item?.nft_price} ${item?.currency}`}
              </Typography>
            )}
            {item?.full_price === "0.00" && (
              <Typography variant={"h8"} sx={{ ml: "7px" }}>
                {t("commons.free")}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", mt: "10px", maxHeight: "130px" }}>
          <Typography
            variant={"subtitle3"}
            sx={{
              maxHeight: "130px",
              overflow: "hidden",
              whiteSpace: "normal",
              textAlign: "justify",
            }}
          >
            {item?.description?.length > 200
              ? item?.description?.slice(0, 200) + " ..."
              : item?.description}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mt: "20px" }}>
          <Box
            sx={{
              display: "flex",
              bottom: "16px",
              right: "16px",
            }}
          >
            <UI.UtilityTag
              utilityType={
                item.utilities?.find(
                  (utility) => utility.is_main_utility === true
                )?.type || ""
              }
            />
            <UI.Tag
              sx={{
                ml: "10px",
                backgroundColor: (theme) => theme.palette.tag.green,
              }}
            >
              {item?.is_imported
                ? t("utilityTag.imported")
                : t("utilityTag.limited")}
            </UI.Tag>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          p: "15px 10px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      ></Box>
    </Box>
  );
};

const FrontSide = ({ item }) => {
  //const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        textAlign: "left",
        cursor: "pointer",
        margin: "0 auto",
        width: "290px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderBottom: "none",
          padding: "10px 20px ",
          height: "70px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              {item?.dao_info.name ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: "185.4px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao_info.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.nft_name ? (
              <Typography
                variant={"h2"}
                sx={{
                  lineHeight: "22px !important",
                  fontSize: "1rem !important",
                  maxWidth: "185.4px",
                }}
              >
                <UI.OverflowTip>
                  {item?.nft_name_slo !== null &&
                  item?.nft_name_slo !== "" &&
                  item?.nft_name_slo !== undefined &&
                  i18n.language === "sl"
                    ? item?.nft_name_slo
                    : item?.nft_name || ""}
                </UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={item?.dao_info?.avatar_url} alt={"avatar"} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "290px",
          width: "100%",
          position: "relative",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      >
        <Typography
          variant={"subtitle3"}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {t("profile.home.list.mintingInProgress")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            position: "absolute",
            bottom: "16px",
            right: "16px",
          }}
        >
          <UI.UtilityTag
            utilityType={
              item?.utilities?.find(
                (utility) => utility.is_main_utility === true
              )?.type || ""
            }
          />
          <UI.Tag
            sx={{
              ml: "10px",
              backgroundColor: (theme) => theme.palette.tag.green,
            }}
          >
            {item?.is_imported
              ? t("utilityTag.imported")
              : t("utilityTag.limited")}
          </UI.Tag>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: "10px 10px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          height: "70px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "10px 10px",
            backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
            height: "70px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {item?.free_nft_gas_fee_payed_by_system === false &&
              item?.full_price !== "0.00" && (
                <Box sx={{ width: "20px", height: "20px", ml: "10px" }}>
                  <UI.PaymentIcon currency={item?.currency || "MATIC"} />
                </Box>
              )}
            {item?.full_price !== "0.00" && (
              <Typography
                variant={"h8"}
                sx={{
                  ml: "10px",
                }}
              >
                {`${item?.full_price}`}
              </Typography>
            )}
            {item?.full_price === "0.00" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  //className={classes.shakeAnimation}
                  variant={"h10"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "68px",
                    height: "22px",
                    borderRadius: "100px",
                    backgroundColor: (theme) => theme.palette.tag.red,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {item?.free_nft_gas_fee_payed_by_system === false && (
                    <Box
                      sx={{ width: "12px", height: "12px", mr: "4px", ml: "-2px" }}
                      component={"img"}
                      src={maticIcon}
                    />
                  )}
                  {t("commons.free")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Item;
