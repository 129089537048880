import React from "react";
import MobileButton from "./MobileButton";
import DesktopButton from "./DesktopButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAccount, useDisconnect } from "wagmi";
import { useUserContext } from "../../userContext";
import WrongAccountDialog from "../../../profile/dialogs/WrongAccountDialog";
import useAuth from "../../auth/useAuth";

const Index = ({ onProfileClick, children }) => {
  const { user: loggedInUser } = useUserContext();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [signInBusy, setSignInBusy] = React.useState(true);
  const [showWrongAccountDialog, setShowWrongAccountDialog] =
    React.useState(false);
  const [connectingWalletInProgress, setConnectingWalletInProgress] =
    React.useState(false);
  const { logout } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { disconnect } = useDisconnect();
  const { isConnecting, isConnected, address } = useAccount();

  //Preserve state on reload
  React.useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    if (access_token && isConnected) {
      setIsAuthenticated(true);
      setSignInBusy(false);
    }
  }, [isConnected]);

  React.useEffect(() => {
    if (isConnecting) {
      setConnectingWalletInProgress(true);
    }
  }, [isConnecting]);

  //Connect Wallet
  React.useEffect(() => {
    const userAlreadyHasConnectedWallet = loggedInUser?.address !== "";
    if (
      connectingWalletInProgress &&
      userAlreadyHasConnectedWallet &&
      isConnected
    ) {
      const connectedWalletAddress = address;

      if (loggedInUser?.address === connectedWalletAddress) {
        // If wallet is the same as the one connected to the account
        setIsAuthenticated(true);
        setSignInBusy(false);
        setConnectingWalletInProgress(false);
      } else {
        // If wallet is different from the one connected to the account
        setShowWrongAccountDialog(Math.random());
      }
    }
    // eslint-disable-next-line
  }, [disconnect, isConnected]);

  // If user is logged in and metamask account changes. logout current user and reload page.
  React.useEffect(() => {
    if (isAuthenticated && address !== undefined) {
      logout();
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [address]);

  return (
    <>
      {isMobile ? (
        <MobileButton
          onProfileClick={onProfileClick}
          isAuthenticated={isAuthenticated}
          signInBusy={signInBusy}
        />
      ) : (
        <DesktopButton
          onProfileClick={onProfileClick}
          isAuthenticated={isAuthenticated}
          signInBusy={signInBusy}
        />
      )}
      <WrongAccountDialog toggleOpen={showWrongAccountDialog} />
    </>
  );
};

export default Index;
