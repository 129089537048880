import React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useService from "../useService";
import UI from "../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Ticket from "./Ticket";
import Skeleton from "@mui/material/Skeleton";
import CustomHooks from "../../@components/hooks";
import { useAccount } from "wagmi";
import collectionIcon from "../../static/collection-icon.png";
import { ethers } from "ethers";
import erc721Artifact from "../../abis/contracts/modules/collection/Collection.sol/Collection.json";
import useHandleErrors from "../../@components/UI/useHandleErrors";
import customHooks from "../../@components/hooks";
import { useTranslation } from "react-i18next";
import discordLogo from "../../static/discord-icon.png";
import spatialLogo from "../../static/spatial-icon.png";
//import albumsLogo from "../../static/albums-icon.png";
import PostsSlider from "./PostsSlider";
import UnlockNFTDialog from "./UnlockNFTDialog";
import DiscordAccessDialog from "../dialogs/DiscordAccessDialog";
import { useSnackbar } from "notistack";
import useMediaQuery from "@mui/material/useMediaQuery";

const CallToActions = ({ collection, discordUtility, spatialUtility }) => {
  //const history = useHistory();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { mobile: "space-between", md: "flex-start" },
      }}
    >
      {/*<Box*/}
      {/*  onClick={() => {*/}
      {/*    history.push(`/brand/${collection?.dao_id}/album/`);*/}
      {/*  }}*/}
      {/*  sx={{*/}
      {/*    mr: "25px",*/}
      {/*    cursor: "pointer",*/}
      {/*    display: "flex",*/}
      {/*    justifyContent: "center",*/}
      {/*    alignItems: "center",*/}
      {/*    width: "105px",*/}
      {/*    height: "118px",*/}
      {/*    borderRadius: (theme) => theme.borderRadius.primary,*/}
      {/*    background:*/}
      {/*      "linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Box>*/}
      {/*    <Box*/}
      {/*      component={"img"}*/}
      {/*      src={albumsLogo}*/}
      {/*      sx={{*/}
      {/*        display: "flex",*/}
      {/*        margin: "0 auto",*/}
      {/*        width: "52px",*/}
      {/*        height: "52px",*/}
      {/*        transition: "all 0.5s ease-in-out",*/}
      {/*        "&:hover": {*/}
      {/*          transform: "scale(1.1)",*/}
      {/*        },*/}
      {/*      }}*/}
      {/*    />*/}
      {/*    <Typography*/}
      {/*      variant={"h8"}*/}
      {/*      sx={{ mt: "10px" }}*/}
      {/*    >*/}
      {/*      ALBUMS*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      {spatialUtility && (
        <Box
          sx={{
            cursor: "pointer",
            mr: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { md: "105px", mobile: "118px" },
            height: "118px",
            borderRadius: (theme) => theme.borderRadius.primary,
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(spatialUtility.value, "_blank");
          }}
        >
          <Box>
            <Box
              component={"img"}
              src={spatialLogo}
              sx={{
                display: "flex",
                margin: "0 auto",
                width: "52px",
                height: "52px",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
            <Typography variant={"h8"} sx={{ mt: "10px" }}>
              SPATIAL
            </Typography>
          </Box>
        </Box>
      )}
      {discordUtility && (
        <DiscordAccessDialog.Action
          item={collection}
          onConfirm={() => {}}
          element={
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { md: "105px", mobile: "118px" },
                height: "118px",
                borderRadius: (theme) => theme.borderRadius.primary,
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))",
              }}
            >
              <Box>
                <Box
                  component={"img"}
                  src={discordLogo}
                  sx={{
                    display: "flex",
                    margin: "0 auto",
                    width: "52px",
                    height: "52px",
                    transition: "all 0.5s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                />
                <Typography variant={"h8"} sx={{ mt: "10px" }}>
                  DISCORD
                </Typography>
              </Box>
            </Box>
          }
        />
      )}
    </Box>
  );
};

const Info = ({ nft, collection, mainUtility }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Box sx={{ ml: { md: "44px", mobile: "0px" } }}>
      <Box sx={{ mb: { md: "2px", mobile: "0px" } }}>
        {nft?.dao_info?.name ? (
          <Typography variant={"subtitle3"}>{nft?.dao_info?.name}</Typography>
        ) : (
          <Skeleton
            sx={{ mb: "10px" }}
            height={"15px"}
            width={"300px"}
            variant={"rectangular"}
          />
        )}
      </Box>
      <Box sx={{ mb: { md: "25px", mobile: "11px" } }}>
        {nft?.nft_name ? (
          <Typography variant={"h3"}>
            {`${nft?.nft_name} #${nft?.nft_id}`}
          </Typography>
        ) : (
          <Skeleton height={"30px"} width={"300px"} variant={"rectangular"} />
        )}
      </Box>
      {nft ? (
        <Box
          sx={{
            mb: { md: "30px", mobile: "20px" },
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
          }}
        >
          <UI.UtilityTag utilityType={mainUtility?.type} />
          <UI.Tag
            sx={{
              ml: { md: "10px", mobile: "0px" },
              mt: { md: "0px", mobile: "10px" },
              backgroundColor: (theme) => theme.palette.tag.green,
            }}
          >
            {collection?.is_imported
              ? t("utilityTag.imported")
              : t("utilityTag.limited")}
          </UI.Tag>
        </Box>
      ) : (
        <Skeleton
          sx={{ mb: "30px", display: "flex" }}
          height={"30px"}
          width={"300px"}
          variant={"rectangular"}
        />
      )}
      <Box sx={{ display: { md: "flex", mobile: "none" } }}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push({
              pathname: `/brand/${nft?.dao_id}/collection/${nft?.event_id}`,
              state: { scrollToTop: true },
            });
          }}
          sx={{
            display: "flex",
            cursor: "pointer",
            transition: "all 0.5s ease-in-out",
            "&:hover": {
              transform: "scale(1.01)",
            },
            mb: "16px",
          }}
        >
          <Box
            sx={{
              width: { md: "32px", mobile: "30px" },
              height: { md: "32px", mobile: "30px" },
              borderRadius: "50%",
              backgroundColor: "rgba(148, 77, 255, 1);",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component={"img"}
              src={collectionIcon}
              sx={{ width: "18px" }}
            />
          </Box>
          <Typography
            variant={"subtitle3"}
            sx={{
              display: "flex",
              alignItems: "center",
              ml: "9px",
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {t("profile.nftPage.viewCollection")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const service = useService();
  const [mainUtility, setMainUtility] = React.useState(null);
  const [nft, setNft] = React.useState(null);
  const { handleMetamaskError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const signer = customHooks.useEthersSigner();
  const { address: ownerAddress } = useAccount();
  const [loading, setLoading] = React.useState(false);
  const [showTransferDialog, setShowTransferDialog] = React.useState(false);
  const [showMoveToWalletDialog, setShowMoveToWalletDialog] =
    React.useState(false);
  const [discordUtility, setDiscordUtility] = React.useState(null);
  const [spatialUtility, setSpatialUtility] = React.useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { data: collection, refetch: refetchCollection } = CustomHooks.useFetch(
    ["myNFT"],
    () => service.nft(id),
    {
      onSuccess: async (nft) => {
        setNft(nft);
        //Check if any of the utilities type is discord_access and add it to the state
        const discordUtility = nft?.utilities.find(
          (utility) => utility.type === "discord_access"
        );
        setDiscordUtility(discordUtility);

        let spatialUtilityField = null;

        nft?.utilities.forEach((utility) => {
          utility.custom_info_fields?.forEach((field) => {
            if (
              field.input_type === "button" &&
              field.value.startsWith("https://www.spatial.io/")
            ) {
              spatialUtilityField = field;
            }
          });
        });

        setSpatialUtility(spatialUtilityField);

        setMainUtility(
          nft?.utilities.find((utility) => utility.is_main_utility)
        );
      },
    }
  );

  const transferTicket = async (ticket, recipientAddress) => {
    setLoading(true);

    try {
      const nft_contract = new ethers.Contract(
        ticket?.nft_address,
        erc721Artifact.abi,
        signer
      );

      const tx = await nft_contract.transferFrom(
        ownerAddress,
        recipientAddress,
        ticket.nft_id
      );
      await tx.wait();
      setLoading(false);
      enqueueSnackbar("NFT was successfully transferred", {
        variant: "success",
      });
    } catch (e) {
      handleMetamaskError(e);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <UI.Busy.FullscreenIndicator show={loading}>
        <Typography variant={"subtitle1"}>
          {t("profile.nftPage.loading")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.nftPage.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: { md: "flex", mobile: "none" },
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "0px", mobile: "10px" },
            pb: "30px",
            pt: { md: "0px", mobile: "20px" },
          }}
        >
          <Box
            sx={{
              display: { md: "flex", mobile: "none" },
              justifyContent: "space-between",
            }}
          >
            <UI.BackButton
              title={t("profile.nftPage.backButton")}
              onClick={() =>
                history.push({
                  pathname: `/profile`,
                  state: { scrollToTop: true },
                })
              }
              address={collection?.nft_address}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingLeft: { mobile: "0px", md: "0px" },
            paddingRight: { mobile: "0px", md: "0px" },
          }}
        >
          <Grid
            sx={{ p: { md: "10px", mobile: "0px" } }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={4} mobile={12}>
              <Box
                sx={{
                  display: "flex",
                  mb: { md: "40px", mobile: "20px" },
                  justifyContent: { mobile: "unset", md: "unset" },
                }}
              >
                <Box
                  sx={{
                    mr: { md: "0px", mobile: "18px" },
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Ticket
                    brandName={nft?.dao_info?.name}
                    brandAvatar={nft?.dao_info?.avatar_url}
                    name={nft?.nft_name}
                    name_slo={nft?.nft_name_slo || null}
                    fileType={nft?.image_file_type}
                    data={nft?.nft_image_url}
                    nft={nft}
                    animationUrl={nft?.nft_animation_url || null}
                    mainUtility={mainUtility}
                    onTransfer={() => setShowTransferDialog(true)}
                    onMoveToWallet={() => setShowMoveToWalletDialog(true)}
                  />
                  <UI.TransferDialog
                    open={showTransferDialog}
                    onClose={() => setShowTransferDialog(false)}
                    onConfirm={(address) => {
                      setShowTransferDialog(false);
                      transferTicket(nft, address);
                    }}
                  />
                  <UnlockNFTDialog
                    nftId={nft?.id}
                    open={showMoveToWalletDialog}
                    onClose={() => setShowMoveToWalletDialog(false)}
                    onSuccess={() => {
                      refetchCollection();
                    }}
                    nftVaultAddress={nft?.nft_vault_address}
                  />
                </Box>
                <Box sx={{ display: { md: "none", mobile: "block" } }}>
                  <Info
                    nft={nft}
                    collection={collection}
                    mainUtility={mainUtility}
                  />
                </Box>
              </Box>
            </Grid>
            {isMobile && (
              <Grid mobile={12}>
                <Box
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push({
                      pathname: `/brand/${nft?.dao_id}/collection/${nft?.event_id}`,
                      state: { scrollToTop: true },
                    });
                  }}
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    transition: "all 0.5s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.01)",
                    },
                    mb: "16px",
                  }}
                >
                  <Box
                    sx={{
                      width: { md: "32px", mobile: "30px" },
                      height: { md: "32px", mobile: "30px" },
                      borderRadius: "50%",
                      backgroundColor: "rgba(148, 77, 255, 1);",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={collectionIcon}
                      sx={{ width: "18px" }}
                    />
                  </Box>
                  <Typography
                    variant={"subtitle3"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: "9px",
                      color: (theme) => theme.palette.text.primary,
                    }}
                  >
                    {t("profile.nftPage.viewCollection")}
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item md={8} mobile={12}>
              {!isMobile && (
                <Box
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <Info
                    nft={nft}
                    collection={collection}
                    mainUtility={mainUtility}
                  />
                  {(discordUtility || spatialUtility) && (
                    <CallToActions
                      collection={nft}
                      discordUtility={discordUtility}
                      spatialUtility={spatialUtility}
                    />
                  )}
                </Box>
              )}
              <PostsSlider collection={nft} />
            </Grid>

            {(discordUtility || spatialUtility) && isMobile && (
              <Grid
                item
                md={12}
                mobile={12}
                sx={{ mb: "30px", display: "flex" }}
              >
                <CallToActions
                  collection={nft}
                  discordUtility={discordUtility}
                  spatialUtility={spatialUtility}
                />
              </Grid>
            )}
            <Grid item md={12} mobile={12} sx={{ pt: "0 !important" }}>
              <Box sx={{ mb: "20px" }}>
                <Typography variant={"h2"} sx={{ fontSize: "20px !important" }}>
                  {t("profile.nftPage.perks")}
                </Typography>
              </Box>
              <UI.Utilities
                showRedeemed
                gasFeesPayedBySystem={nft?.free_nft_gas_fee_payed_by_system}
                utilities={nft?.utilities}
                currency={nft?.currency}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default Index;
