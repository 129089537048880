import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useAccount } from "wagmi";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { useTranslation } from "react-i18next";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: "-15px",
    top: "7.5px",
    backgroundColor: "#44b700",
    color: "#44b700",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledBadgeNotConnected = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: "-10px",
    top: "7.5px",
    backgroundColor: "#ff6767",
    color: "#ff6767",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const MobileWalletStatus = () => {
  const { t } = useTranslation();
  const { isConnected, chain } = useAccount();

  return (
    <>
      {isConnected && (
        <div style={{ display: "flex", gap: 10 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                fontFamily: "Open Sans",
                fontSize: "10px",
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
                height: "50px",
                cursor: "pointer",
                border: "none",
                borderRadius: (theme) => theme.borderRadius.secondary,
                display: "flex",
                padding: "20px 6px",
                backgroundColor: "transparent",
                alignItems: "center",
                transition: "all 0.2s ease-in-out",
                ":hover": {
                  color: (theme) => theme.palette.text.primary,
                },
              }}
            >
              <StyledBadge
                sx={{
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
                overlap="circular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                variant="dot"
              >
                {chain.name === "Polygon Mumbai" ? "Mumbai" : chain.name}
              </StyledBadge>
            </Box>
          </Box>
          <IconButton
            size="large"
            aria-label="wallet"
            sx={{
              display: { mobile: "none", md: "flex" },
              fontSize: "1.8rem",
              transition: "all 0.2s ease-in-out",
              color: (theme) => theme.palette.text.secondary,
              "&:hover": {
                color: (theme) => theme.palette.text.primary,
              },
            }}
          >
            <AccountBalanceWalletIcon sx={{ fontSize: "2rem" }} />
          </IconButton>
        </div>
      )}
      {!isConnected && (
        <Box
          sx={{
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              fontFamily: "Open Sans",
              fontSize: "10px",
              fontWeight: "bold",
              color: (theme) => theme.palette.text.secondary,
              height: "60px",
              ml: "5px",
              border: "none",
              borderRadius: (theme) => theme.borderRadius.secondary,
              display: "flex",
              backgroundColor: "transparent",
              alignItems: "center",
              transition: "all 0.2s ease-in-out",
              ":hover": {
                color: (theme) => theme.palette.text.primary,
              },
            }}
          >
            <StyledBadgeNotConnected
              sx={{
                transition: "all 0.2s ease-in-out",
                "&:hover": {
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              variant="dot"
            >
              {t("commons.notConnected")}
            </StyledBadgeNotConnected>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MobileWalletStatus;
