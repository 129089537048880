import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import UI from "../../../@components/UI";
import FormProvider, { useFormDataContext } from "./context";
import BasicInfo from "./basicInfo";
import BillingMethod from "./billingMethod";
import Review from "./review";
import Apply from "./apply";
import useQvrseService from "../../../qvrse/useService";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import useDraftService from "../../../profile/created/draft/useService";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../../@components/userContext";
import { useAccount } from "wagmi";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const qvrseService = useQvrseService();
  const draftService = useDraftService();
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const { handleApiError } = useHandleErrors();
  const [dao, setDao] = React.useState(null);
  const [draft, setDraft] = React.useState(null);
  const { formData, setFormData } = useFormDataContext();
  const { enqueueSnackbar } = useSnackbar();

  const { state } = useLocation();

  // Needed for UI.Upload component.
  const prepareDraftData = (draft) => {
    return {
      ...draft,
      avatar_url: {
        ...draft?.avatar_url,
        file: { type: "image/png" },
      },
      whitepaper_url: {
        ...draft?.whitepaper_url,
        file: { type: "application/pdf" },
      },
      token_icon_url: {
        ...draft?.token_icon_url,
        file: { type: "image/png" },
      },
    };
  };

  const saveDraft = (values, showSnackbar) => {
    return new Promise(async (resolve, reject) => {
      const draftId = localStorage.getItem("draft_id");

      const allData = {
        data: { ...formData, ...values },
        ...(draftId && { draft_id: draftId }),
      };

      setFormData(values);

      draftService
        .save(allData)
        .then((response) => {
          const draft = response.data.data;
          const allData = prepareDraftData(draft);

          setDraft({ ...response.data, data: allData });
          localStorage.setItem("draft_id", response.data.id);

          showSnackbar &&
            enqueueSnackbar(
              t("applyYourBrand.snackbar", {
                draftId: response.data.active_draft_id,
              }),
              {
                variant: "success",
              }
            );
          resolve(response.data);
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const removeDraft = (draftId) => {
    draftService
      .remove(draftId)
      .then(() => {})
      .catch((error) => {
        handleApiError(error);
      });
  };

  const getDraft = async (id) => {
    await draftService
      .draft(id)
      .then((response) => {
        const draft = response.data.data;
        const allData = prepareDraftData(draft);

        setDraft({ ...response.data, data: allData });

        localStorage.setItem("draft_id", response.data.id);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  React.useEffect(() => {
    if (isConnected) {
      state.draft_id && getDraft(state.draft_id);
    }

    //eslint-disable-next-line
  }, [state, isConnected, loggedInUser]);

  React.useEffect(() => {
    // Clean up local storage items.
    return () => {
      localStorage.removeItem("draft_id");
    };
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      await qvrseService
        .dao()
        .then((response) => {
          setDao(response);
        })
        .catch((error) => {
          handleApiError(error);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const steps = [
    t("applyYourBrand.steps.step1"),
    t("applyYourBrand.steps.step2"),
    t("applyYourBrand.steps.step3"),
    t("applyYourBrand.steps.step4"),
  ];

  const mobileSteps = [
    t("applyYourBrand.steps.mobileSteps.step1"),
    t("applyYourBrand.steps.mobileSteps.step2"),
    t("applyYourBrand.steps.mobileSteps.step3"),
    t("applyYourBrand.steps.mobileSteps.step4"),
  ];

  return (
    <Container maxWidth={"md"} sx={{ px: { mobile: "0px", md: "unset" } }}>
      <Box
        sx={{
          width: "100%",
          px: { md: "0px", mobile: "10px" },
        }}
      >
        <Box sx={{ mt: "40px", display: { md: "block", mobile: "none" } }}>
          <UI.Stepper steps={steps} activeStep={activeStep} />
        </Box>
        <Box sx={{ mt: "0px", display: { md: "none", mobile: "block" } }}>
          <UI.Stepper steps={mobileSteps} activeStep={activeStep} />
        </Box>
      </Box>
      <Box sx={{ textAlign: "center", mt: "50px" }}>
        {activeStep === 0 && (
          <BasicInfo
            onSave={(values, showSnackbar) => saveDraft(values, showSnackbar)}
            draft={draft}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        )}
        {activeStep === 1 && (
          <BillingMethod
            onSave={(values, showSnackbar) => saveDraft(values, showSnackbar)}
            draft={draft}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        )}

        {activeStep === 2 && (
          <Review
            onSave={(values, showSnackbar) => saveDraft(values, showSnackbar)}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        )}
        {activeStep === 3 && dao && (
          <Apply
            onRemoveDraft={() => {
              const draftId = localStorage.getItem("draft_id");
              if (draftId) {
                removeDraft(draftId);
              }
            }}
            DAO={dao}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
          />
        )}
      </Box>
    </Container>
  );
};

const ApplyForDao = () => {
  return (
    <FormProvider>
      <Index />
    </FormProvider>
  );
};

export default ApplyForDao;
