import { createTheme } from "@mui/material/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

const color = {
  // text color
  text: {
    primary: "rgba(255, 255, 255, 1)",
    secondary: "rgba(255, 255, 255, 0.7)",
    tertiary: "rgba(255, 255, 255, 0.5)",
    link: "#944DFF",
    linkHover: "#b185f2",
    linkBlue: "#1976D2",
    linkBlueHover: "#1565C0",
    disabled: "rgb(255 255 255 / 30%)",
  },
  // color
  color: {
    primary: "#944DFF",
    secondary: "#b185f2",
    darkGray: "#0C0C16", //#121424 #1B1E2F
    lightGray: "#272A3A",
    darkRed: "#EF5350",
    gray: "#303346",
  },
  // background transparent
  bgTransparent: {
    primary: "rgba(255, 255, 255, 0.06)",
    secondary: "rgba(255, 255, 255, 0.12)",
    tertiary: "rgba(255, 255, 255, 0.03)",
    quaternary: "rgba(255, 255, 255, 0.09)",
  },
  // tag background color
  tag: {
    primary: "#944DFF",
    green: "#50AF95",
    yellow: "#FFC144",
    red: "#F8275C",
  },
};

const Dark = createTheme({
  // Breakpoints
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 500,
      md: 1100,
      lg: 1400,
      xl: 1536,
      xxl: 1900,
    },
  },
  // Typography
  typography: {
    fontFamily: "Quantico, Open Sans",
    fontStyle: "normal",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    // Quantico (naslov)
    h1: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      [breakpoints.up("md")]: {
        fontSize: "32px",
      },
      [breakpoints.down("md")]: {
        fontSize: "28px",
      },
      lineHeight: "1.5",
      textTransform: "uppercase",
      color: color.text.primary,
    },
    h2: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      [breakpoints.up("md")]: {
        fontSize: "28px",
      },
      [breakpoints.down("md")]: {
        fontSize: "24px",
      },
      textTransform: "uppercase",
      lineHeight: "1.5",
      color: color.text.primary,
    },
    h3: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      [breakpoints.up("md")]: {
        fontSize: "24px",
      },
      [breakpoints.down("md")]: {
        fontSize: "20px",
      },
      lineHeight: "1.5",
      textTransform: "uppercase",
      color: color.text.primary,
    },
    h4: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "1.5",
      textTransform: "uppercase",
      color: color.text.primary,
    },
    h5: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      [breakpoints.up("md")]: {
        fontSize: "18px",
      },
      [breakpoints.down("md")]: {
        fontSize: "16px",
      },
      lineHeight: "1.5",
      textTransform: "uppercase",
      color: color.text.primary,
    },
    h6: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      textTransform: "uppercase",
      color: color.text.primary,
    },
    h7: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      [breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [breakpoints.down("md")]: {
        fontSize: "14px",
      },
      lineHeight: "1.5",
      textTransform: "uppercase",
      color: color.text.primary,
    },
    h8: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "1.5",
      textTransform: "uppercase",
      color: color.text.primary,
    },
    h9: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "1.5",
      textTransform: "uppercase",
      color: color.text.primary,
    },
    h10: {
      fontFamily: "Quantico",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "10px",
      lineHeight: "unset",
      textTransform: "uppercase",
      color: color.text.primary,
    },
    // Open Sans (text)
    subtitle1: {
      fontFamily: "Open Sans",
      fontWeight: "400",
      whiteSpace: "pre-line",
      fontSize: "16px",
      lineHeight: "1.5",
      color: color.text.secondary,
    },
    subtitle2: {
      fontFamily: "Open Sans",
      fontWeight: "400",
      whiteSpace: "pre-line",
      [breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [breakpoints.down("md")]: {
        fontSize: "14px",
      },
      lineHeight: "1.5",
      color: color.text.secondary,
    },
    subtitle3: {
      whiteSpace: "pre-line",
      fontFamily: "Open Sans",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "1.5",
      color: color.text.secondary,
    },
    subtitle4: {
      whiteSpace: "pre-line",
      fontFamily: "Open Sans",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "1.5",
      color: color.text.tertiary,
    },
  },
  // Palette
  palette: {
    type: "dark",
    // text color
    text: {
      primary: color.text.primary,
      secondary: color.text.secondary,
      tertiary: color.text.tertiary,
      link: color.text.link,
      linkHover: color.text.linkHover,
      linkBlue: color.text.linkBlue,
      linkBlueHover: color.text.linkBlueHover,
      disabled: color.text.disabled,
    },
    // tag background color
    tag: {
      primary: color.tag.primary,
      green: color.tag.green,
      yellow: color.tag.yellow,
      red: color.tag.red,
    },
    // background transparent
    bgTransparent: {
      primary: color.bgTransparent.primary,
      secondary: color.bgTransparent.secondary,
      tertiary: color.bgTransparent.tertiary,
      quaternary: color.bgTransparent.quaternary,
    },
    // colors
    colors: {
      primary: color.color.primary,
      secondary: color.color.secondary,
      darkGray: color.color.darkGray,
      lightGray: color.color.lightGray,
      darkRed: color.color.darkRed,
      gray: color.color.gray,
    },

    badgePrimary: {
      main: color.color.primary,
      contrastText: "#fff",
    },
    background: {
      default: "#080A19",
    },
    appbar: {
      text: "rgba(255, 255, 255, 0.62)",
      background: "rgba(10, 10, 20, 0.85)",
      boxShadow: "rgb(0 0 0) 10px 0px 20px",
      backdropFilter: "blur(12px)",
    },
    dialog: {
      background: "rgba(255, 255, 255, 0.08)",
      backdropFilter: "blur(80px)",
    },
    buttons: {
      primary: color.color.primary,
      secondary: color.color.secondary,
      tertiary: "transparent",
    },
  },
  // border Radius
  borderRadius: {
    primary: "8px",
    secondary: "5px",
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: color.text.primary,
          backgroundColor: color.bgTransparent.quaternary,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: color.text.secondary,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: color.text.secondary + "!important",
          minWidth: "unset !important",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          border: "1px solid transparent",
          "&.Mui-selected": {
            color: color.text.primary,
            borderRadius: "8px",
            backgroundColor: "#1B1E2F !important",
            border: "1px solid #944DFF",
            filter: "drop-shadow(0px 0px 15px rgba(138, 38, 255, 0.5))",
            transition: "filter 0.5s ease-in-out",
            "& .MuiListItemText-primary, & .MuiListItemIcon-root": {
              color: color.text.primary,
            },
          },
          "&:hover": {
            border: "1px solid #944DFF",
            borderRadius: "8px",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "& .MuiListItemButton-root": {
            height: "97px",
            justifyContent: "center",
          },
          "& .MuiListItemText-primary": {
            color: color.text.primary,
            fontFamily: "Quantico",
            fontStyle: "normal ",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "25px",
            letterSpacing: "0.01em",
            textTransform: "uppercase",
          },
          "& .MuiListItemText-root": {
            flex: "unset",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "30px",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: color.color.primary,
        },
      },
    },
    // Tooltip
    MuiTooltip: {
      styleOverrides: {
        popper: {
          "& .MuiTooltip-arrow": {
            color: color.color.darkGray,
          },
          zIndex: 999999 + "!important",
        },
        tooltip: {
          backgroundColor: color.color.darkGray,
          fontFamily: "Open Sans",
          fontWeight: "400",
          fontSize: "12px",
          color: color.text.tertiary,
          borderRadius: "3px",
        },
      },
    },
    MuiTabIndicator: {
      styleOverrides: {
        root: {
          backgroundColor: color.color.primary,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          [breakpoints.down("md")]: {
            height: "40px",
          },
        },
      },
    },
    //Tab
    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root.MuiTab-root": {
            [breakpoints.down("md")]: {
              height: "36px",
            },
            backgroundColor: color.bgTransparent.primary,
            fontFamily: "Quantico",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "38px",
            color: color.text.tertiary,
            borderRadius: "5px",
            margin: "0 2px",
          },
          "&.MuiTab-root.Mui-selected": {
            backgroundColor: color.bgTransparent.secondary,
            color: color.text.primary,
          },
        },
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        staticTooltipLabel: {
          color: "#ffffff",
          backgroundColor: color.color.darkGray,
          width: 150,
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: "#0f1224",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: "#0f1224",
          color: "#fff",
          "& .MuiButton-root": {
            color: "#fff",
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#0f1224",
        },
        background: "red",
        backgroundColor: "#0f1224",
      },
    },

    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          backgroundColor: "#0f1224",
        },

        switchViewButton: {
          backgroundColor: "#0f1224",
          color: color.text.primary,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: "#0f1224",
          "&.Mui-selected": {
            backgroundColor: "#944DFF !important",
            "&:hover": {
              backgroundColor: "#944DFF",
            },
          },
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          color: color.text.primary,
        },
      },
    },
    // Accordion
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          backgroundColor: color.bgTransparent.primary,
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          ".MuiAutocomplete-option": {
            "&.Mui-focused": {
              backgroundColor: color.color.lightGray,
            },
            '&[aria-selected="true"]': {
              backgroundColor: color.color.lightGray,
            },
            '&.Mui-focused[aria-selected="true"]': {
              backgroundColor: color.color.lightGray,
            },
          },
        },
        endAdornment: {
          color: "white",
        },
        clearIndicator: {
          color: "white",
        },
        popupIndicator: {
          color: "white",
        },
        option: {
          padding: "0px",
          margin: "1px !important",
          backgroundColor: color.color.darkGray,
        },
        tag: {
          borderRadius: "8px",
          border: `1px solid  ${color.color.lightGray}`,
          backgroundColor: color.color.lightGray,
        },
        hasClearIcon: {
          color: "white",
        },
        inputRoot: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: color.color.lightGray,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: color.color.primary,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: color.color.primary,
          },
        },
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },

          "&.Mui-focused .MuiInputLabel-outlined": {
            color: color.color.primary,
          },
          "&.Mui-focused .MuiOutlinedInput": {
            color: color.color.primary,
          },
        },
        "& :hover": {
          border: `1px solid ${color.color.primary}`,
        },
      },
    },
    // Alert
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: "Quantico",
          opacity: "1",
          fontWeight: "700",
          fontSize: "14px",
          borderRadius: "5px",
          borderWidth: "0.5px",
          height: "auto !important",
          lineHeight: "1.5",
          [breakpoints.up("md")]: {
            padding: "6px 24px",
          },
          [breakpoints.down("md")]: {
            padding: "5px 14px",
          },
          "&.MuiAlert-outlined .MuiAlert-icon": {
            opacity: "1",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
          },
          "&.MuiAlert-outlinedSuccess .MuiAlert-icon": {
            color: "rgb(77, 255, 148)",
          },
          "&.MuiAlert-outlinedSuccess": {
            color: "rgb(77, 255, 148)",
            backgroundColor: "rgba(77, 255, 148, 0.09)",
            borderColor: "rgba(77, 255, 148, 0.09)",
          },
          "&.MuiAlert-outlinedError .MuiAlert-icon": {
            color: "rgb(239, 83, 80)",
          },
          "&.MuiAlert-outlinedError": {
            color: "rgb(239, 83, 80)",
            backgroundColor: "rgba(239, 83, 80, 0.09)",
            borderColor: "rgba(239, 83, 80, 0.09)",
          },
          "&.MuiAlert-outlinedInfo .MuiAlert-icon": {
            color: "rgb(77, 184, 255)",
          },
          "&.MuiAlert-outlinedInfo": {
            color: "rgb(77, 184, 255)",
            backgroundColor: "rgba(77, 184, 255, 0.09)",
            borderColor: "rgba(77, 184, 255, 0.09)",
          },
        },
      },
    },
    // Paper
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          elevation: "0",
          boxShadow: "none",
          transition: "all 0.5s ease-in-out",
        },
      },
    },
    // Toggle Button
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          background: color.bgTransparent.primary,
          color: color.text.tertiary,
          border: "0px solid transparent",
          margin: "0 1px",
          "&:hover": {
            background: color.bgTransparent.quaternary,
          },
          "&.Mui-selected": {
            background: color.bgTransparent.secondary,
            color: color.text.primary,
            border: "0px solid transparent",
          },
        },
      },
    },
    MuiAlertMessage: {
      styleOverrides: {
        root: {
          padding: "5px",
        },
      },
    },
  },
});

export default Dark;
