import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import Grid from "@mui/material/Grid";
import Ticket from "../../Ticket";
import { useHistory, useParams } from "react-router-dom";
import CustomHooks from "../../../../../../@components/hooks";
import useCollectionService from "../../../useService";
import Typography from "@mui/material/Typography";

const Failed = ({ DAO }) => {
  const { id } = useParams();
  const collectionService = useCollectionService(DAO?.id);
  const [mainUtility, setMainUtility] = React.useState(null);
  const history = useHistory();

  const { data: collection } = CustomHooks.useFetch(
    ["collection"],
    () => collectionService.collection(id),
    {
      onSuccess: async (collection) => {
        setMainUtility(
          collection?.utilities.find((utility) => utility.is_main_utility)
        );
      },
    }
  );

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "0px", mobile: "10px" },
            pb: "60px",
            pt: { md: "20px", mobile: "20px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <UI.BackButton
              title={"BACK TO COLLECTION"}
              onClick={() => history.push(`/brand/${DAO?.id}/collection/${id}`)}
              address={collection?.nft_address}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingLeft: { mobile: "10px", md: "0px" },
            paddingRight: { mobile: "10px", md: "0px" },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={4} mobile={12}>
              <Box
                sx={{
                  display: "flex",
                  mb: "60px",
                  justifyContent: { mobile: "center", md: "unset" },
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Ticket
                    brandName={DAO?.name}
                    brandAvatar={DAO?.avatar_url}
                    name={collection?.name}
                    fileType={collection?.nft_file_type}
                    data={collection?.nft_image_url}
                    mainUtility={mainUtility}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} mobile={12}>
              <Box sx={{ ml: { md: "60px", mobile: "0px" } }}>
                <Typography variant={"h2"}>
                  Purchase Unsuccessful: Payment Issue
                </Typography>
                <Box sx={{ mt: "40px" }}>
                  <Typography variant={"subtitle2"}>
                    We're sorry, but there was an issue processing your payment
                    and your NFT purchase could not be completed. Please verify
                    your payment details and attempt the transaction again. If
                    you continue to experience difficulties, please reach out to
                    our customer support for help.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default Failed;
