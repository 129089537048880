import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import UI from "../../../@components/UI";
import { useHistory } from "react-router-dom";
import Busy from "../../../@components/UI/busy";
import { useTranslation } from "react-i18next";
import CustomHooks from "../../../@components/hooks";
import useService from "../../useService";

const List = () => {
  const service = useService();
  const { t } = useTranslation();

  const {
    data: userData,
    isLoading,
    refetch,
  } = CustomHooks.useFetch(["full_user_profile"], service.info, {
    enabled: false,
  });

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container spacing={6} columns={20} sx={{ justifyContent: "center" }}>
      {isLoading && (
        <Grid key={1} item mobile={20} md={20}>
          <Busy.Indicator />
        </Grid>
      )}
      {!isLoading &&
        userData?.my_brands &&
        userData?.my_brands.length > 0 &&
        userData?.my_brands.map((data, index) => (
          <Item key={"DAO_" + index} DAO={data} />
        ))}

      {!isLoading &&
        userData?.my_brands &&
        userData?.my_brands.length === 0 && (
          <Grid key={1} item mobile={20} md={20} lg={20} xxl={20}>
            <UI.NoResults text={t("profile.home.notPartOfABrand")} />
          </Grid>
        )}
    </Grid>
  );
};

const Item = ({ DAO }) => {
  const history = useHistory();
  return (
    <Grid item mobile={20} md={5} lg={5} xxl={5}>
      <Box
        sx={{
          cursor: "pointer",
        }}
        onClick={() =>
          history.push({
            pathname: DAO.id === 1 ? `/qvrse` : `/brand/${DAO.id}`,
            state: { scrollToTop: true },
          })
        }
      >
        <Box
          sx={{
            position: "relative",
            width: "140px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            height: "140px",
            overflow: "hidden",
            borderRadius: "50%",
            border: "2px solid transparent",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              boxShadow: "#944dff 0px 0px 15px 0px",
              border: "2px solid #944dff",
              transform: "scale(1.1)",
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          <UI.ImageWithSkeleton
            sx={{
              position: "absolute",
              width: "140px",
              height: "140px",
              textAlign: "center",
              objectFit: "cover",
            }}
            width={140}
            src={DAO.avatar_url}
            alt={"dao avatar"}
          />
        </Box>
        <Box
          sx={{
            mt: "20px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant={"h4"}
            sx={{
              margin: "0 auto",
              maxWidth: "320px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {DAO.name}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default List;
