import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import UI from "../../../../@components/UI";
import applyNowIcon from "../../../../static/apply-now-icon.png";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const ApplyNowDialog = ({ toggleOpen, onConfirm }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [showError, setShowError] = React.useState(null);

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
          padding: { md: "60px 60px", mobile: "40px 0px" },
          width: "800px",
          textAlign: "center",
          margin: { mobile: "15px" },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: "0px" }}>
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 30,
            top: 36,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Box
              component={"img"}
              src={applyNowIcon}
              sx={{
                width: "60px",
                height: "68px",
                display: "flex",
                margin: "0 auto",
              }}
            />
            <Typography
              variant={"h3"}
              sx={{
                textAlign: "center",
                mt: { md: "35px", mobile: "26px" },
              }}
            >
              {t("applyYourBrand.apply.applyNowDialog.title")}
            </Typography>
            <Typography variant={"subtitle2"} sx={{ m: { mobile: "10px 10px 0", md: "16px 10px 0" } }}>
              {t("applyYourBrand.apply.applyNowDialog.subtitle")}
            </Typography>

            <Box sx={{ mt: { mobile: "20px", md: "30px" },px: "10px" }}>
              <Box>
                <UI.TextField
                  value={message}
                  onChange={(e) => {
                    setShowError(false);
                    setMessage(e.target.value);
                  }}
                  multiline
                  rows={5}
                  placeholder={t(
                    "applyYourBrand.apply.applyNowDialog.placeholder"
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {showError && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("applyYourBrand.apply.applyNowDialog.error.required")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: "35px", px: "10px" }}>
              <UI.Button
                onClick={(e) => {
                  if (message == null || message === "") {
                    setShowError(true);
                  } else {
                    setShowError(false);
                    onConfirm(message);
                  }
                }}
                type={"primary"}
                title={t("buttons.apply")}
                sx={{ width: "200px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default ApplyNowDialog;
