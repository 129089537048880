import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UI from "../../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Explore from "./explore";
import Select from "./Select";
import Create from "./Create";
import Import from "./import";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useAccount } from "wagmi";
import { useUserContext } from "../../../@components/userContext";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useUserRole } from "../context";
import { useTranslation } from "react-i18next";

const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const location = useLocation();
  const history = useHistory();
  const { isBrandMember } = useUserRole();
  const { isConnected } = useAccount();

  return (
    <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          px: { md: "0px", mobile: "10px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          {isBrandMember && (
            <Box
              sx={{
                display: { md: "none", mobile: "flex" },
                flexDirection: { mobile: "column", md: "row" },
                mr: { md: "20px", mobile: "0px" },
                pb: "50px",
                pt: "40px",
              }}
            >
              <UI.VertMenu>
                <MenuItem
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  onClick={() =>
                    history.push({
                      pathname: `${location.pathname}/new`,
                      state: { scrollToTop: true },
                    })
                  }
                >
                  <ListItemIcon sx={{ mr: "10px" }}>
                    <AddBoxIcon sx={{ color: "#944DFF" }} />
                  </ListItemIcon>
                  {t("brands.collection.createButton")}
                </MenuItem>
                <MenuItem
                  disabled={
                    !isConnected ||
                    !loggedInUser?.email_verified ||
                    !isBrandMember
                  }
                  onClick={() =>
                    history.push({
                      pathname: `${location.pathname}/import`,
                      state: { scrollToTop: true },
                    })
                  }
                >
                  <ListItemIcon sx={{ mr: "10px" }}>
                    <DriveFolderUploadIcon sx={{ color: "#944DFF" }} />
                  </ListItemIcon>
                  {t("brands.collection.importButton")}
                </MenuItem>
              </UI.VertMenu>
            </Box>
          )}
          {isBrandMember && (
            <>
              <Box
                sx={{
                  width: "236px",
                  display: { md: "flex", mobile: "none" },
                  mt: { md: "0px", mobile: "20px" },
                  mr: { md: "20px", mobile: "0px" },
                }}
              >
                <UI.Button
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  onClick={() =>
                    history.push({
                      pathname: `${location.pathname}/import`,
                      state: { scrollToTop: true },
                    })
                  }
                  title={t("commons.import")}
                  type={"secondary"}
                />
              </Box>
              <Box
                sx={{
                  width: "236px",
                  display: { md: "flex", mobile: "none" },
                  mt: { md: "0px", mobile: "20px" },
                }}
              >
                <UI.Button
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  onClick={() =>
                    history.push({
                      pathname: `${location.pathname}/new`,
                      state: { scrollToTop: true },
                    })
                  }
                  title={t("brands.collection.createButton")}
                  type={"primary"}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      {DAO && (
        <Box
          sx={{
            width: "100%",
            padding: { md: "0px 0px", mobile: "0px 0px" },
          }}
        >
          <Explore DAO={DAO} />
        </Box>
      )}
    </Paper>
  );
};

Index.Create = Create;
Index.Import = Import;
Index.Select = Select;

export default Index;
