import React from "react";
import { ethers } from "ethers";
import collectionNativeDeployerArtifact from "../../../../../abis/contracts/modules/collection/matic/Deployer.sol/DeployerCollectionMatic.json";
import collectionFiatDeployerArtifact from "../../../../../abis/contracts/modules/collection/fiat/Deployer.sol/DeployerCollectionFiat.json";
import collectionFreeDeployerArtifact from "../../../../../abis/contracts/modules/collection/free/Deployer.sol/DeployerCollectionFree.json";
import useQvrseService from "../../../../../qvrse/useService";
import useCollectionService from "../../../collection/useService";
import useHandleErrors from "../../../../../@components/UI/useHandleErrors";
import customHooks from "../../../../../@components/hooks";
import { useAccount } from "wagmi";
import CustomHooks from "../../../../../@components/hooks";

const useDeployCollection = () => {
  const signer = customHooks.useEthersSigner();
  const { address } = useAccount();
  const qvrseService = useQvrseService();
  const collectionService = useCollectionService();
  const [fee_address, setFeeAddress] = React.useState(null);
  const { handleApiError } = useHandleErrors();

  const { refetch: fetchNativeCollectionDeployer } = CustomHooks.useFetch(
    ["collection_matic"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );

  const { refetch: fetchFiatCollectionDeployer } = CustomHooks.useFetch(
    ["collection_fiat"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );
  const { refetch: fetchFreeCollectionDeployer } = CustomHooks.useFetch(
    ["collection_free"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );
  const { refetch: fetchFiatMultiCollectionDeployer } = CustomHooks.useFetch(
    ["collection_fiat_multi_image"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );

  const { refetch: fetchNativeMultiCollectionDeployer } = CustomHooks.useFetch(
    ["collection_matic_multi_image"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );
  const { refetch: fetchFreeMultiCollectionDeployer } = CustomHooks.useFetch(
    ["collection_free_multi_image"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );

  React.useEffect(() => {
    qvrseService
      .fee_address()
      .then((response) => {
        const {
          data: { fee_address },
        } = response;
        setFeeAddress(fee_address);
      })
      .catch((error) => {
        handleApiError(error);
      });
    // eslint-disable-next-line
  }, []);

  const deployNativeCollection = async (formData, DAO) => {
    const response = await fetchNativeCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const collectionDeployer = new ethers.Contract(
      collectionDeployerData?.address,
      collectionNativeDeployerArtifact.abi,
      signer
    );

    const config = {
      owner: DAO.maintainer_address || address,
      minters: DAO.system_minters_address_list,
      name: formData.basic_info.name,
      symbol: formData.basic_info.symbol,
      uri: formData.nft_ipfs_uri,
      price: ethers.parseUnits(
        String(formData.totalPrice),
        formData?.payment?.crypto?.token?.decimals || 18
      ),
      maxMintLimit: formData.basic_info.supply,
      treasuryAddress: DAO.treasury_contract.address,
      feeReceiverAddress: fee_address,
      percentTreasuryFee: DAO.collection_buy_fee,
    };

    const deployCollectionTx = await collectionDeployer[
      collectionDeployerData.call_function_name
    ](config);

    const collectionReceipt = await deployCollectionTx.wait(1);

    const collectionCreatedEvent = collectionReceipt.logs.find(
      (event) => event.eventName === "CollectionDeployed"
    );
    return collectionCreatedEvent;
  };

  const deployFiatCollection = async (formData, DAO) => {
    const response = await fetchFiatCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const collectionDeployer = new ethers.Contract(
      collectionDeployerData?.address,
      collectionFiatDeployerArtifact.abi,
      signer
    );

    const deployCollectionTx = await collectionDeployer[
      collectionDeployerData.call_function_name
    ](
      DAO.maintainer_address || address,
      DAO.system_minters_address_list,
      formData.basic_info.name,
      formData.basic_info.symbol,
      formData.nft_ipfs_uri,
      formData.basic_info.supply
    );

    const collectionReceipt = await deployCollectionTx.wait(1);

    const collectionCreatedEvent = collectionReceipt.logs.find(
      (event) => event.eventName === "CollectionDeployed"
    );
    return collectionCreatedEvent;
  };

  const deployNativeMultiUploadCollection = async (formData, DAO) => {
    const response = await fetchNativeMultiCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const collectionDeployer = new ethers.Contract(
      collectionDeployerData?.address,
      collectionNativeDeployerArtifact.abi,
      signer
    );

    const config = {
      owner: DAO.maintainer_address || address,
      minters: DAO.system_minters_address_list,
      name: formData.basic_info.name,
      symbol: formData.basic_info.symbol,
      contractUri: formData.nft_ipfs_uri,
      price: ethers.parseUnits(
        String(formData.totalPrice),
        formData?.payment?.crypto?.token?.decimals || 18
      ),
      maxMintLimit: formData.basic_info.supply,
      treasuryAddress: DAO.treasury_contract.address,
      feeReceiverAddress: fee_address,
      percentTreasuryFee: DAO.collection_buy_fee,
    };
    const deployCollectionTx = await collectionDeployer[
      collectionDeployerData.call_function_name
    ](config);

    const collectionReceipt = await deployCollectionTx.wait(1);

    const collectionCreatedEvent = collectionReceipt.logs.find(
      (event) => event.eventName === "CollectionDeployed"
    );
    return collectionCreatedEvent;
  };
  const deployFiatMultiUploadCollection = async (formData, DAO) => {
    const response = await fetchFiatMultiCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const collectionDeployer = new ethers.Contract(
      collectionDeployerData?.address,
      collectionFiatDeployerArtifact.abi,
      signer
    );

    const deployCollectionTx = await collectionDeployer[
      collectionDeployerData.call_function_name
    ](
      DAO.maintainer_address || address,
      DAO.system_minters_address_list,
      formData.basic_info.name,
      formData.basic_info.symbol,
      formData.nft_ipfs_uri,
      formData.basic_info.supply
    );

    const collectionReceipt = await deployCollectionTx.wait(1);

    const collectionCreatedEvent = collectionReceipt.logs.find(
      (event) => event.eventName === "CollectionDeployed"
    );
    return collectionCreatedEvent;
  };

  const deployFreeCollection = async (formData, DAO) => {
    const response = await fetchFreeCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }
    const collectionDeployer = new ethers.Contract(
      collectionDeployerData?.address,
      collectionFreeDeployerArtifact.abi,
      signer
    );

    const deployCollectionTx = await collectionDeployer[
      collectionDeployerData.call_function_name
    ](
      DAO.maintainer_address || address,
      DAO.system_minters_address_list,
      formData.basic_info.name,
      formData.basic_info.symbol,
      formData.nft_ipfs_uri,
      formData.basic_info.supply
    );

    const collectionReceipt = await deployCollectionTx.wait(1);

    const collectionCreatedEvent = collectionReceipt.logs.find(
      (event) => event.eventName === "CollectionDeployed"
    );
    return collectionCreatedEvent;
  };

  const deployFreeMultiCollection = async (formData, DAO) => {
    const response = await fetchFreeMultiCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const collectionDeployer = new ethers.Contract(
      collectionDeployerData?.address,
      collectionFreeDeployerArtifact.abi,
      signer
    );

    const deployCollectionTx = await collectionDeployer[
      collectionDeployerData.call_function_name
    ](
      DAO.maintainer_address || address,
      DAO.system_minters_address_list,
      formData.basic_info.name,
      formData.basic_info.symbol,
      formData.nft_ipfs_uri,
      formData.basic_info.supply
    );

    const collectionReceipt = await deployCollectionTx.wait(1);

    const collectionCreatedEvent = collectionReceipt.logs.find(
      (event) => event.eventName === "CollectionDeployed"
    );
    return collectionCreatedEvent;
  };

  return {
    deployNativeCollection,
    deployFiatCollection,
    deployNativeMultiUploadCollection,
    deployFiatMultiUploadCollection,
    deployFreeCollection,
    deployFreeMultiCollection,
  };
};

export default useDeployCollection;
