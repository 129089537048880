import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import UI from "../@components/UI";
import SettingsButton from "./SettingsButton";
import { useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import truncateEthAddress from "truncate-eth-address";
import Skeleton from "@mui/material/Skeleton";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  categoryItem: {
    width: "180px",
    height: "40px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    background: ({ selected }) => {
      return selected
        ? theme.palette.bgTransparent.secondary
        : theme.palette.bgTransparent.tertiary;
    },
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
  },
}));

const MobileMenu = ({ user, currentUrl }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { address, isConnected } = useAccount();
  const swiperRef = React.useRef(null);

  return (
    <Box sx={{ display: { mobile: "block", md: "none" }, mb: "30px" }}>
      <Box
        onClick={() => {
          history.push(`/profile`);
        }}
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          ml: "7px",
          mb: "20px",
          pr: "16px",
        }}
      >
        <Paper
          sx={{
            borderRadius: "50%",
            width: { md: "175px", mobile: "35px" },
            height: { md: "175px", mobile: "35px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          {user ? (
            <img
              style={{
                width: "100%",
                height: "100%",
                margin: "0 auto",
                textAlign: "center",
                objectFit: "cover",
              }}
              src={user?.avatar_url}
              alt={"dao-logo"}
            />
          ) : (
            <Avatar sx={{ margin: "0 auto", width: "100%", height: "100%" }} />
          )}
        </Paper>
        {user ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  ml: "10px",
                  textAlign: "left",
                  maxWidth: "268px",
                }}
                variant={"h4"}
              >
                {user?.username || t("commons.user")}
              </Typography>

              {isConnected && (
                <Typography
                  variant={"h4"}
                  sx={{
                    ml: "10px",
                    textAlign: "center",
                    display: "none",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <UI.CopyToClipboard
                    label={truncateEthAddress(address || "")}
                    value={address || ""}
                    showBorder
                  />
                </Typography>
              )}
            </Box>
            {user && (
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                sx={{
                  width: "35px",
                  height: "35px",
                  zIndex: 2,
                }}
              >
                <SettingsButton />
              </Box>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              ml: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton variant={"text"} width={200} height={50} />
          </Box>
        )}
      </Box>
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        slidesPerView={3}
        className="collection-category-swiper"
      >
        <SwiperSlide>
          <Item
            selected={location.pathname === `/profile`}
            onSelect={() => {
              history.push(`${currentUrl}`);
            }}
            index={0}
            title={t("profile.menu.home")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/profile/created`)}
            onSelect={() => {
              history.push(`${currentUrl}/created`);
            }}
            index={1}
            title={t("profile.menu.created")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/profile/finance`)}
            onSelect={() => {
              history.push(`${currentUrl}/finance`);
            }}
            index={2}
            title={t("profile.menu.finance")}
          />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

const Item = (props) => {
  const { selected, index, onSelect, title, isAdminItem } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile });
  return (
    <Box
      sx={{ position: "relative" }}
      className={classes.categoryItem}
      onClick={() => onSelect(index)}
      {...props}
    >
      {isAdminItem && (
        <Typography
          variant={"subtitle4"}
          sx={{
            position: "absolute",
            top: "7px",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "10px !important",
          }}
        >
          admin
        </Typography>
      )}
      <Typography
        sx={{
          margin: "0 auto",
          fontWeight: "bold",
          fontSize: "12px !important",
        }}
      >
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default MobileMenu;
