import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import backArrow from "../../../../../static/back-arrow.png";
import { makeStyles } from "@mui/styles";

import Form from "./Form";
import Select from "./Select";
import { useForm, FormProvider } from "react-hook-form";
import { useFormData } from "../context";
import UI from "../../../../../@components/UI";
import { useUserContext } from "../../../../../@components/userContext";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },

  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
  },

  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "13px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },
}));

const Index = ({ draft, nextStep, previouseStep, onSave }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, setFormValues } = useFormData();
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const [utilityFormInputs, setUtilityFormInputs] = React.useState(null);

  const methods = useForm({
    defaultValues: {
      main_utility: {
        is_main_utility: true,
        ...data.main_utility,
      },
    },
    mode: "all",
  });

  // Reset form with draft data.
  React.useEffect(() => {
    if (draft) {
      setFormValues(draft.data);
      methods.reset({
        main_utility: { ...draft.data.main_utility, is_main_utility: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  // On change of main utility, reset form with utility data.
  React.useEffect(() => {
    utilityFormInputs &&
      methods.reset({
        main_utility: { ...utilityFormInputs, is_main_utility: true },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utilityFormInputs]);

  const onSubmit = async (values, action) => {
    switch (action) {
      case "save_and_continue": {
        await onSave(values, false);
        nextStep();
        break;
      }
      case "save": {
        await onSave(values, true);
        break;
      }
      default: {
        await onSave(values, true);
        break;
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((values) =>
          onSubmit(values, "save_and_continue")
        )}
      >
        <Box
          sx={{
            mt: { md: "15px", mobile: "20px" },
            p: { md: "40px 65px", mobile: "0px 10px" },
          }}
        >
          <Box
            onClick={() => previouseStep()}
            className={classes.backButtonOuter}
            sx={{
              display: "flex",
              cursor: "pointer",
              mb: { md: "50px", mobile: "30px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "8px",
              }}
            >
              <img
                className={"backArrow"}
                style={{ transition: "0.4s ease-in" }}
                src={backArrow}
                alt={"back-arrow"}
                width={"13px"}
                height={"13px"}
              />
            </Box>
            <Box
              sx={{
                mt: "2px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box className={classes.backButton}>
                {" "}
                {t("brands.collection.create.mainUtility.backButton")}
              </Box>
            </Box>
          </Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mb: { mobile: "20px", md: "40px"},
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant={"h3"}>
                  {t("brands.collection.create.mainUtility.title")}
                </Typography>
              </Box>

              <Box sx={{ mb: { mobile: "30px", md: "90px" } }}>
                <Select
                  draft={draft}
                  onSelect={(selectedUtilityFormInputs) =>
                    setUtilityFormInputs(selectedUtilityFormInputs)
                  }
                />
              </Box>

              <Form />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "210px" }}>
              <UI.Button
                disabled={
                  !!Object.keys(methods?.formState?.errors).length ||
                  !isConnected ||
                  !loggedInUser?.email_verified
                }
                title={t("buttons.saveAndContinue")}
                type={"primary"}
              />
              <UI.Button
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const allValues = methods.getValues();
                  await onSubmit(allValues, "save");
                }}
                disabled={!isConnected || !loggedInUser?.email_verified}
                sx={{
                  mt: "10px",
                  filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                }}
                title={t("buttons.saveForLater")}
                type={"tertiary"}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default Index;
