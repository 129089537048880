import React from "react";
import Box from "@mui/material/Box";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Autoplay, Pagination } from "swiper/modules";

import "./styles.css";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import UI from "../../../../@components/UI";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import maticIcon from "../../../../static/matic-icon-white.png";

const useStyles = makeStyles((theme) => ({
  shakeAnimation: {
    animation: `$shake 6s ease infinite`, // Total duration includes shake time and pause
  },
  "@keyframes shake": {
    "0%, 100%": { transform: "skewY(0deg)" },
    "2.5%": { transform: "skewY(6deg)" },
    "5%": { transform: "skewY(-6deg)" },
    "7.5%": { transform: "skewY(6deg)" },
    "10%": { transform: "skewY(-6deg)" },
    "12.5%": { transform: "skewY(6deg)" },
    "15%": { transform: "skewY(-6deg)" },
    "17.5%, 100%": { transform: "skewY(0deg)" }, // Remain still for the rest of the time
  },
}));

const Item = ({ disableClick, utilityTagsVisible, theme, item }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box
      onClick={() => {
        if (disableClick) {
          return;
        }
        history.push({
          pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
          state: { scrollToTop: true },
        });
      }}
      sx={{
        textAlign: "left",
        cursor: "pointer",
        margin: "0 auto",
        width: "290px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        opacity: item.visible ? 1 : 0.5,
      }}
    >
      <Box
        sx={{
          backgroundColor: theme
            ? theme.card_background_color
            : (theme) => theme.palette.bgTransparent.primary,
          borderBottom: "none",
          padding: "10px 20px ",
          height: "54px",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              {item?.name ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: "185.4px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao?.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.name ? (
              <Typography
                variant={"h6"}
                sx={{
                  maxWidth: "185.4px",
                }}
              >
                <UI.OverflowTip>{`${item?.name}`}</UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={item?.dao?.avatar_url} alt={"avatar"} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "290px",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          component={"span"}
          sx={{
            boxSizing: "border-box",
            display: "block",
            overflow: "hidden",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: 1,
            border: "0px",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            inset: 0,
            lineHeight: "0",
            fontSize: "0",
          }}
        >
          {item ? (
            item.nft_file_type?.toLowerCase().startsWith("video") ? (
              <video
                playsInline
                width="100%"
                height={"auto"}
                autoplay="autoplay"
                loop
                muted
              >
                <source src={item.nft_image_url} />
              </video>
            ) : (
              <UI.ImageWithSkeleton
                sx={{
                  position: "absolute",
                  inset: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                src={item.nft_image_url}
                width={"100%"}
                alt={"slider-item"}
              />
            )
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
          {!item.visible && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                inset: 0,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "#fff",
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("nftCard.privateVisibility")}
            </Box>
          )}
        </Box>

        {utilityTagsVisible && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              bottom: "16px",
              right: "16px",
            }}
          >
            <UI.UtilityTag
              sx={{
                padding: "1px 14px",
              }}
              utilityType={
                item.utilities.find(
                  (utility) => utility.is_main_utility === true
                )?.type || ""
              }
            />
            <UI.Tag
              sx={{
                ml: "10px",
                backgroundColor: (theme) => theme.palette.tag.green,
                padding: "1px 14px",
              }}
            >
              {item?.is_imported
                ? t("utilityTag.imported")
                : t("utilityTag.limited")}
            </UI.Tag>
          </Box>
        )}
      </Box>
      {item?.is_imported ? (
        <Box
          sx={{
            height: "70px",
            backgroundColor: theme
              ? theme.card_background_color
              : (theme) => theme.palette.bgTransparent.primary,
            borderRadius: "0 0 5px 5px",
          }}
        ></Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: "20px",
            height: "70px",
            backgroundColor: (theme) => theme.palette.bgTransparent.primary,
            borderRadius: "0 0 5px 5px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {item?.free_nft_gas_fee_payed_by_system === false &&
              item?.total_price_with_tax !== "0.00" && (
                <Box sx={{ width: "18px", height: "18px", mr: "7px" }}>
                  <UI.PaymentIcon currency={item?.currency} />
                </Box>
              )}

            {item?.total_price_with_tax !== "0.00" && (
              <Typography variant={"h8"}>
                {`${item?.total_price_with_tax}`}
              </Typography>
            )}

            {item?.total_price_with_tax === "0.00" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className={classes.shakeAnimation}
                  variant={"h10"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "68px",
                    height: "22px",
                    borderRadius: "100px",
                    backgroundColor: (theme) => theme.palette.tag.red,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {item?.free_nft_gas_fee_payed_by_system === false && (
                    <Box
                      sx={{
                        width: "12px",
                        height: "12px",
                        mr: "4px",
                        ml: "-2px",
                      }}
                      component={"img"}
                      src={maticIcon}
                    />
                  )}
                  {t("commons.free")}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography
            variant={"h8"}
            sx={{
              maxWidth: "150px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <UI.OverflowTip>{`${item.nft_amount_minted}/${item.nft_amount_limit}`}</UI.OverflowTip>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const CollectionSlider = ({
  theme,
  onSlideChange,
  items,
  disableClick,
  utilityTagsVisible = true,
  isLoopEnabled = true,
  isPaginationEnabled = true,
}) => {
  const swiperRef = React.useRef(null);

  return (
    <>
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        slidesPerView={3}
        spaceBetween={20}
        onSlideChange={onSlideChange}
        speed={500}
        initialSlide={items.length > 3 ? 2 : 0}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        loop={isLoopEnabled && items.length > 3}
        centeredSlides={true}
        navigation={true}
        pagination={{
          enabled: isPaginationEnabled,
          dynamicBullets: true,
          clickable: true,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        className="desktopCollectionSlider"
      >
        {items &&
          items.map((item, index) => (
            <SwiperSlide key={index}>
              <Item
                disableClick={disableClick}
                utilityTagsVisible={utilityTagsVisible}
                theme={theme}
                item={item}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default CollectionSlider;
