import { ethers } from "ethers";

export const encodedFunctionParameters = (address, data) => {
  const daoData = ethers.AbiCoder.defaultAbiCoder().encode(
    ["address", "string", "string"],
    [address, data.name, data.description]
  );

  const tokenData = ethers.AbiCoder.defaultAbiCoder().encode(
    ["string", "string", "uint256", "uint256", "address"],
    [
      data.token_name,
      data.token_symbol,
      ethers.parseEther(data.token_supply),
      ethers.parseEther("0"),
      ethers.ZeroAddress,
    ]
  );

  const governorData = ethers.AbiCoder.defaultAbiCoder().encode(
    ["uint256", "uint256", "uint256", "uint256"],
    [data.quorum, data.voting_delay, data.voting_period, data.timelock_delay]
  );

  return {
    daoData,
    tokenData,
    governorData,
  };
};
