import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Logo from "../../../static/qvrse-logo.png";
import Button from "@mui/material/Button";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import UI from "../";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import MobileMenu from "./MobileMenu";
import RegisterDialog from "../../../profile/dialogs/RegisterDialog";
import EmailConfirmationDialog from "../../../profile/dialogs/EmailConfirmation";
import { useUserContext } from "../../userContext";
import useUserService from "../../../profile/useService";
import ConnectWallet from "../connectWallet";
import UserAvatar from "./UserAvatar";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./languageSwitcher";
import ConnectWalletWithAccountButton from "./ConnectWalletToAccountButton";
import QvrsButton from "../Button";
import MobileWalletStatus from "./MobileWalletStatus";
import LogInDialogFlow from "../../../profile/dialogs/LogInDialogFlow";
import Presale from "./Presale";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
// import HomeIcon from "@mui/icons-material/Home";


const useStyles = makeStyles((theme) => ({
  navButton: {
    color: theme.palette.text.primary,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: "transparent !important",
      "&:before": {
        width: "90%",
      },
    },
    "&:before": {
      content: "",
      position: "absolute",
      bottom: "10px",
      right: 0,
      left: 0,
      display: "block",
      height: "1px",
      width: "90%",
      transition: ".2s ease-in",
      margin: "auto",
    },
  },
  listItemButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
}));

const ElevationScroll = (props) => {
  const { children, window } = props;
  const theme = useTheme();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will utilities to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    style: {
      boxShadow: trigger ? theme.palette.appbar.boxShadow : "none",
      background: trigger ? theme.palette.appbar.background : "transparent",
      backdropFilter: trigger ? theme.palette.appbar.backdropFilter : "none",
    },
  });
};

const MaterialAppBar = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const userService = useUserService();
  const { user: loggedInUser, setUser: setUserContext } = useUserContext();
  const location = useLocation();
  const [triggerMenuOpen, setTriggerMenuOpen] = React.useState(null);
  const [triggerRegisterDialog, setTriggerRegisterDialog] =
    React.useState(null);
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [triggerEmailConfirmationDialog, setTriggerEmailConfirmation] =
    React.useState(null);

  const showroomMatch = useRouteMatch("/brand/:brandId/showroom");
  const isShowRoomPage = showroomMatch !== null;
  const brandMatch = useRouteMatch("/brand/:brandId/");
  const brandExploreMatch = useRouteMatch("/brand/explore");
  const applyForDAOMatch = useRouteMatch("/brand/apply");

  const isExploreMainPage = location.pathname === "/";

  const isBrandPage = brandMatch !== null;
  const isBrandExplorePage = brandExploreMatch !== null;
  const isApplyForDAOPage = applyForDAOMatch !== null;

  React.useEffect(() => {
    if (i18n && loggedInUser) {
      i18n.changeLanguage(loggedInUser.user_language);
    }
  }, [loggedInUser, i18n]);

  React.useEffect(() => {
    const fetchData = async () => {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        await userService
          .basic_info()
          .then(async (response) => {
            setUserContext(response);
            //props.onThemeChange(null, response.user_theme === "dark");
          })
          .catch(() => {});
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        display:
          isShowRoomPage === true ||
          (isBrandPage === true &&
            isBrandExplorePage === false &&
            isApplyForDAOPage === false &&
            isMobile === true)
            ? "none"
            : "block",
      }}
    >
      <ElevationScroll {...props}>
        <AppBar
          sx={{
            borderRadius: "0px",
            paddingLeft: { xs: "0px", md: "50px", lg: "100px", mobile: "0px" },
            paddingRight: {
              xs: "0px",
              md: "50px",
              lg: "100px",
              mobile: "0px",
            },
            height: "72px",
            zIndex: 999,
            width: "100%",
            left: 0,
            right: 0,
            margin: "auto",
            backgroundColor: (theme) => theme.palette.appbar.background,
            color: (theme) => theme.palette.appbar.text,
            justifyContent: "center",
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between", height: "72px" }}>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                left: { md: 0, mobile: "16px" },
              }}
            >
              <Box
                onClick={() =>
                  history.push({
                    pathname: "/",
                    state: { scrollToTop: true },
                  })
                }
                sx={{
                  mr: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <img src={Logo} width={"42px"} height={"42px"} alt={"logo"} />
                  <Box
                    sx={{
                      display: { mobile: "flex", md: "flex" },
                      flexDirection: "column",
                      justifyContent: "center",
                      ml: "2.5px",
                      fontWeight: "bold",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant={"h4"}>QVRSE</Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "-24px",
                        }}
                      >
                        <Typography
                          variant={"h10"}
                          sx={{ color: (theme) => theme.palette.text.tertiary, fontSize: "8px" }}
                        >
                          Beta
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: { mobile: "none", md: "flex", marginLeft: "44px" },
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title={t("actions.joinPresale")}
                      placement="bottom"
                      arrow
                    >
                      <Link
                        target="_blank"
                        to={{ pathname: "https://about.qvrse.io/presale/" }}
                        rel="noreferrer"
                      >
                        <Presale />
                      </Link>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Box sx={{ display: { mobile: "none", md: "flex" } }}>
              <Button
                  data-test={"appbar-brands"}
                  classes={{ root: classes.navButton }}
                  onClick={() =>
                    history.push({
                      pathname: "/",
                      state: { scrollToTop: true },
                    })
                  }
                  sx={{
                    color:
                      location.pathname === "/"
                        ? (theme) => theme.palette.text.primary
                        : "unset",
                    my: 2,
                    padding: "0px 20px",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontFamily: "Quantico, serif",
                    display: "flex",
                  }}
                   // startIcon={<HomeIcon sx={{ fontSize: "1.35rem", mb: "1px", mr: "2px" }} />} // Add HomeIcon as startIcon
                >
                  {t("appBar.home")}
                </Button>
                <Button
                  data-test={"appbar-brands"}
                  classes={{ root: classes.navButton }}
                  onClick={() =>
                    history.push({
                      pathname: "/brand/explore",
                      state: { scrollToTop: true },
                    })
                  }
                  sx={{
                    color:
                      location.pathname === "/brand/explore"
                        ? (theme) => theme.palette.text.primary
                        : "unset",
                    my: 2,
                    padding: "0px 20px",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontFamily: "Quantico, serif",
                    display: "block",
                  }}
                >
                  {t("appBar.brands")}
                </Button>
                <Button
                  data-test={"appbar-collections"}
                  classes={{ root: classes.navButton }}
                  onClick={() =>
                    history.push({
                      pathname: "/collection/explore",
                      state: { scrollToTop: true },
                    })
                  }
                  sx={{
                    color:
                      location.pathname === "/collection/explore"
                        ? (theme) => theme.palette.text.primary
                        : "unset",
                    my: 2,
                    padding: "0px 20px",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontFamily: "Quantico, serif",
                    display: "block",
                  }}
                >
                  {t("appBar.collections")}
                </Button>
                <Button
                  data-test={"appbar-apply"}
                  classes={{ root: classes.navButton }}
                  onClick={() =>
                    history.push({
                      pathname: "/brand/apply",
                      state: { scrollToTop: true },
                    })
                  }
                  sx={{
                    color:
                      location.pathname === "/brand/apply"
                        ? (theme) => theme.palette.text.primary
                        : "unset",
                    my: 2,
                    padding: "0px 20px",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontFamily: "Quantico, serif",
                    display: "block",
                  }}
                >
                  {t("appBar.applyYourBrand")}
                </Button>

                <Button
                  data-test={"appbar-qvrse"}
                  classes={{ root: classes.navButton }}
                  onClick={() =>
                    history.push({
                      pathname: "/qvrse",
                      state: { scrollToTop: true },
                    })
                  }
                  sx={{
                    color: location.pathname.startsWith("/qvrse")
                      ? (theme) => theme.palette.text.primary
                      : "unset",
                    my: 2,
                    padding: "0px 20px",
                    fontSize: "14px",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontFamily: "Quantico, serif",
                    display: "block",
                  }}
                >
                  QVRSE DAO
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                right: { md: 0, mobile: "16px" },
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: { mobile: "none", md: "none" },
                  alignItems: "center",
                }}
              >
                <Tooltip
                  title={t("actions.joinPresale")}
                  placement="bottom"
                  arrow
                >
                  <Link
                    target="_blank"
                    to={{ pathname: "https://about.qvrse.io/presale/" }}
                    rel="noreferrer"
                  >
                    <Presale />
                  </Link>
                </Tooltip>
              </Box>
              {!isMobile && !loggedInUser && (
                <Box sx={{ display: "flex" }}>
                  <UI.Button
                    data-test={"login-button"}
                    sx={{
                      width: "unset",
                      mr: "9px",
                      ml: "3px",
                      fontSize: "14px",
                      transition: "all 0.2s ease-in-out",
                      color: (theme) => theme.palette.text.primary, 
                      "&:hover": {
                        color: (theme) => theme.palette.text.secondary,
                      }, 
                    }}
                    type={"tertiary"}
                    onClick={() => setTriggerLoginDialog(Math.random())}
                    title={t("appBar.logIn")}
                    startIcon={<LoginIcon sx={{ fontSize: "1.35rem" }} />}
                  />
                  <UI.Button
                    data-test={"create-account-button"}
                    sx={{ height: "42px", fontSize: "14px", padding: "5px 16px", width: "unset" }}
                    type={"primary"}
                    onClick={() => setTriggerRegisterDialog(Math.random())}
                    title={t("appBar.createAccount")}
                  />
                </Box>
              )}
              {!isMobile && loggedInUser && (
                <>
                  <Box sx={{ display: "flex", ml: "18px" }}>
                    {loggedInUser.address && (
                      <ConnectWallet
                        onProfileClick={() => setTriggerMenuOpen(Math.random())}
                      />
                    )}
                    {loggedInUser.address === "" && (
                      <ConnectWalletWithAccountButton>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "100%",
                            mr: "10px",
                          }}
                        >
                          <QvrsButton
                            sx={{
                              height: "42px",
                              fontSize: "14px",
                              padding: "4px 16px",
                            }}
                            title={t("buttons.connectWallet")}
                            type={"secondary"}
                          />
                        </Box>
                      </ConnectWalletWithAccountButton>
                    )}
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <UserAvatar user={loggedInUser} />
                  </Box>
                </>
              )}

              {isMobile && (
                <>
                  {loggedInUser && (
                    <Box sx={{ display: "flex" }}>
                      <MobileWalletStatus />
                    </Box>
                  )}
                  <IconButton
                    sx={{ display: { mobile: "flex", md: "none" } }}
                    onClick={() => setTriggerMenuOpen(Math.random())}
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                  >
                    <MenuIcon
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: "24px",
                        "&:hover": {
                          transition: "all 0.1s ease-in-out",
                          transform: "scale(1.2)",
                        },
                      }}
                    />
                  </IconButton>
                </>
              )}
              {!isMobile && (
                <Box
                  sx={{
                    ml: "14px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <UI.ThemeSwitcher />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                width: "50px",
                right: "-48px",
              }}
            >
              <LanguageSwitcher />
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <MobileMenu
        triggerMenuOpen={triggerMenuOpen}
        onLogin={() => {
          setTriggerMenuOpen(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
        onRegister={() => setTriggerRegisterDialog(Math.random())}
      />
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />
      <RegisterDialog
        toggleOpen={triggerRegisterDialog}
        onRegister={() => setTriggerEmailConfirmation(Math.random())}
        showLogInDialog={() => {
          setTriggerRegisterDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
      />
      <EmailConfirmationDialog toggleOpen={triggerEmailConfirmationDialog} />

      <Toolbar
        sx={{ height: "90px", display: isExploreMainPage ? "none" : "block" }}
        variant="dense"
      />
    </Box>
  );
};

export default MaterialAppBar;
