import { create as ipfsHttpClient } from "ipfs-http-client";
import { useSnackbar } from "notistack";
// in this component we set IPFS up to host out nft data of file storage
const projectId = "2FJrR2634gDmft79seWtyfxalex";
const projectSecret = "7919990e44d516f4c2cbeb92b9df199a";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

const useIPFS = () => {
  const { enqueueSnackbar } = useSnackbar();
  const prepareNFTUri = async (form, imageData, DAO) => {
    const description =
      form.basic_info.description +
      `\n\n ----- \n\n Visit this collection on Qvrse: https://qvrse.io/brand/${DAO.id}`;
    //upload to IPFS
    const data = JSON.stringify({
      name: imageData.name,
      description: description,
      image: imageData.image_url,
      animation_url: form.basic_info.nft_animation_url,
      external_url: `${
        window.env.CHAIN === "MUMBAI"
          ? "https://mumbai.qvrse.io"
          : "https://qvrse.io"
      }/brand/${DAO.id}`,
      attributes: [
        {
          trait_type: "Utility",
          value: form.main_utility,
        },
        ...form.extra_utilities.map((utility) => {
          return {
            trait_type: "Utility",
            value: utility.name,
          };
        }),
      ],
    });

    try {
      const added = await client.add(data);
      const url = `https://hazellabs.infura-ipfs.io/ipfs/${added.path}`;
      //run a function that creates sale and passes in the url
      return url;
    } catch (error) {
      debugger;
      enqueueSnackbar(error || "Error uploading file:", {
        variant: "error",
      });
    }
  };

  const prepareCollectionNFTUri = async (form, DAO) => {
    const description =
      form.basic_info.description +
      `\n\n ----- \n\n Visit this collection on Qvrse: https://qvrse.io/brand/${DAO.id}`;
    //upload to IPFS
    const data = JSON.stringify({
      name: form.basic_info.name,
      description: description,
      image: form.basic_info.picture_url.ipfs,
      animation_url: form.basic_info.nft_animation_url,
      external_url: `${
        window.env.CHAIN === "MUMBAI"
          ? "https://mumbai.qvrse.io"
          : "https://qvrse.io"
      }/brand/${DAO.id}`,
      attributes: [
        {
          trait_type: "Utility",
          value: form.main_utility,
        },
        ...form.extra_utilities.map((utility) => {
          return {
            trait_type: "Utility",
            value: utility.name,
          };
        }),
      ],
    });

    try {
      const added = await client.add(data);
      const url = `https://hazellabs.infura-ipfs.io/ipfs/${added.path}`;
      //run a function that creates sale and passes in the url
      return url;
    } catch (error) {
      enqueueSnackbar(error || "Error uploading file:", {
        variant: "error",
      });
    }
  };
  return {
    prepareNFTUri,
    prepareCollectionNFTUri,
  };
};

export default useIPFS;
