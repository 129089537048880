import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useService from "./useService";
import CircularProgress from "@mui/material/CircularProgress";
import CustomHooks from "../../../@components/hooks";
import { useTranslation } from "react-i18next";

const Select = (props) => {
  const { t } = useTranslation();
  const { DAO, onChange, defaultValue } = props;
  const service = useService(DAO?.id);

  const { data: collections, isLoading } = CustomHooks.useFetch(
    "collection",
    service.list,
    {
      select: (response) => {
        const collectionList = response.map((item) => {
          return {
            title: item.name,
            id: item.id,
          };
        });
        return collectionList;
      },
    }
  );

  return (
    <Autocomplete
      onChange={(e, selectedItems) =>
        onChange(selectedItems.map((item) => item))
      }
      loading={isLoading}
      multiple
      limitTags={2}
      id="select"
      defaultValue={defaultValue}
      options={collections || []}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={t("brands.feed.create.collectionSelect.label")}
          placeholder={t("brands.feed.create.collectionSelect.placeholder")}
        />
      )}
      sx={{ width: "100%" }}
    />
  );
};

export default Select;
