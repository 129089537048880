import React from "react";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import { useParams } from "react-router-dom";
import useService from "../../../useService";
import CustomHooks from "../../../../../../@components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";

const Dialog = ({ albumSpace, DAO, open, onClose, data, refetch }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { albumId } = useParams();
  const service = useService(DAO?.id);

  const { mutate: submitCard } = CustomHooks.usePost(service.submitCard, {
    onSuccess: async () => {
      await refetch();
    },
  });

  React.useEffect(() => {
    if (open && albumSpace.album_card.length === 0) {
      submitCard({
        album_id: albumId,
        album_space_type: albumSpace.album_space_type,
        space_id: data.id,
      });
    }

    // eslint-disable-next-line
  }, [open]);
  return (
    <MaterialDialog
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          background: "transparent",
          boxShadow: "none",
          maxWidth: "max-content",
        },
      }}
      PaperProps={{
        maxWidth: "1200px",
      }}
      open={open}
      onClose={onClose}
    >
      {!isMobile && (
        <iframe
          title={"animation"}
          style={{ border: "none" }}
          src={data.data_json.animation_url}
          width="800px"
          height="600px"
        />
      )}
      {isMobile && (
        <iframe
          title={"animation"}
          style={{ border: "none" }}
          src={data.data_json.animation_url}
          width="100%"
          height="400px"
        />
      )}
    </MaterialDialog>
  );
};

const Action = ({ element: Component, data, DAO, albumSpace, refetch }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box
        sx={{ cursor: "pointer" }}
        component={"span"}
        onClick={() => setOpen(!open)}
      >
        {Component}
      </Box>

      <Dialog
        refetch={refetch}
        DAO={DAO}
        open={open}
        albumSpace={albumSpace}
        onClose={() => setOpen(false)}
        data={data}
      />
    </>
  );
};

Dialog.Action = Action;

export default Dialog;
