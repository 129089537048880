import Gateway from "../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`dao/${daoId}/collection/new/`, data);
  };

  const collection = async (id) => {
    let newId = id;
    if (daoId === 16) {
      //Gregor Ravnik brand
      if (id === "60") {
        //redirect to 61
        newId = "61";
      }
    }

    const response = await gateway.get(`dao/${daoId}/collection/${newId}/`);
    return response.data;
  };

  const reservedSeats = async (id) => {
    const response = await gateway.get(
      `dao/${daoId}/collection/${id}/occupied_seats/`
    );
    return response.data;
  };

  const list = async (data) => {
    const { queryKey: filter } = data;

    let url = `dao/${daoId}/collection/`;

    if (filter.length > 0 && Array.isArray(filter[0]) && filter[0].length > 0) {
      const ids = filter.join(",");
      url += `?ids=${ids}`;
    }
    const response = await gateway.get(url);

    return response.data.results;
  };

  const paymentOptions = async () => {
    const response = await gateway.get(`payment_options/`);
    return response.data.results;
  };

  const buyTicket = (id, data) => {
    return gateway.post(`dao/${daoId}/collection/${id}/buy_ticket/`, data);
  };

  const getNFTVaultPassword = (orderId) => {
    return gateway.post(`get_nft_vault_password/`, {
      order_id: orderId,
    });
  };

  const allBuyers = (id) => {
    return gateway.get(`dao/${daoId}/collection/${id}/buyers/`);
  };

  const getNextTicketImage = (id) => {
    return gateway.get(`dao/${daoId}/collection/${id}/get_ticket_image_url/`);
  };

  const createOrder = async (data) => {
    const response = await gateway.post(
      `dao/${daoId}/collection/${data.collection_id}/order/new/`,
      data.data
    );
    return response.data;
  };

  const toggleVisibility = async (data) => {
    const { collectionId, isVisible } = data;

    const response = await gateway.post(
      `dao/${daoId}/collection/${collectionId}/visibility/`,
      { visible: isVisible }
    );
    return response.data;
  };

  const toggleEnablePurchase = async (data) => {
    const { collectionId, isEnabled } = data;

    const response = await gateway.post(
      `dao/${daoId}/collection/${collectionId}/purchase_enabled/`,
      { enabled: isEnabled }
    );
    return response.data;
  };

  const getCollectionDeployer = (options) => {
    const deployerNameTag = options?.queryKey[0];
    return gateway.get(`contracts/deployer/${deployerNameTag}`);
  };

  const getUserNFTs = async (collectionId) => {
    const response = await gateway.post(
      `dao/${daoId}/collection/${collectionId}/get_user_nfts/`
    );
    return response.data;
  };

  return {
    reservedSeats,
    getUserNFTs,
    buyTicket,
    save,
    collection,
    toggleVisibility,
    list,
    allBuyers,
    paymentOptions,
    createOrder,
    toggleEnablePurchase,
    getNFTVaultPassword,
    getNextTicketImage,
    getCollectionDeployer,
  };
};

export default useService;
