import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Menu from "@mui/material/Menu";
import qrIcon from "../../static/qr-icon.png";
import transferIcon from "../../static/transfer-icon.png";
import collectionIcon from "../../static/collection-icon.png";
import openSeaIcon from "../../static/opensea-icon.png";
import walletIcon from "../../static/wallet-icon-white.png";
import { Box } from "@material-ui/core";
import CustomHooks from "../../@components/hooks";
import { useSnackbar } from "notistack";
import useService from "../useService";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

const Item = ({ title, icon, onClick, disabled }) => {
  return (
    <Box
      onClick={(e) => !disabled && onClick(e)}
      sx={{
        cursor: disabled ? "default" : "pointer",
        display: "flex",
        mr: "5px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          backgroundColor: disabled
            ? "rgb(51 54 69)"
            : "rgba(148, 77, 255, 1);",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box component={"img"} src={icon} sx={{ width: "12px" }} />
      </Box>
      <Typography
        variant={"subtitle3"}
        sx={{
          opacity: disabled ? 0.5 : 1,
          pl: "8px",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const DropdownMenu = ({
  openMenu,
  onClose,
  nft,
  onTransfer,
  onMoveToWallet,
}) => {
  const { t } = useTranslation();
  const service = useService();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    openMenu && setAnchorEl(openMenu);
  }, [openMenu]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const { mutate: generateQR } = CustomHooks.usePost(service.generateQR, {
    onSuccess: () => {
      enqueueSnackbar(t("profile.nftPage.snackbar"), {
        variant: "success",
      });
    },
  });

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: (theme) => theme.borderRadius.primary,
          width: 260,
          height: "auto",
          bgcolor: (theme) => theme.palette.colors.darkGray,
          overflow: "visible",
          mt: 1.5,
          "&:before": {
            content: '""',
            display: "none",
            position: "absolute",
            top: 0,
            right: 18,
            width: 10,
            height: 10,
            bgcolor: (theme) => theme.palette.colors.darkGray,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Typography
        variant={"subtitle3"}
        sx={{
          px: "16px",
          pt: "8px",
          pb: "8px",
        }}
      >
        {t("profile.nftPage.nftActions")}
      </Typography>
      <MenuItem sx={{ py: "5px", minHeight: "0px" }}>
        <Item
          onClick={(e) => {
            e.stopPropagation();
            generateQR({
              collection_id: nft.event_id,
              ticket_id: nft?.nft_id,
            });
          }}
          icon={qrIcon}
          title={t("profile.nftPage.generatedQr")}
        />
      </MenuItem>
      <MenuItem sx={{ py: "5px", minHeight: "0px" }}>
        <Item
          disabled={!nft?.is_minted}
          onClick={onTransfer}
          icon={transferIcon}
          title={t("profile.nftPage.transfer")}
        />
      </MenuItem>
      <MenuItem
        sx={{ py: "5px", minHeight: "0px" }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          history.push({
            pathname: `/brand/${nft?.dao_id}/collection/${nft?.event_id}`,
            state: { scrollToTop: true },
          });
        }}
      >
        <Item
          icon={collectionIcon}
          title={t("profile.nftPage.viewCollection")}
        />
      </MenuItem>
      <MenuItem sx={{ py: "5px", minHeight: "0px" }}>
        <Item
          disabled={!nft?.is_minted}
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              `https://${
                process.env.NODE_ENV === "development" ||
                window.env.CHAIN === "MUMBAI"
                  ? "testnets.opensea.io"
                  : "opensea.io"
              }/assets/${
                process.env.NODE_ENV === "development" ||
                window.env.CHAIN === "MUMBAI"
                  ? "mumbai"
                  : "matic"
              }/${nft?.nft_address}/${nft.nft_id}`,
              "_blank"
            );
          }}
          icon={openSeaIcon}
          title={t("profile.nftPage.viewOpenSea")}
        />
      </MenuItem>

      {nft?.locked_in_vault && (
        <>
          <Typography
            variant={"subtitle3"}
            sx={{
              px: "16px",
              pt: "16px",
              pb: "8px",
            }}
          >
            {t("profile.nftPage.nftStorage.title2")}
          </Typography>
          <Tooltip
            sx={{ fontSize: "18px !important" }}
            title={t("profile.nftPage.vaultTooltip")}
            arrow
          >
            <MenuItem sx={{ py: "5px", minHeight: "0px" }}>
              <Item
                icon={walletIcon}
                title={t("profile.nftPage.nftStorage.button")}
                onClick={onMoveToWallet}
              />
            </MenuItem>
          </Tooltip>
        </>
      )}
    </Menu>
  );
};

export default DropdownMenu;
