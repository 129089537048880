import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import { useHistory } from "react-router-dom";
import CardSkeletonList from "./CardSkeletonList";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import freeSignIcon from "../../../../static/free-sign-small-icon.png";
//import { makeStyles } from "@mui/styles";
import maticIcon from "../../../../static/matic-icon-white.png";

// const useStyles = makeStyles((theme) => ({
//   shakeAnimation: {
//     animation: `$shake 3s ease infinite`, // Reduced total duration to 3 seconds
//   },
//   "@keyframes shake": {
//     "0%, 100%": { transform: "skewY(0deg)" },
//     "5%": { transform: "skewY(6deg)" }, // Adjusted for 3s duration
//     "10%": { transform: "skewY(-6deg)" }, // Adjusted for 3s duration
//     "15%": { transform: "skewY(6deg)" }, // Adjusted for 3s duration
//     "20%": { transform: "skewY(-6deg)" }, // Adjusted for 3s duration
//     "25%": { transform: "skewY(6deg)" }, // Adjusted for 3s duration
//     "30%": { transform: "skewY(-6deg)" }, // Adjusted for 3s duration
//     "35%, 100%": { transform: "skewY(0deg)" }, // Shortened pause
//   },
// }));

const CardList = ({ items, busy }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} columns={12}>
      {busy && <CardSkeletonList />}
      {items?.length > 0 &&
        items?.map((item, index) => <Item item={item} key={item.id} />)}
      {!busy && items && items.length === 0 && (
        <Grid
          item
          mobile={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <UI.NoResults text={t("brands.collection.notFound")} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const Item = ({ item }) => {
  return (
    <Grid
      item
      mobile={12}
      md={4}
      lg={4}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <UI.FlipCard
        frontComponent={<FrontSide item={item} />}
        backComponent={<BackSide item={item} />}
      />
    </Grid>
  );
};

const BackSide = ({ item }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <Box
      sx={{
        cursor: "pointer",
        textAlign: "left",
        margin: "0 auto",
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        opacity: item.visible ? 1 : 0.5,
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderBottom: "none",
          padding: "14px 20px 10px",
          height: "70px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center", mr: "12px" }}>
            <Avatar src={item?.dao?.avatar_url} alt={"avatar"} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: "2px" }}>
            <Box sx={{ textAlign: "left" }}>
              {item?.name ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: "195px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao?.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.name ? (
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "15px !important",
                  maxWidth: "195px",
                }}
              >
                <UI.OverflowTip>
                  {item?.name_slo !== null &&
                  item?.name_slo !== "" &&
                  i18n.language === "sl"
                    ? item?.name_slo
                    : item?.name || ""}
                </UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          height: "290px",
          flexDirection: "column",
          p: "0px 20px 10px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      >
        {item?.is_imported ? (
          ""
        ) : (
          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant={"subtitle4"}
                sx={{
                  mr: "10px",
                }}
              >
                {t("commons.price")}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {item?.free_nft_gas_fee_payed_by_system === false && (
                  <Box sx={{ width: "18px", height: "18px", mr: "7px" }}>
                    <UI.PaymentIcon currency={item?.currency} />
                  </Box>
                )}
                {item?.total_price_with_tax !== "0.00" && (
                  <Typography
                    variant={"h8"}
                    sx={{ lineHeight: "1 !important" }}
                  >
                    {`${item?.total_price_with_tax} ${item?.currency}`}
                  </Typography>
                )}
                {item?.total_price_with_tax === "0.00" && (
                  <Typography
                    variant={"h8"}
                    sx={{ lineHeight: "1 !important" }}
                  >
                    {t("commons.free")}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex", mt: "5px" }}>
              <Typography
                variant={"subtitle4"}
                sx={{
                  mr: "10px",
                }}
              >
                {t("commons.availability")}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant={"h8"}
                  sx={{
                    lineHeight: "1 !important",
                  }}
                >
                  {`${item.nft_amount_minted} / ${item.nft_amount_limit}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box sx={{ display: "flex", mt: "12px", maxHeight: "141px" }}>
          <Typography
            variant={"subtitle3"}
            sx={{
              maxHeight: "141px",
              lineHeight: "1.4",
              overflow: "hidden",
              whiteSpace: "normal",
              textAlign: "justify",
              textOverflow: "ellipsis",
            }}
          >
            {item?.description_slo !== null &&
            item?.description_slo !== "" &&
            i18n.language === "sl"
              ? item?.description_slo.length > 240
                ? item?.description_slo.slice(0, 240) + " ..."
                : item?.description_slo
              : item?.description.length > 240
              ? item?.description.slice(0, 240) + " ..."
              : item?.description}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mt: "20px" }}>
          <Box
            sx={{
              display: "flex",
              bottom: "16px",
              right: "16px",
            }}
          >
            <UI.UtilityTag
              utilityType={
                item.utilities.find(
                  (utility) => utility.is_main_utility === true
                )?.type || ""
              }
            />
            <UI.Tag
              sx={{
                ml: "10px",
                backgroundColor: (theme) => theme.palette.tag.green,
              }}
            >
              {item?.is_imported
                ? t("utilityTag.imported")
                : t("utilityTag.limited")}
            </UI.Tag>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          p: "5px 10px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      >
        <Box sx={{ width: "180px" }}>
          <UI.Button
            sx={{ height: "40px" }}
            type={"primary"}
            title={t("commons.view")}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push({
                pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
                state: { scrollToTop: true },
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const FrontSide = ({ item }) => {
  //const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isFreeNFT = item?.total_price_with_tax === "0.00";
  return (
    <Box
      sx={{
        textAlign: "left",
        cursor: "pointer",
        margin: "0 auto",
        width: "290px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        opacity: item.visible ? 1 : 0.5,
      }}
    >
      {isFreeNFT && (
        <Box
          component={"img"}
          src={freeSignIcon}
          sx={{
            position: "absolute",
            width: "84px",
            bottom: "0px",
            right: "-0px",
          }}
        />
      )}
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderBottom: "none",
          padding: "14px 20px 10px",
          height: "70px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center", mr: "12px" }}>
            <Avatar src={item?.dao?.avatar_url} alt={"avatar"} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: "2px" }}>
            <Box>
              {item?.name ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: "195px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao?.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.name ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant={"h2"}
                  sx={{
                    fontSize: "15px !important",
                    maxWidth: "195px",
                  }}
                >
                  <UI.OverflowTip>
                    {item?.name_slo !== null &&
                    item?.name_slo !== "" &&
                    i18n.language === "sl"
                      ? item?.name_slo
                      : item?.name || ""}
                  </UI.OverflowTip>
                </Typography>
              </Box>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "290px",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          component={"span"}
          sx={{
            boxSizing: "border-box",
            display: "block",
            overflow: "hidden",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: 1,
            border: "0px",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            inset: 0,
            lineHeight: "0",
            fontSize: "0",
          }}
        >
          {item ? (
            item.nft_file_type?.startsWith("video") ? (
              <video
                playsInline
                width="300px"
                height={"300px"}
                autoPlay="autoplay"
                loop
                muted
              >
                <source src={item.nft_image_url} />
              </video>
            ) : (
              <UI.ImageWithSkeleton
                sx={{
                  position: "absolute",
                  inset: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                src={item.nft_image_url}
                width={"100%"}
                alt={"slider-item"}
              />
            )
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
          {!item.visible && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                inset: 0,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: (theme) => theme.palette.text.primary,
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("commons.private")}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            position: "absolute",
            bottom: "16px",
            right: "16px",
          }}
        >
          <UI.UtilityTag
            utilityType={
              item.utilities.find((utility) => utility.is_main_utility === true)
                ?.type || ""
            }
          />
          <UI.Tag
            sx={{
              ml: "10px",
              backgroundColor: (theme) => theme.palette.tag.green,
            }}
          >
            {item?.is_imported
              ? t("utilityTag.imported")
              : t("utilityTag.limited")}
          </UI.Tag>
        </Box>
      </Box>

      {item?.is_imported ? (
        <Box
          sx={{
            height: "70px",
            backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          }}
        ></Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: "20px",
            height: "70px",
            backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {item?.free_nft_gas_fee_payed_by_system === false &&
              item?.total_price_with_tax !== "0.00" && (
                <Box sx={{ width: "18px", height: "18px", mr: "7px" }}>
                  <UI.PaymentIcon currency={item?.currency} />
                </Box>
              )}

            {item?.total_price_with_tax !== "0.00" && (
              <Typography variant={"h8"}>
                {`${item?.total_price_with_tax}`}
              </Typography>
            )}

            {item?.total_price_with_tax === "0.00" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  // className={classes.shakeAnimation}
                  variant={"h10"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "68px",
                    height: "22px",
                    borderRadius: "100px",
                    backgroundColor: (theme) => theme.palette.tag.red,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {item?.free_nft_gas_fee_payed_by_system === false && (
                    <Box
                      sx={{
                        width: "12px",
                        height: "12px",
                        mr: "4px",
                        ml: "-2px",
                      }}
                      component={"img"}
                      src={maticIcon}
                    />
                  )}
                  {t("commons.free")}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography
            variant={"h8"}
            sx={{
              maxWidth: "150px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <UI.OverflowTip>{`${item.nft_amount_minted}/${item.nft_amount_limit}`}</UI.OverflowTip>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CardList;
