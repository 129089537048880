import React from "react";
import { useFormDataContext } from "../context";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import UI from "../../../../@components/UI";
import { ethers } from "ethers";
import useService from "../../useService";
import backArrow from "../../../../static/back-arrow.png";
import Confirmation from "./Confirmation";
import { encodedFunctionParameters } from "./utils";
import { useAccount } from "wagmi";
import useHandleErrors from "../../../../@components/UI/useHandleErrors";
import governorArtifact from "../../../../abis/contracts/modules/governor/GovernorERC20.sol/DaoGovernorERC20.json";
import daoLauncherERC20Artifact from "../../../../abis/contracts/qtech/DaoLauncherERC20.sol/DaoLauncherERC20.json";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import ApplyNowDialog from "./ApplyNowDialog";
import ApplyLaterDialog from "./ApplyLaterDialog";
import { useUserContext } from "../../../../@components/userContext";
import voteWalletIcon from "../../../../static/voteWallet.png";
import customHooks from "../../../../@components/hooks";
import { useTranslation } from "react-i18next";
import CustomHooks from "../../../../@components/hooks";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "1.5",
    textTransform: "uppercase,",
    color: theme.palette.text.primary,
  },
  subtitle: {
    paddingRight: "20px",
    textAlign: "left",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "1.5",
    color: theme.palette.text.secondary,
  },
  title2: {
    marginBottom: "0px",
    marginTop: "0px",
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "1.5",
    textTransform: "uppercase,",
    color: theme.palette.text.primary,
  },
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },

  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "1.5",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
}));

const Index = ({ DAO, previouseStep, onRemoveDraft }) => {
  const { t, i18n } = useTranslation();
  const signer = customHooks.useEthersSigner();
  const history = useHistory();
  const classes = useStyles();
  const { user: loggedInUser } = useUserContext();
  const { formData } = useFormDataContext();
  const { isConnected } = useAccount();
  const { enqueueSnackbar } = useSnackbar();

  const { address } = useAccount();
  const service = useService();
  const { handleApiError, handleMetamaskError } = useHandleErrors();
  const [showDialog, setShowDialog] = React.useState(false);
  const [showApplyNowDialog, setShowApplyNowDialog] = React.useState(false);
  const [showApplyLaterDialog, setShowApplyLaterDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { data: daoDeployer } = CustomHooks.useFetch(
    ["daoLauncher"],
    service.getDAOLauncher
  );

  const onSubmit = async (messageToUsers) => {
    setIsLoading(true);

    const allFormValues = {
      ...formData,
      country_of_incorporation: JSON.stringify(
        formData?.country_of_incorporation
      ),
    };
    const daoLauncher = new ethers.Contract(
      daoDeployer.data.address,
      daoLauncherERC20Artifact.abi,
      signer
    );

    const { daoData, tokenData, governorData } = encodedFunctionParameters(
      address,
      allFormValues
    );
    const encodedFunctionCall = daoLauncher.interface.encodeFunctionData(
      "createDao(bytes daoData, bytes tokenData, bytes governorData)",
      [daoData, tokenData, governorData]
    );
    const proposalData = {
      targets: [daoLauncher.target],
      values: [0],
      calldatas: [encodedFunctionCall],
      description: allFormValues.description,
    };
    try {
      const governor = new ethers.Contract(
        DAO?.governor_contract?.address,
        governorArtifact.abi,
        signer
      );
      // Add proposal to Governor smart contract.
      const proposeTx = await governor.propose(
        proposalData.targets,
        proposalData.values,
        proposalData.calldatas,
        proposalData.description
      );

      const proposeReceipt = await proposeTx.wait(1);
      const proposalId = proposeReceipt.logs[0].args.proposalId.toString();
      // Save proposal to BE.
      service
        .save({
          avatar_url: allFormValues.avatar_url.ipfs,
          proposal_type: "applyForDao",
          proposal_hash_id: proposalId,
          calldatas: [], //to be removed
          signatures: [], //to be removed
          targets: [], //to be removed
          start_block: Number(
            proposeReceipt.logs[0].args.startBlock
          ).toString(),
          end_block: Number(proposeReceipt.logs[0].args.endBlock).toString(),
          status: "Active",
          progress: 0,
          data: {
            messageToUsers,
            proposal_data: proposalData,
            ...allFormValues,
            token_icon_url: allFormValues?.token_icon_url?.ipfs
              ? allFormValues?.token_icon_url?.ipfs
              : null,
            whitepaper_url: allFormValues?.whitepaper_url?.ipfs,
            avatar_url: allFormValues?.avatar_url.ipfs,
          },
        })
        .then(() => {
          enqueueSnackbar("Proposal succesfully sent.", { variant: "success" });
          onRemoveDraft();
          //setProposalSent(true);
          setShowDialog(Math.random());
          setIsLoading(false);
        })
        .catch((error) => {
          handleApiError(error);
          setIsLoading(false);
        });
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      {
        <Box
          sx={{
            p: { md: "40px 100px", mobile: "50px 10px 60px" },
            pt: "0px !important",
          }}
        >
          <Box
            onClick={() => previouseStep()}
            className={classes.backButtonOuter}
            sx={{
              display: "flex",
              cursor: "pointer",
              mb: { md: "50px", mobile: "30px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "8px",
              }}
            >
              <img
                className={"backArrow"}
                style={{ transition: "0.4s ease-in" }}
                src={backArrow}
                alt={"back-arrow"}
                width={"15px"}
                height={"15px"}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box className={classes.backButton}>
                {" "}
                {t("applyYourBrand.apply.backButton")}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mb: "50px",
              display: "flex",
              flexDirection: { md: "row", mobile: "column" },
              textAlign: "left",
            }}
          >
            <Box>
              <Typography variant={"h3"}>
                {t("applyYourBrand.apply.title")}
              </Typography>
              <Typography variant={"subtitle2"}>
                {t("applyYourBrand.apply.subtitle1")}
                <br />
                <br />
                {t("applyYourBrand.apply.subtitle2")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              textAlign: "left",
              mt: "55px",
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item md={4} mobile={12} sx={{ mt: "80px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    width: { md: "290px", mobile: "100%" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0 auto",
                      mb: "20px",
                      width: "82px",
                      height: "82px",
                      borderRadius: "50%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      background: "#944DFF",
                      boxShadow: "0px 0px 10px rgba(148, 77, 255, 0.5)",
                    }}
                  >
                    <Typography variant={"h1"}>1</Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography variant={"h4"} sx={{ padding: "20px" }}>
                      {t("applyYourBrand.apply.step1")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} mobile={12} sx={{ mt: "80px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    width: { md: "290px", mobile: "100%" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0 auto",
                      mb: "20px",
                      width: "82px",
                      height: "82px",
                      borderRadius: "50%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      background: "#944DFF",
                      boxShadow: "0px 0px 10px rgba(148, 77, 255, 0.5)",
                    }}
                  >
                    <Typography variant={"h1"}>2</Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography variant={"h4"} sx={{ padding: "20px" }}>
                      {t("applyYourBrand.apply.step2")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} mobile={12} sx={{ mt: "80px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    width: { md: "290px", mobile: "100%" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0 auto",
                      mb: "20px",
                      width: "82px",
                      height: "82px",
                      borderRadius: "50%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      background: "#944DFF",
                      boxShadow: "0px 0px 10px rgba(148, 77, 255, 0.5)",
                    }}
                  >
                    <Typography variant={"h1"}>3</Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography variant={"h4"} sx={{ padding: "20px" }}>
                      {t("applyYourBrand.apply.step3")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  mt: { md: "90px", mobile: "70px" },
                }}
              >
                <UI.Button
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  onClick={() => setShowApplyNowDialog(Math.random)}
                  sx={{ width: i18n.language === "sl" ? "220px" : "200px" }}
                  type={"primary"}
                  title={t("buttons.applyNow")}
                  startIcon={
                    <Box
                      component={"img"}
                      src={voteWalletIcon}
                      sx={{ width: "43px", height: "19px" }}
                    />
                  }
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  mt: "10px",
                }}
              >
                <UI.Button
                  disabled={!isConnected || !loggedInUser?.email_verified}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowApplyLaterDialog(Math.random());
                  }}
                  sx={{ width: i18n.language === "sl" ? "200px" : "150px" }}
                  type={"tertiary"}
                  title={t("buttons.applyLater")}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      }
      <ApplyNowDialog
        toggleOpen={showApplyNowDialog}
        onConfirm={(message) => {
          setShowApplyNowDialog(Math.random());
          onSubmit(message);
        }}
      />
      <ApplyLaterDialog
        toggleOpen={showApplyLaterDialog}
        onConfirm={() => {
          setShowApplyLaterDialog(Math.random());
          history.push({
            pathname: "/profile/created",
            state: { scrollToTop: true },
          });
        }}
      />
      <Confirmation
        toggleOpen={showDialog}
        onConfirm={(pathname) => {
          setShowDialog(false);
          history.push({
            pathname: pathname,
            state: { scrollToTop: true },
          });
        }}
      />
    </React.Fragment>
  );
};

export default Index;
