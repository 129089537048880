import React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useProposalService from "../../../proposal/useService";
import UI from "../../../../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import QPEOPLE from "../../../qpeople";
import useHandleErrors from "../../../../../@components/UI/useHandleErrors";
import truncateEthAddress from "truncate-eth-address";
import { useUserRole } from "../../../context";
import CustomHooks from "../../../../../@components/hooks";
import Stepper from "../../../../../qvrse/vote/@id/Stepper";
import useBlockchainState from "../../../../../qvrse/vote/@id/useBlockchainState";
import { useTranslation } from "react-i18next";
import VotingPanel from "./VotingPanel";

const Item = (props) => {
  const { children } = props;
  return (
    <Box {...props}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
          borderRadius: (theme) => theme.borderRadius.primary,
          display: "flex",
          alignItems: "center",
          py: { md: "18px", mobile: "15px" },
          pl: { md: "30px", mobile: "15px" },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const history = useHistory();
  const { id } = useParams();
  const { handleApiError } = useHandleErrors();
  const proposalService = useProposalService(DAO?.id);
  const [proposal, setProposal] = React.useState(null);
  const { isBrandMember } = useUserRole();
  const { proposalState } = useBlockchainState(DAO, proposal);

  const steps = [
    t("brands.vote.steps.step1"),
    t("brands.vote.steps.step2"),
    t("brands.vote.steps.step3"),
    t("brands.vote.steps.step4"),
  ];

  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  React.useEffect(() => {
    if (proposalState === "Pending") {
      setActiveStep(0);
    } else if (proposalState === "Active") {
      setActiveStep(1);
    } else if (proposalState === "Queued" || proposalState === "Succeeded") {
      setActiveStep(2);
    } else if (proposalState === "Executed") {
      setActiveStep(3);
    } else if (proposalState === "Defeated") {
      setActiveStep(3);
    }
  }, [proposalState]);

  const fetchDatabaseData = async () => {
    proposalService
      .proposal(id)
      .then((response) => {
        setProposal(response.data);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  React.useEffect(() => {
    id && fetchDatabaseData();
    // eslint-disable-next-line
  }, [id]);
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            mt: { md: "50px", mobile: "0px" },
            display: { md: "block", mobile: "none" },
          }}
        >
          <Stepper
            steps={[
              t("brands.vote.steps.step1"),
              t("brands.vote.steps.step2"),
              t("brands.vote.steps.step3"),
              proposalState === "Defeated"
                ? t("brands.vote.steps.step5")
                : t("brands.vote.steps.step4"),
            ]}
            proposalState={proposalState}
            activeStep={activeStep}
          />
        </Box>
        <Box
          sx={{
            mt: { md: "50px", mobile: "0px" },
            display: { md: "none", mobile: "block" },
          }}
        >
          <Stepper steps={steps} activeStep={activeStep} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          justifyContent: { md: "space-between", mobile: "center" },
          px: { md: "70px", mobile: "0px" },
          pt: { md: "40px", mobile: "40px" },
        }}
      >
        <UI.BackButton
          title={t("brands.vote.addMember.backButton")}
          onClick={() =>
            history.push({
              pathname: `/brand/${DAO?.id}/vote`,
              state: { scrollToTop: true },
            })
          }
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          px: { md: "80px", mobile: "10px" },
          pb: "75px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: { mobile: "55px", md: "70px" },
            mt: "0px",
          }}
        >
          <Box
            component={"img"}
            sx={{
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              mr: "20px",
              textAlign: "center",
              objectFit: "cover",
            }}
            src={proposal?.data?.userProfileImage}
            alt={"dao avatar"}
          />

          <Typography variant={"h1"} sx={{ wordBreak: "break-word" }}>
            {t("brands.vote.addMember.title")}
          </Typography>
        </Box>
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Typography variant={"h2"} sx={{ mb: { mobile: "12px", md: "20px" } }} >
              {t("brands.vote.addMember.subtitle")}
            </Typography>
            <Item>
              <Typography
                variant={"h5"}
                sx={{
                  mr: "10px",
                }}
              >
                {`${t("brands.vote.addMember.email")}: ${
                  proposal?.data?.emailAddress
                }`}
              </Typography>
            </Item>
            <Item sx={{ mt: { mobile: "12px", md: "18px" } }}>
              <Typography
                variant={"h5"}
                sx={{
                  mr: "16px",
                }}
              >
                {`${t("brands.vote.addMember.walletAddress")}: `}
              </Typography>
              <UI.CopyToClipboard
                label={truncateEthAddress(proposal?.data?.walletAddress || "")}
                value={proposal?.data?.walletAddress}
                showBorder
              />
            </Item>
          </Grid>
          <Grid
            sx={{ mt: { mobile: "0px", md: "0px" } }}
            item
            mobile={12}
            md={12}
          >
            <Box
              sx={{
                mt: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ mb: { mobile: "12px", md: "20px" }, mt: "50px" }}>
                <Typography variant={"h2"}>
                  {t("brands.vote.addMember.voting")}
                </Typography>
              </Box>
              <VotingPanel
                DAO={DAO}
                proposal={proposal}
                onChange={() => fetchDatabaseData()}
              />
            </Box>
          </Grid>
          <Grid
            sx={{ mt: { mobile: "50px", md: "50px" } }}
            item
            mobile={12}
            md={12}
          >
            <Box>
              <Typography variant={"h2"}>
                {t("brands.vote.addMember.participants")}
              </Typography>
              <Box sx={{ mt: { mobile: "12px", md: "20px" } }}>
                {proposal?.votes?.length > 0 && (
                  <QPEOPLE.List items={proposal.votes} />
                )}
                {proposal?.votes?.length === 0 && (
                  <UI.NoResults text={t("brands.vote.addMember.notFound")} />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Index;
