import React from "react";
import UI from "../../../@components/UI";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useHistory, useParams } from "react-router-dom";
import useService from "../useService";
import useHandleErrors from "../../../@components/UI/useHandleErrors";
import Avatar from "@mui/material/Avatar";
import { useAccount } from "wagmi";
import Skeleton from "@mui/material/Skeleton";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import truncateEthAddress from "truncate-eth-address";
import moment from "moment";
import { ethers } from "ethers";
import stakingArtifact from "../../../abis/contracts/qtech/staking/Staking.sol/QtechStaking.json";
import { useSnackbar } from "notistack";
import customHooks from "../../../@components/hooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    padding: "30px",
    flexDirection: "column",
    justifyContent: "center",
    width: "400px",
    [theme.breakpoints.down("tablet")]: {
      width: "100%",
    },
    height: "400px",
    border: "1px solid #944DFF",
    opacity: 1,
    filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5))",
  },
  cardMedia: {
    opacity: 1,
    height: "auto",
    width: "75px !important",
    margin: "0 auto",
    objectFit: "unset !important",
    marginTop: "20px",
    textAlign: "center",
  },
}));

const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const signer = customHooks.useEthersSigner();
  const service = useService();
  const { isConnected, address } = useAccount();
  const { id } = useParams();
  const history = useHistory();
  const { handleApiError, handleMetamaskError } = useHandleErrors();
  const [isLoading, setIsLoading] = React.useState(false);
  const [membership, setMembership] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      await service
        .stake(id)
        .then((response) => {
          response.data && setMembership(response.data);
        })
        .catch((error) => {
          handleApiError(error);
        });
    }

    id && DAO && fetchData();
    // eslint-disable-next-line
  }, [id, DAO]);

  const closeStake = async () => {
    await service
      .closeStake(id)
      .then(() => {
        enqueueSnackbar(t("qvrseDAO.membership.card.snackbar"), {
          variant: "success",
        });
        setIsLoading(false);
        history.push("/qvrse/membership");
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  const endMembership = async () => {
    setIsLoading(true);
    try {
      const staking = new ethers.Contract(
        membership?.staking_contract_address,
        stakingArtifact.abi,
        signer
      );

      await staking.completeStake(membership?.stake_id);
      await closeStake();
    } catch (error) {
      setIsLoading(false);
      handleMetamaskError(error);
    }
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <Paper sx={{ width: "100%", backgroundColor: (theme) => theme.palette.bgTransparent.primary, }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "70px", mobile: "0px" },
            pb: "20px",
            pt: { md: "70px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={
              <Typography variant={"h3"}>
                {t("qvrseDAO.membership.card.backButton")}
              </Typography>
            }
            onClick={() => history.go(-1)}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: { md: "75px 80px", mobile: "20px 10px" },
          }}
        >
          <Card className={classes.card}>
            {membership ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  sx={{
                    width: "60px",
                    height: "60px",
                  }}
                  src={membership?.user?.avatar_url}
                />
              </Box>
            ) : (
              <Skeleton
                className={classes.cardMedia}
                sx={{ height: "75px" }}
                variant={"circular"}
              />
            )}
            <CardContent>
              {membership ? (
                <React.Fragment>
                  <Typography
                    variant={"h2"}
                    sx={{ textAlign: "center", lineHeight: "30px" }}
                  >
                    <UI.CopyToClipboard
                      label={truncateEthAddress(membership?.user?.address)}
                      value={membership?.user?.address}
                    />
                  </Typography>
                  <Typography
                    variant={"subtitle3"}
                    sx={{ textAlign: "center"}}
                  >
                    {t("qvrseDAO.membership.card.memberSince", {
                      dateTime: moment(membership?.datetime_created).format(
                        "DD MMM YYYY"
                      ),
                    })}
                  </Typography>
                  <Typography
                    variant={"subtitle3"}
                    sx={{ textAlign: "center"}}
                  >
                    {t("qvrseDAO.membership.card.canBeRevoked", {
                      dateTime: moment(membership?.datetime_completion).format(
                        "DD MMM YYYY"
                      ),
                    })}
                  </Typography>
                </React.Fragment>
              ) : (
                <Skeleton height={"30px"} variant={"rectangular"} />
              )}
              {membership && DAO ? (
                <Typography
                  variant={"h2"}
                  sx={{
                    mt: "20px",
                    textAlign: "center",
                    color: (theme) => theme.palette.colors.primary,
                  }}
                >
                  {`${membership?.amount} ${DAO?.dao_token?.token_symbol}`}
                </Typography>
              ) : (
                <Skeleton
                  sx={{
                    mt: "20px",
                    textAlign: "center",
                  }}
                  height={"30px"}
                  variant={"rectangular"}
                />
              )}
            </CardContent>
            {membership ? (
              <Box
                sx={{
                  mt: "20px",
                }}
              >
                <UI.Button
                  disabled={
                    !(
                      isConnected &&
                      !moment(membership?.datetime_completion).isAfter() &&
                      address === membership?.user?.owner
                    )
                  }
                  type={"primary"}
                  title={t("qvrseDAO.membership.card.button")}
                  onClick={() => endMembership()}
                />
              </Box>
            ) : (
              <Skeleton
                sx={{
                  mt: "20px",
                  textAlign: "center",
                }}
                height={"60px"}
                variant={"rectangular"}
              />
            )}
          </Card>
        </Box>
      </Paper>
    </Box>
  );
};

export default Index;
