import React from "react";
import Box from "@mui/material/Box";
import FormProvider, { useFormData } from "./context";
import MainUtility from "./mainUtility";
import ExtraUtilities from "./extraUtilities";
import BasicInfo from "./basicInfo";

import Review from "./review";
import Stepper from "./Stepper";
import useDraftService from "../../../../profile/created/draft/useService";
import { useUserContext } from "../../../../@components/userContext";
import { useAccount } from "wagmi";
import { useSnackbar } from "notistack";
import useHandleErrors from "../../../../@components/UI/useHandleErrors";
import { useLocation } from "react-router-dom";
import { useUserRole } from "../../context";
import CustomHooks from "../../../../@components/hooks";
import { useTranslation } from "react-i18next";

const Create = ({ DAO }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const draftService = useDraftService();
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const [draft, setDraft] = React.useState(null);
  const { data: formData, setFormValues: setFormData } = useFormData();
  const { enqueueSnackbar } = useSnackbar();
  const { handleApiError } = useHandleErrors();
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  const steps = [
    t("brands.collection.import.steps.step1"),
    t("brands.collection.import.steps.step2"),
    t("brands.collection.import.steps.step3"),
    t("brands.collection.import.steps.step4"),
  ];

  const mobileSteps = [
    t("brands.collection.import.steps.step1"),
    t("brands.collection.import.steps.step2"),
    t("brands.collection.import.steps.step3"),
    t("brands.collection.import.steps.step4"),
  ];

  const { state } = useLocation();

  const saveDraft = (values, showSnackbar) => {
    const draftId = localStorage.getItem("draft_id");

    if (values.basic_info) {
      values.basic_info = {
        ...values.basic_info,
        picture_url: {
          ...values.basic_info.picture_url,
          file: {
            type: values.basic_info.picture_url.file.type,
          },
        },
      };
    }

    const allData = {
      draft_type: "collection_import",

      data: {
        dao_id: DAO.id,
        ...formData,
        ...values,
      },
      ...(draftId && { draft_id: draftId }),
    };

    setFormData(values);

    draftService
      .save(allData)
      .then((response) => {
        const draft = response.data.data;

        setDraft({ ...response.data, data: draft });
        localStorage.setItem("draft_id", response.data.id);
        const index = state?.draft_display_index
          ? state?.draft_display_index
          : "";
        showSnackbar &&
          enqueueSnackbar(t("drafts.success", { index }), {
            variant: "success",
          });
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const removeDraft = (draftId) => {
    draftService
      .remove(draftId)
      .then(() => {})
      .catch((error) => {
        handleApiError(error);
      });
  };

  const getDraft = async (id) => {
    await draftService
      .draft(id)
      .then((response) => {
        const draft = response.data.data;

        setDraft({ ...response.data, data: draft });

        localStorage.setItem("draft_id", response.data.id);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  React.useEffect(() => {
    if (isConnected) {
      state.draft_id && getDraft(state.draft_id);
    }

    //eslint-disable-next-line
  }, [state, isConnected, loggedInUser]);

  React.useEffect(() => {
    // Clean up local storage items.
    return () => {
      localStorage.removeItem("draft_id");
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            mt: { md: "50px", mobile: "0px" },
            display: { md: "block", mobile: "none" },
          }}
        >
          <Stepper steps={steps} activeStep={activeStep} />
        </Box>
        <Box
          sx={{
            mt: { md: "50px", mobile: "10px" },
            display: { md: "none", mobile: "block" },
          }}
        >
          <Stepper steps={mobileSteps} activeStep={activeStep} />
        </Box>
      </Box>
      {activeStep === 0 && (
        <BasicInfo
          DAO={DAO}
          onSave={(values, showSnackbar) => saveDraft(values, showSnackbar)}
          draft={draft}
          nextStep={() => {
            setActiveStep(activeStep + 1);
            window.scrollTo(0, 0);
          }}
        />
      )}
      {activeStep === 1 && (
        <MainUtility
          onSave={(values, showSnackbar) => saveDraft(values, showSnackbar)}
          draft={draft}
          previouseStep={() => {
            setActiveStep(activeStep - 1);
            window.scrollTo(0, 0);
          }}
          nextStep={() => {
            setActiveStep(activeStep + 1);
            window.scrollTo(0, 0);
          }}
        />
      )}
      {activeStep === 2 && (
        <ExtraUtilities
          onSave={(values, showSnackbar) => saveDraft(values, showSnackbar)}
          draft={draft}
          previouseStep={() => {
            setActiveStep(activeStep - 1);
            window.scrollTo(0, 0);
          }}
          nextStep={() => {
            setActiveStep(activeStep + 1);
            window.scrollTo(0, 0);
          }}
        />
      )}

      {activeStep === 3 && (
        <Review
          onRemoveDraft={() => {
            const draftId = localStorage.getItem("draft_id");
            if (draftId) {
              removeDraft(draftId);
            }
          }}
          DAO={DAO}
          previouseStep={() => {
            setActiveStep(activeStep - 1);
            window.scrollTo(0, 0);
          }}
        />
      )}
    </>
  );
};

const ImportCollection = ({ DAO }) => {
  return (
    <FormProvider>
      <Create DAO={DAO} />
    </FormProvider>
  );
};

export default ImportCollection;
