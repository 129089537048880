import React from "react";
import Box from "@mui/material/Box";
import CollectionSlider from "../../home/collections/CollectionSlider";
import Busy from "../../../../@components/UI/busy";
import UI from "../../../../@components/UI";
import { useUserRole } from "../../context";
import useCollectionService from "../../collection/useService";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomHooks from "../../../../@components/hooks";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import qvrseLogo from "../../../../static/qvrse-logo.png";
import { useTranslation } from "react-i18next";

import RollerAnimation from "./rollerAnimation";

const Preview = ({ triggerRefetch, DAO, settings, onBuy }) => {
  const { t } = useTranslation();
  const { isBrandMember } = useUserRole();
  const collectionService = useCollectionService(DAO?.id);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] =
    React.useState(0);

  React.useEffect(() => {
    if (DAO && triggerRefetch) {
      fetchCollections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DAO, triggerRefetch]);

  const {
    data: items,
    isLoading: collectionsLoading,
    refetch: fetchCollections,
  } = CustomHooks.useFetch(
    [settings?.collections.map((c) => c.id)],
    collectionService.list,
    {
      enabled: false,
      select: (response) => {
        // Filter the items based on the visibility condition
        const filteredItems = response.filter((item) => {
          // Return all items if user has ADMIN or MODERATOR role
          if (isBrandMember) {
            return true;
          }
          // Filter out items that have item.visible = false
          return item.visible !== false;
        });

        return filteredItems;
      },
    }
  );

  React.useEffect(() => {
    if (DAO) {
      fetchCollections();
    }
  }, [DAO, fetchCollections]);
  return (
    <Box
      sx={{
        backgroundColor: settings.background_color,
        padding: { md: "20px 60px 60px", mobile: "20px" },
        height: "auto",
        borderRadius: (theme) => theme.borderRadius.primary,
      }}
    >
      <Container
        maxWidth={"md"}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingLeft: { mobile: "0px" },
          paddingRight: { mobile: "0px" },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <RollerAnimation imageSrc={qvrseLogo} />
        </Box>

        {!collectionsLoading && items && (
          <Box>
            <Typography
              variant={"subtitle3"}
              sx={{
                color: settings.text_color,
              }}
            >
              {items[currentActiveSlideIndex]?.dao?.name}
            </Typography>
            <Typography
              variant={"h2"}
              sx={{ color: settings.text_color }}
            >
              {items[currentActiveSlideIndex]?.name}
            </Typography>

            <Box sx={{ mt: "10px", pt: { md: "40px", mobile: "20px" } }}>
              {!isMobile && (
                <>
                  {!collectionsLoading && items && items.length > 0 && (
                    <CollectionSlider
                      disableClick
                      utilityTagsVisible={false}
                      isLoopEnabled={false}
                      isPaginationEnabled={false}
                      theme={settings}
                      onSlideChange={(swiperContext) =>
                        setCurrentActiveSlideIndex(swiperContext.activeIndex)
                      }
                      items={items}
                      busy={collectionsLoading}
                    />
                  )}
                  {collectionsLoading && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Busy.Indicator />
                    </Box>
                  )}
                  {!collectionsLoading && items && items.length === 0 && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <UI.NoResults
                        text={t("infoMessages.collectionNotFound")}
                      />
                    </Box>
                  )}
                </>
              )}
              {isMobile && (
                <>
                  {items && items.length !== 0 && (
                    <UI.SwiperMobile
                      disableClick
                      theme={settings}
                      onSlideChange={(swiperContext) =>
                        setCurrentActiveSlideIndex(swiperContext.activeIndex)
                      }
                      items={items}
                    />
                  )}
                  {collectionsLoading && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Busy.Indicator />
                    </Box>
                  )}
                  {!collectionsLoading && items && items.length === 0 && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <UI.NoResults
                        text={t("infoMessages.collectionNotFound")}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>

            <Box sx={{ mt: "15px" }}>
              <UI.UtilityTag
                customStyles={{
                  backgroundColor: settings.button_color,
                }}
                utilityType={
                  items[currentActiveSlideIndex]?.utilities?.find(
                    (utility) => utility.is_main_utility === true
                  )?.type || ""
                }
              />
            </Box>
            <Box sx={{ mt: "28px" }}>
              <UI.ReadMore
                fontSize={"16px"}
                color={settings.text_color}
                maxTextLength={300}
              >
                {items[currentActiveSlideIndex]?.description
                  ? items[currentActiveSlideIndex]?.description
                  : ""}
              </UI.ReadMore>
            </Box>
            <Box
              sx={{
                mt: "15px",
                mb: "7px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    color: settings.text_color,
                    opacity: "0.5",
                  }}
                >
                  {t("commons.price")}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    ml: "4.5px",
                    fontSize: "11px !important",
                    mt: "4.3px",
                  }}
                >
                  {t("commons.withTax")}
                </Typography>
              </Box>

              {items[currentActiveSlideIndex]?.total_price_with_tax > 0 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant={"h6"}
                    sx={{
                      color: settings.text_color,
                      ml: "10px",
                      lineHeight: "unset !important",
                    }}
                  >
                    {`${items[currentActiveSlideIndex]?.total_price_with_tax} ${items[currentActiveSlideIndex]?.currency}`}
                  </Typography>
                </Box>
              )}
              {items[currentActiveSlideIndex]?.total_price_with_tax ===
                "0.00" && (
                <Typography
                  variant={"h6"}
                  sx={{
                    color: settings.text_color,
                    ml: "10px",
                    lineHeight: "unset !important",
                  }}
                >
                  {t("commons.free")}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                mb: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"subtitle1"}
                sx={{
                  color: settings.text_color,
                  opacity: "0.5",
                }}
              >
                {t("commons.availability")}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant={"h6"}
                  sx={{
                    color: settings.text_color,
                    ml: "10px",
                    lineHeight: "unset !important",
                  }}
                >
                  {`${items[currentActiveSlideIndex]?.nft_amount_minted}/${items[currentActiveSlideIndex]?.nft_amount_limit}`}
                </Typography>
              </Box>
            </Box>

            <UI.Button
              disabled={
                items[currentActiveSlideIndex]?.nft_amount_limit -
                  items[currentActiveSlideIndex]?.nft_amount_minted <=
                0
              }
              sx={{
                height: "46px",
                width: { md: "300px", mobile: "100%" },
                backgroundColor: settings.button_color,
                "&:hover": {
                  backgroundColor: settings.button_color,
                  opacity: "0.8",
                  border: settings.button_color,
                  filter: "none",
                },
              }}
              type={"primary"}
              title={
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {items[currentActiveSlideIndex]?.total_price_with_tax ===
                      "0.00"
                        ? t(
                            "brands.collection.collectionPage.buyButton.freeNft"
                          )
                        : t("brands.collection.collectionPage.buyButton.buy")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {items[currentActiveSlideIndex]?.total_price_with_tax >
                      0 && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant={"h9"}
                          sx={{
                            ml: "10px",
                            lineHeight: "unset !important",
                          }}
                        >
                          {`${items[currentActiveSlideIndex]?.total_price_with_tax} ${items[currentActiveSlideIndex]?.currency}`}
                        </Typography>
                      </Box>
                    )}
                    {items[currentActiveSlideIndex]?.total_price_with_tax ===
                      "0.00" && (
                      <Typography
                        variant={"h1"}
                        sx={{
                          fontSize: "15px !important",
                          ml: "10px",
                          lineHeight: "unset !important",
                        }}
                      >
                        0.00
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              }
              onClick={() => {
                onBuy(items[currentActiveSlideIndex]?.id);
              }}
            />

            <Grid
              item
              md={12}
              mobile={12}
              sx={{ mt: { mobile: "60px", md: "50px" } }}
            >
              <Box sx={{ mb: "10px" }}>
                <Typography
                  variant={"h2"}
                  sx={{
                    fontSize: "22px !important",
                    color: settings.text_color,
                  }}
                >
                  {t("commons.perks")}
                </Typography>
              </Box>
              <UI.Utilities
                gasFeesPayedBySystem={
                  items[currentActiveSlideIndex]
                    ?.free_nft_gas_fee_payed_by_system
                }
                hidePagination={true}
                customStyles={settings}
                utilities={items[currentActiveSlideIndex]?.utilities}
                currency={items[currentActiveSlideIndex]?.currency}
              />
            </Grid>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Preview;
