import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import SwitchButton from "./SwitchButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Cards from "./cards";
import Create from "./cards/create";
import UI from "../../../../../@components/UI";
import CustomHooks from "../../../../../@components/hooks";
import useService from "../../useService";
import { useSnackbar } from "notistack";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import { useTranslation } from "react-i18next";

const Edit = ({ DAO }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { albumId } = useParams();
  const service = useService(DAO?.id);

  const { data: album, refetch: fetchAlbum } = CustomHooks.useFetch(
    ["album"],
    () => service.album(albumId),
    {
      enabled: false,
    }
  );

  const { mutate: removeAlbum } = CustomHooks.usePost(service.remove, {
    onSuccess: async () => {
      history.push(`/brand/${DAO.id}/album`);
    },
  });

  React.useEffect(() => {
    if (DAO) {
      fetchAlbum(albumId);
    }
    // eslint-disable-next-line
  }, [DAO]);

  const { mutate: toggleVisibility } = CustomHooks.usePost(
    service.toggleVisibility,
    {
      onSuccess: () => {
        enqueueSnackbar(t("brands.albums.snackbar"), {
          variant: "success",
        });
        fetchAlbum(albumId);
      },
    }
  );

  return (
    <Box
      sx={{
        width: "100%",
        p: { mobile: "0px 10px", md: "0px" },
      }}
    >
      <UI.BackButton
        title={t("brands.albums.backToAlbum")}
        onClick={() => history.push(`/brand/${DAO.id}/album/${albumId}`)}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: { mobile: "22px", md: "20px" },
          mt: { mobile: "20px", md: "40px" },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant={"h3"}>{album?.name}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mb: { mobile: "22px", md: "20px" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant={"subtitle2"}>{album?.description}</Typography>
      </Box>
      <Box
        sx={{
          mb: "30px",
          display: "flex",
          width: "220px",
          flexDirection: "column",
        }}
      >
        <SwitchButton
          label={t("brands.albums.makePublic")}
          defaultChecked={!album?.is_hidden}
          handleChange={(e) => {
            toggleVisibility(albumId);
          }}
        />
      </Box>
      {DAO && (
        <Box
          sx={{
            mb: { mobile: "30px", md: "40px" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Cards DAO={DAO} />
          <Box
            sx={{
              mt: "40px",
              width: "100%",
              mb: "40px",
              display: "flex",
              flexDirection: { mobile: "column", md: "unset" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { md: "180px", mobile: "90%" },
                mr: { md: "10px", mobile: "0" },
              }}
            >
              <EditDialog.Action
                DAO={DAO}
                album={album}
                onConfirm={() => {
                  fetchAlbum(albumId);
                }}
                element={
                  <UI.Button
                    title={t("brands.albums.deleteAlbumDialog.button.edit")}
                    type={"primary"}
                  />
                }
              />
            </Box>
            <Box
              sx={{
                width: { md: "210px", mobile: "90%" },
                mt: { md: "0px", mobile: "10px" },
              }}
            >
              <DeleteDialog.Action
                element={
                  <UI.Button
                    title={t("brands.albums.deleteAlbumDialog.button.delete")}
                    type={"delete"}
                    startIcon={<DeleteForeverIcon />}
                  />
                }
                onConfirm={() => {
                  removeAlbum(albumId);
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
const Index = ({ DAO }) => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Edit DAO={DAO} />
      </Route>
      <Route path={`${path}/create/collection`}>
        <Create.Collection DAO={DAO} />
      </Route>
      <Route path={`${path}/create/text`}>
        <Create.Text DAO={DAO} />
      </Route>
      <Route path={`${path}/create/image`}>
        <Create.Image DAO={DAO} />
      </Route>
      <Route path={`${path}/create/video`}>
        <Create.Video DAO={DAO} />
      </Route>
      <Route path={`${path}/create/spatial`}>
        <Create.Spatial DAO={DAO} />
      </Route>
      <Route path={`${path}/create/code`}>
        <Create.Code DAO={DAO} />
      </Route>
      <Route path={`${path}/create/game`} component={Create.Game} />
      <Route path={`${path}/create/discount`}>
        <Create.Discount DAO={DAO} />
      </Route>
      <Route path={`${path}/create/animation`}>
        <Create.Animation DAO={DAO} />
      </Route>
      <Redirect from="*" to={`${path}`} />
    </Switch>
  );
};

export default Index;
