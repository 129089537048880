import React from "react";

import { useHistory, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Featured from "./featured";
import NewCollections from "./newCollections";
import PaymentOptions from "./paymentOptions";
import Promotion from "./promotion";
import promotionBanner from "../static/slikaizpostavit.png";
import promotionBanner2 from "../static/mrfy-banner.png";
import Trending from "./trending";
import News from "./news";
import UI from "../@components/UI";
import CustomHooks from "../@components/hooks";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import RedirectToProfileDialog from "./RedirectToProfileDialog";
import { useTranslation } from "react-i18next";

const PromotionItem = ({ text }) => {
  return (
    <Box component={"span"} sx={{ display: "flex" }}>
      <DoubleArrowIcon sx={{ mr: "8px", color: "#944dff", width: "18px" }} />
      {text}
    </Box>
  );
};

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let query = CustomHooks.useQuery();
  const location = useLocation();

  const [emailRedirectDialogText, setEmailRedirectDialogText] =
    React.useState(null);

  const [emailRedirectToProfile, setEmailRedirectToProfile] =
    React.useState(null);

  React.useEffect(() => {
    const email_redirect = query.get("email_redirect");
    const email_redirect_profile = query.get("email_redirect_profile");
    if (email_redirect) setEmailRedirectDialogText(email_redirect);
    if (email_redirect_profile)
      setEmailRedirectToProfile(email_redirect_profile);
  }, [query]);

  return (
    <Container maxWidth={false} sx={{ minHeight: "100vh", px: "0px" }}>
      {emailRedirectDialogText && (
        <UI.InfoDialog
          contentText={emailRedirectDialogText}
          onClose={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
        />
      )}
      {emailRedirectToProfile && (
        <RedirectToProfileDialog
          contentText={emailRedirectToProfile}
          onClose={() => {
            setEmailRedirectToProfile(null);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setEmailRedirectToProfile(null);
            history.push(`/profile`);
          }}
        />
      )}
      <Box>
        <Box
          sx={{ mt: "110px", mx: { lg: "100px", md: "50px", mobile: "10px" } }}
        >
          <Featured />
        </Box>
        <Box sx={{ px: { lg: "100px", md: "50px", mobile: "10px" } }}>
          <Box sx={{ mt: { md: "80px", mobile: "70px" } }}>
            <NewCollections
              onClick={(collection) => {
                history.push({
                  pathname: `/brand/${collection.dao.id}/collection/${collection.id}`,
                  state: { scrollToTop: true },
                });
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            my: { md: "60px", mobile: "70px" },
          }}
        >
          {/*<Box sx={{ display: { md: "block", mobile: "none" } }}>*/}
          {/*  <PaymentOptions />*/}
          {/*</Box>*/}
        </Box>

        <Box
          sx={{
            px: { lg: "100px", md: "50px", mobile: "10px" },
            mt: { md: "80px", mobile: "0" },
          }}
        >
          <Box>
            <Promotion
              isExteranlUrl={true}
              image={promotionBanner}
              title={t("explore.promotion1.title")}
              subtitle={t("explore.promotion1.subtitle")}
              content={
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <PromotionItem
                    text={t("explore.promotion1.promotionItem1")}
                  />
                  <PromotionItem
                    text={t("explore.promotion1.promotionItem2")}
                  />
                  <PromotionItem
                    text={t("explore.promotion1.promotionItem3")}
                  />
                  <PromotionItem
                    text={t("explore.promotion1.promotionItem4")}
                  />
                  <PromotionItem
                    text={t("explore.promotion1.promotionItem5")}
                  />
                </Box>
              }
              url={
                "https://www.spatial.io/s/QvrseStation-65a40428c7c93d76b461340a?share=1152549945052585475"
              }
            />
          </Box>
          <Box sx={{ my: "70px" }}>
            <Trending
              onClick={(id) => {
                history.push({
                  pathname: id === 1 ? `/qvrse` : `/brand/${id}`,
                  state: { scrollToTop: true },
                });
              }}
            />
          </Box>
          <Box>
            <Promotion
              reverse={true}
              image={promotionBanner2}
              title={t("explore.promotion2.title")}
              subtitle={t("explore.promotion2.subtitle")}
              content={
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <PromotionItem
                    text={t("explore.promotion2.promotionItem1")}
                  />
                  <PromotionItem
                    text={t("explore.promotion2.promotionItem2")}
                  />
                  <PromotionItem
                    text={t("explore.promotion2.promotionItem3")}
                  />
                  <PromotionItem
                    text={t("explore.promotion2.promotionItem4")}
                  />
                </Box>
              }
              url={"/brand/20/collection"}
            />
          </Box>
        </Box>

        <Box
          sx={{
            px: { lg: "100px", md: "50px" },
            pl: { mobile: "10px", md: "0" },
            mt: { md: "100px", mobile: "70px" },
            mb: { md: "100px", mobile: "70px" },
          }}
        >
          <News />
        </Box>

        <Box
          sx={{
            my: { md: "100px", mobile: "70px" },
          }}
        >
          <Box sx={{ display: { md: "block", mobile: "none" } }}>
            <PaymentOptions />
          </Box>
        </Box>

        <Box sx={{ my: "70px" }}>
          {/*<Box sx={{ display: { md: "block", mobile: "none" } }}>*/}
          {/*  <Specialities />*/}
          {/*</Box>*/}
        </Box>
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    px: { lg: "100px", md: "50px" },*/}
        {/*    mt: { mobile: "90px", md: "0px" },*/}
        {/*    pb: "80px",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    variant={"h4"}*/}
        {/*    sx={{*/}
        {/*      fontSize: { md: "30px !important", mobile: "27px !important" },*/}
        {/*      mb: "30px",*/}
        {/*      display: { md: "none", mobile: "flex" },*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {t("appBar.collections")}*/}
        {/*  </Typography>*/}
        {/*  <Collections />*/}
        {/*</Box>*/}
      </Box>
    </Container>
  );
};

export default Index;
