import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../@components/UI";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";



const Promotion = ({
  image,
  video,
  title,
  subtitle,
  content,
  url,
  isExteranlUrl = false,
  reverse,
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          md: reverse ? "row-reverse" : "row",
          mobile: "column",
        },
        maxHeight: { md: "450px", mobile: "100%" },
      }}
    >
      
      <Box
        sx={{
          ...(isMobile
            ? { borderRadius: "8px 8px 0 0" }
            : {
                borderTopLeftRadius: reverse ? "0px" : { md: "8px", mobile: "0px" },
                borderBottomLeftRadius: reverse ? "0px" : { md: "8px", mobile: "0px" },
                borderTopRightRadius: reverse ? { md: "8px", mobile: "0px" } : "0px",
                borderBottomRightRadius: reverse ? { md: "8px", mobile: "0px" } : "0px",
              }),
          flex: 1,
          maxWidth: { md: "50%", mobile: "100%" },
          height: "auto",
          overflow: "hidden",
          margin: 0, // Reset margin
          padding: 0, // Reset padding
        }}
      >

        
        {image && (
          <Box
            component={"img"}
            src={image}
            sx={{
              width: "100%",
              height: "auto",
              margin: 0, // Reset margin
              padding: 0, // Reset padding
              display: "block", // Ensures inline spacing isn't applied
            }}
          />
        )}
        {video && (
          <video
            playsInline
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center",
              objectFit: "cover",
              color: "transparent",
              textIndent: "10000px",
              float: "left",
            }}
            autoPlay="autoplay"
            loop
            muted
          >
            <source src={video} />
          </video>
        )}
      </Box>
      <Box
        sx={{
          ...(isMobile
            ? { borderRadius: "0 0 8px 8px" }
            : {
                borderTopLeftRadius: reverse ? { md: "8px", mobile: "0px" } : "0px",
                borderBottomLeftRadius: reverse ? { md: "8px", mobile: "0px" } : "0px",
                borderTopRightRadius: reverse ? "0px" : { md: "8px", mobile: "0px" },
                borderBottomRightRadius: reverse ? "0px" : { md: "8px", mobile: "0px" },
              }),
          flex: 1,
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            ml: { md: "100px", mobile: "0px" },
            mr: { md: "120px", mobile: "0px" },
            padding: { mobile: "50px 20px 60px", md: "unset" },
          }}
        >
          <Box sx={{ flexDirection: "column" }}>
            <Typography
              variant={"h4"}
              sx={{
                fontSize: { md: "30px !important", mobile: "26px !important" },
                whiteSpace: "pre-line",
                lineHeight: "1.4",
                textAlign: { mobile: "center", md: "start" },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{
                fontSize: { md: "18px !important", mobile: "16px !important" },
                mt: { md: "10px", mobile: "14px" },
                mb: { mobile: "12px", md: "18px" },
                textAlign: { mobile: "center", md: "start" },
              }}
            >
              {subtitle}
            </Typography>
            {isExteranlUrl === false && (
              <UI.Button
                type={"primary"}
                size={"small"}
                onClick={() =>
                  history.push({ pathname: url, state: { scrollToTop: true } })
                }
                sx={{
                  mt: "26px",
                  width: { mobile: "100%", md: "250px" },
                  height: "46px",
                }}
                title={t("commons.seeMore")}
              />
            )}
            {isExteranlUrl === true && (
              <Link to={{ pathname: url }} target="_blank" rel="noreferrer">
                <UI.Button
                  type={"primary"}
                  size={"small"}
                  sx={{
                    mt: "26px",
                    width: { mobile: "100%", md: "250px" },
                    height: "46px",
                  }}
                  title={t("commons.seeMore")}
                />
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Promotion;
