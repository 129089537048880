import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import UI from "../../../../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { useUserContext } from "../../../../../../../@components/userContext";
import useService from "../../../../useService";
import CustomHooks from "../../../../../../../@components/hooks";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";


const Discount = ({ DAO }) => {
  const { albumId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { user: loggedInUser } = useUserContext();
  const service = useService(DAO?.id);

  const { mutate: createDiscount } = CustomHooks.usePost(service.newCard, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.albums.cards.snackbar"), {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/album/${albumId}/edit`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      value: "",
      code: "",
      description: "",
      external_link: "",
    },
  });

  const onSubmit = async (form) => {
    const data = {
      name: "Discount",
      album_space_type: "Discount",
      data_json: {
        ...form,
      },
    };
    await createDiscount({ albumId: albumId, data: data });
  };
  return (
    <Paper
      sx={{ width: "100%", backgroundColor: (theme) => theme.palette.bgTransparent.primary, }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "50px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.albums.backToAlbum")}
            onClick={() => history.go(-1)}
          />

          <Typography
            variant={"h3"}
            sx={{
              mb: "25px",
              mt: "30px",
            }}
          >
            {t("brands.albums.createCard.discount.createDiscountCard")}
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.createCard.discount.discountValue")}
                  </Typography>
                </Box>

                <Controller
                  name={"value"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <UI.TextField {...field} />}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.value?.type === "required" && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.albums.createCard.error.discountValue")}
                    </Alert>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.createCard.discount.discountCode")}
                  </Typography>
                </Box>

                <Controller
                  name={"code"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <UI.TextField {...field} />}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.code?.type === "required" && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.albums.createCard.error.discountCode")}
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography variant={"h6"}>
                  {t("brands.albums.createCard.discount.description")}
                  </Typography>
                </Box>

                <Controller
                  name={"description"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField multiline rows={5} {...field} />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.description?.type === "required" && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.albums.createCard.error.description")}
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.createCard.discount.externalLink")}
                  </Typography>
                </Box>

                <Controller
                  name={"external_link"}
                  rules={{
                    required: false,
                    pattern: {
                      value:
                        /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/g,
                      message: "Invalid URL",
                    },
                  }}
                  control={control}
                  render={({ field }) => <UI.TextField {...field} />}
                />
                {errors.external_link?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.albums.createCard.error.externalLink")}
                    </Alert>
                  </Box>
                )}
                {errors?.external_link?.type === "pattern" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {errors?.external_link.message}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            mb: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length || !loggedInUser?.email_verified
              }
              title={t("brands.albums.createCard.button.createCard")}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Discount;
