import React, { Suspense } from "react";
import "./index.css";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import Qvrse from "./qvrse";
import DAO from "./daos";
import Explore from "./explore";
import Collections from "./collections";
import Profile from "./profile";
import QrTicketScan from "./qrTicketScan";
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalStyles from "@material-ui/core/GlobalStyles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import qvrseDark from "./static/qvrse-dark.png";

import "@rainbow-me/rainbowkit/styles.css";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import UserProvider, { useUserContext } from "./@components/userContext";
import {
  RainbowKitProvider,
  getDefaultConfig,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { SnackbarProvider } from "notistack";
import { useAccount, WagmiConfig } from "wagmi";
import { hardhat, polygonMumbai, polygon } from "wagmi/chains";
import { http } from "wagmi";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import QvrseProvider from "./@components/contextAPI";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import UI from "./@components/UI";
import Themes from "./@components/designSystem/themes";
import { makeStyles } from "@mui/styles";
import backgroundStars from "./static/qvrse-bg.webp";
import backgroundLight from "./static/bgLight.jpg";
import backgroundStarsMobile from "./static/qvrse-bg-mobile.webp";
import "./i18n";
import LanguageSwitcher from "./@components/UI/appBar/languageSwitcher";
import { isIOS } from "react-device-detect";
import useMediaQuery from "@mui/material/useMediaQuery";

const getChainsForEnvironment = () => {
  if (window.env.CHAIN === "POLYGON") {
    console.log("POLYGON chain");
    return [polygon];
  } else if (window.env.CHAIN === "MUMBAI") {
    console.log("MUMBAI chain");
    return [polygonMumbai];
  } else {
    console.log("Return utilities chains");
    return [hardhat, polygonMumbai, polygon];
  }
};

const config = getDefaultConfig({
  appName: "Qvrse",
  projectId: "baab8c3625087d0dadd45a3c5b5c815e",
  chains: getChainsForEnvironment(),
  transports: {
    [polygon.id]: http(
      "https://polygon-mainnet.infura.io/v3/ed8f41cd961b407ca7310eec767be5a7"
    ),
  },
});

const queryClient = new QueryClient();

const useStyles = makeStyles((theme) => {
  return {
    containerRootMobileIos: {
      position: "relative",
      "&::after": {
        content: '""',
        position: "fixed",
        zIndex: "-1",
        display: "block",
        top: "0px",
        left: "0px",
        bottom: "0px",
        right: "0px",
        backgroundImage: ({ selectedTheme }) => {
          return selectedTheme === "dark"
            ? `url(${backgroundStarsMobile})`
            : `url(${backgroundLight})`;
        },
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
      },
    },
    containerRootMobileAndroid: {
      backgroundImage: ({ selectedTheme }) => {
        return selectedTheme === "dark"
          ? `url(${backgroundStarsMobile})`
          : `url(${backgroundLight})`;
      },
      backgroundRepeat: "repeat",
      backgroundSize: "100%",
      backgroundAttachment: "fixed",
    },
    containerRoot: {
      backgroundImage: ({ selectedTheme }) => {
        return selectedTheme === "dark"
          ? `url(${backgroundStars})`
          : `url(${backgroundLight})`;
      },
      backgroundRepeat: "repeat",
      backgroundSize: "100%",
    },
  };
});

const App = () => {
  const { selectedTheme } = useUserContext();
  const isMobile = useMediaQuery("(max-width:600px)"); // Checks if the screen width is 600px or less
  const classes = useStyles({ selectedTheme });
  const { address } = useAccount();
  const addressRef = React.useRef(address);
  const [resetDate, setResetDate] = React.useState(0);

  React.useEffect(() => {
    if (!address && addressRef.current) {
      setResetDate(new Date().getTime());
    }
    addressRef.current = address;
  }, [address]);

  return (
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider
        key={`rainbowkit-${resetDate}`}
        theme={darkTheme({
          accentColor: "#944DFF",
          accentColorForeground: "white",
          borderRadius: "none",
          fontStack: "system",
          overlayBlur: "small",
        })}
      >
        <GoogleOAuthProvider
          clientId={
            "1013355947383-g54tjubhuenvaqnkop6g8hcpfn3710li.apps.googleusercontent.com"
          }
        >
          <UI.ScrollToTop />

          <ThemeProvider
            theme={selectedTheme === "dark" ? Themes.Dark : Themes.Light}
          >
            <Box
              className={
                isMobile
                  ? isIOS
                    ? classes.containerRootMobileIos
                    : classes.containerRootMobileAndroid
                  : classes.containerRoot
              }
              style={{
                backgroundImage:
                  selectedTheme === "dark"
                    ? `url(${backgroundStars})`
                    : `url(${backgroundLight})`,
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                flex: "1",
              }}
            >
              <CssBaseline />
              <GlobalStyles
                styles={{
                  "& :root": {
                    colorScheme: selectedTheme === "dark" ? "dark" : "light",
                  },
                  ".seat": {
                    width: "40px !important",
                    height: "48px !important",
                    borderRadius: "1px 1px 18px 18px !important",
                    border: "1px solid",
                    backgroundColor:
                      selectedTheme !== "dark"
                        ? "rgba(0, 0, 0, 0.4) !important"
                        : "rgba(255, 255, 255, 0.25) !important",
                    borderColor: "transparent",
                    transition: "all 0.3s ease-in-out",
                    marginRight: "4px !important",
                    "&:hover": {
                      backgroundColor:
                        selectedTheme !== "dark"
                          ? "b185f2 !important"
                          : "a064fd !important",
                    },
                  },
                  ".seat--reserved": {
                    backgroundColor:
                      selectedTheme !== "dark"
                        ? "rgba(0, 0, 0, 0.02) !important"
                        : "rgba(255, 255, 255, 0.06) !important",
                    color:
                      selectedTheme !== "dark"
                        ? "rgba(0, 0, 0, 0.2) !important"
                        : "rgba(255, 255, 255, 0.35) !important",
                    borderColor:
                      selectedTheme !== "dark"
                        ? "rgba(0, 0, 0, 0.04) !important"
                        : "rgba(255, 255, 255, 0.07) !important",
                    "&:hover": {
                      backgroundColor:
                        selectedTheme !== "dark"
                          ? "rgba(0, 0, 0, 0.02) !important"
                          : "rgba(255, 255, 255, 0.06) !important",
                    },
                  },

                  ".seat--selected": {
                    cursor: "pointer !important",
                    backgroundColor: "#944dff !important",
                  },
                  ".seat-picker": {
                    margin: "auto !important",
                    paddingBottom: "20px",
                  },
                  ".seat-content": {
                    overflowX: "auto !important",
                    width: "100% !important",
                  },
                  ".seat-picker__row--enabled:hover, .seat-picker__row--selected":
                    {
                      backgroundColor: "transparent !important",
                    },
                  ".seat-picker > *:not(:last-child)": {
                    marginBottom: "10px",
                  },
                  ".seat-picker__row__number": {
                    color:
                      selectedTheme === "dark"
                        ? "white !important"
                        : "black !important",
                    fontWeight: "bold",
                    width: "50px",
                  },
                  ".seat-row": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
              />
              <SnackbarProvider
                preventDuplicate
                maxSnack={3}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                autoHideDuration={5000}
              >
                <QvrseProvider>
                  <UI.AppBar />
                  <Box sx={{ flex: "1" }}>
                    <Switch>
                      <Route path="/qvrse" component={Qvrse} />
                      <Route
                        path="/qr-ticket-scan/:id/:salt"
                        component={QrTicketScan}
                      />
                      <Route path="/brand" component={DAO} />
                      <Route path="/collection" component={Collections} />
                      <Route path="/profile" component={Profile} />
                      <Route path="/" component={Explore} />

                      <Redirect from="*" to="/" />
                    </Switch>
                  </Box>
                  <Box sx={{ pt: "50px" }}>
                    <Box
                      sx={{
                        display: "none",
                        width: "50px",
                        margin: "0 auto",
                      }}
                    >
                      <LanguageSwitcher />
                    </Box>
                    <Box sx={{ marginTop: "auto", width: "100%" }}>
                      <UI.Footer />
                    </Box>
                  </Box>
                </QvrseProvider>
              </SnackbarProvider>
            </Box>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </RainbowKitProvider>
    </QueryClientProvider>
  );
};

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Suspense
      fallback={
        <Box
          sx={{
            backgroundColor: "#0C0C16 !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
          }}
        >
          <img src={qvrseDark} alt="qvrse-logo" width="200px" />
        </Box>
      }
    >
      <BrowserRouter basename={""}>
        <WagmiConfig config={config}>
          <UserProvider>
            <App />
          </UserProvider>
        </WagmiConfig>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();
