import React from "react";
import MobileMenu from "../../@components/UI/appBar/MobileMenu";
import RegisterDialog from "../../profile/dialogs/RegisterDialog";
import EmailConfirmationDialog from "../../profile/dialogs/EmailConfirmation";
import LogInDialog from "../../profile/dialogs/LogInDialog";
import NoExistingAccountDialog from "../../profile/dialogs/NoExistingAccountDialog";
import PasswordResetDialog from "../../profile/dialogs/ResetPasswordDialog";
import LoginWithWalletDialog from "../../profile/dialogs/LogInWithWalletDialog";

const MobileSlideMenu = ({ setTriggerMenuOpen, triggerMenuOpen }) => {
  const [triggerRegisterDialog, setTriggerRegisterDialog] =
    React.useState(null);
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const [triggerPasswordResetDialog, setTriggerPasswordResetDialog] =
    React.useState(null);
  const [noExistingAccountDialog, setNoExistingAccountDialog] =
    React.useState(null);
  const [triggerEmailConfirmationDialog, setTriggerEmailConfirmation] =
    React.useState(null);
  const [showLoginWithWalletDialog, setShowLoginWithWalletDialog] =
    React.useState(null);
  return (
    <>
      <MobileMenu
        triggerMenuOpen={triggerMenuOpen}
        onLogin={() => {
          setTriggerMenuOpen(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
        onRegister={() => setTriggerRegisterDialog(Math.random())}
      />
      <RegisterDialog
        toggleOpen={triggerRegisterDialog}
        onRegister={() => setTriggerEmailConfirmation(Math.random())}
        showLogInDialog={() => {
          setTriggerRegisterDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
      />
      <EmailConfirmationDialog toggleOpen={triggerEmailConfirmationDialog} />
      <LogInDialog
        toggleOpen={triggerLoginDialog}
        showNoExistingAccountError={() => {
          setTriggerLoginDialog(Math.random());
          setNoExistingAccountDialog(Math.random());
        }}
        showLoginWithWalletDialog={() => {
          setTriggerLoginDialog(Math.random());
          setShowLoginWithWalletDialog(true);
        }}
        showCreateAccountDialog={() => {
          setTriggerLoginDialog(Math.random());
          setTriggerRegisterDialog(Math.random());
        }}
        showPasswordResetDialog={() => {
          setTriggerLoginDialog(Math.random());
          setTriggerPasswordResetDialog(Math.random());
        }}
      />
      <NoExistingAccountDialog toggleOpen={noExistingAccountDialog} />
      <PasswordResetDialog toggleOpen={triggerPasswordResetDialog} />
      <LoginWithWalletDialog
        open={showLoginWithWalletDialog}
        onClose={() => setShowLoginWithWalletDialog(false)}
        onSuccess={() => {
          setShowLoginWithWalletDialog(false);
        }}
        showLogInDialog={() => {
          setShowLoginWithWalletDialog(false);
          setTriggerLoginDialog(Math.random());
        }}
        onNoExistingAccountError={() => {
          setShowLoginWithWalletDialog(false);
          setNoExistingAccountDialog(Math.random());
        }}
      />
    </>
  );
};

export default MobileSlideMenu;
