import React from "react";
import { useSnackbar } from "notistack";
import useService from "./useService";
import { Controller, useForm } from "react-hook-form";
import CustomHooks from "../../../../@components/hooks";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
  inputOuter: {
    marginTop: "15px",
    marginBottom: "0px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const PaypalDialog = ({ DAO, open, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const { mutate: saveSettings } = CustomHooks.usePost(service.save, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.settings.payment.paypalDialog.snackbar"), {
        variant: "success",
      });
      onSuccess();
    },
  });

  const onSubmit = async (form) => {
    saveSettings(form);
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          position: "relative",
          width: "800px",
          p: { md: "30px", mobile: "0px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={(e) => {
        onClose();
      }}
      open={open}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogTitle
        sx={{
          p: { md: "8px 24px 0px", mobile: "28px 24px 10px" },
          fontSize: "24px !important",
        }}
      >
        {t("brands.settings.payment.paypalDialog.title")}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box className={classes.inputOuter}>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <Typography variant={"h4"}>
                  {t("brands.settings.payment.paypalDialog.clientId")}
                </Typography>
              </Box>

              <Controller
                name={"payment.paypal.public_key"}
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <UI.TextField {...field} />
                )}
              />
              {errors.payment?.paypal?.public_key?.type === "required" && (
                <Box className={classes.description} sx={{ mt: "15px" }}>
                  <Alert
                    variant="outlined"
                    severity="error"
                  >
                    {t("brands.settings.payment.paypalDialog.idIsRequired")}
                  </Alert>
                </Box>
              )}
            </Box>
            <Box className={classes.inputOuter}>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <Typography variant={"h4"}>
                  {t("brands.settings.payment.paypalDialog.clientSecret")}
                </Typography>
              </Box>

              <Controller
                name={"payment.paypal.secret_key"}
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <UI.TextField {...field} />
                )}
              />
              {errors.payment?.paypal?.secret_key?.type === "required" && (
                <Box className={classes.description} sx={{ mt: "15px" }}>
                  <Alert
                    variant="outlined"
                    severity="error"
                  >
                    {t("brands.settings.payment.paypalDialog.secretIsRequired")}
                  </Alert>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              mt: { md: "50px", mobile: "10px" },
              display: "flex",
              justifyContent: "center",
              mb: { md: "0px", mobile: "14px" },
            }}
          >
            <Box sx={{ width: "200px", mt: { md: "0px", mobile: "20px" } }}>
              <UI.Button title={t("buttons.saveChanges")} type={"primary"} />
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Action = ({ element: Component, DAO, onSuccess }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box
        component={"span"}
        onClick={(e) => {
          setOpen(!open);
        }}
      >
        {Component}
      </Box>

      <PaypalDialog
        DAO={DAO}
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={(e) => {
          onSuccess();
          setOpen(false);
        }}
      />
    </>
  );
};

PaypalDialog.Action = Action;

export default PaypalDialog;
