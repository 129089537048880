import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LogoutIcon from "@mui/icons-material/Logout";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LoginIcon from "@mui/icons-material/Login";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import HomeIcon from "@mui/icons-material/Home";
import CreateIcon from "@mui/icons-material/Create";
import collectionIcon from "../../../static/collection-icon.png";
import collectionIconDark from "../../../static/collection-icon-dark.png";
import qvrseIcon from "../../../static/qvrse-white-logo.png";
import qvrseIconDark from "../../../static/qvrse-dark-logo.png";
import Alert from "@mui/material/Alert";
import MobileSidebar from "../MobileSidebar";
import { useUserContext } from "../../userContext";
import { useHistory, useLocation } from "react-router-dom";
import Auth from "../../auth";
import { useTranslation } from "react-i18next";
import ConnectWallet from "../connectWallet";
import ConnectWalletWithAccountButton from "./ConnectWalletToAccountButton";
import Presale from "./Presale";
import { useTheme } from "@mui/material/styles";

const MobileMenu = ({ triggerMenuOpen, onLogin, onRegister }) => {
  const { t } = useTranslation();
  const { logout } = Auth.useAuth();
  const { user: loggedInUser } = useUserContext();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();

  return (
    <MobileSidebar toggleMenuOpen={triggerMenuOpen}>
      <Box sx={{ mt: "10px" }}>
        <Box sx={{ p: "0 10px" }}>
          <Box
            onClick={() => {
              history.push({
                pathname: "/",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              display: "flex",
              padding: "10px",
              borderRadius: (theme) => theme.borderRadius.secondary,
              border: location.pathname === "/" ? `1px solid #944dff` : "unset",
              backgroundColor: (theme) =>
                location.pathname === "/"
                  ? theme.palette.bgTransparent.primary
                  : "unset",
              transition: "all 0.2s ease.in-out",
              boxShadow:
                location.pathname === "/" ? `#944dff 0px 0px 5px 0px` : "unset",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mr: "8px",
              }}
            >
              <HomeIcon sx={{ fontSize: "1.35rem" }} />
            </Box>
            <Typography variant={"h8"}>{t("appBar.home")}</Typography>
          </Box>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <Box
            onClick={() => {
              history.push({
                pathname: "/brand/explore",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              display: "flex",
              padding: "10px",
              borderRadius: (theme) => theme.borderRadius.secondary,
              transition: "all 0.2s ease.in-out",
              backgroundColor: (theme) =>
                location.pathname.startsWith("/brand/explore")
                  ? theme.palette.bgTransparent.primary
                  : "unset",
              border: location.pathname.startsWith("/brand/explore")
                ? `1px solid #944dff`
                : "unset",
              boxShadow: location.pathname.startsWith("/brand/explore")
                ? `#944dff 0px 0px 5px 0px`
                : "unset",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mr: "8px",
              }}
            >
              <WorkspacesIcon sx={{ fontSize: "1.35rem" }} />
            </Box>
            <Typography variant={"h8"}>{t("appBar.brands")}</Typography>
          </Box>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <Box
            onClick={() => {
              history.push({
                pathname: "/collection/explore",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              display: "flex",
              padding: "10px",
              borderRadius: (theme) => theme.borderRadius.secondary,
              transition: "all 0.2s ease.in-out",
              backgroundColor: (theme) =>
                location.pathname.startsWith("/collection/explore")
                  ? theme.palette.bgTransparent.primary
                  : "unset",
              border: location.pathname.startsWith("/collection/explore")
                ? `1px solid #944dff`
                : "unset",
              boxShadow: location.pathname.startsWith("/collection/explore")
                ? `#944dff 0px 0px 5px 0px`
                : "unset",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "8px",
                mb: "2px",
              }}
            >
              {theme.palette.type === "dark" ? (
                <Box
                  component={"img"}
                  src={collectionIcon}
                  sx={{ width: "21px" }}
                />
              ) : (
                <Box
                  component={"img"}
                  src={collectionIconDark}
                  sx={{ width: "21px" }}
                />
              )}
            </Box>
            <Typography variant={"h8"}>{t("appBar.collections")}</Typography>
          </Box>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <Box
            onClick={() => {
              history.push({
                pathname: "/brand/apply",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              display: "flex",
              padding: "10px",
              borderRadius: (theme) => theme.borderRadius.secondary,
              transition: "all 0.2s ease.in-out",
              backgroundColor: (theme) =>
                location.pathname.startsWith("/brand/apply")
                  ? theme.palette.bgTransparent.primary
                  : "unset",
              border: location.pathname.startsWith("/brand/apply")
                ? `1px solid #944dff`
                : "unset",
              boxShadow: location.pathname.startsWith("/brand/apply")
                ? `#944dff 0px 0px 5px 0px`
                : "unset",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mr: "8px",
              }}
            >
              <CreateIcon sx={{ fontSize: "1.35rem" }} />
            </Box>
            <Typography variant={"h8"}>{t("appBar.applyYourBrand")}</Typography>
          </Box>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              borderRadius: (theme) => theme.borderRadius.secondary,
              transition: "all 0.2s ease.in-out",
              backgroundColor: (theme) =>
                location.pathname.startsWith("/qvrse")
                  ? theme.palette.bgTransparent.primary
                  : "unset",
              border: location.pathname.startsWith("/qvrse")
                ? `1px solid #944dff`
                : "unset",
              boxShadow: location.pathname.startsWith("/qvrse")
                ? `#944dff 0px 0px 5px 0px`
                : "unset",
            }}
            onClick={() => {
              history.push({
                pathname: "/qvrse",
                state: { scrollToTop: true },
              });
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "9px",
                mb: "2px",
              }}
            >
              {theme.palette.type === "dark" ? (
                <Box component={"img"} src={qvrseIcon} sx={{ width: "21px" }} />
              ) : (
                <Box
                  component={"img"}
                  src={qvrseIconDark}
                  sx={{ width: "21px" }}
                />
              )}
            </Box>
            <Typography variant={"h8"}>QVRSE DAO</Typography>
          </Box>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <a
            href="https://about.qvrse.io/presale/"
            className="btn btn-link px-0 d-lg-none d-inline-flex ms-5"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "8px",
                  mb: "2px",
                  mt: "2px",
                  width: "21px",
                }}
              >
                <Link
                  target="_blank"
                  to={{ pathname: "https://about.qvrse.io/presale/" }}
                  rel="noreferrer"
                >
                  <Presale width={60} height={60} />
                </Link>
              </Box>
              <Typography variant={"h8"}>{t("actions.joinPresale")}</Typography>
            </Box>
          </a>
        </Box>
        <Box sx={{ p: "0 10px" }}>
          <Box
            sx={{
              ml: "35px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          ></Box>
        </Box>

        {loggedInUser && (
          <Box>
            <Divider
              sx={{
                borderColor: (theme) => theme.palette.bgTransparent.primary,
                m: "17px 16px 20px",
                borderWidth: "0.5px",
              }}
            />
            <Box sx={{ p: "0 10px" }}>
              <Box
                onClick={() => {
                  history.push({
                    pathname: "/profile",
                    state: { scrollToTop: true },
                  });
                }}
                sx={{
                  display: "flex",
                  padding: "10px",
                  borderRadius: (theme) => theme.borderRadius.secondary,
                  transition: "all 0.2s ease.in-out",
                  backgroundColor: (theme) =>
                    location.pathname.startsWith("/profile")
                      ? theme.palette.bgTransparent.primary
                      : "unset",
                  border: location.pathname.startsWith("/profile")
                    ? `1px solid #944dff`
                    : "unset",
                  boxShadow: location.pathname.startsWith("/profile")
                    ? `#944dff 0px 0px 5px 0px`
                    : "unset",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mr: "8px",
                  }}
                >
                  <Badge
                    badgeContent={"!"}
                    overlap="circular"
                    color="error"
                    invisible={loggedInUser?.email_verified}
                  >
                    <Avatar
                      sx={{ width: "24px", height: "24px" }}
                      src={loggedInUser?.avatar_url}
                      alt={"avatar"}
                    />
                  </Badge>
                </Box>
                <Typography variant={"h8"}>{t("appBar.profile")}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        {loggedInUser && (
          <Box sx={{ p: "0 10px" }}>
            <Box
              onClick={() => {
                history.push({
                  pathname: "/profile",
                  state: { scrollToTop: true },
                });
              }}
              sx={{
                display: "flex",
                padding: "10px",
                border: location.pathname.startsWith("/profile")
                  ? `unset`
                  : "unset",
                boxShadow: location.pathname.startsWith("/profile")
                  ? `unset`
                  : "unset",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: "8px",
                  mb: "2px",
                }}
              >
                {theme.palette.type === "dark" ? (
                  <Box
                    component={"img"}
                    src={collectionIcon}
                    sx={{ width: "21px" }}
                  />
                ) : (
                  <Box
                    component={"img"}
                    src={collectionIconDark}
                    sx={{ width: "21px" }}
                  />
                )}
              </Box>
              <Typography variant={"h8"}>{t("appBar.myNFTs")}</Typography>
            </Box>
          </Box>
        )}
        {loggedInUser && (
          <Box>
            <Box
              sx={{
                p: "0 10px",
                background: (theme) => theme.palette.bgTransparent.secondary,
                borderRadius: (theme) => theme.borderRadius.secondary,
                m: "24px 30px 2px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {loggedInUser.address && <ConnectWallet />}
              {loggedInUser.address === "" && (
                <ConnectWalletWithAccountButton>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: "8px",
                      }}
                    >
                      <AccountBalanceWalletIcon
                        sx={{
                          color: (theme) =>
                            theme.palette.text.primary + "!important",
                          fontSize: "1.35rem",
                        }}
                      />
                    </Box>
                    <Typography
                      variant={"h6"}
                      sx={{ color: (theme) => theme.palette.text.primary }}
                    >
                      {t("appBar.connectWallet")}
                    </Typography>
                  </Box>
                </ConnectWalletWithAccountButton>
              )}
            </Box>
          </Box>
        )}
        {loggedInUser && (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              logout();
            }}
            sx={{
              p: "0 10px",
              background: (theme) => theme.palette.colors.primary,
              borderRadius: (theme) => theme.borderRadius.secondary,
              m: "2px 30px",
              mt: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mr: "8px",
                }}
              >
                <LogoutIcon sx={{ color: "white", fontSize: "1.35rem" }} />
              </Box>
              <Typography variant={"h6"} sx={{ color: "white" }}>
                {t("appBar.logout")}
              </Typography>
            </Box>
          </Box>
        )}
        {!loggedInUser && (
          <Box>
            <Divider
              sx={{
                borderColor: (theme) => theme.palette.bgTransparent.primary,
                m: "17px 16px 20px",
                borderWidth: "0.5px",
              }}
            />
            <Box
              onClick={() => {
                onLogin();
              }}
              sx={{
                p: "0 10px",
                background: (theme) => theme.palette.bgTransparent.secondary,
                borderRadius: (theme) => theme.borderRadius.secondary,
                m: "2px 30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "10px",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mr: "8px",
                  }}
                >
                  <LoginIcon
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                      fontSize: "1.35rem",
                    }}
                  />
                </Box>
                <Typography
                  variant={"h6"}
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  {t("appBar.logIn")}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {!loggedInUser && (
          <Box
            onClick={() => {
              onRegister();
            }}
            sx={{
              p: "0 10px",
              background: (theme) => theme.palette.colors.primary,
              borderRadius: (theme) => theme.borderRadius.secondary,
              m: "2px 30px",
              mt: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mr: "8px",
                }}
              >
                <HowToRegIcon sx={{ color: "white", fontSize: "1.35rem" }} />
              </Box>
              <Typography variant={"h6"} sx={{ color: "white" }}>
                {t("appBar.createAccount")}
              </Typography>
            </Box>
          </Box>
        )}
        {loggedInUser && !loggedInUser?.email_verified && (
          <Box
            sx={{
              p: "0 18px",
              mt: "15px",
            }}
          >
            <Alert variant="outlined" severity="info">
              {t("appBar.emailMessage")}
              <Link
                onClick={() => history.push(`/profile/settings`)}
                sx={{
                  color: (theme) => theme.palette.text.linkBlue,
                  textDecorationColor: (theme) => theme.palette.text.linkBlue,
                  ml: "5px",
                  "&:hover": {
                    color: (theme) => theme.palette.text.linkBlueHover,
                    textDecorationColor: (theme) =>
                      theme.palette.text.linkBlueHover,
                    transition: "all 0.3s ease-in-out",
                  },
                }}
              >
                {t("appBar.goToSettings")}
              </Link>
            </Alert>
          </Box>
        )}
      </Box>
    </MobileSidebar>
  );
};

export default MobileMenu;
