import React from "react";
import { Controller, useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import { useFormDataContext } from "../context";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import { makeStyles } from "@mui/styles";
import { useAccount } from "wagmi";
import { useUserContext } from "../../../../@components/userContext";
import Alert from "@mui/material/Alert";
import RegisterDialog from "../../../../profile/dialogs/RegisterDialog";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import InputAdornment from "@mui/material/InputAdornment";
import linkedInIcon from "../../../../static/linkedin.png";
import discordIcon from "../../../../static/discord.png";
import twitterIcon from "../../../../static/twitter.png";
import telegramIcon from "../../../../static/telegram.png";
import instagramIcon from "../../../../static/instagram.png";
import facebookIcon from "../../../../static/facebook.png";
import youtubeIcon from "../../../../static/youtube-icon.png";
import spotifyIcon from "../../../../static/spotify-icon.png";
import { useTranslation } from "react-i18next";
import LogInDialogFlow from "../../../../profile/dialogs/LogInDialogFlow";
import EmailConfirmationDialog from "../../../../profile/dialogs/EmailConfirmation";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: { md: "20px", mobile: "0px" },
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Index = ({ nextStep, draft, onSave }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user: loggedInUser } = useUserContext();
  const { openConnectModal } = useConnectModal();
  const { formData, setFormData } = useFormDataContext();
  const { isConnected } = useAccount();
  const [triggerRegisterDialog, setTriggerRegisterDialog] =
    React.useState(null);
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const [triggerEmailConfirmationDialog, setTriggerEmailConfirmation] =
    React.useState(null);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      draft_id: formData?.draft_id,
      avatar_url: formData?.avatar_url,
      name: formData?.name,
      company_name: formData?.company_name,
      city: formData?.city,
      zip_code: formData?.zip_code,
      description: formData?.description,
      webpage_url: formData?.webpage_url,
      whitepaper_url: formData?.whitepaper_url,
      business_address: formData?.business_address,
      vat_number: formData?.vat_number,
      country_of_incorporation: formData?.country_of_incorporation,
      identification_number: formData?.identification_number,
      is_vat_registered: formData?.is_vat_registered,
      socials: formData?.socials,
      token_type: formData?.token_type,
      quorum: formData?.quorum,
      voting_delay: formData?.voting_delay,
      voting_period: formData?.voting_period,
      timelock_delay: formData?.timelock_delay,
      token_icon_url: formData?.token_icon_url,
      token_name: formData?.token_name,
      token_symbol: formData?.token_symbol,
      token_supply: formData?.token_supply,
    },
    mode: "onChange",
  });

  const generateTokenSymbol = (brandName) => {
    const cleanedBrandName = brandName.replace(/\s+/g, " "); // Replace multiple spaces with a single space

    return cleanedBrandName
      .split(" ")
      .map((word) => word[0].toUpperCase())
      .join("");
  };

  React.useEffect(() => {
    if (!!draft) {
      setFormData(draft.data);
      reset(draft.data);
    }
    //eslint-disable-next-line
  }, [draft]);

  const onSubmit = async (data, action) => {
    const values = {
      ...data,
      // set utilities values
      token_name: `${data.name} Governance Token`,
      token_symbol: generateTokenSymbol(`${data.name} Governance Token`),
    };

    switch (action) {
      case "save_and_continue": {
        if (isDirty) {
          try {
            await onSave(values, false);
            nextStep();
          } catch (e) {}
        } else {
          !isDirty && nextStep();
        }

        break;
      }
      case "save": {
        await onSave(values, true);
        break;
      }
      default: {
        await onSave(values, true);
        break;
      }
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit((values) =>
          onSubmit(values, "save_and_continue")
        )}
      >
        <Box
          sx={{
            width: "100%",
            px: { md: "100px", mobile: "10px" },
          }}
        >
          <Typography
            variant={"subtitle2"}
            sx={{ mt: "50px", textAlign: "left", opacity: 1 }}
          >
            {t("applyYourBrand.basicInfo.surtitle")}
          </Typography>
        </Box>
        {loggedInUser && loggedInUser?.email_verified && isConnected ? (
          <Box sx={{ p: { md: "40px 100px", mobile: "50px 10px 60px" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                mb: { md: "57px", mobile: "40px" },
              }}
            >
              <Typography variant={"h2"}>
                {t("applyYourBrand.basicInfo.title")}
              </Typography>
            </Box>
            <Box>
              <Controller
                name={"avatar_url"}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UI.Upload
                    noHeight
                    noPadding
                    showAvatarPreview
                    defaultValue={formData?.avatar_url}
                    {...field}
                    id={"profile"}
                    title={t("applyYourBrand.basicInfo.upload.title")}
                    subtitle={t("applyYourBrand.basicInfo.upload.subtitle")}
                    acceptFiles={"image/jpeg,image/gif,image/png"}
                  />
                )}
              />
              <Box sx={{ mt: "15px" }}>
                {errors?.avatar_url?.type === "required" && (
                  <Alert
                    variant="outlined"
                    severity="error"
                  >
                    {t("applyYourBrand.basicInfo.upload.error.required")}
                  </Alert>
                )}
              </Box>
            </Box>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid
                item
                md={12}
                mobile={12}
                sx={{
                  mt: { md: "24px", mobile: "18px" },
                  pt: "0px !important",
                }}
              >
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography variant={"h2"}>
                      {t("applyYourBrand.basicInfo.formInputs.brandName.title")}
                    </Typography>
                  </Box>

                  <Controller
                    name={"name"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <UI.TextField {...field} />}
                  />
                  <Box sx={{ mt: "15px" }}>
                    {errors?.name?.type === "required" && (
                      <Alert
                        variant="outlined"
                        severity="error"
                      >
                        {t(
                          "applyYourBrand.basicInfo.formInputs.brandName.error.required"
                        )}
                      </Alert>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                mobile={12}
                sx={{ mt: { md: "30px", mobile: "18px" } }}
              >
                <Paper
                  sx={{
                    p: { md: "40px 50px", mobile: "35px 10px" },
                    backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                  }}
                >
                  <Box
                    sx={{ display: "flex", mb: { md: "40px", mobile: "35px" } }}
                  >
                    <Typography variant={"h2"} sx={{ textAlign: "left" }}>
                      {t("applyYourBrand.basicInfo.section1.title")}
                    </Typography>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {t(
                          "applyYourBrand.basicInfo.formInputs.businessName.title"
                        )}
                      </Typography>
                    </Box>

                    <Controller
                      name={"company_name"}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => <UI.TextField {...field} />}
                    />
                    <Box sx={{ mt: "15px" }}>
                      {errors?.company_name?.type === "required" && (
                        <Alert
                          variant="outlined"
                          severity="error"
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.businessName.error.required"
                          )}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {t(
                          "applyYourBrand.basicInfo.formInputs.businessAddress.title"
                        )}
                      </Typography>
                    </Box>

                    <Controller
                      name={"business_address"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => <UI.TextField {...field} />}
                    />
                    <Box sx={{ mt: "15px" }}>
                      {errors?.business_address?.type === "required" && (
                        <Alert
                          variant="outlined"
                          severity="error"
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.businessAddress.error.required"
                          )}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {t("applyYourBrand.basicInfo.formInputs.city.title")}
                      </Typography>
                    </Box>

                    <Controller
                      name={"city"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => <UI.TextField {...field} />}
                    />
                    <Box sx={{ mt: "15px" }}>
                      {errors?.city?.type === "required" && (
                        <Alert
                          variant="outlined"
                          severity="error"
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.city.error.required"
                          )}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {t(
                          "applyYourBrand.basicInfo.formInputs.postalCode.title"
                        )}
                      </Typography>
                    </Box>

                    <Controller
                      name={"zip_code"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => <UI.TextField {...field} />}
                    />
                    <Box sx={{ mt: "15px" }}>
                      {errors?.zip_code?.type === "required" && (
                        <Alert
                          variant="outlined"
                          severity="error"
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.postalCode.error.required"
                          )}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {t(
                          "applyYourBrand.basicInfo.formInputs.vatNumber.title"
                        )}
                      </Typography>
                    </Box>

                    <Controller
                      name={"vat_number"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => <UI.TextField {...field} />}
                    />
                    <Box sx={{ mt: "15px" }}>
                      {errors?.vat_number?.type === "required" && (
                        <Alert
                          variant="outlined"
                          severity="error"
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.vatNumber.error.required"
                          )}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {t("applyYourBrand.basicInfo.formInputs.country.title")}
                      </Typography>
                    </Box>

                    {formData.country_of_incorporation && (
                      <Controller
                        name={"country_of_incorporation"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <UI.CountrySelect
                            {...field}
                            defaultValue={formData.country_of_incorporation}
                          />
                        )}
                      />
                    )}
                    <Box sx={{ mt: "15px" }}>
                      {errors?.country_of_incorporation?.type ===
                        "required" && (
                        <Alert
                          variant="outlined"
                          severity="error"
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.country.error.required"
                          )}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {t(
                          "applyYourBrand.basicInfo.formInputs.identificationNumber.title"
                        )}
                      </Typography>
                    </Box>

                    <Controller
                      name={"identification_number"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => <UI.TextField {...field} />}
                    />
                    <Box sx={{ mt: "15px" }}>
                      {errors?.identification_number?.type === "required" && (
                        <Alert
                          variant="outlined"
                          severity="error"
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.identificationNumber.error.required"
                          )}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Controller
                        name={"is_vat_registered"}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <UI.Checkbox
                            {...field}
                            nomargin={"true"}
                            label={
                              <Box
                                component={"span"}
                                sx={{ fontWeight: "bold" }}
                              >
                                {t(
                                  "applyYourBrand.basicInfo.formInputs.vat.title"
                                )}
                              </Box>
                            }
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Paper>

                <Paper
                  sx={{
                    mt: "50px",
                    p: { md: "40px 50px", mobile: "35px 10px" },
                    backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                  }}
                >
                  <Box
                    sx={{ display: "flex", mb: { md: "40px", mobile: "35px" } }}
                  >
                    <Typography variant={"h2"} sx={{ textAlign: "left" }}>
                      {t("applyYourBrand.basicInfo.section2.title")}
                    </Typography>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {" "}
                        {t(
                          "applyYourBrand.basicInfo.formInputs.brandDescription.title"
                        )}
                      </Typography>
                      <UI.Tooltip>
                        {t(
                          "applyYourBrand.basicInfo.formInputs.brandDescription.tooltip"
                        )}
                      </UI.Tooltip>
                    </Box>

                    <Controller
                      name={"description"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <UI.TextField multiline rows={4} {...field} />
                      )}
                    />
                    <Box sx={{ mt: "15px" }}>
                      {errors?.description?.type === "required" && (
                        <Alert
                          variant="outlined"
                          severity="error"
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.brandDescription.error.required"
                          )}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {t(
                          "applyYourBrand.basicInfo.formInputs.websiteLink.title"
                        )}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant={"subtitle3"}
                          sx={{
                            ml: "6px",
                            textTransform: "lowercase",
                          }}
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.websiteLink.subtitle"
                          )}
                        </Typography>
                      </Box>
                    </Box>

                    <Controller
                      name={"webpage_url"}
                      rules={{ required: false }}
                      control={control}
                      render={({ field }) => <UI.TextField {...field} />}
                    />
                  </Box>

                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>
                        {t(
                          "applyYourBrand.basicInfo.formInputs.document.title"
                        )}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant={"subtitle3"}
                          sx={{
                            ml: "6px",
                            textTransform: "lowercase",

                          }}
                        >
                          {t(
                            "applyYourBrand.basicInfo.formInputs.document.subtitle"
                          )}
                        </Typography>
                      </Box>
                      <UI.Tooltip>
                        {t(
                          "applyYourBrand.basicInfo.formInputs.document.tooltip"
                        )}
                      </UI.Tooltip>
                    </Box>

                    <Box
                      sx={{ mt: { md: "33px", mobile: "10px" }, mb: "10px" }}
                    >
                      <Controller
                        name={"whitepaper_url"}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <UI.Upload
                            noHeight
                            noPadding
                            defaultValue={formData?.whitepaper_url}
                            {...field}
                            id={"attachments"}
                            title={t(
                              "applyYourBrand.basicInfo.formInputs.whitepaperUrl.title"
                            )}
                            subtitle={t(
                              "applyYourBrand.basicInfo.formInputs.whitepaperUrl.subtitle"
                            )}
                            acceptFiles={"application/pdf"}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Paper>

                <Paper
                  sx={{
                    mt: "50px",
                    p: { md: "40px 50px", mobile: "35px 10px" },
                    backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                  }}
                >
                  <Box
                    sx={{ display: "flex", mb: { md: "40px", mobile: "35px" } }}
                  >
                    <Typography variant={"h2"} sx={{ textAlign: "left" }}>
                      {t("applyYourBrand.basicInfo.section3.title")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant={"subtitle3"}
                        sx={{
                          ml: "6px",
                          textTransform: "lowercase",
                        }}
                      >
                        {t("applyYourBrand.basicInfo.section3.subtitle")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>LinkedIn</Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: "15px" }}>
                      <Controller
                        name={"socials.linkedin"}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component={"img"}
                                  src={linkedInIcon}
                                  alt="linkedin-icon"
                                  width={"35px"}
                                  height={"35px"}
                                  sx={{ mr: "3px" }}
                                />
                              </InputAdornment>
                            }
                            {...field}
                            start
                            placeholder={"URL"}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>Discord</Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: "15px" }}>
                      <Controller
                        name={"socials.discord"}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            {...field}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component={"img"}
                                  src={discordIcon}
                                  alt="discord-icon"
                                  width={"35px"}
                                  height={"35px"}
                                  sx={{ mr: "3px" }}
                                />
                              </InputAdornment>
                            }
                            placeholder={"URL"}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>Twitter</Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: "15px" }}>
                      <Controller
                        name={"socials.twitter"}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            {...field}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component={"img"}
                                  src={twitterIcon}
                                  alt="twitter-icon"
                                  width={"35px"}
                                  height={"35px"}
                                  sx={{ mr: "3px" }}
                                />
                              </InputAdornment>
                            }
                            placeholder={"URL"}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>Telegram</Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: "15px" }}>
                      <Controller
                        name={"socials.telegram"}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            {...field}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component={"img"}
                                  src={telegramIcon}
                                  alt="telegram-icon"
                                  width={"35px"}
                                  height={"35px"}
                                  sx={{ mr: "3px" }}
                                />
                              </InputAdornment>
                            }
                            placeholder={"URL"}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>Instagram</Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: "15px" }}>
                      <Controller
                        name={"socials.instagram"}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            {...field}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component={"img"}
                                  src={instagramIcon}
                                  alt="instagram-icon"
                                  width={"35px"}
                                  height={"35px"}
                                  sx={{ mr: "3px" }}
                                />
                              </InputAdornment>
                            }
                            placeholder={"URL"}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>Facebook</Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: "15px" }}>
                      <Controller
                        name={"socials.facebook"}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            {...field}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component={"img"}
                                  src={facebookIcon}
                                  alt="facebook-icon"
                                  width={"35px"}
                                  height={"35px"}
                                  sx={{ mr: "3px" }}
                                />
                              </InputAdornment>
                            }
                            placeholder={"URL"}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>Youtube</Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: "15px" }}>
                      <Controller
                        name={"socials.youtube"}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            {...field}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component={"img"}
                                  src={youtubeIcon}
                                  alt="facebook-icon"
                                  width={"35px"}
                                  height={"35px"}
                                  sx={{ mr: "3px" }}
                                />
                              </InputAdornment>
                            }
                            placeholder={"URL"}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.inputOuter}>
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography variant={"h4"}>Spotify</Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: "15px" }}>
                      <Controller
                        name={"socials.spotify"}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            {...field}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component={"img"}
                                  src={spotifyIcon}
                                  alt="facebook-icon"
                                  width={"35px"}
                                  height={"35px"}
                                  sx={{ mr: "3px" }}
                                />
                              </InputAdornment>
                            }
                            placeholder={"URL"}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Box
                sx={{
                  width: "100%",
                  mt: { md: "80px", mobile: "60px" },
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "210px" }}>
                    <UI.Button
                      disabled={
                        !!Object.keys(errors).length ||
                        !isConnected ||
                        !loggedInUser?.email_verified
                      }
                      sx={{
                        filter:
                          "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                      }}
                      title={t("buttons.saveAndContinue")}
                      type={"primary"}
                    />
                    <UI.Button
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const allValues = getValues();
                        await onSubmit(allValues, "save");
                      }}
                      disabled={
                        !isDirty ||
                        !isConnected ||
                        !loggedInUser?.email_verified
                      }
                      sx={{
                        mt: "10px",
                        filter:
                          "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                      }}
                      title={t("buttons.saveForLater")}
                      type={"tertiary"}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Typography
              variant={"h2"}
              sx={{
                mt: { md: "66px", mobile: "40px" },
                lineHeight: "1.6 !important",
                fontSize: { md: "20px !important", mobile: "16px !important" },
              }}
            >
              {t("applyYourBrand.basicInfo.logInMessage1")} <br></br>{" "}
              {t("applyYourBrand.basicInfo.logInMessage2")}
            </Typography>

            <Box
              sx={{
                mt: { md: "48px", mobile: "40px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {!loggedInUser?.email_verified && (
                <UI.Button
                  sx={{ width: "190px" }}
                  type={"primary"}
                  title={t("buttons.logIn")}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTriggerLoginDialog(Math.random());
                  }}
                />
              )}
              {!isConnected && loggedInUser?.email_verified && (
                <UI.Button
                  sx={{ width: "240px" }}
                  type={"primary"}
                  title={t("buttons.connectWallet")}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openConnectModal();
                  }}
                />
              )}
              <UI.Button
                sx={{ width: "240px", mt: "15px" }}
                type={"tertiary"}
                title={t("buttons.createAccount")}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setTriggerRegisterDialog(Math.random());
                }}
              />
            </Box>
          </Box>
        )}
      </form>
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />
      <EmailConfirmationDialog toggleOpen={triggerEmailConfirmationDialog} />
      <RegisterDialog
        toggleOpen={triggerRegisterDialog}
        onRegister={() => setTriggerEmailConfirmation(Math.random())}
        showLogInDialog={() => {
          setTriggerRegisterDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
      />
    </>
  );
};

export default Index;
