import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Collections from "./collections";
import About from "./about";
import Social from "./social";
import PaymentOptions from "./paymentOptions";
import CustomHooks from "../../../@components/hooks";
import Link from "@mui/material/Link";
import { useHistory, useLocation } from "react-router-dom";
import EmailRedirectAdmin from "./EmailRedirectAdmin";
import EmailRedirect from "./EmailRedirect";
import EmailRedirectCreatedBrand from "./EmailRedirectCreatedBrand";

import Alert from "@mui/material/Alert";

import { useUserContext } from "../../../@components/userContext";
import LogInDialogFlow from "../../../profile/dialogs/LogInDialogFlow";
import EmailRedirectVote from "./EmailRedirectVote";
import { useTranslation } from "react-i18next";
const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const { user: loggedInUser } = useUserContext();
  const [socialsVisible, setSocialsVisible] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  let query = CustomHooks.useQuery();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (DAO) {
      if (
        DAO?.discord ||
        DAO?.facebook ||
        DAO?.instagram ||
        DAO?.linkedin ||
        DAO?.reddit ||
        DAO?.telegram ||
        DAO?.twitter ||
        DAO?.youtube ||
        DAO?.spotify
      ) {
        setSocialsVisible(true);
      }
      setIsLoading(false);
    }
  }, [DAO]);

  const [showAdminRoleDialog, setShowAdminRoleDialog] = React.useState(false);
  const [showRoleDialog, setShowRoleDialog] = React.useState(false);
  const [showCreatedBrandDialog, setCreatedBrandDialog] = React.useState(false);
  const [showVoteRedirectDialog, setShowVoteRedirectDialog] =
    React.useState(null);
  const [voteRedirectUrl, setVoteRedirectUrl] = React.useState(null);

  React.useEffect(() => {
    const email_redirect_role = query.get("email_redirect_role");

    if (email_redirect_role === "ADMIN") {
      setShowAdminRoleDialog(true);
    }
    if (
      email_redirect_role === "MODERATOR" ||
      email_redirect_role === "TICKET_REDEEMER"
    ) {
      setShowRoleDialog(true);
    }
  }, [query]);

  React.useEffect(() => {
    const email_redirect = query.get("email_redirect");
    if (email_redirect === "created_brand") {
      setCreatedBrandDialog(true);
    }
  }, [query]);

  React.useEffect(() => {
    const is_email_redirect_vote = query.get("email_redirect_vote");
    const redirect_url = query.get("redirect_url");
    if (is_email_redirect_vote && redirect_url) {
      setShowVoteRedirectDialog(true);
      setVoteRedirectUrl(redirect_url);
    }
  }, [query]);

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        pb: { md: "100px", mobile: "50px" },
        background: "transparent",
      }}
    >
      {showAdminRoleDialog && (
        <EmailRedirectAdmin
          onClose={() => {
            setShowAdminRoleDialog(false);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setShowAdminRoleDialog(false);
            history.push(`${location.pathname}/membership/votingPower`);
          }}
        />
      )}
      {showRoleDialog && (
        <EmailRedirect
          onClose={() => {
            setShowRoleDialog(false);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setShowRoleDialog(false);
            history.push(`${location.pathname}`);
          }}
        />
      )}
      {showCreatedBrandDialog && (
        <EmailRedirectCreatedBrand
          onClose={() => {
            setCreatedBrandDialog(false);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setCreatedBrandDialog(false);
            history.push(`${location.pathname}`);
          }}
        />
      )}
      {showVoteRedirectDialog && (
        <EmailRedirectVote
          onClose={() => {
            setShowVoteRedirectDialog(false);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setShowVoteRedirectDialog(false);
            history.push(`${location.pathname}/${voteRedirectUrl}`);
          }}
        />
      )}
      {!isLoading && (
        <Box sx={{ padding: { md: "10px 70px", mobile: "0px 10px" } }}>
          {loggedInUser &&
            DAO?.memberRole === "ADMIN" &&
            DAO?.memberStatus === "ACTIVE" &&
            (DAO?.memberVotingPower === 0 ||
              DAO?.memberVotingPower === null) && (
              <Box
                sx={{
                  mb: "15px",
                  display: { md: "block", mobile: "none" },
                }}
              >
                <Alert
                  variant="outlined"
                  severity="info"
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {t("brands.about.alerts.adminRights1")} {"  "}
                    <Box
                      sx={{
                        ml: "5px",
                        cursor: "pointer",
                        textDecoration: "underline",
                        transition: "all 0.2s ease-in-out",
                        color: (theme) => theme.palette.text.link,
                          "&:hover": {
                            color: (theme) => theme.palette.text.linkHover,
                          },
                      }}
                      onClick={() =>
                        history.push(
                          `${location.pathname}/membership/votingPower`
                        )
                      }
                      component={"span"}
                    >
                      {t("brands.about.alerts.adminRights2")}
                    </Box>
                  </Box>
                </Alert>
              </Box>
            )}
          <Box>
            <Grid container>
              <Grid
                item
                mobile={12}
                md={12}
                sx={{
                  mt: { mobile: "15px", md: "20px" },
                }}
              >
                <Collections DAO={DAO} />
              </Grid>
              <Grid
                item
                mobile={12}
                md={12}
                sx={{
                  mt: { mobile: "35px", md: "0px" },
                }}
              >
                <Typography
                  sx={{
                    mb: "0px",
                    textAlign: "center",
                  }}
                  variant={"h3"}
                >
                  {t("brands.about.title")}
                </Typography>

                <About DAO={DAO} />
              </Grid>
              {socialsVisible && (
                <Grid
                  item
                  mobile={12}
                  md={12}
                  sx={{
                    mt: { mobile: "35px", md: "50px" },
                  }}
                >
                  <Typography
                    sx={{
                      mb: "23px",
                      textAlign: "center",
                    }}
                    variant={"h4"}
                  >
                    {t("brands.about.socials")}
                  </Typography>
                  <Social DAO={DAO} />
                </Grid>
              )}
              {DAO?.webpage_url && (
                <Grid
                  item
                  mobile={12}
                  md={12}
                  sx={{
                    mt: { mobile: "35px", md: "50px" },
                  }}
                >
                  <Typography
                    sx={{
                      mb: "17px",
                      textAlign: "center",
                    }}
                    variant={"h4"}
                  >
                    {t("brands.about.website")}
                  </Typography>
                  <Link
                    target="_blank"
                    href={DAO?.webpage_url}
                    underline="hover"
                  >
                    <Typography
                      sx={{ 
                        textAlign: "center", 
                        cursor: "pointer",
                        textDecoration: "underline",
                        transition: "all 0.2s ease-in-out",
                        color: (theme) => theme.palette.text.link,
                          "&:hover": {
                            color: (theme) => theme.palette.text.linkHover,
                          }, 
                      }}
                      variant={"subtitle2"}
                    >
                      {DAO?.webpage_url}
                    </Typography>
                  </Link>
                </Grid>
              )}
              <Grid
                item
                mobile={12}
                md={12}
                sx={{
                  mt: { mobile: "35px", md: "50px" },
                }}
              >
                <Typography
                  sx={{
                    mb: "23px",
                    textAlign: "center",
                  }}
                  variant={"h4"}
                >
                  {t("brands.about.paymentOptions")}
                </Typography>
                <PaymentOptions DAO={DAO} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />
    </Paper>
  );
};

export default Index;
