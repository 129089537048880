import React from "react";
import Box from "@mui/material/Box";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import { Navigation, Autoplay } from "swiper/modules";

import "./styles.css";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import UI from "../../@components/UI";
import CustomHooks from "../../@components/hooks";
import useDaoService from "../../daos/useService";
import { useHistory } from "react-router-dom";

const ImageItem = ({ item, onClick }) => {
  return (
    <>
      <Box
        onClick={() => onClick(item?.id)}
        sx={{
          width: { xl: "200px", md: "160px", mobile: "200px" },
          height: { xl: "200px", md: "160px", mobile: "200px" },
          borderRadius: "131px",
          transition: "all 0.3s ease-in-out",
          border: "1px solid transparent",
          "&:hover": {
            boxShadow: "0px 0px 5px 2px #944dff",
            border: "1px solid #944DFF",
          },
        }}
      >
        <Box
          component={"img"}
          sx={{ width: "100px", height: "auto", borderRadius: "131px" }}
          src={item.avatar_url}
        />
      </Box>
    </>
  );
};

const SkeletonItem = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "400px",
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "0px",
        background:
          "linear-gradient(rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)",
      }}
    >
      <Skeleton
        variant={"rectangular"}
        sx={{
          inset: "0px",
          boxSizing: "border-box",
          padding: "0px",
          border: "none",
          margin: "auto",
          display: "block",
          width: "0px",
          height: "0px",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};
const Trending = ({ onClick }) => {
  const { t } = useTranslation();
  const daoService = useDaoService();
  const history = useHistory();
  const { data: brands, isLoading: brandsBusy } = CustomHooks.useFetch(
    ["brands"],
    daoService.featured
  );

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: { mobile: "column", md: "row" } }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mr: { md: "50px", mobile: "0px" },
            mb: { md: "0px", mobile: "30px" },
          }}
        >
          <Box
            sx={{
              width: { md: "300px", mobile: "100%" },
              flexDirection: "column",
            }}
          >
            <Typography
              variant={"h4"}
              sx={{
                fontSize: { md: "30px !important", mobile: "26px !important" },
                whiteSpace: "pre-line",
                textAlign: { md: "start", mobile: "center" },
              }}
            >
              {t("explore.trendingBrands.title")}
            </Typography>
            <UI.Button
              type={"primary"}
              size={"small"}
              onClick={() => {
                history.push({
                  pathname: "/brand/explore",
                  state: { scrollToTop: true },
                });
              }}
              sx={{
                mt: "36px",
                width: "250px",
                height: "46px",
                display: { md: "flex", mobile: "none" },
              }}
              title={t("explore.trendingBrands.button.label")}
            />
          </Box>
        </Box>

        <Swiper
          grabCursor={true}
          slidesPerView={4}
          spaceBetween={100}
          slidesPerGroup={1}
          speed={1000}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false,
          }}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            100: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            1000: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            1100: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1250: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1400: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1600: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="trendingSwiper"
        >
          {!brandsBusy &&
            brands?.map((item) => (
              <SwiperSlide key={item.id}>
                <ImageItem item={item} onClick={(item) => onClick(item)} />
              </SwiperSlide>
            ))}
          {brandsBusy &&
            [0, 1, 2, 3].map((index) => (
              <SwiperSlide key={index}>
                <SkeletonItem />
              </SwiperSlide>
            ))}
        </Swiper>
        <Box
          sx={{
            display: { md: "none", mobile: "flex" },
            width: "100%",
            justifyContent: "center",
          }}
        >
          <UI.Button
            type={"primary"}
            size={"small"}
            onClick={() => {
              history.push({
                pathname: "/brand/explore",
                state: { scrollToTop: true },
              });
            }}
            sx={{
              mt: "40px",
              width: "90%",
              height: "46px",
              display: { md: "none", mobile: "flex" },
            }}
            title={t("explore.trendingBrands.button.label")}
          />
        </Box>
      </Box>
    </>
  );
};

export default Trending;
