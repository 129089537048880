import * as React from "react";
import MaterialSelect from "@mui/material/Select";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dropdownStyle: {
    backgroundColor: theme.palette.colors.darkGray + "!important",
    marginTop: "10px",
    borderRadius: "8px !important",
  },
  dropdownListStyle: {
    borderRadius: "10px !important",
    "& .Mui-selected": {
      backgroundColor: theme.palette.bgTransparent.primary + "!important",
      color: theme.palette.text.primary,
    },
    "& .MuiMenuItem-root": {
      color: theme.palette.text.secondary,
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: theme.palette.bgTransparent.primary,
      color: theme.palette.text.secondary,
    },
  },
  icon: {
    right: "30px",
    fontSize: "30px",
    color: theme.palette.text.primary + " !important",
  },
}));

const Select = (props) => {
  const classes = useStyles();
  const { value, defaultOpen, defaultValue, onChange, children, ...other } =
    props;

  return (
    <MaterialSelect
      sx={{
        color: (theme) => theme.palette.text.primary,
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        borderRadius: (theme) => theme.borderRadius.primary,
        width: "100%",
        fontFamily: "Open Sans",
        textAlign: "left",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "19px",
        height: "55px",
      }}
      MenuProps={{
        classes: {
          paper: classes.dropdownStyle,
          list: classes.dropdownListStyle,
        },
      }}
      defaultOpen={defaultOpen}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      displayEmpty
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
      {...other}
    >
      {children}
    </MaterialSelect>
  );
};

export default Select;
