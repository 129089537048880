import React from "react";
import Box from "@mui/material/Box";
import lockedIcon from "../../../../../../static/lock-icon.png";

const Locked = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 900,
        borderRadius: (theme) => theme.borderRadius.secondary
      }}
    >
      <Box
        component={"img"}
        src={lockedIcon}
        sx={{ height: "59px" }}
      />
    </Box>
  );
};
const Action = ({ element: Component, onDelete }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{ cursor: "pointer" }}
        component={"span"}
        onClick={() => setOpen(!open)}
      >
        {Component}
      </Box>

      <Locked
        open={open}
        onDelete={() => onDelete()}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};

Locked.Action = Action;

export default Locked;
