import React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import Auth from "../../auth";
import collectionIcon from "../../../static/collection-icon.png";
import collectionIconDark from "../../../static/collection-icon-dark.png";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { useTheme } from '@mui/material/styles';


const UserMenu = ({ user, openMenu, onClose }) => {
  const { t } = useTranslation();
  const { logout } = Auth.useAuth();
  const theme = useTheme();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    openMenu && setAnchorEl(openMenu);
  }, [openMenu]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          pt: "3px !important",
          pb: "7px !important",
          backgroundColor: (theme) => theme.palette.colors.darkGray, 
          "& .MuiAvatar-root": {
            width: 24,
            height: 24,
            ml: "8px",
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 18,
            width: 10,
            height: 10,
            bgcolor: (theme) => theme.palette.colors.darkGray,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() =>
          history.push({
            pathname: "/profile",
            state: { scrollToTop: true },
          })
        }
        sx={{ 
          p: "0 10px",
          background: (theme) => theme.palette.bgTransparent.primary,
          borderRadius: (theme) => theme.borderRadius.secondary,
          m: "2px 16px",
          transition: "all 0.2s ease-in-out",            
            "&:hover": {
              background: (theme) => theme.palette.bgTransparent.secondary,
              color: (theme) => theme.palette.text.primary,
            },
        }}
      >
        <Avatar
          sx={{
            transition: "all 0.2s ease-in-out",
            boxShadow: user?.email_verified ? "unset" : "0 0 0 1px #d32f2f",
            "&:hover": {
              boxShadow: user?.email_verified
                ? "0 0 0 1px #944DFF"
                : "0 0 0 1px #d32f2f",
            },
          }}
          src={user?.avatar_url}
        />{" "}
        <Typography 
          variant={"subtitle3"} 
          sx={{ 
            transition: "all 0.2s ease-in-out",            
            "&:hover": {
              color: (theme) => theme.palette.text.primary,
            },
          }}
        >
          {t("appBar.userMenu.profile")}
        </Typography>
      </MenuItem>
      <Divider
        sx={{
          borderColor: theme.palette.bgTransparent.primary,
          m: "27px 10px",
          borderWidth: "0.5px",
          mb: "10px !important"
        }}
      />
      <MenuItem
        onClick={() =>
          history.push({
            pathname: "/profile",
            state: { scrollToTop: true },
          })
        }
        sx={{ 
          minHeight: "42px",
          mx: "8px",
          borderRadius: "5px",
        }}
      >
        <ListItemIcon sx={{ mr: "7px" }}>
          {theme.palette.type === "dark" ? 
            <Box
              component={"img"}
              src={collectionIcon}
              sx={{ 
                width: "19px",
                opacity: "0.7",
              }}
            /> : 
            <Box
              component={"img"}
              src={collectionIconDark}
              sx={{ 
                width: "19px",
                opacity: "0.7",
            }}
          />
          }
        </ListItemIcon>
        <Typography 
          variant={"subtitle3"} 
          sx={{ 
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              color: (theme) => theme.palette.text.primary,
            },
          }}
        >
          {t("appBar.userMenu.myNFTs")}
        </Typography>
      </MenuItem>

      <MenuItem
        onClick={() =>
          history.push({
            pathname: "/profile/settings",
            state: { scrollToTop: true },
          })
        }
        sx={{ 
          minHeight: "42px",
          mx: "8px",
          borderRadius: "5px",
        }}
      >
        <ListItemIcon sx={{ mr: "7px" }}>
          <Settings 
            sx={{ 
              fontSize: "19px !important",
            }} 
          />
        </ListItemIcon>
        <Typography 
          variant={"subtitle3"} 
          sx={{ 
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              color: (theme) => theme.palette.text.primary,
            },
          }}
        >
          {t("appBar.userMenu.settings")}
        </Typography>
      </MenuItem>

      <MenuItem 
        onClick={() => logout()} 
        sx={{ 
          p: "0 10px",
          background: "#944dff",
          borderRadius: (theme) => theme.borderRadius.secondary,
          m: "0 20px",
          mt: "12px",
          minHeight: "38px",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            color: "white",
            background: "#b185f2",
          },          
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "0 20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mr: "7px",
            }}
          >
            <Logout sx={{ color: "white", width: "18px" }} />
          </Box>
          <Typography variant={"h8"} sx={{ color: "white"}} >
            {t("appBar.userMenu.logOut")}
          </Typography>
        </Box>
      </MenuItem>

    </Menu>
  );
};

export default UserMenu;
