import React from "react";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory, useLocation } from "react-router-dom";

import { useMenu, useUserRole } from "./context";
import { useUserContext } from "../../@components/userContext";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const Menu = ({ DAO, currentUrl }) => {
  const { t } = useTranslation();
  const { activeVotes } = useMenu();
  const { isBrandMember } = useUserRole();
  let location = useLocation();
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  const { user: loggedInUser } = useUserContext();

  React.useEffect(() => {
    if (DAO && location.pathname) {
      if (location.pathname.startsWith(`/brand/${DAO?.id}/feed`)) {
        setValue(1);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/album`)) {
        setValue(2);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/collection`)) {
        setValue(3);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/community`)) {
        setValue(4);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/tresuary`)) {
        setValue(5);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/vote`)) {
        setValue(6);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/membership`)) {
        setValue(7);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/settings`)) {
        //setValue(0);
      } else {
        setValue(0);
      }
    }
    // eslint-disable-next-line
  }, [DAO, location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        margin: "35px 0px",
        display: {
          md: "block",
          mobile: "none",
        },
      }}
    >
      {DAO ? (
        <Box sx={{ width: "100%" }}>
          <Tabs
            TabIndicatorProps={{
              sx: {
                backgroundColor: (theme) =>
                  !location.pathname.startsWith(`/brand/${DAO?.id}/settings`)
                    ? theme.palette.colors.primary
                    : "transparent",
              },
            }}
            variant={isBrandMember ? "scrollable" : "unset"}
            scrollButtons
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab
              index={"home"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) => location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? theme.palette.bgTransparent.tertiary + "!important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}`)}
              label={t("brands.menu.home")}
            />
            <Tab
              index={"feed"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) => location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? theme.palette.bgTransparent.tertiary + "!important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/feed`)}
              label={t("brands.menu.feed")}
            />
            <Tab
              index={"album"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) => location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? theme.palette.bgTransparent.tertiary + "!important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/album`)}
              label={t("brands.menu.albums")}
            />
            <Tab
              index={"collection"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) => location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? theme.palette.bgTransparent.tertiary + "!important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/collection`)}
              label={t("brands.menu.collections")}
            />
            <Tab
              index={"community"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) => location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? theme.palette.bgTransparent.tertiary + "!important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/community`)}
              label={t("brands.menu.community")}
            />

            <Tab
              index={"tresuary"}
              sx={{
                display:
                  isBrandMember && loggedInUser?.email_verified
                    ? "block"
                    : "none",
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) => location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? theme.palette.bgTransparent.tertiary + "!important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/tresuary`)}
              label={
                <Box sx={{ position: "relative" }}>
                  <Typography
                    variant={"subtitle4"}
                    sx={{
                      position: "absolute",
                      top: "-2.5px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      fontSize: "10px !important",
                      opacity: "0.5",
                      textTransform: "lowercase",
                    }}
                  >
                    admin
                  </Typography>
                  <Typography
                    component={"span"}
                    variant={"h8"}
                    sx={{
                      color: "inherit",
                    }}
                  >
                    {t("brands.menu.treasury")}
                  </Typography>
                </Box>
              }
            />
            <Tab
              index={"vote"}
              sx={{
                display:
                  isBrandMember && loggedInUser?.email_verified
                    ? "block"
                    : "none",
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) => location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? theme.palette.bgTransparent.tertiary + "!important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/vote`)}
              label={
                <Box sx={{ position: "relative" }}>
                  <Typography
                    variant={"subtitle4"}
                    sx={{
                      position: "absolute",
                      fontSize: "10px !important",
                      top: "-2.5px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      opacity: "0.5",
                      textTransform: "lowercase",
                    }}
                  >
                    admin
                  </Typography>

                  <Badge
                    sx={{
                      "& span": {
                        top: "2px",
                        right: "-12px",
                      },
                    }}
                    badgeContent={activeVotes}
                    color={"badgePrimary"}
                    invisible={activeVotes === 0}
                  >
                    <Typography
                      component={"span"}
                      variant={"h8"}
                      sx={{
                        color: "inherit",
                      }}
                    >
                      {t("brands.menu.vote")}
                    </Typography>
                  </Badge>
                </Box>
              }
            />

            <Tab
              index={"membership"}
              sx={{
                display:
                  isBrandMember && loggedInUser?.email_verified
                    ? "block"
                    : "none",
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) => location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? theme.palette.bgTransparent.tertiary + "!important"
                    : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/membership`)}
              label={
                <Box sx={{ position: "relative" }}>
                  <Typography
                    variant={"subtitle4"}
                    sx={{
                      position: "absolute",
                      top: "-2.5px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      fontSize: "10px !important",
                      opacity: "0.5",
                      textTransform: "lowercase",
                    }}
                  >
                    admin
                  </Typography>
                  <Typography
                    component={"span"}
                    variant={"h8"}
                    sx={{
                      color: "inherit",
                    }}
                  >
                    {t("brands.menu.membership")}
                  </Typography>
                </Box>
              }
            />
          </Tabs>
        </Box>
      ) : (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box sx={{ display: "flex" }}>
            <Skeleton variant="rectangular" width={145} height={70} />
            <Skeleton
              sx={{ ml: "2px" }}
              variant="rectangular"
              width={145}
              height={70}
            />
            <Skeleton
              sx={{ ml: "2px" }}
              variant="rectangular"
              width={145}
              height={70}
            />
            <Skeleton
              sx={{ ml: "2px" }}
              variant="rectangular"
              width={145}
              height={70}
            />
            <Skeleton
              sx={{ ml: "2px" }}
              variant="rectangular"
              width={145}
              height={70}
            />
            <Skeleton
              sx={{ ml: "2px" }}
              variant="rectangular"
              width={145}
              height={70}
            />
            <Skeleton
              sx={{ ml: "2px" }}
              variant="rectangular"
              width={145}
              height={70}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Menu;
